export default {
  dropoffsReportsRequested:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
}