<template>
  <footer class="TheFooter" :class="blueFooter">
    <div class="TheFooter__wrapper">
      <!-- <BaseLogo class="TheFooter__logo" color="white" variation="olist" /> -->
      <p class="TheFooter__tagline" :class="blueFooter">
        somos gente de verdade . somos todos donos . somos um só time . fazemos acontecer . vencemos do jeito certo . somos focados no sucesso dos nossos clientes
      </p>
    </div>
  </footer>
</template>

<script>
// import { BaseLogo } from '@/components/atoms'
export default {
  name: 'TheFooter',
  components: {
    // BaseLogo
  },
  computed: {
    blueFooter() {
      return { 'TheFooter--blue-footer' : this.$route.meta?.theme === 'blue'}
    }
  }
}
</script>

<style lang="scss" scoped>
.TheFooter {
  background-color: $color-neutral-stronger;
  height: inherit;
  position: relative;
  @media #{$mobile-view} {
    height: 120px;
  }
  &__wrapper {
    width: calc(100% - 40px);
    height: 100%;
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  &__tagline {
    color: $color-neutral-softest;
    font-size: $font-size-2xmini;
    font-weight: $font-weight-bold;
    opacity: 0.5;
  }
  &__logo{
    height: 15px;
    width: 38px;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }

  &--blue-footer {
    background-color: $color-primary-base;
    opacity: 1;
  }
}
</style>