export default {
  rg(value){
    value = value.replace(/(\d{2})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\w{3})(\w{1,2})$/, '$1-$2')
    return value.toUpperCase()
  },

  cpf(value, el) {
    if (el) {
      el.maxLength = 14
    }
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return value
  },

  cnpj(value, el) {
    if (el) {
      el.maxLength = 18
    }
    if (value) {
      value = value.toString()
      value = value.replace(/\D/g, '')
      value = value.replace(/^(\d{2})(\d)/, '$1.$2')
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
      value = value.replace(/(\d{4})(\d)/, '$1-$2')
    }
    return value
  },

  cpfCnpj(value, el) {
    if (typeof value != 'string') return ''
    
    value = value.replace(/\D/g, '')
    if (el) {
      el.maxLength = 18
    }
    if (value.length <= 11) {
      value = this.cpf(value)
    } else {
      value = this.cnpj(value)
    }
    return value
  },

  phone(value) {
    let _newValue = value.toString().replace(/\D/g, '')
    const _re = /^(?:0?(\d{2}))(9?\d{0,4})(\d{0,4})/.exec(_newValue)

    if (_re) {
      if (_re[2] && _re[2].length > 0) {
        _newValue = '(' + _re[1]
        _newValue += ') ' + _re[2]
      }
      if (_re[3] && _re[3].length > 0) {
        _newValue += '-' + _re[3]
      }
    }
    
    return _newValue.trim()
  },

  carPlate(value, el) {
    if (el) {
      el.maxLength = 8
    }
    value = value ? value.replace(/([a-zA-Z]{3})(\w)/, '$1-$2').toUpperCase() : ''
    return value
  },

  parsedDriver(value){
    if(!value) {
      return value
    }
    const parsed = value.split('-')
    const name = parsed[0].length > 15 ? `${parsed[0].substring(0, 15)}...` : parsed[0]

    const plate = this.carPlate(parsed[1], null)
    return `${name.trim()} ${plate}`
  },

  cep(value, el) {
    if(!value) {
      return ''
    }
    
    if (el) {
      el.maxLength = 9
    }
    value = value.toString()
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{5})(\d)/, '$1-$2')
    return value
  },

  letters(value) {
    value = value.replace(/[^a-zA-Z\s/]+$/g, '')
    return value
  },

  number(value) {
    if(!value) {
      return ''
    }

    value = value.toString().replace(/\D/g, '')
    return value
  },

  date(value, el) {
    if (el) {
      el.maxLength = 10
    }
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '$1/$2')
    value = value.replace(/(\d{2})(\d)/, '$1/$2')
    return value
  },

  millisecondsToDate(value){
    if(!value) {
      return ''
    }
    const formatedHour = new Intl.DateTimeFormat('pt-BR',{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).format(value)

    return formatedHour
  },

  remainingTime(value, data){
    if(!value || !data?.remainingTime) {
      return value || ''
    }
    let nowDate = new Date().getTime()
    let timePassedSinceCreated = Math.ceil((nowDate - value) / 1000)
    let remainingTime = data.remainingTime || 0
    remainingTime = (remainingTime - timePassedSinceCreated)
    return remainingTime <= 0 ? 'Expirado' : this.secondsToMinutes(remainingTime)
  },

  millisecondsToYear(value){
    if(!value) {
      return ''
    }
    const formatedHour = new Intl.DateTimeFormat('pt-BR',{
      year: 'numeric',
    }).format(value)

    return formatedHour
  },

  millisecondsToHour(value){
    if(!value) {
      return ''
    }
    const formatedHour = new Intl.DateTimeFormat('pt-BR',{
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).format(value)

    return formatedHour
  },

  millisecondsToDateHour(value){
    if(!value) {
      return ''
    }
    const formatedHour = new Intl.DateTimeFormat('pt-BR',{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(value)

    return formatedHour
  },

  secondsToMinutes(time) {
    let hours = parseInt(time / (60*60))
    let minutes = parseInt(time / 60 % 60)
    let seconds = time % 60

    var formatted = (x) => (x < 10) ? ('0' + x) : x
    return ((time >= 60*60) ? (formatted(hours) + 'h ') : '') +
           ((time >= 60) ? (formatted(minutes) + 'min ') : '') +
           formatted(seconds) + 's'
  },

  hour(value, el) {
    if (el) {
      el.maxLength = 5
    }
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, '$1:$2')
    return value
  },

  money(value) {
    let result
    let originalValue = value
    
    if (typeof value === 'number') {
      value = String(value)
    }
    if (typeof value !== 'string') {
      return value
    }

    value = value.replace(/\D/g,'')
    value = String(Number(value))

    if (value.length === 1) {
      value = value.replace(/(\d)/,'0.0$1')
    } else if (value.length === 2) {
      value = value.replace(/(\d)/,'0.$1')
    } else if (value.length > 2) {
      value = value.replace(/(\d{2})$/,'.$1')
    }
    
    value = value.replace(/\D/g, '')
    value = value.replace(/([0-9]{2})$/g, ',$1')

    if (value.length > 6) {
      value = value.replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, '$1.')
    }

    if (value.length === 0) {
      result = value
    } else {
      if (Math.sign(originalValue) === -1) {
        result = 'R$  -' + value
      } else {
        result = 'R$ ' + value
      }
    }

    return result
  },

  numberHyphen(value) {
    if(!value) {
      return ''
    }
    value = value.toString().replace(/[^0-9-]/g, '')
    if (value.indexOf('--') >= 0) {
      value = value.replace('--', '-')
    }
    if (value.split('-').length > 2) {
      value = value.replace('-', '')
    }
    if (value.indexOf('-') === 0) {
      value = value.substring(1)
    }
    
    return value
  },

  account(value) {
    return this.numberHyphen(value)
  },

  agency(value) {
    return this.numberHyphen(value)
  },

  percentage(value){

    return value.toFixed(2) + '%'
  },

  check(value) {
    return value ? '✔' : '✖︎'
  },

  assignLineColor(value) {
    return value
  },

  warning(value) {
    return value
  },

  stores(value) {
    if(value.length === 0) {
      return ''
    }
    const stores = value.map((store) => {
      return store.name
    })
    return stores.join(', ')
  },

  volume(value) {
    return `${value} m³`
  },

  translateProductStatus(value) {
    let status_list = {
      analysis: 'Em análise',
      canceled: 'Cancelado',
      collected_out_of_spot: 'Coletado fora do ponto',
      collected: 'Coletado',
      dispatched: 'Despachado',
      dropoff_to_hub: 'Em transferência para o Hub',
      lost: 'Extraviado',
      not_bleeped: 'Não bipado',
      pending: 'Pendente',
      received_dropoff: 'Recebido no Dropoff',
      received: 'Recebido',
    }
    return status_list[value] || value
  },
}