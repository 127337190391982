export default {
  setError({ commit }, error) {
    commit('SET_ERROR', error)
  },
  setErrorAdditional({ commit }, errorAdditional) {
    commit('SET_ERROR_ADDITIONAL', errorAdditional)
  },
  setAlert({ commit }, alert) {
    commit('SET_ALERT', alert)
  }
}