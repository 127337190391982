<template functional>
  <transition name="TheError--anim">
    <div v-if="props.error" class="TheError">
      <component
        :is="$options.components['Dialog']"
        v-show="props.error"
        class="TheError__dialog"
        icon="close"
        :warning="props.error"
        :additional="props.errorAdditional"
        label-cancel=""
        @cancel="listeners['removeError']"
        @confirm="listeners['removeError']"
      />
    </div>
  </transition>
</template>

<script>
import { Dialog } from '@/components/molecules'

export default {
  name: 'TheError',
  components: {
    Dialog
  },
  props: {
    error: {
      type: String,
      required: true
    },
    errorAdditional: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.TheError {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: $z-modal;
  position: fixed;

  &--anim {
    &-enter-active {
      transition: all .2s ease-out;
    }
    &-leave-active {
      transition: all .2s ease-out;
    }
    &-enter {
      opacity: 0;
    }
    &-leave-to {
      opacity: 0;
    }
  }
}
</style>
<style lang="scss">
.TheError {
  .Dialog {
    &--additionalActive {
      max-width: 720px;
    }
  }
}
</style>
