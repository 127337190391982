import { transporters } from '@/api'

export default {
  async fetchTransporters({ commit }, status_id = 1) {
    return await transporters.fetchTransporters(status_id, (res) => commit('SET_TRANSPORTERS', res.data) , (e) => e)
  },

  async fetchTransportersAsync({ commit, rootGetters }, status_id) {
    return await transporters.fetchTransporters(status_id, (res) => {
      commit('SET_TRANSPORTERS', res.data)
      return rootGetters.treeSelectModel('transporters', 'transporters')
    }, (e) => e)
  },

  async fetchTransportersTransfer({ commit }) {
    return await transporters.fetchTransportersTransfer((res) => commit('SET_TRANSPORTERS_TRANSFER', res) , (e) => e)
  },

  async fetchStoresTransporter({ commit }, id) {
    return await transporters.fetchStoresTransporter({id}, (res) => commit('SET_STORES_TRANSPORTERS', res) , (e) => e)
  },

  async fetchTransportersTable({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await transporters.fetchTransportersTable(params, (res) =>
      commit('SET_TRANSPORTERS_TABLE', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchTransportersDrivers({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return transporters.fetchTransportersDrivers(params, (res) =>
      commit('SET_TRANSPORTERS_DRIVERS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async bindDriversToTransporters({ commit }, body) {
    this.dispatch('loading/setLoading', true)
    return await transporters.bindDriver(body, (res) => 
      commit('UNSHIFT_TRANSPORTERS_DRIVERS', res), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async unBindDriversToTransporters({ commit }, body) {
    this.dispatch('loading/setLoading', true)
    return await transporters.unBindDriver(body, () => 
      commit('REMOVE_TRANSPORTERS_DRIVERS', body.driverId), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async fetchTransferHistoryTable({ commit }, params = {}) {
    this.dispatch('loading/setLoading', true)
    return await transporters.fetchTransferHistoryTable(params, (res) =>
      commit('SET_TRANSPORTER_TRANSFER_HISTORY_TABLE', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchModalTransferHistory({ commit }, params = {}) {
    this.dispatch('loading/setLoading', true)
    return await transporters.fetchModalTransferHistory(params, (res) =>
      commit('SET_MODAL_TRANSFER_HISTORY', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },
}