export default {
  access_token: null,
  expires_at: null,
  expired: false,
  user: {
    name: '',
    email: ''
  },
  permissions: {},
  permissionsModules: [],
  permissionsList: [],
  hasPermissionChanges: false
}