<template>
  <div class="BaseTag" :title="value">
    <div class="BaseTag__value">
      <span>{{ parsedValue }}</span>
    </div>
    <div v-if="!readOnly" class="BaseTag__close" @click="close">
      <Icon name="close" class="BaseTag__icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTag',
  props: {
    value:{
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      characterLimit: 30
    }
  },
  computed:{
    parsedValue(){
      // return this.value.length < this.characterLimit ? this.value : `${this.value.slice(0, this.characterLimit)}...`
      return this.value
    }
  },
  methods: {
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
$tagSpace:5px;
.BaseTag{
  background-color: $color-primary-base;
  color: $color-neutral-softest;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  &__value{
    margin-right: $tagSpace;
    margin-left: $tagSpace;
    display: flex;
    align-items: center;
    span{
      font-size: $font-size-mini;
    }
  }
  &__close{ 
    padding: 3px 8px;
    cursor: pointer;
    display: flex;
    cursor: pointer;
    height: 100%;
    align-items: center;
    
    .BaseTag__icon{
      filter: $filter-white;
    }
    &:hover{
      background-color: $color-neutral-stronger;
    }
  }
  &__icon{
    width: 10px;
  }
}
</style>