const Routes = () => import( /* webpackChunkName: 'Routes' */ './Routes.vue')
const RoutesForm = () => import( /* webpackChunkName: 'RoutesForm' */ './Form/RoutesForm.vue')
const RoutEasySync = () => import( /* webpackChunkName: 'RoutEasySync' */ './RoutEasySync/RoutEasySync.vue')
const RoutesTransfer = () => import( /* webpackChunkName: 'RoutesTransfer' */ './Transfer/Transfer.vue')
const CteManagement = () => import( /* webpackChunkName: 'CteManagement' */ './CteManagement/CteManagement.vue')
const CteDetails = () => import( /* webpackChunkName: 'CteDetails' */ './CteManagement/Cte/CteDetails.vue')
const CteDetailsEdit = () => import( /* webpackChunkName: 'CteDetailsEdit' */ './CteManagement/Cte/CteDetailsEdit.vue')
const MdfeDetails = () => import( /* webpackChunkName: 'MdfeDetails' */ './CteManagement/Mdfe/MdfeDetails.vue')
const HubControl = () => import( /* webpackChunkName: 'HubControl' */ './Control/HubControl.vue')
const HubControlForm = () => import( /* webpackChunkName: 'HubControlForm' */ './Control/Form/HubControlForm.vue')

const routes = [
  //Routes
  {
    name: 'routes',
    meta:{
      label: 'Rota',
      module: 'module_routes'
    },
    path: '/routes',
    component: Routes
  },
  {
    name: 'routesAdd',
    meta:{
      label: 'Rota',
      module: 'module_routes'
    },
    path: '/routes/add',
    component: RoutesForm
  },

  //RoutEasy
  {
    name: 'routEasySync',
    meta:{
      label: 'Rota',
      module: 'module_stores'
    },
    path: '/routes/routeasy/sync',
    component: RoutEasySync
  },

  //CTe Management
  {
    name: 'cteManagement',
    meta:{
      label: 'Gestão de CTe',
      module: 'module_routes'
    },
    path: '/routes/management',
    component: CteManagement,
  },
  {
    name: 'cteDetailsEdit',
    meta:{
      label: 'Detalhes da CTe',
      module: 'module_routes'
    },
    path: '/routes/management/cte/:route_id/edit/:cte_id',
    component: CteDetailsEdit
  },
  {
    name: 'cteDetails',
    meta:{
      label: 'Detalhes da CTe',
      module: 'module_routes'
    },
    path: '/routes/management/cte/:route_id',
    component: CteDetails
  },
  {
    name: 'mdfeDetails',
    meta:{
      label: 'Detalhes da MDFe',
      module: 'module_routes'
    },
    path: '/routes/management/mdfe/:id',
    component: MdfeDetails
  },

  //Hub
  {
    name: 'hubControl',
    meta:{
      label: 'Controle de HUB',
      module: 'module_routes'
    },
    path: '/routes/hub',
    component: HubControl
  },
  {
    name: 'hubControlAdd',
    meta:{
      label: 'Cadastro de HUB',
      module: 'module_routes'
    },
    path: '/routes/hub/add',
    component: HubControlForm
  },
  {
    name: 'hubControlDetail',
    meta:{
      label: 'Edição de HUB',
      module: 'module_routes'
    },
    path: '/routes/hub/:id',
    component: HubControlForm
  },
  {
    name: 'routesTransfer',
    meta:{
      label: 'Nova Transferência',
      module: 'module_routes'
    },
    path: '/routes/transfer',
    component: RoutesTransfer
  },

]

export default routes