export default {
  SET_DRIVERS(state, drivers) {
    state.drivers = drivers
  },

  DELETE_DRIVER(state, deletedDriver){
    state.drivers.data.content = state.drivers.data.content.filter((driver) => driver.id !== deletedDriver.id)
  },

  SET_DRIVERS_TRACKING(state, data){
    state.driversTracking = data
  },

  SET_DRIVERS_OBSERVATIONS(state, driversObservations) {
    state.driversObservations = driversObservations
  },

  SET_VEHICLE_BRANDS(state, payload) {
    state.vehicleBrands = payload
    state.vehicleModels = []
  },
  SET_VEHICLE_MODELS(state, payload) {
    state.vehicleModels = payload
  },
  SET_BANKS(state, payload) {
    state.banks = payload
  },
  SET_BANK_TYPES(state, payload) {
    state.bankTypes = payload
  },
  SET_DRIVERS_VEHICLES(state, payload) {
    state.vehicles = payload
  }
}
