<template>
  <div class="Files__Item" :style="style">
    <BaseInputFileUploaded :file="file" :multiple="multiple" :list="list" :actions="actions" />
    <div class="Files__Item__Information">
      <div class="Files__Item__Name">
        <p>{{ file.name }}</p>
      </div>
      <div class="Files__Item__Close" @click="confirmRemove(file)">
        <Icon name="close" color="white" class="Files__Icon" />
      </div>
      <div v-if="hasProgress" class="Files__Item__Progress">
        <span class="Files__Item__Progress--bar" />
        <span class="Files__Item__Progress--size">{{ sizeProgressText }}</span>
        <span class="Files__Item__Progress--percent">{{ percentProgressText }} </span>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInputFileUploaded from './BaseInputFileUploaded'
export default {
  name: 'BaseInputFileListItem',
  components: {
    BaseInputFileUploaded
  },
  props: {
    file: {
      type: Object,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Boolean,
      default: false
    },
    hasProgress: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sizeUnit: 'MB',
      sizeValue: 0,
      progress: 0,
      timeProgress: 10,
    }
  },
  computed: {
    //Styles
    style: function() {
      return {
        '--progress': this.percent + '%',
      }
    },

    //Texts
    sizeProgressText() {
      return `${this.progress} de ${this.sizeValue} ${this.sizeUnit}`
    },
    percentProgressText() {
      return this.percent < 100 ? this.percent + '%' : 'Concluído'
    },

    percent() {
      return parseInt((100*this.progress)/this.sizeValue)
    }
    
  },
  mounted() {
    this.setProgress()
  },
  methods: {

    confirmRemove(data) {
      this.$emit('confirmRemove', data)
    },
    
    handleRemove(data) {
      this.$emit('remove', data)
    },

    //Progress
    setProgress() {
      this.handleSizeUnit()
      this.handleProgress()
    },

    handleSizeUnit() {
      const fSExt = new Array('Bytes', 'KB', 'MB', 'GB')
      const sizeInBytes = this.file.size
      let fSize = sizeInBytes
      let i=0
      while(fSize>900) {
        fSize/=1024
        i++
      }

      this.sizeUnit = fSExt[i]
      this.sizeValue = (Math.round(fSize*100)/100)
    },

    handleProgress() {
      let setIntervalRef = setInterval(() => {
        this.progress++
        if (this.progress >= this.sizeValue) {
          this.progress = this.sizeValue
          clearInterval(setIntervalRef)
        }
      }, this.timeProgress)
    },
      
  }
}
</script>
<style lang="scss" scoped>
.Files {
  &__Item {
    display: flex;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;

    &:last-of-type {
      border: none;
    }

    &__Information {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-left: 10px;
    }

    &__Name {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      max-width: 90%;

      p {
        word-break: break-all;
      }
    }

    &__Close {
      cursor: pointer;
      padding: 5px;
      background-color: $color-neutral-stronger;
      max-height: 26px;

      .Icon {
        width: 15px;
        display: block;
      }
    }

    &__Progress {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 15px;

      &--bar {
        position: relative;
        width: 100%;
        height: 10px;
        margin-bottom: 5px;

        &::before,
        &::after {
          content: '';
          width: 100%;
          height: 10px;
          position: absolute;
          top: 0;
          left: 0;
        }

        &::before {
          background-color: #e3e3e3;
        }

        &::after {
          width: var(--progress);
          background-color: $color-primary-base;
        }
      }

      &--size {
        color: $color-neutral-stronger;
        font-weight: $font-weight-bold;
        @media (max-width: $viewport-xs) {
          order: 3;
        }
      }

      &--percent {
        color: $color-primary-base;
        font-weight: $font-weight-bold;
      }
    }
  }
}
</style>
