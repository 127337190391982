const Financial = () => import('./Financial.vue')
const EditUploadedHistory = () => import('./EditUploadedHistory/EditUploadedHistory.vue')
const HistoryUploadSheets = () => import('./HistoryUploadSheets/HistoryUploadSheets.vue')
const FinancialConsolidatedOperations = () => import('./FinancialConsolidatedOperations/FinancialConsolidatedOperations.vue')
const FinancialConsolidated = () => import('./FinancialConsolidated/FinancialConsolidated.vue')
const Fortnight = () => import('./Fortnight/Fortnight.vue')
const AnalystReview = () => import('./AnalystReview/AnalystReview.vue')
const MasterManagerApprove = () => import('./MasterManagerApprove/MasterManagerApprove.vue')
const Receipts = () => import('./Receipts/Receipts.vue')
const CreatePaymentClosingHistory = () => import('./CreatePaymentClosingHistory/CreatePaymentClosingHistory.vue')
const PaymentClosingHistory = () => import('./PaymentClosingHistory/PaymentClosingHistory.vue')
const FinancialOnlineErrors = () => import('./FinancialOnlineErrors/FinancialOnlineErrors.vue')
const EditVehicles = () => import('./EditVehicles/EditVehicles.vue')
const FinancialApprove = () => import('./FinancialApprove/FinancialApprove.vue')
const routes = [
  {
    path: '/financial',
    name: 'financial',
    meta: {
      module: 'module_financial'
    },
    component: Financial,
    children: [
      {
        path: 'edit-upload/:id',
        name: 'editUploadedHistory',
        meta: {
          module: 'module_financial'
        },
        component: EditUploadedHistory
      },
      {
        path: 'history-upload-sheets',
        name: 'historyUploadSheets',
        meta: {
          module: 'module_financial',
          tagName: 'History Upload Sheets'
        },
        title: 'Histórico <br>Uploads',
        isTab: true,
        component: HistoryUploadSheets,
      },
      {
        path: 'financial-consolidated-operations',
        name: 'financialConsolidatedOperations',
        meta: {
          module: 'module_financial',
          tagName: 'Financial Consolidated Operations'
        },
        title: 'Consolidado <br>Operação',
        isTab: true,
        component: FinancialConsolidatedOperations,
      },
      {
        path: 'financial-consolidated',
        name: 'financialConsolidated',
        meta: {
          module: 'module_financial',
          tagName: 'Financial Consolidated'
        },
        title: 'Consolidado <br>Financeiro',
        isTab: true,
        component: FinancialConsolidated
      },
      {
        path: 'fortnight/:id',
        name: 'fortnight',
        meta: {
          module: 'module_financial'
        },
        component: Fortnight
      },
      {
        path: 'analyst-review',
        name: 'analystReview',
        meta: {
          module: 'module_financial',
          tagName: 'Analyst Review'
        },
        title: 'Revisão <br>Analista',
        isTab: true,
        component: AnalystReview
      },
      {
        path: 'master-manager-approve',
        name: 'masterManagerApprove',
        meta: {
          module: 'module_financial',
          tagName: 'Master Manager Approve'
        },
        title: 'Aprovação <br>Gestores',
        isTab: true,
        component: MasterManagerApprove
      },
      {
        path: 'financial-approve',
        name: 'financeApprove',
        meta: {
          module: 'module_financial',
          tagName: 'Financial Approve'
        },
        title: 'Aprovação <br>Financeiro',
        isTab: true,
        component: FinancialApprove
      },
      {
        path: 'receipts',
        name: 'receipts',
        title: 'Comprovantes',
        meta: {
          module: 'module_financial',
          tagName: 'Receipts'
        },
        isTab: true,
        component: Receipts
      },
      {
        path: 'create-payment-closing-history',
        name: 'createPaymentClosingHistory',
        meta: {
          module: 'module_financial',
          tagName: 'Create Payment Closing History'
        },
        title: 'Criar fechamento <br>de pagamento',
        isTab: true,
        component: CreatePaymentClosingHistory
      },
      {
        path: 'payment-closing-history',
        name: 'paymentClosingHistory',
        meta: {
          module: 'module_financial',
          tagName: 'Payment Closing History'
        },
        title: 'Histórico de <br>fechamentos',
        isTab: true,
        component: PaymentClosingHistory
      },
      {
        path: 'financial-online-errors',
        name: 'financialOnlineErrors',
        meta: {
          module: 'module_financial'
        },
        component: FinancialOnlineErrors
      },
      {
        path: 'edit-vehicles',
        name: 'editVehicles',
        meta: {
          module: 'module_financial'
        },
        component: EditVehicles
      }
    ]
  },
]
export default routes