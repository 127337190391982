export default {
  occurrencesRealTime: {
    data:{
      headers: {},
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  occurrencesHistory: {
    data:{
      headers: {},
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  occurrencesStatusList: [],
  occurrencesConfig: {
    standardAttendanceTime: null,
    storesConfig: [],
    storesConfigTable: {
      content: [],
      headers: {
        id: {
          id: 'id',
          param: 'id',
          method: false,
          label: 'ID',
          isVisible: false
        },
        name: {
          id: 'name',
          param: 'name',
          label: 'Lojistas',
          method: false,
          isVisible: true
        },
        slaInput: {
          id: 'slaInput',
          param: 'slaInput',
          label: 'SLA',
          method: 'datepicker',
          isVisible: true
        },
        actions: {
          id: 'actions',
          param: 'actions',
          label: 'Ações',
          method: false,
          actions: true,
          isVisible: true
        },
      },          
    },
  },
}