import request from '@/api/plugins/request'

export default {

  async uploadFile(body, success, error, done) {
    return await request({
      method: 'post',
      url: '/photos/upload',
      body
    }, success, error, done)
  },

  async postFiles(payload, success, error, done) {
    const body = payload.files
    return await request({
      method: 'post',
      url: `/orders/${payload.id}/attachments`,
      body
    }, success, error, done)
  },

  async fetchFiles(id, success, error, done) {
    return await request({
      method: 'get',
      url: `/orders/${id}?with[]=attachments`,
    }, success, error, done)
  },

  async deleteFiles(body, success, error, done) {
    return await request({
      method: 'delete',
      url: `/orders/${body.order_id}/attachments/${body.id}`,
    }, success, error, done)
  }
}