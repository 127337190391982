export default {
  sellersVipsImportTableWithLoading(state){
    return state.sellersVipsImportTable.data.content.map((s) => {
      const i = s
      if (i['status.name'] === 'Andamento') {
        i.actions = [{action_id: 'loading', has_permission: true}]
      }
      return i
    })
  },
}