<template>
  <div class="BaseTimeline">
    <div
      v-for="item in items"
      :key="item.name"
      class="BaseTimeline__Item"
      :class="`BaseTimeline__Item--${item.theme}`"
    >
      <div
        class="BaseTimeline__Item__Circle"
        :class="`BaseTimeline__Item__Circle--${item.theme}`"
      >
        <Icon
          class="BaseTimeline__Item__Circle__Icon"
          :class="`BaseTimeline__Item__Circle__Icon--${item.theme}`"
          :name="item.icon"
        />
      </div>
      <div class="BaseTimeline__Item__Text">
        <strong>{{ item.name }}</strong>
        <span>{{ item.date }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTile',
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.BaseTimeline {
  display: flex;
  flex-direction: column;
  gap: 42px;

  &__Item {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    
    &:after {
      content: '';
      display: block;
      width: 3px;
      height: 42px;
      background: $color-neutral-soft;
      position: absolute;
      top: 100%;
      left: 20px;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    &--primary:after,
    &--primary-softer:after {
      background: $color-primary-base
    }
    &--alert:after,
    &--alert-softer:after {
      background: $color-alert-soft
    }
    &--success:after,
    &--success-softer:after {
      background: $color-success-soft
    }
    &--neutral:after,
    &--neutral-softer:after {
      background: $color-neutral-soft
    }

    &__Circle {
      width: 42px;
      height: 42px;
      background: #eee;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &--primary { background: $color-primary-base }
      &--primary-softer { background: $color-primary-softer }
      &--alert { background: $color-alert-soft }
      &--alert-softer { background: $color-alert-softer }
      &--success { background: $color-success-soft }
      &--success-softer { background: $color-success-softer }
      &--neutral { background: $color-neutral-base }
      &--neutral-softer { background: $color-neutral-softer }

      &__Icon {
        height: 18px;
        &--primary { filter: invert(100%) }
        &--primary-softer { filter: $filter-primary-base brightness(85%); }
        &--alert { filter: invert(100%) }
        &--alert-softer {filter: $filter-red brightness(85%); }
        &--success { filter: invert(100%) }
        &--success-softer { filter: $filter-green brightness(85%);}
        &--neutral { filter: invert(100%) }
      }
    }

    &__Text {
      display: flex;
      flex-direction: column;
      line-height: 120%;
      font-size: 14px;
    }
  }
}
</style>