export default {
  SET_SELLERS(state, sellers) {
    state.sellers = sellers
  },

  SET_SELLERS_TABLE(state, sellersTable) {
    state.sellersTable = sellersTable
  },

  SET_SELLERS_VIPS_IMPORT_TABLE(state, sellersVipsImportTable) {
    state.sellersVipsImportTable = sellersVipsImportTable
  },

  SET_SELLERS_VIPS_IMPORT_ERRORS(state, sellersVipsImportErrors) {
    state.sellersVipsImportErrors = sellersVipsImportErrors
  },

  SET_SELLERS_PARENTS(state, sellersParents) {
    state.sellersParents = sellersParents
  },

}
