import state from './state.sellers'
import getters from './getters.sellers'
import mutations from './mutations.sellers'
import actions from './actions.sellers'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}