export default {
  storesByGroup(state) {
    let storesByGroup = []

    // Create groups with stores without parent_id
    state.stores.map((store) => {
      if (store.parent_id === null) {
        storesByGroup.push({
          id: store.id,
          name: store.name,
          stores: []
        })
      }
    })

    // Create groups with stores with parent_id
    state.stores.map((store) => {
      if (store.parent_id !== null && storesByGroup.filter(group => group.id === store.parent_id.id).length === 0) {
        storesByGroup.push({
          id: store.parent_id.id,
          name: store.parent_id.name,
          stores: []
        })
      }
    })
    
    // Fill stores in groups
    state.stores.map((store) => {
      if (store.parent_id != null) {
        storesByGroup.map((group, index) => {
          if (group.id === store.parent_id.id) {
            storesByGroup[index].stores.push(store)
          }
        })
      }
    })

    return storesByGroup
  },

  storesWithoutParents(state) {
    return state.stores.filter((store) => store.parent_id !== null)
  },

  parentsStores(state) {
    let parents = []

    const parentIds = new Set()

    for (let index = 0; index < state.stores.length; index++) {
      const store = state.stores[index]
      const parentId = store?.parent_id?.id

      if(!parentId || parentIds.has(parentId)) continue

      parents.push({ ...store.parent_id })
      parentIds.add(parentId)
    }

    return parents
  }
}