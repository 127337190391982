<template>
  <div class="InputAddress" :style="applyGrid">
    <InputGroup
      :ref="fields.zip"
      :value="model[fields.zip]"
      :read-only="disabled"
      label="CEP"
      class="InputAddress__Zip"
      autocomplete="no"
      mask="cep"
      :is-invalid="isInvalid(fields.zip)"
      invalid-label="CEP inválido"
      @input="onZipInput"
      @debounce="searchZip"
      @blur="setInvalidField(fields.zip, ['required', 'cep'])"
    />
    <InputGroup
      :ref="fields.address"
      :value="model[fields.address]"
      :read-only="addressFound.address && blockFields || disabled"
      class="InputAddress__Address"
      label="Endereço" 
      data-test="address"
      :is-invalid="isInvalid(fields.address)"
      invalid-label="Insira um endereço"
      @input="updateInput(fields.address, $event)"
      @blur="setInvalidField(fields.address, ['required'])"
    />
    <InputGroup
      :ref="fields.number"
      :value="model[fields.number]"
      :read-only="!addressFound && blockFields || disabled"
      class="InputAddress__Number"
      mask="number"
      label="Número"
      data-test="number"
      :is-invalid="isInvalid(fields.number)"
      invalid-label="Insira um número"
      @input="updateInput(fields.number, $event)"
      @blur="setInvalidField(fields.number, allowEmptyNumber ? [] : ['required'])"
    />
    <InputGroup
      :ref="fields.complement"
      :value="model[fields.complement]"
      :read-only="!addressFound && blockFields || disabled"
      class="InputAddress__Complement"
      label="Complemento"
      data-test="complement"
      :is-invalid="isInvalid(fields.complement)"
      @input="updateInput(fields.complement, $event)"
    />
    <InputGroup
      :ref="fields.neighborhood"
      :value="model[fields.neighborhood]"
      :read-only="addressFound.neighborhood && blockFields || disabled"
      class="InputAddress__Neighborhood"
      label="Bairro"
      data-test="neighborhood"
      :is-invalid="isInvalid(fields.neighborhood)"
      invalid-label="Insira um bairro"
      @input="updateInput(fields.neighborhood, $event)"
      @blur="setInvalidField(fields.neighborhood, ['required'])"
    />
    <InputGroup
      :ref="fields.city"
      :value="model[fields.city]"
      :read-only="addressFound.city && blockFields || disabled"
      class="InputAddress__City"
      label="Cidade"
      autocomplete="no"
      data-test="city"
      :is-invalid="isInvalid(fields.city)"
      invalid-label="Insira um Cidade"
      @input="updateInput(fields.city, $event)"
      @blur="setInvalidField(fields.city, ['required'])"
    />
    <BaseTreeSelect 
      :ref="fields.state_id"
      :value="model[fields.state_id]"
      :read-only="addressFound.state_id && blockFields || disabled"
      name="Estado"
      label="Estado"
      data-test="state"
      :is-invalid="isInvalid(fields.state_id)"
      invalid-label="Insira um Estado"
      class="InputAddress__State"
      :clearable="false"
      :select-options="states"
      @input="updateInput(fields.state_id, $event)"
      @blur="setInvalidField(fields.state_id, ['required'])"
    />
  </div>
</template>

<script>
import { address } from '@/api'
import { mapState, mapActions } from 'vuex'
import { BaseTreeSelect } from '@/components/atoms'
import { InputGroup } from '@/components/molecules'
import Rules from '@doc88/flux-validator-js'

export default {
  name: 'InputAddress',
  components: {
    BaseTreeSelect,
    InputGroup
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true,
      validator: item => {
        return (
          Object.prototype.hasOwnProperty.call(item, 'zip') &&
          Object.prototype.hasOwnProperty.call(item, 'address') &&
          Object.prototype.hasOwnProperty.call(item, 'number') &&
          Object.prototype.hasOwnProperty.call(item, 'complement') &&
          Object.prototype.hasOwnProperty.call(item, 'neighborhood') &&
          Object.prototype.hasOwnProperty.call(item, 'city') &&
          Object.prototype.hasOwnProperty.call(item, 'state_id')
        )
      }
    },
    grid: {
      type: String,
      default: 'Zip City City Neighborhood Neighborhood Address Address Address Number Complement Complement State'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    blockFields: {
      type: Boolean,
      default: true
    },
    allowEmptyNumber: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addressFound: {
        city: false,
        neighborhood: false,
        address: false,
        state_id: false
      },
      invalidFields: []
    }
  },
  computed: {
    ...mapState({
      states: state => state.address.states
    }),

    applyGrid() {
      return `grid-template-areas: "${this.grid}";`
    }
  },
  mounted() {
    this.fetchStates()
  },
  methods: {
    ...mapActions({
      fetchStates: 'address/fetchStates'
    }),

    isValidField(rules, value){
      return !rules.filter(rule => !Rules[rule](rule, value).valid).length
    },

    setInvalidField(field, rules) {
      const isValid = this.isValidField(rules, this.model[field])
      if (isValid) {
        this.invalidFields = this.invalidFields.filter(i => i !== field)
      } else {
        if (!this.invalidFields.includes(field)) this.invalidFields.push(field)
      }
    },

    isInvalid(field) {
      return this.invalidFields.includes(field)
    },

    emitInput(obj) {
      this.$emit('setAddress', obj)
      this.$emit('blur')
    },

    focusOnNumber() {
      this.$refs[this.fields.number].setFocus()
    },

    onBlur(e) {
      this.$emit('blur', e)
    },

    onZipInput(e) {
      this.updateInput(this.fields.zip, e)
      if (this.addressFound) this.$nextTick(() => this.resetInputs())
    },

    updateInput(field, e) {
      this.emitInput({ ...this.model, [field]: e })
    },

    resetAddressFound() {
      this.addressFound = {
        city: false,
        neighborhood: false,
        address: false,
        state_id: false
      }
    },

    resetInputs() {
      const obj = {}
      for (let i in this.fields) {
        const field = this.fields[i]
        if (field !== this.fields.zip) obj[field] = null
      }
      const newObj = { ...this.model, ...obj}

      this.resetAddressFound()
      this.emitInput(newObj)
    },

    async fetchAddress(cep) {
      return await address.fetchAddress({ cep, hideErrors: !this.blockFields }, (res) => res, (e) => e)
    },

    async searchZip(val) {
      this.setInvalidField(this.fields.zip, ['required', 'cep'])
      if (this.isInvalid(this.fields.zip)) return

      let address = {}
      let foundAddress = await this.fetchAddress(val)
      if (!foundAddress.zip) return this.resetAddressFound()
      this.addressFound = {
        city: !!foundAddress.city,
        neighborhood: !!foundAddress.neighborhood,
        address: !!foundAddress.address,
        state_id: !!foundAddress.state_id
      },

      foundAddress.state_id = this.states.filter((state) => state.id === foundAddress.state_id.id)[0]

      // map address to provided inputs
      for (let i in foundAddress) {
        address[this.fields[i]] = foundAddress[i]
      }

      this.emitInput({ ...this.model, ...address })
      this.$nextTick(() => this.focusOnNumber())
    }
  }
}
</script>

<style lang="scss" scoped>
.InputAddress {
  display: grid;
  grid-column-gap: 20px;

  &__Zip          { grid-area: Zip; }
  &__Address      { grid-area: Address; }
  &__Number       { grid-area: Number; }
  &__Complement   { grid-area: Complement; }
  &__Neighborhood { grid-area: Neighborhood; }
  &__City         { grid-area: City; }
  &__State        { grid-area: State; width: 100px; }
}
</style>