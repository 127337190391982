export default {
  paymentClosingHistory: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  paymentImportErrors: [],
  
  paymentUploadHistory: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  operationConsolidate: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  masterManagerApprove: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  financialApprove: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  analystsComments: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  financialConsolidate: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  analystReview: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  fortnight: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  uploadHistoryEdit: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  duplicateHistoryLines: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  statusList: [],

  onlineErrors: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  receipts: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  vehiclesEdit: {
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: {}
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },

  vehiclesTypes: [],
  fieldErrorsList: []
}
