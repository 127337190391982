<template>
  <header class="TheHeader">
    <div class="TheHeader__Wrapper">
      <BaseLogo :href="logoLink" color="white" variation="olistPax" class="TheHeader__logo" />
      <BaseMenu :menu="parsedHeader" class="TheHeader__menu" />
      <div class="TheHeader__userMenu">
        <router-link
          v-if="getUser.name && hasProfilePermissions"
          class="TheHeader__profile"
          :to="`/users/${getUser.id}`"
        >
          <Profile :name="getUser.name" />
        </router-link>
        <div class="TheHeader__exit" @click="signOutHandler">
          <Icon name="exit" class="TheHeader__exit-icon" />
          <span class="TheHeader__exit-label">{{ exitLabel }}</span>
        </div>
      </div>
    </div>
    <div class="TheHeader__menu-mobile" :class="{close: menuOpen}" @click="toogleMenu">
      <div v-for="(line, index) in 3" :key="index" class="TheHeader__line" />
    </div>
    <div class="TheHeader__mobile-container" :class="{open: menuOpen}">
      <router-link
        v-if="getUser.name"
        class="TheHeader__profile"
        :to="`/users/${getUser.id}`"
      >
        <Profile class="TheHeader__profile" :name="getUser.name" />
      </router-link>
      <div class="TheHeader__exit" @click="signOutHandler">
        <Icon name="exit" class="TheHeader__exit-icon" />
        <span class="TheHeader__exit-label">{{ exitLabel }}</span>
      </div>
      <BaseMenu :menu="parsedHeader" class="TheHeader__menuList-mobile" />
    </div>    
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BaseLogo, BaseMenu } from '@/components/atoms'
import { Profile } from '@/components/molecules'
import header from './utils/header'
import store from '../../../store/index.js'

export default {
  name: 'TheHeader',
  components: { BaseLogo, BaseMenu, Profile },
  data() {
    return {
      header,
      exitLabel: 'Sair',
      responsive: 870,
      width: window.outerWidth,
      signOutRoute: '/auth/signin'
    }
  },
  computed: {
    ...mapState({
      menuOpen: state => state.userAgent.menuOpen,
      permissionsModules: state => state.auth.permissionsModules
    }),

    parsedHeader(){
      if(!this.permissionsModules) return []

      return header.reduce((result, item) => {
        if(item.children) {
          const sonsWithPermission = item.children.filter(son => this.permissionsModules.some(module => module.name === son.module))
          if (sonsWithPermission.length) {
            return [...result, {
              name: item.name,
              children: sonsWithPermission
            }]
          }
        }
        const hasPermission = this.permissionsModules.some(module => module.name === item.module)
        if (hasPermission) {
          return [...result, item]
        }
        return result
      }, [])
    },

    logoLink() {
      const type = JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name
      if (type === 'motorista_dropoff') {
        return '/packages/incoming'
      } else {
        return '/'
      }
    },

    allStates(){
      return [
        'orders.orders',
        'orders.importOrders',
        'routes.routes',
        'routes.routesStores',
        'routes.ordersWithoutRoutes',
        'drivers.drivers',
        'transporters.transportersTable',
        'transporters.transportersDrivers',
        'stores.storesTable',
        'stores.storesTransporters',
        'users.users',
      ]
    },

    getUser(){
      return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))  : ''
    },

    hasProfilePermissions() {
      if (!this.permissionsModules.length) return
      return !!this.permissionsModules.find(module => module.name === 'module_users')
    }
  },
  mounted() {
    this.menuResponsive()
    window.addEventListener('resize', this.menuResponsive)
  },
  destroyed() {
    window.removeEventListener('resize', this.menuResponsive)
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      responsiveMenuOpen: 'userAgent/responsiveMenuOpen'
    }),


    signOutHandler() {
      this.signOut().then(() => {
        this.allStates.map((state) =>{
          let uri = state.split('.')
          return store.state[uri[0]][uri[1]].data.content = []
        })
        store.state.orders.ordersTracking = []
        this.$router.push(this.signOutRoute)
      })
    },

    toogleMenu() {
      this.responsiveMenuOpen(!this.menuOpen)
    },

    menuResponsive() {
      this.width = window.outerWidth
      if (this.width > this.responsive) this.responsiveMenuOpen(false)
    }
  }
}
</script>

<style lang="scss" scoped>
$header-height: 74px;

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes resetlineTop {
  0% {
    transform: translateY(5px) rotate(40deg);
  }
  50% {
    transform: translateY(5px) rotate(0);
  }
  100% {
    transform: translateY(0px) rotate(0);
  }
}

@keyframes resetLineBottom {
  0% {
    transform: translateY(-5px) rotate(-40deg);
  }
  50% {
    transform: translateY(-5px) rotate(0);
  }
  100% {
    transform: translateY(0px) rotate(0);
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes lineRotateTop {
  0% {
    transform: translateY(0px) rotate(0);
  }
  50% {
    transform: translateY(5px) rotate(0);
  }
  100% {
    transform: translateY(5px) rotate(40deg);
  }
}

@keyframes lineRotateBottom {
  0% {
    transform: translateY(0px) rotate(0);
  }
  50% {
    transform: translateY(-5px) rotate(0);
  }
  100% {
    transform: translateY(-5px) rotate(-40deg);
  }
}

.TheHeader {
  width: 100%;
  background-color: $color-primary-base;
  box-shadow: $shadow-low;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: $z-nav;
  @media #{$mobile-view} {
    box-sizing: border-box;
    overflow: initial;
    padding: 0 1rem;
    justify-content: space-between;
  }

  &__Wrapper {
    display: flex;
    width: calc(100% - 40px);
    height: $header-height;
    max-width: 1180px;
    gap: $spacing-stack-1xlarge;
    @media (max-width: $viewport-lg) {
      gap: $spacing-stack-medium;
    }
    @media #{$mobile-view} {
      width: initial;
    }
  }

  &__logo {
    width: 120px;
    flex-shrink: 0;
    @media #{$mobile-view} {
      width: 100px;
    }
  }

  &__menu {
    height: $header-height;
    width: 100%;
    @media #{$mobile-view} {
      display: none;
    }
    &-mobile {
      width: 20px;
      align-self: center;
      justify-self: end;
      display: none;
      cursor: pointer;
      @media #{$mobile-view} {
        display: block;
      }
      .TheHeader__line {
        width: 100%;
        height: 2px;
        background-color: $color-neutral-softest;
        margin-bottom: 3px;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(1) {
          animation-name: resetlineTop;
          animation-duration: 0.3s;
          transform: translateY(0) rotate(0);
        }
        &:nth-child(2) {
          opacity: 1;
          animation-name: show;
          animation-duration: 0.3s;
        }
        &:nth-child(3) {
          animation-name: resetLineBottom;
          animation-duration: 0.3s;
          transform: translateY(0) rotate(0);
        }
      }
      &.close {
        .TheHeader__line {
          &:nth-child(1) {
            animation-name: lineRotateTop;
            animation-duration: 0.3s;
            transform: translateY(5px) rotate(40deg);
          }
          &:nth-child(2) {
            opacity: 0;
            animation-name: hide;
            animation-duration: 0.3s;
          }
          &:nth-child(3) {
            animation-name: lineRotateBottom;
            animation-duration: 0.3s;
            transform: translateY(-5px) rotate(-40deg);
          }
        }
      }
    }
  }

  &__userMenu{
    display: flex;
    justify-self: center;
    flex-shrink: 0;
    @media #{$mobile-view} {
      display: none;
    }
  }

  &__profile{
    align-self: center;
    display: flex;
    color: $color-neutral-softest;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    &:before {
      content: "";
      background-color: $color-neutral-softest;
      height: 37px;
      width: 2px;
      position: relative;
      margin-right: 15px;
      @media #{$mobile-view} {
        display: none;
      }
    }
  }

  &__exit {
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-neutral-softest;
    text-transform: lowercase;
    &-icon {
      width: 20px;
      filter: $filter-white;
    }
    &:before {
      content: "";
      background-color: $color-neutral-softest;
      height: 37px;
      width: 2px;
      position: relative;
      margin-right: 20px;
    }
    &:hover {
      color: $color-neutral-softer;
      &-icon {
        filter: $filter-white;
      }
    }
  }

  &__mobile-container {
    position: fixed;
    width: 100%;
    height: calc(100vh - #{$header-height});
    top: $header-height;
    background-color: $color-primary-base;
    transform: translateX(100%);
    transition: transform 0.3s;
    z-index: $z-label;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 1rem 2rem;
    border-top: 1px solid rgba(#000, 0.1);

    &.open {
      transform: translateX(calc(0% - 1rem));

    }
    .TheHeader__menuList-mobile {
      height: calc(100% - 38px);
      box-sizing: border-box;
      width: 100%;
    }
  }
}
</style>