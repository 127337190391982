import state from './state.tracking'
import getters from './getters.tracking'
import mutations from './mutations.tracking'
import actions from './actions.tracking'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}