import request from '@/api/plugins/request'

export default {
  async fetchOrderHistory({code, document = ''}, success, error, done) {
    return await request({
      method: 'get',
      url: `orders-history?is_table=tracking&tracking_code=${code}&document=${document}&paginate=999`
    }, success, error, done)
  },

  async fetchTrackingWithStoreId({storeId, orderNumber}, success, error, done) {
    return await request({
      method: 'get',
      url: `orders-history?is_table=tracking&store_id=${storeId}&order_number=${orderNumber}&paginate=999`
    }, success, error, done)
  },

  async fetchOrdersByDocument({document}, success, error, done) {

    return await request({
      method: 'get',
      url: `orders-byDocument/${document}?paginate=999&page=1`
    }, success, error, done)
  },

  async fetchTrackingComments(id, success, error, done) {
    return await request({
      method: 'get',
      url: `/orders-history/comment?tracking_id=${id}`
    }, success, error, done)
  },

  async fetchAllTrackingComments(orderNumber, success, error, done) {
    return await request({
      method: 'get',
      url: `/orders-history/comment?order_number=${orderNumber}`
    }, success, error, done)
  },

  async submitTrackingMessage({body, id}, success, error, done) {
    return await request({
      method: 'post',
      url: `orders-history/comment/${id}`,
      body
    }, success, error, done)
  },
}