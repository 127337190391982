import auth from './auth/index.routes'
import transporters from './transporters/index.routes'
import driversMap from './driversMap/index.routes'
import errors from './errors/index.routes'
import notification from './notification/index.routes'
import orders from './orders/index.routes'
import users from './users/index.routes'
import drivers from './drivers/index.routes'
import routes from './routes/index.routes'
import stores from './stores/index.routes'
import tracking from './tracking/index.routes'
import financial from './financial/index.routes'
import vehicles from './vehicles/index.routes'
import welcome from './welcome/index.routes'
import packages from './packages/index.routes'
import sellers from './sellers/index.routes'
import controlTower from './controlTower/index.routes'
import playground from './playground/index.routes'
import groups from './groups/index.groups'
import dropoff from './dropoff/index.routes'

export default [
  ...auth,
  ...transporters,
  ...driversMap,
  ...errors,
  ...notification,
  ...orders,
  ...users,
  ...drivers,
  ...routes,
  ...stores,
  ...tracking,
  ...financial,
  ...vehicles,
  ...welcome,
  ...sellers,
  ...packages,
  ...controlTower,
  ...playground,
  ...groups,
  ...dropoff
]