// ##########
// CTe
// ##########

//Fetch CTes Routes
const fetchCtesRoutesMock = {
  data:{
    headers: {
      id: {
        id: 'id',
        isVisible: false,
        label: 'id',
        method: false,
        param: 'ctes.id',
      },
      note_id: {
        id: 'note_id',
        isVisible: true,
        label: 'ID da Rota',
        method: false,
        param: 'ctes.note_id',
      },
      date: {
        id: 'date',
        isVisible: true,
        label: 'Data de emissão',
        method: 'millisecondsToDate',
        mutator: 'dateToTimestamp',
        param: 'ctes.date',
      },
      status: {
        id: 'status.name',
        isVisible: true,
        label: 'Status',
        method: false,
        param: 'status.name',
      },
      sefaz: {
        id: 'sefaz',
        isVisible: true,
        label: 'SEFAZ',
        method: false,
        param: 'ctes.sefaz',
      },
      actions: {
        actions: true,
        id: 'actions',
        isVisible: true,
        label: 'Ações',
        method: false,
        param: 'actions',
      },
      buttons: {
        actions: true,
        id: 'buttons',
        isVisible: true,
        label: '',
        method: false,
        param: 'buttons',
      },
    },
    content: []
  },
  pagination:{
    last_page: 0,
    total: 20,
    per_page: 0
  }
}

for (let i = 0; i < 10; i++) {
  const even = i % 2 === 0
  let obj = {
    id: i,
    note_id: 'Nota-00'+i,
    date: 1623701552000,
    status: 'A emitir',
    sefaz: even ? 'Negado' : 'Aprovado',
    actions: [
      {action_id: 'download', has_permission: true, disabled: even ? true : false},
      {action_id: 'visualize', has_permission: true}
    ],
    buttons: [
      {button_id: 'generate', disabled: even ? false : true, name: 'Gerar CTe', has_permission: true},
    ],
  }
  fetchCtesRoutesMock.data.content.push(obj)
}

//Fetch CTe
let fetchCteMock = {
  route_name: 'Rota Bimbola',
  status: 'A emitir'
}

//Fetch CTes
const fetchCtesMock = {
  data:{
    headers: {
      id: {
        id: 'id',
        isVisible: false,
        label: 'id',
        method: false,
        param: 'ctes.id',
      },
      name: {
        id: 'name',
        isVisible: true,
        label: 'Nome',
        method: false,
        param: 'ctes.name',
      },
      date: {
        id: 'date',
        isVisible: true,
        label: 'Data de emissão',
        method: 'millisecondsToDate',
        mutator: 'dateToTimestamp',
        param: 'ctes.date',
      },
      status: {
        id: 'status.name',
        isVisible: true,
        label: 'Status',
        method: false,
        param: 'status.name',
      },
      sefaz: {
        id: 'sefaz',
        isVisible: true,
        label: 'SEFAZ',
        method: false,
        param: 'ctes.sefaz',
      },
      actions: {
        actions: true,
        id: 'actions',
        isVisible: true,
        label: 'Ações',
        method: false,
        param: 'actions',
      },
    },
    content: []
  },
  pagination:{
    last_page: 0,
    total: 20,
    per_page: 0
  }
}

for (let i = 0; i < 10; i++) {
  let obj = {
    id: i,
    name: 'Nota-00'+i,
    date: 1623701552000,
    status: 'A emitir',
    sefaz: 'Aprovado',
    actions: [
      {action_id: 'visualize', has_permission: true},
      {action_id: 'download', has_permission: true}
    ],
  }
  fetchCtesMock.data.content.push(obj)
}

//Fetch CTe Details
let fetchCteDetailsMock = {
  cte_number: 'AAAAAA',
  cte_date: 1623701552000,
  cte_type: 'BBBBB'
}

//Fetch CTe Details Info
let fetchCteDetailsInfoMock = {
  cte_cfop: {id : 0},
  cte_ier: '1311321331119',
  cte_service_type: {id : 0},
  cte_taker: {id : 0},
  cte_value: '1',
  cte_uf_start: {id : 25},
  cte_uf_end: {id : 24},
  cte_emmited: false,
}

// ##########
// MDFe
// ##########

//Fetch CTes Routes
const fetchMdfesMock = {
  data:{
    headers: {
      id: {
        id: 'id',
        isVisible: false,
        label: 'id',
        method: false,
        param: 'mdfes.id',
      },
      id_name: {
        id: 'id_name',
        isVisible: true,
        label: 'ID MDFe',
        method: false,
        param: 'mdfes.id_name',
      },
      date: {
        id: 'date',
        isVisible: true,
        label: 'Data de emissão',
        method: 'millisecondsToDate',
        mutator: 'dateToTimestamp',
        param: 'mdfes.date',
      },
      status: {
        id: 'status.name',
        isVisible: true,
        label: 'Status',
        method: false,
        param: 'status.name',
      },
      sefaz: {
        id: 'sefaz',
        isVisible: true,
        label: 'SEFAZ',
        method: false,
        param: 'mdfes.sefaz',
      },
      driver: {
        id: 'driver',
        isVisible: true,
        label: 'Motorista',
        method: false,
        param: 'mdfes.driver',
      },
      actions: {
        actions: true,
        id: 'actions',
        isVisible: true,
        label: 'Ações',
        method: false,
        param: 'actions',
      },
      buttons: {
        actions: true,
        id: 'buttons',
        isVisible: true,
        label: 'Ações',
        method: false,
        param: 'buttons',
      },
    },
    content: []
  },
  pagination:{
    last_page: 0,
    total: 20,
    per_page: 0
  }
}

for (let i = 0; i < 10; i++) {
  const even = i % 2 === 0
  let obj = {
    id: i,
    id_name: 'Nota-00'+i,
    date: 1623701552000,
    status: 'A emitir',
    sefaz: even ? 'Negado' : 'Aprovado',
    driver: even ? 'Rick' : 'Morty',
    actions: [
      {action_id: 'download', has_permission: true, disabled: even ? true : false},
      {action_id: 'visualize', has_permission: true}
    ],
    buttons: [
      {buttons_id: 'generateMdfe', has_permission: true, name: 'Gerar MDFE', disabled: even ? false : true}
    ]
  }
  fetchMdfesMock.data.content.push(obj)
}

//Fetch MDFe
let fetchMdfeMock = {
  id_name: 'Rick',
  status: 'A emitir',
  //MDFe
  vehicle_code: '5932',
  vehicle_tara: '123123123123',
  vehicle_kg: 'Nomal',
  vehicle_type: {id: 0},
  vehicle_body: {id: 0},
  eminent: {id: 0},
  //Insurance
  insurance_responsible: {id: 0},
  insurance_responsible_cnpj: '1231231231231',
  insurance_company_name: 'OLIST PAX',
  insurance_company_cnpj: '1231231231231',
  policy_number: '123321312',
  registration_number: '12233321',
}

export {
  fetchCtesRoutesMock,
  fetchCtesMock,
  fetchCteMock,
  fetchCteDetailsMock,
  fetchCteDetailsInfoMock,

  //Mdfe
  fetchMdfesMock,
  fetchMdfeMock
}