import state from './state.printer'
import getters from './getters.printer'
import mutations from './mutations.printer'
import actions from './actions.printer'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}