<template>
  <div class="InputGroup" :class="InputGroupClass">
    <label v-if="!!label" class="InputGroup__label" :class="labelClass">{{ label }}</label>

    <!-- Tooltip -->
    <BaseTooltip
      v-if="tooltip.length > 0 || tooltipLabel"
      :tooltip="tooltip"
      :tooltip-label="tooltipLabel"
      :tooltip-icon="tooltipIcon"
      @tooltip="onTooltip"
    />

    <input
      ref="input"
      class="InputGroup__input"
      v-bind="$attrs"
      :class="inputClass"
      :value="maskedValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :read-only="readOnly"
      :tabindex="readOnly ? '-1' : tabIndex"
      v-on="listeners"
    >
    <Icon v-if="icon" :name="icon" class="InputGroup__icon" @click="$emit('click')" />

    <p v-if="isInvalid" class="InputGroup__invalidLabel">
      {{ invalidLabel }}
    </p>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseTooltip } from '@/components/atoms'
import Mask from '@/plugins/mask/Mask.js'

export default {
  name: 'InputGroup',
  components: { BaseTooltip },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    floatingLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: [String, Boolean],
      default: false
    },
    readOnly: {
      type: [String, Boolean],
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    debounceTime: {
      type: Number,
      default: 500
    },
    mask: {
      type: String,
      default: ''
    },
    replaceMaskOnKeyUp: {
      type: Boolean,
      default: true
    },
    autoFillColor:{
      type: String,
      default: 'white',
      validator: val => ['white', 'yellow', 'primary-base', 'gray'].includes(val)
    },
    filled: {
      type: String,
      default: null,
      validator: val => ['white', 'yellow', 'primary-base', 'gray'].includes(val)
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    invalidLabel: {
      type: String,
      default: ''
    },
    theme:{
      type: String,
      default: 'dark',
      validator: val => ['dark', 'light'].includes(val)
    },
    placeholder: {
      type: String,
      default: ''
    },
    tabIndex: {
      type: Number,
      default: 0
    },

    //Tooltip
    tooltip: {
      type: [Array, Boolean, String],
      description: '',
      default: () => [],
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
    tooltipIcon: {
      type: String,
      default: '',
    },

    //Bipping
    enableBipping: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      debounce: null,
      focus: false
    }
  },
  computed: {
    ...mapGetters({
      bippingSensitivity: 'userAgent/bippingSensitivity',
    }),

    //General
    listeners() {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        focus: this.onFocus,
        input: this.onInput,
        keyup: this.onKeyup,
      }
    },

    //Styles
    InputGroupClass() {
      return [
        this.readOnly && 'InputGroup--read-only',
        {'InputGroup--invalid': !!this.isInvalid},
        this.disabled && 'InputGroup--disabled',
        `InputGroup__AutoFill--${this.autoFillColor}`,
        `InputGroup__Theme--${this.theme}`,
        this.filled ? `InputGroup--filled-${this.filled}` : null,
      ]
    },
    labelClass() {
      return [
        this.floatingLabel && 'InputGroup__label--floating',
        (!!this.focus || !!this.value) && 'InputGroup__label--upwards'
      ]
    },
    inputClass() {
      return { 'InputGroup__input--has-icon': this.icon }
    },
    maskedValue() {
      return this.mask ? Mask[this.mask](this.value, this.$refs.input) : this.value
    }
  },

  methods: {

    //Handlers
    onBlur(evt) {
      this.focus = !this.focus
      this.$emit('blur', evt)
    },

    onFocus(evt) {
      this.focus = !this.focus
      this.$emit('focus', evt)
    },

    onInput(evt) {
      this.debounceInput(evt)
    },

    onKeyup(evt) {
      this.handlerOnKeyUp(evt)
      let value = evt ? evt.target.value : this.value
      value = this.mask ? Mask[this.mask](value, this.$refs.input) : value
      if (this.mask && this.replaceMaskOnKeyUp) this.$emit('input', this.removeMask(value))
    },

    //Tooltip
    onTooltip(action) {
      this.$emit(action, action)
    },

    //Custom Handlers
    debounceInput(e) {
      const val = this.mask && this.replaceMaskOnKeyUp ? this.removeMask(e.target.value) : e.target.value
      this.$emit('input', val)

      clearTimeout(this.debounce)
      this.debounce = 
        setTimeout(() => this.$emit('debounce', val), this.debounceTime)
    },

    handlerOnKeyUp(evt) {
      if (this.enableBipping) {
        if (evt.key === 'Enter') {
          let value = evt ? evt.target.value : this.value
          const dateNow = new Date().getTime()
          if (dateNow - this.previousTimestamp < this.bippingSensitivity) {
            this.$emit('bipping', value)
          } else {
            this.$emit('not-bipping', value)
          }

          //Remove Focus
          let active = document.activeElement
          active.blur()
          
        } else {
          this.previousTimestamp = new Date().getTime()
        }
      }
    },
 
    //Helpers
    setFocus() {
      this.$refs.input.focus()
    },

    removeMask(val) {
      let value = this.$options.filters.noSpecialCharacters(val)
      if (this.mask === 'money') {
        value = this.$options.filters.moneyToNumber(value)
      } 
      return value
    },
   
  }
}
</script>

<style lang="scss" scoped>
//Remove AutoFill
@mixin webkitAutofill($color){
  -webkit-box-shadow: 0 0 0px 1000px $color inset;
  background-color: transparent;
}

$colors: (
  "yellow": $color-notice-base,
  "primary-base": $color-primary-base,
  "white": $color-neutral-softest,
  "gray": $color-neutral-strong
);

.InputGroup {
  position: relative;
  
  @media #{$mobile-view} {
    width: 100%;
    margin-bottom: 20px;
  }

  //Status
  &--read-only {
    pointer-events: none !important;
    opacity: 0.5;

    * {
      pointer-events: none !important;
    }
  }

  &--disabled {
    .InputGroup__input {
      pointer-events: none;
      background-color: $color-neutral-softer;
    }
  }

  &--filled {
    @each $type, $color in $colors {
      &-#{$type} {
        input {
          background-color: $color;
        }
      }
    }
  }

  &__Theme{
    &--dark{
      &:hover:not(.InputGroup--disabled) {
        .InputGroup__input {
          border-color: $color-neutral-stronger;
        }
      }
      .InputGroup__label{
        color: $color-neutral-stronger;
      }
      .InputGroup__input{
        border-bottom: 2px solid $color-neutral-soft;
        color: $color-neutral-stronger;
      }
    }
    &--light{
      &:hover:not(.InputGroup--disabled) {
        .InputGroup__input {
          border-color: $color-neutral-softer;
        }
      }
      .InputGroup__label{
        color: $color-neutral-softest;
      }
      .InputGroup__input{
        border-bottom: 2px solid $color-neutral-soft;
        color: $color-neutral-softest;
      }
    }
  }

  &__label {
    margin-right: 10px;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
    transition: transform 0.2s;
    pointer-events: none;
    &--floating {
      position: absolute;
      transform: translateY(5px);
      left: 10px;
    }
    &--upwards {
      transform: translateY(-20px);
    }

    .InputGroup--invalid & {
      color: $color-alert-base;
    }
  }

  &__input {
    padding: 9px;
    width: 100%;
    background: transparent;
    transition: all 0.2s ease;

    &::placeholder {
      color: $color-neutral-base;
    }

    &--has-icon {
      padding-right: 35px;
    }

    .InputGroup--invalid & {
      color: $color-alert-base;
      border-bottom: 2px solid $color-alert-base;
    }
  }

  &__icon {
    cursor: pointer;
    width: 17px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-10px, 10px);
    filter: invert(100%);
  }

  &__invalidLabel {
    padding: 5px 10px;
    font-size: $font-size-1xmini;
    color: $color-alert-base;
    font-weight: $font-weight-bold;
    position: absolute;
  }

  &__AutoFill{
    @each $type, $color in $colors {
      &--#{$type}{
        input:-internal-autofill-selected{
          @include webkitAutofill($color)
        }
      }
      &--primary-base {
        input {
          -webkit-text-fill-color: $color-white;
        }
      }
    }
  }
}
</style>
