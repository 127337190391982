import { vehicles } from '@/api'

export default {
  async fetchVehicles({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await vehicles.fetchVehicles(params, (res) => {
      commit('SET_VEHICLES', res)
    }, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async deleteVehicle({ commit }, id) {
    return await vehicles.deleteVehicle(id, () => commit('DELETE_VEHICLE', id), (e) => e)
  },
}