import state from './state.users'
import getters from './getters.users'
import mutations from './mutations.users'
import actions from './actions.users'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}