export default {
  SET_OBJ_PARAMETER(state, parameter) {
    state.queryString.object = parameter
  },

  SET_QUERY_PARAMETER(state, str) {
    state.queryString.string = str
  },

  CLEAR_PARAMETERS(state){
    state.queryString.object.storeNumber = []
    state.queryString.object.store = []
    state.queryString.object.transporter = []
    state.queryString.object.dateRange = {}
    state.queryString.object.storeLabel = []
    state.queryString.object.storeTransporters = []
    state.queryString.object.storeStatus = []
    state.queryString.object.query = []
    state.queryString.object.searchValue = []
    state.queryString.object.statusSelected = {}
    state.queryString.object.driversId = []
    state.queryString.object.parsedDrivers = null
    state.queryString.object.romaneioSelected = {}
    state.queryString.object.driveSelected = {}
    state.queryString.object.id = []
    state.queryString.object.transporter_id = []
    state.queryString.object.status_id = ''
    state.queryString.object.storeSelected = []
    state.queryString.object.typeSelected = []
  }
}
