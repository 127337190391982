export default {

  //#region Orders
  SET_ORDERS(state, orders){
    state.orders = orders
  },

  SET_STATUS(state, status){
    state.status = status
  },

  SUBMIT_ORDER_LOCATION_LOCK(state, { id, check_location }){
    let content = state.orders.data.content
    let order_index = content.map(o => o.id).indexOf(id)
    let order = content[order_index]
    let actions = order.actions
    for (let i = 0; i < actions.length; i++) {
      let action_id = actions[i].action_id
      if (action_id === 'disableLocationLock') {
        actions[i].has_permission = check_location
      }
      if (action_id === 'enableLocationLock') {
        actions[i].has_permission = !check_location
      }
    }
    state.orders.data.content = content
  },

  //#endregion

  //#region Orders Form
  DELETE_ORDER(state, deletedOrder){
    state.orders.data.content = state.orders.data.content.filter((order) => order.id !== deletedOrder.id)
  },

  SET_PRODUCTS_SIZES(state, data){
    state.productsSizes = data
  },

  SET_ORDER_PACKAGES(state, data){
    state.orderPackages = data
  },

  //#endregion

  //#region Orders Incoming
  SET_INCOMING_ORDERS(state, incomingOrders){
    if (state.incomingOrders.data.content.length > 0) {
      state.incomingOrders.data.content.push(incomingOrders.data.content[0])
    } else {
      state.incomingOrders = incomingOrders
    }
  },

  SET_INCOMING_ORDERS_TYPES(state, incomingOrdersTypes){
    state.incomingOrdersTypes = incomingOrdersTypes.types
  },

  //#endregion

  //#region Change Status
  SET_STATUS_ONLY(state, statusOnly){
    state.statusOnly = statusOnly
  },

  SET_IMPORT_STATUS_ORDERS(state, importStatusOrders){
    state.importStatusOrders = importStatusOrders
  },

  SET_IMPORT_STATUS_ERROS(state, importStatusErros){
    state.importStatusErros = importStatusErros
  },

  //#endregion

  //#region Orders Export
  SET_REPORTS_REQUESTED(state, reportsRequested){
    state.reportsRequested = reportsRequested
  },

  //#endregion

  //#region Orders Import
  SET_IMPORT_ORDERS(state, importOrders){
    state.importOrders = importOrders
  },

  SET_IMPORT_ERROS(state, importErros){
    state.importErros = importErros
  },
  //#endregion

  SET_DELIVERY_FAILURES(state, deliveryFailures) {
    state.deliveryFailures = deliveryFailures
  },

  //#region Error List
  DELETE_IMPORT_ERROS(state, id){
    state.importErros = state.importErros.filter((importData) => importData.id !== id)
  },
  //#endregion

  //#region Financial
  SET_IMPORT_STATUS_LIST(state, data){
    state.importStatusList = data
  },
  //#endregion

}
