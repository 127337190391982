export default [
  {
    name: 'Torre de Controle',
    children: [
      {
        name: 'Monitoramento Diário',
        to: '/monitoring',
        module: 'module_control_tower',
        tagName: 'Control Tower Monitoring',
      },
      {
        name: 'Ocorrências',
        to: '/occurrence',
        module: 'module_control_tower',
        tagName: 'Control Tower',
      },
    ],
  },
  {
    name: 'Pedidos',
    to: '/orders',
    module: 'module_orders',
    tagName: 'Orders',
  },
  {
    name: 'Rotas',
    to: '/routes',
    module: 'module_routes',
    tagName: 'Routes',
  },
  {
    name: 'Usuários',
    children: [
      {
        name: 'Lojistas',
        to: '/stores',
        module: 'module_stores',
        tagName: 'Stores',
      },
      {
        name: 'Motoristas',
        to: '/drivers',
        module: 'module_drivers',
        tagName: 'Drivers',
      },
      {
        name: 'Operadores',
        to: '/users',
        module: 'module_users',
        tagName: 'Users',
      },
      {
        name: 'Sellers',
        to: '/sellers',
        module: 'module_sellers',
        tagName: 'Sellers',
      },
    ],
  },
  {
    name: 'Veículos',
    to: '/vehicles',
    module: 'module_vehicles',
    tagName: 'Vehicles',
  },
  {
    name: 'Transportadoras',
    to: '/transporters',
    module: 'module_transporters',
    tagName: 'Transporters',
  },
  {
    name: 'Financeiro',
    to: '/financial/',
    module: 'module_financial',
    tagName: 'Financial',
  },
  {
    name: 'Comunicações',
    to: '/notification',
    module: 'module_notification',
    tagName: 'Notification',
  },
]