import ctemanagement from './ctemanagement/actions.cte'
import { routes } from '@/api'

export default {
  async fetchRoutes({ commit }, params = {paginate:20, page:1}) {
    // this.dispatch('loading/setLoading', true)
    return await routes.fetchRoutes(params, (res) => commit('SET_ROUTES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchRouteHistory({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await routes.fetchRouteHistory(params, (res) => {
      commit('SET_ROUTE_HISTORY', res)
    }, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchRouteErrors({ commit }, id) {
    this.dispatch('loading/setLoading', true)
    return await routes.fetchRouteErrors(id, (res) => {
      commit('SET_ROUTE_ERRORS', res)
    }, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchRoutesStores({ commit }, id) {
    return await routes.fetchRoutesStores({id}, (res) =>
      commit('SET_ROUTES_STORES', res), (e) => e)
  },

  async clearRoutesStores({ commit }) {
    commit('CLEAR_ROUTES_STORES')
  },

  async routeAddOrder({commit}, {routeId, orderNumber, storeId}){
    return await routes.routeAddOrder({routeId, orderNumber, storeId}, () => {
      commit('UPDATE_ROUTES_DRIVERS', routeId)
    }, (e) => e)
  },

  async bindDriver({commit}, {routeId, driverId, driverName}){
    this.dispatch('loading/setLoading', true)
    return await routes.bindDriver({routeId, driverId, driverName}, () =>
      commit('UPDATE_ROUTES_COUNT', {routeId, driverName}), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async bindDriverWithoutMutation({commit}, {routeId, driverId, driverName}){
    this.dispatch('loading/setLoading', true)
    return await routes.bindDriver({routeId, driverId, driverName}, () =>
      commit, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async routeDeleteOrder({commit}, {routeId, orderId}){
    this.dispatch('loading/setLoading', true)
    await routes.routeDeleteOrder({routeId, orderId}, (res) => {
      commit('DELETE_ROUTES_STORES', res)
      commit('UPDATE_ROUTES_DRIVERS', routeId)
    }, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async deleteRoute({ commit }, id) {
    return await routes.deleteRoute({id}, () => commit('DELETE_ROUTE', id), (e) => e)
  },

  async fetchOrdersWithoutRoutes({ commit }, params = {paginate:50, page:1}) {
    this.dispatch('loading/setLoading', true)
    let response = await routes.fetchOrdersWithoutRoutes(params, (res) =>{
      commit('SET_ORDERS_WITHOUT_ROUTES', res)
      return true
    }, (e) => e)

    return response
  },

  async fetchOrdersWithoutRoutesTransfer({ commit }, params = {paginate:50, page:1}) {
    this.dispatch('loading/setLoading', true)
    let response = await routes.fetchOrdersWithoutRoutesTransfer(params, (res) =>{
      commit('SET_ORDERS_WITHOUT_ROUTES_TRANSFER', res)
      return true
    }, (e) => e, () => 
      this.dispatch('loading/setLoading', false))

    return response
  },

  async fetchRoutesNameByStores({ commit }, payload) {
    this.dispatch('loading/setLoading', true)
    let response = await routes.fetchRoutesNameByStores({type: payload.type, id: payload.id}, (res) =>{
      commit('SET_ROUTES_NAME_BY_STORE', res)
      return true
    }, (e) => e, () => 
      this.dispatch('loading/setLoading', false)
    )

    return response
  },

  async sendRomaneio({ commit }, id){
    this.dispatch('loading/setLoading', true)
    return await routes.sendRomaneio(id, (res) => 
      commit('UPDATE_ROUTE_ROMANEIO', {res, id}) , (e) => e, 
    () => this.dispatch('loading/setLoading', false))
  },

  async fetchTransitPoints({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await routes.fetchTransitPoints(params, (res) =>
      commit('SET_TRANSITPOINTS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async deleteTransitPoint({commit}, id){
    return await routes.deleteTransitPoint({id}, () => 
      commit('DELETE_TRANSITPOINT', id), (e) => e)
  },

  async fetchTransitPointImport({ commit }) {
    return await routes.fetchTransitPointImport((res) => commit('SET_TRANSITPOINT_IMPORT', res.data), (e) => e)
  },

  async getPermissionLinkDriver(ctx, id) {
    return await routes.getPermissionLinkDriver({id}, (res) => res, (e) => e)
  },

  async fetchTransitPointImportAsync({ commit, rootGetters }) {
    return await routes.fetchTransitPointImport((res) => {
      commit('SET_TRANSITPOINT_IMPORT', res.data)
      return rootGetters.treeSelectModel('routes', 'transitPointImport')
    }, (e) => e)
  },

  //CTe Managements
  ...ctemanagement


}