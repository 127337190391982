import axios from 'axios'
import store from '@/store'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/vue'

const isValidToken = () => {
  if (!localStorage.getItem('expires_at')) return false

  const expiresAt = localStorage.getItem('expires_at').replace(/-/g, '/')

  const tokenExpireDate = new Date(expiresAt)
  const currentDate =  new Date()

  return currentDate < tokenExpireDate
}

const axiosInstance = () => {
  const instance = axios.create({
    timeout: 600000,
    headers: {'Content-Type': 'application/json'},
    baseURL: process.env.VUE_APP_BASE_URL,
    validateStatus: () => true
  })

  let axiosConfig

  instance.interceptors.request.use((config) => {

    axiosConfig = config
    
    if (isValidToken()) {
      config.headers.Authorization = `Bearer ${Cookies.get('access_token')}`
    } else {
      store.dispatch('auth/signOut')
      if (config.url !== 'auth/google' && config.url !== 'auth/login' && config.url.indexOf('password-recover') < 0) store.dispatch('auth/setExpired', true)
    }
    return config
  }, (error) => {
    Sentry.captureException(error, {
      ...axiosConfig
    })
    return Promise.reject(error)
  })
  
  instance.interceptors.response.use(async (response) => {
    if (response.data instanceof Blob && response.status === 200) {
      if (response.data.type === 'application/json') {
        let data = JSON.parse(await response.data.text())
        if (data.status) {
          return response
        } else {
          store.dispatch('error/setError', data.result.message)
          return Promise.reject(data)
        }
      } else {
        return response
      }
    } else if (response.data.status) {
      return response.data.result
    } else {
      if (response.data && response.data.result && response.data.result.validation) {
        return Promise.reject(response.data.result)
      } else {
        if (response.status === 511 || response.status === 401) {
          store.dispatch('auth/signOut')
          store.dispatch('auth/setExpired', true)
          return Promise.reject(response.data.result)
        }
        if (response.status === 500 || response.status === 404) {
          store.dispatch('error/setError', 'Ops... Ocorreu algum erro. Por favor<br> recarregue a página ou tente novamente.')
          if (response.data.result) store.dispatch('error/setErrorAdditional', `<pre>${JSON.stringify(response.data.result, null, 2)}</pre>`)
          return Promise.reject(response.data.result? response.data.result : response)  
        }
        if (response.request.responseURL.indexOf('hideErrors') < 0) {
          store.dispatch('error/setError', response.data.result.message)
        }
        return Promise.reject(response.data.result)
      }
    }
  }, (error) => {
    store.dispatch('error/setError', 'Ops... Ocorreu algum erro. Por favor<br> recarregue a página ou tente novamente.')
    Sentry.captureException(error, {
      ...axiosConfig
    })
    return Promise.reject(error)
  })

  return instance
}

export default axiosInstance()