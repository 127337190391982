<template>
  <div class="ThumbAction">
    <BaseThumbnail class="ThumbAction__thumbnail" :label="label" :name="label" :disabled="disabled" :image="value.path || ''" @upload="upload">
      <Icon v-if="!disabled" name="upload" color="dark" class="ThumbAction__icon" title="Upload" />
      <Icon name="show" color="dark" class="ThumbAction__icon" title="Visualizar" @click.native.prevent="showLightbox" />
    </BaseThumbnail>

    <transition v-if="isApprovable && value.path && !disabled" name="ThumbAction--anim">
      <BaseTreeSelect
        v-if="!value.status_id"
        :value="value.observation_id || null"
        :select-options="options"
        name="Motivo da rejeição"
        label="Motivo da rejeição"
        class="ThumbAction__select"
        @input="setObservationId"
      />
    </transition>

    <div v-if="isApprovable && value.path && !disabled" class="ThumbAction__actions">
      <BaseButton
        class="ThumbAction__reject"
        :filled="!value.status_id"
        label="Rejeitar"
        theme="simple"
        color="red"
        :text-color="rejectTxtColor"
        @click="setStatusId(false)"
      />
      <BaseButton
        class="ThumbAction__approve"
        :filled="value.status_id"
        label="Aprovar"
        theme="simple"
        color="green"
        :text-color="approveTxtColor"
        @click="setStatusId(true)"
      />
    </div>
  </div>
</template>

<script>
import { BaseThumbnail, BaseButton, BaseTreeSelect } from '@/components/atoms'

export default {
  name: 'ThumbAction',
  components: {
    BaseThumbnail,
    BaseButton,
    BaseTreeSelect
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    isApprovable: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      observation: null
    }
  },
  computed: {
    rejectTxtColor() {
      return this.value.status_id ? 'black' : 'white'
    },
    approveTxtColor() {
      return this.value.status_id ? 'white' : 'black'
    },
  },
  methods: {
    showLightbox() {
      this.$emit('showLightbox', this.value.path, this.label)
    },

    emit(value) {
      this.$emit('input', value)
    },

    setObservationId(e) {
      this.emit({ ...this.value, observation_id: e })
    },

    setStatusId(bool) {
      this.emit({ ...this.value, status_id: bool })
    },

    upload(e) {
      this.emit({ ...this.value, path: e })
    }
  }
}
</script>

<style lang="scss" scoped>
.ThumbAction {
  &__thumbnail {
    position: relative;
    background-color: white;
    margin-bottom: 10px;
    z-index: $z-overlay;
  }
  &__actions {
    display: flex;
  }
  &__select {
    position: relative;
    margin-bottom: 10px;
  }
  &__reject {
    margin-right: 5px;
  }
  &__approve {
    margin-left: 5px;
  }
  &__icon{
    width: 35px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &--anim {
    &-enter-active {
      transition: all .2s ease-out;
    }
    &-leave-active {
      transition: all .2s ease-out;
    }
    &-enter {
      transform: translateY(-50px);
      margin-bottom: -50px;
      opacity: 0;
    }
    &-leave-to {
      transform: translateY(-50px);
      margin-bottom: -50px;
      opacity: 0;
    }
  }
}
</style>