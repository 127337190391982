import { users } from '@/api'

export default {
  async fetchUsers({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await users.fetchUsers(params, (res) =>
      commit('SET_USER', res) , (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async fetchUserTypes({ commit }) {
    return await users.fetchUserTypes((res) => commit('SET_USER_TYPES', res.data) , (e) => e)
  },

  async fetchUserEnableCreate({ commit }) {
    return await users.fetchUserEnableCreate((res) => commit('SET_USER_ENABLE_CREATE', res.data) , (e) => e)
  },

  async deleteUser({ commit }, id) {
    return await users.deleteUser(id, () => commit('DELETE_USER', id), (e) => e)
  }
}