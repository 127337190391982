<template>
  <section class="TheSubHeader" :class="TheSubHeaderClass">
    <section class="TheSubHeader__main">
      <div class="TheSubHeader__mainWrap">
        <hgroup class="TheSubHeader__title">
          <Icon v-if="icon" :name="icon" color="primary-base" />
          <h1>{{ title }}</h1>
        </hgroup>
        <section class="TheSubHeader__actions" :class="theSubHeaderActionsClasses">
          <slot name="beforeButtons" />
          <BaseButton 
            v-for="(action, i) in actionsFiltered" 
            :key="i" 
            :label="action.text"
            :theme="action.theme || 'icon-right'" 
            :color="action.color || 'dark'"
            :icon="action.icon"
            class="TheSubHeader__button"
            :class="theSubHeaderButtonClass(action.action)" 
            :track="trackSubheaderButtonString(pageTitleTag, action.action)"
            @click="clickAction(action.action)"
          />
          <slot name="afterButtons" />
        </section>
      </div>
    </section>
    <section v-if="isntDefaultType" :class="TheSubHeaderSectionClass">
      <BaseTabs 
        v-if="isTypeTab"
        :items="type === 'tabs' ? tabs : routerTabs"
        :type="type"
        :current-tab="currentTab"
        :page-title-tag="pageTitleTag"
        @click="handleOnClickItem($event)"
      />
      <Organizer :rows="organizerRows" :columns="organizerColumns" :gap="organizerGap" :align-items="alignItems" class="TheSubHeader__organizer" :class="isTypeTab ? 'TheSubHeader__organizer--tabs' : ''">
        <template #filter>
          <slot name="filter" />
        </template>
        <template #tag>
          <slot name="tag" />
        </template>
      </Organizer>
    </section>
    <BaseButton
      v-if="actionsFiltered.length > 0 || !!$slots.afterButtons || !!$slots.beforeButtons"
      :icon="floatActionIcon" 
      filled 
      :color="floatActionColor" 
      icon-color="white"
      class="TheSubHeader__floatbutton" 
      :class="[intercom ? 'TheSubHeader__floatbutton--intercom' : '']"
      @click="toggleMobileActions()"
    />
  </section>
</template>

<script>
import { Organizer } from '@/components/molecules'
import { BaseButton, BaseTabs } from '@/components/atoms'
import { mapState } from 'vuex'

export default {
  name: 'TheSubHeader',
  components: {Organizer, BaseButton, BaseTabs},
  props: {
    icon: {
      type: [String, null],
      default: null
    },
    title: {
      type: String,
      required: true
    },
    pageTitleTag: {
      type: [String, null],
      default: null
    },
    addnewLink: {
      type: String,
      default: '/'
    },
    addnewLinkIcon: {
      type: String,
      default: 'dashboard'
    },
    actions: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'default',
      validator: val => ['default', 'filter', 'tabs', 'routerTabs'].includes(val)
    },
    tabs: {
      type: Array,
      default: () => [],
      validator: items => 
        items.filter(item => 
          Object.prototype.hasOwnProperty.call(item, 'id') && 
          Object.prototype.hasOwnProperty.call(item, 'title')
        ).length === items.length
    },
    organizerRows: {
      type: String,
      default: '1fr'
    },
    organizerColumns: {
      type: String,
      default: '1fr'
    },
    organizerGap: {
      type: String,
      default: ''
    },
    alignItems: {
      type: String,
      default: 'end'
    },
  },
  data() {
    return {
      currentTab: 0,
      visibleMobileActions: false,
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.auth.permissions,
      intercom: state => state.root.intercom
    }),

    routeLabel() {
      return this.$router.currentRoute.meta.label
    },
    btnList(){
      return [
        {
          id:'back',
          text: 'Voltar',
          icon: 'chevron-left-alt',
          action:'back',
          theme: 'icon-left'
        },
        {
          id:'goto',
          text: `Ir para ${this.routeLabel}`,
          icon: this.addnewLinkIcon,
          action:'goto'
        },
        {
          id:'importButton',
          text: 'Importar',
          icon: 'download-alt',
          action:'import'
        },
        {
          id:'exportButton',
          text: 'Exportar',
          icon: 'export-alt',
          action:'export'
        },
        {
          id:'isVisible',
          text: `Ver ${this.routeLabel}s`,
          icon: 'package-delivery-alt',
          action:'see'
        },
        {
          id:'addNewButton',
          text: `Cadastrar ${this.routeLabel}`,
          icon: 'add',
          color: 'primary-base',
          action:'addnew'
        },
        {
          id:'addNewButton',
          text: 'Cadastrar novo feriado',
          icon: 'add',
          color: 'primary-base',
          action:'calendar-add'
        },
      ]
    },
    
    actionsFiltered() {
      return this.btnList.filter((action) =>{
        if (action.action === 'back') {
          return this.actions.includes(action.action)
        }

        if (this.permissions && this.permissions[this.$router.currentRoute.name] && this.permissions[this.$router.currentRoute.name][action.id]) {
          return this.actions.includes(action.action)
        }
      })
    },

    isTypeTab() {
      return this.type === 'tabs' || this.type === 'routerTabs'
    },
    isntDefaultType() {
      return this.type !== 'default'
    },
    TheSubHeaderClass() {
      return `TheSubHeader--${this.type}`
    },
    theSubHeaderActionsClasses() {
      return { 
        'TheSubHeader__actions--mobile': this.visibleMobileActions,
        'TheSubHeader__actions--default': !this.isntDefaultType
      }
    },
    TheSubHeaderSectionClass() {
      return `TheSubHeader--${this.type}-content`
    },
    floatActionIcon() {
      return this.visibleMobileActions ? 'remove' : 'add'
    },
    floatActionColor() {
      return this.visibleMobileActions ? 'primary-base' : 'primary-base'
    },

    routerTabs() {
      if (this.type === 'routerTabs') {
        let tabs
        let routes = this.$router.options.routes.filter(r => r.children)
        for (let r = 0; r < routes.length; r++) {
          let children = routes[r].children
          for (let i = 0; i < children.length; i++) {
            if (this.$route.name === children[i].name) {
              tabs = children
                .filter(t => t.isTab)
                .filter(t => this.permissions[t.name]?.isVisible)
                .map((t, index) => {
                  return {
                    id: index,
                    key: t.name,
                    route: t.path,
                    title: t.title,
                    tagName: t?.meta?.tagName
                  }
                })
            }
          }
        }
        return tabs
      } else {
        return []
      }
    },

    currentTabByRoute() {
      return this.routerTabs.find(el => el.key === this.$router.currentRoute.name)
    },
  },

  mounted() {
    if (this.type === 'routerTabs') {
      this.handleOnClickItem(this.currentTabByRoute)
    }
  },
  
  methods: {
    clickAction(type) {
      const parentPath = this.$router.currentRoute.path.split('/').filter(Boolean)[0]

      if(type === 'back') return this.$router.go(-1)
      if(type === 'import') return this.$router.push(`/${parentPath}/import`)
      if(type === 'see') return this.$router.push(`/${parentPath}`)
      if(type === 'export') return this.$router.push(`/${parentPath}/export`)
      if(type === 'addnew') return this.$router.push(`/${parentPath}/add`)

      return this.$router.push(this.addnewLink)
    },
    theSubHeaderButtonClass(action) {
      return `TheSubHeader__button--${action}`
    },
    handleOnClickItem(e) {
      this.currentTab = e.id
      this.$emit('changeTab', e)
    },
    toggleMobileActions() {
      this.visibleMobileActions = !this.visibleMobileActions
    }
  }
}
</script>

<style lang="scss" scoped>

//Grid setup
$grid-columns: 1fr minmax(min-content, $viewport-lg) 1fr;
$grid-columns-main: 1fr minmax(min-content, $viewport-lg) 1fr;
$grid-template-main: "main main main";
$grid-template-content: ". content .";
$grid-template-areas-main: ". wrap .";
$grid-template-areas-main-mobile: ". wrap .";

  .TheSubHeader {
    /* Positioning */
    position: relative;
    z-index: $z-banner;

    /* Box model */
    display: grid;
    grid-template-columns: $grid-columns;
    grid-template-areas: $grid-template-main $grid-template-content;
    grid-column-gap: 1rem;

    &__organizer--tabs {
      border-radius: 0 $border-radius-1xsmall $border-radius-1xsmall $border-radius-1xsmall;
      padding-top: $spacing-stack-small;
    }

    &--default {
      /* Box model */
      grid-template-rows: 80px;
      grid-template-areas: $grid-template-main;
      margin-bottom: 1rem;
    }
    &--filter, &--tabs, &--routerTabs { grid-template-rows: 120px min-content }
    &--filter, &--tabs, &--routerTabs {
      pointer-events: none;

      .TheSubHeader__main, .TheSubHeader__floatbutton {
        pointer-events: all;
      }

      &-content { 
        pointer-events: all;
        transform: translateY(-30px);
      }
      @media #{$mobile-view} {
        &-content { transform: translateY(0) }
      }
    }
    &--tabs, &--routerTabs {
      /* Positioning */
      &-content { transform: translateY(-35px) }

      /* Box model */
      // grid-template-rows: 160px 124px;
      // margin-bottom: -82px;
      // @media #{$mobile-view} { margin-bottom: 0 }
      // @media #{$mobile-view} {
      //   & > .TheSubHeader__main { height: 120px }
      //   &-content { transform: translateY(-44px) }
      // }
    }
    &--filter,
    &--tabs, &--routerTabs {
      &-content { grid-area: content }
      
      @media #{$mobile-view} {
        /* Box model */
        display: flex;
        flex-flow: column;
        &-content { margin-bottom: 30px }
      }
    }
    &__main {
      /* Box model */
      grid-area: main;
      display: grid;
      grid-template-columns: $grid-columns-main;
      grid-template-areas: $grid-template-areas-main;
      @media (max-width: 1220px) { grid-column-gap: 1rem }
      @media #{$mobile-view} { grid-template-areas: $grid-template-areas-main-mobile }

      /* Visual */
      background: $color-blue-100;
    }

    &__mainWrap {
      grid-area: wrap;
      display: flex;
      margin: 0 auto;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      background: $color-blue-100;
    }
    &__title {
      /* Box model */
      grid-area: title;
      height: 80px;
      h1 {
        text-transform: lowercase;
      }
    }
    &__actions {
      /* Box model */
      grid-area: actions;
      justify-self: end;
      display: flex;
      align-self: center;
      height: 80px;
      gap: 1rem;
      &--default { height: auto }

      /* Visual */
      background: inherit;

      @media #{$mobile-view} {
        /* Positioning */
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: $z-label;

        /* Box model */
        height: auto;
        width: 100vw;
        flex-flow: column;
        align-items: flex-end;
        padding: 1.5rem 2rem 5rem;
        
        /* Visual */
        transition: transform .1s ease-out;
        transform: translateY(100%);

        &--mobile {
          /* Animation */
          transition: transform .1s ease-in;
          transform: translateY(0%);
        }
      }
    }
    &__button {
      /* Box model */
      // max-width: 250px;
      @media #{$mobile-view} { margin: .5rem 0; width: auto; }
    }
    &__floatbutton { display: none }
    &__floatbutton {
      @media #{$mobile-view} {
        /* Positioning */
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: $z-overlay;

        /* Box model */
        display: block;
        width: 42px;
        padding: 0;
        box-shadow: $shadow-low;

        &--intercom {
          right: 6rem;
        }
      }
    }
  }
</style>
<style lang="scss">
  .TheSubHeader {
    &__floatbutton {
      @media #{$mobile-view} {
        .BaseButton__Icon {
          margin: 0;
        }
      }
    }
  }
</style>