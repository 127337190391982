import RoutesMock from './routes'

const mockPromise = (status, response = {message: 'Success'}) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if(status) {
        resolve(response)
      } else {
        reject({message: 'Error'})
      }
    }, 500)
  })
}

const httpRequestMock = async ({status, response}, success, error, done = () => {}) => {
  try {
    const res = await mockPromise(status, response)
    return await success(res)
  } catch (e) {
    return await error(e)
  } finally {
    done()
  }

}

export {
  httpRequestMock,
  RoutesMock
}