import state from './state.dropoff'
import actions from './actions.dropoff'
import mutations from './mutations.dropoff'
import getters from './getters.dropoff'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}