const Orders = () => import( /* webpackChunkName: `orders` */ './Orders.vue')
const OrdersImport = () => import( /* webpackChunkName: 'OrdersImport' */ './Import/OrdersImport.vue')
const OrdersExport = () => import( /* webpackChunkName: 'OrdersExport' */ './Export/OrdersExport.vue')
const OrdersForm = () => import( /* webpackChunkName: 'OrdersForm' */ './Form/OrdersForm.vue')
const OrdersChangeStatus = () => import( /* webpackChunkName: 'OrdersChangeStatus' */ './ChangeStatus/OrdersChangeStatus.vue')
const OrdersIncoming = () => import( /* webpackChunkName: 'OrdersIncoming' */ './Incoming/OrdersIncoming.vue')
const DeliveryFailure = () => import( /* webpackChunkName: 'DeliveryFailure' */ './DeliveryFailure/DeliveryFailure.vue')


const routes = [
  {
    name: 'orders',
    meta:{
      label: 'pedido',
      module: 'module_orders'
    },
    path: '/orders',
    component: Orders
  },
  {
    name: 'ordersImport',
    meta:{
      label: 'pedido',
      module: 'module_orders'
    },
    path: '/orders/import',
    component: OrdersImport
  },
  {
    name: 'ordersExport',
    meta:{
      label: 'pedido',
      module: 'module_orders'
    },
    path: '/orders/export',
    component: OrdersExport
  },
  {
    name: 'ordersAdd',
    meta:{
      label: 'pedido',
      module: 'module_orders'
    },
    path: '/orders/add',
    component: OrdersForm
  },
  {
    name: 'ordersIncoming',
    meta:{
      label: 'pedido',
      module: 'module_orders'
    },
    path: '/orders/incoming',
    component: OrdersIncoming
  },
  {
    name: 'ordersChangeStatus',
    path: '/orders/changeStatus',
    meta:{
      label: 'pedido',
      module: 'module_orders'
    },
    component: OrdersChangeStatus
  },
  {
    name: 'ordersDeliveryFailure',
    meta:{
      label: 'Insucesso de entrega',
      module: 'module_orders'
    },
    path: '/orders/deliveryFailure',
    component: DeliveryFailure
  },
  {
    name: 'ordersDetail',
    meta:{
      label: 'pedido',
      module: 'module_orders'
    },
    path: '/orders/:id',
    component: OrdersForm
  },
]

export default routes