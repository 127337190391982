<template>
  <div class="Link" :class="LinkClass">
    <label v-if="!!label" ref="label" class="Link__label">{{ label }}</label>
    <a
      ref="input"
      class="Link__a"
      v-bind="$attrs"
      :href="value"
    >
      {{ value }}
    </a>
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Link',
  props: {
    value: {
      type: [String],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    theme:{
      type: String,
      default: 'dark',
      validator: val => ['dark', 'light'].includes(val)
    }
  },
  data() {
    return {
      debounce: null,
      focus: false
    }
  },
  computed: {
    LinkClass() {
      return [
        `Link__Theme--${this.theme}`
      ]
    },
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

$colors: (
  "yellow": $color-notice-base,
  "primary-base": $color-primary-base,
  "white": $color-neutral-softest,
  "gray": $color-neutral-strong
);

.Link {
  position: relative;
  opacity: .5;
  color: $color-neutral-stronger;
  .Link__a {
    display: block;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: $font-weight-regular;
    font-stretch: normal;
    font-size: $font-size-mini;
    line-height: $line-height-2xsmall;
  }

  &__Theme{
    &--dark{
      .Link__label{
        color: $color-neutral-stronger;
      }
      .Link__a{
        border-bottom: 2px solid $color-neutral-stronger;
        color: $color-neutral-stronger;
      }
    }
    &--light{
      .Link__label{
        color: $color-neutral-softest;
      }
      .Link__a{
        border-bottom: 2px solid $color-neutral-softest;
        color: $color-neutral-softest;
      }
    }
  }

  &__label {
    margin-right: 10px;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
    transition: transform 0.2s;
    pointer-events: none;
  }

  &__a {
    padding: 9px;
    width: 100%;
    background: transparent;

  }
}
</style>
