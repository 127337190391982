import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'
import download from '@/api/plugins/download'

export default {
  async fetchStores(success, error) {
    return await request({
      method: 'get',
      url: 'stores?with[]=info&paginate=9999'
    }, success, error)
  },

  async fetchRoutEasyStores(success, error) {
    return await request({
      method: 'get',
      url: 'routeasy/stores-enabled'
    }, success, error)
  },

  async fetchHubs(success, error) {
    return await request({
      method: 'get',
      url: 'transit_points?with[]=stores&paginate=999'
    }, success, error)
  },

  async fetchStoresToImport(success, error) {
    return await request({
      method: 'get',
      url: 'stores?filter[info][import_application]=1&parent_id[signal]=!=&paginate=9999&parent_id[value]=null'
    }, success, error)
  },

  async fetchStoresImport(success, error) {
    return await request({
      method: 'get',
      url: 'stores?paginate=9999&parent_id[signal]=<>&parent_id[value]=null'
    }, success, error)
  },

  async fetchStoresTable({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')

    return await request({
      method: 'get',
      url: `stores?is_table=stores&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchStoresWithInfo(success, error) {
    return await request({
      method: 'get',
      url: 'stores?with[]=info&parent_id[signal]=<>&parent_id[value]=null&paginate=9999'
    }, success, error)
  },

  async fetchStore({ id }, success, error) {
    return await request({
      method: 'get',
      url: `stores/${id}?with[]=info&with[]=address&with[]=transporters&with[]=integrationsParams&with[]=integrations`
    }, success, error)
  },

  async fetchStoresParents(success, error) {
    return await request({
      method: 'get',
      url: 'stores-parents'
    }, success, error)
  },

  async fetchStoresTransporters({id, paginate, page }, success, error, done) {
    return await request({
      method: 'get',
      url: `stores-transporters/stores?is_table=transportersVinculo&store_id=${id}&paginate=${paginate}&page=${page}`
    }, success, error, done)
  },

  async createStore(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'stores',
      body
    }, success, error, done)
  },

  async updateStore({ id, body }, success, error, done) {
    return await request({
      method: 'post',
      url: `stores/${id}`,
      body
    }, success, error, done)
  },

  async bindStoreTransporter(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'stores-transporters',
      body
    }, success, error, done)
  },

  async removeStoreTransporter({ transporterId, storeId }, success, error, done) {
    return await request({
      method: 'delete',
      url: `stores-transporters/${transporterId}/${storeId}`
    }, success, error, done)
  },

  async fetchStoresChildren( id, success, error) {
    return await request({
      method: 'get',
      url: `stores?paginate=9999&parent_id=${id}`
    }, success, error)
  },

  async saveStoresLocation(body, success, error, done) {
    let data = {
      lat: body.lat,
      lng: body.lng,
      geolocation_radius: body.geolocation_radius
    }
    return await request({
      method: 'put',
      url: `stores/${body.id}/location`,
      body: data
    }, success, error, done)
  },

  async downloadSpreadsheet(id, success, error, done) {
    return await download({
      method: 'get',
      url: `stores/${id}/download-spreadsheet-template`
    }, success, error, done)
  },

  async syncRoutEasy(id, body, success, error, done) {
    return await request({
      method: 'put',
      url: `routeasy/${id}/autosync`,
      body
    }, success, error, done)
  },
}