import state from './state.error'
import getters from './getters.error'
import mutations from './mutations.error'
import actions from './actions.error'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}