<template>
  <Fragment>
    <transition name="Drawer--animation">
      <div v-if="show" v-scroll-lock="show" class="Drawer">
        <div class="Drawer__Overlay" @click="confirmCloseAction" />
        <div
          class="Drawer__Container"
          :class="`Drawer__Container--${position}`"
          :style="containerStyle"
        >
          <div class="Drawer__Header">
            <div class="Drawer__HeaderTitle">
              <slot name="header" />
            </div>
            <Icon
              name="close-alt"
              class="Drawer__Close"
              @click="confirmCloseAction"
            />
          </div>
          <div class="Drawer__Body">
            <slot />
          </div>
        </div>
      </div>
    </transition>
    <Dialog
      v-if="confirmCloseDialog"
      :title="confirmCloseTitle"
      :warning="confirmCloseMessage"
      :label-cancel="confirmCloseCancel"
      :label-confirm="confirmCloseButton"
      @confirm="close"
      @cancel="confirmCloseDialog = false"
    >
      <slot name="confirmClose" />
    </Dialog>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
import { Dialog } from '@/components/molecules'
export default {
  name: 'Drawer',
  components: {
    Fragment,
    Dialog
  },
  props: {
    position: {
      type: String,
      default: 'right'
    },
    confirmClose: {
      type: Boolean,
      default: false
    },
    confirmCloseTitle: {
      type: String,
      default: ''
    },
    confirmCloseMessage: {
      type: String,
      default: 'Tem certeza que deseja sair?'
    },
    confirmCloseButton: {
      type: String,
      default: 'Sair'
    },
    confirmCloseCancel: {
      type: String,
      default: 'Cancelar'
    },
    maxWidth: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      show: false,
      confirmCloseDialog: false
    }
  },
  computed: {
    containerStyle() {
      return {
        maxWidth: `${this.maxWidth}px`
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyUp)
  },
  destroyed() {
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    open() {
      this.show = true
      this.$emit('open')
    },
    close() {
      this.show = false
      this.confirmCloseDialog = false
      this.$emit('close')
    },
    onKeyUp(e) {
      if (e.keyCode === 27 && this.show) this.confirmCloseAction()
    },
    confirmCloseAction() {
      if (this.confirmClose) {
        this.$emit('confirmClose')
        this.confirmCloseDialog = true
      } else {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: $z-modal-bg;
  transition: all 0.2s ease-out;

  &--animation {
    &-enter,
    &-leave-to {
      opacity: 0;
      .Drawer__Container {
        &--left {
          left: -100%;
        }
        &--right {
          right: -100%;
        }
      }
    }
  }

  &__Overlay {
    background-color: rgba($color-primary-stronger, $opacity-strong);
    width: 100vw;
    height: 100vh;
  }

  &__Container {
    position: absolute;
    height: 100vh;
    background: $color-white;
    top: 0;
    transition: all 0.2s ease-out;
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;

    @media #{$mobile-view} {
      width: 100%;
      max-width: 100%;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__Header {
    padding: $spacing-inset-medium;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media #{$mobile-view} {
      background: $color-primary-base;
      color: $color-white;
      text-align: center;
      align-items: center;
    }
  }

  &__HeaderTitle {
    width: 100%;
  }

  &__Close {
    width: 16px;
    cursor: pointer;
    @media #{$mobile-view} {
      filter: $filter-white;
    }
  }

  &__Body {
    padding: $spacing-inset-medium;
    padding-top: 0;
    overflow-y: auto;
    height: 100%;
  }
}
</style>
