const helpers = {
  methods: {
    isLinkAbsolute(to) {
      return (to.match(/^(http(s)?|ftp):\/\//)) ? true : false
    },

    openInNewTab(url) {
      url = !this.isLinkAbsolute(url) ? `${window.location.origin}${url}` : url
      window.open(url, '_blank')
    },

    trackTableAction(pageName, tagName, data) {
      this.$mixpanel.track(`${this.capitalizeString(pageName)} - Clicked on ${this.capitalizeString(tagName)} Table Action`, data)
    },

    trackSubheaderButtonString(pageName, tagName) {
      return pageName ? `${this.capitalizeString(pageName)} - Clicked on ${this.capitalizeString(tagName)} Button in Subheader` : ''
    },

    trackTabString(pageName, tagName) {
      return pageName ? `${this.capitalizeString(pageName)} - Clicked on ${this.capitalizeString(tagName)} Tab` : ''
    },

    capitalizeString(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
    }
  },
}

export default helpers