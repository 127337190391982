const Stores = () => import( /* webpackChunkName: 'Stores' */ './Stores.vue')
const StoresForm = () => import( /* webpackChunkName: 'StoresForm' */ './Form/StoresForm.vue')

const routes = [
  {
    name: 'stores',
    meta:{
      label: 'Novo',
      module: 'module_stores'
    },
    path: '/stores',
    component: Stores
  },
  {
    name: 'storesAdd',
    meta:{
      module: 'module_stores'
    },
    path: '/stores/add',
    component: StoresForm
  },
  {
    name: 'storesDetail',
    meta:{
      module: 'module_stores'
    },
    path: '/stores/:id',
    component: StoresForm
  },
]


export default routes