export default {
  queryString: {
    string: '',
    object: {
      storeNumber: [],
      store: [],
      transporter: [],
      dateRange: {},
      storeLabel: [],
      storeTransporters: [],
      storeStatus: [],
      query: [],
      searchValue: [],
      statusSelected: {},
      driversId: [],
      parsedDrivers: null,
      romaneioSelected: {},
      driveSelected: {},
      id: [],
      transporter_id: [],
      status_id: '',
      storeSelected: [],
      typeSelected: []
    }
  }
}