<template>
  <nav class="BaseTabs" v-bind="$attrs">
    <ul class="BaseTabs__list" :class="{'BaseTabs__list--single': items.length <= 1}">
      <li
        v-for="item in items"
        :ref="item.id"
        :key="item.title"
        class="BaseTabs__item"
        :class="classItem(item)"
        @click="handleClickItem(item)"
      >
        <router-link v-if="type === 'routerTabs'" :to="item.route">
          <span v-html="item.title" />
        </router-link>
        <span v-else v-html="item.title" />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    currentTab: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true,
      validator: items => {
        return (
          items.filter(item => {
            return Object.prototype.hasOwnProperty.call(item, 'id') && Object.prototype.hasOwnProperty.call(item, 'title')
          }).length === items.length
        )
      }
    },
    pageTitleTag: {
      type: [String, null],
      default: null
    },
    type: {
      type: String,
      default: 'default',
      validator: val => ['default', 'filter', 'tabs', 'routerTabs'].includes(val)
    },
  },
  data() {
    return {
      newCurrentTab: ''
    }
  },
  methods: {
    classItem(item) {
      return [{ 'BaseTabs__item--active': this.currentTab === item.id }]
    },
    setNewCurrentTab(id) {
      this.newCurrentTab = id
    },
    handleClickItem(item) {
      if (this.pageTitleTag && item.tagName) this.$mixpanel.track(this.trackTabString(this.pageTitleTag, item.tagName))
      this.$emit('click', item)
      this.setNewCurrentTab(item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.BaseTabs {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 300vw;
    height: calc(100% + 30px);
    background: $color-blue-100;
    bottom: -30px;
    left: -100vw;
    z-index: -1;
  }

  @media #{$mobile-view} {
    margin-top: 35px;
  }

  &__list {
    padding: 0;
    margin: 0px;
    display: flex;

    &--single {
      .BaseTabs__item {
        box-shadow: none !important;
      }
    }
  } 

  &__item {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
    box-shadow: inset 0 -1px 0 0 rgba($color-primary-softer, 0.33);
    text-transform: lowercase;

    &:first-of-type {
      border-top-left-radius: $border-radius-1xsmall;
    }
    &:last-of-type {
      border-top-right-radius: $border-radius-1xsmall;
    }

    span {
      display: inline-block;
      padding: $spacing-inset-small;
    }

    &--active {
      position: relative;
      z-index: $z-label;
      box-shadow: inset 0 -3px 0 0 $color-primary-base;
    }
  }
}
</style>
