export default {
  ordersWithConfirmedPackagesWithAction(state) {    
    return { ...state.ordersWithConfirmedPackages,
      data: { 
        content: state.ordersWithConfirmedPackages.data.content.map((o) => {
          return {
            ...o,
            actions: [{action_id: 'download', has_permission: true}]

          }
        }),
        headers: {
          ...state.ordersWithConfirmedPackages.data.headers,
          transporter: {
            ...state.ordersWithConfirmedPackages.data.headers.transporter,
            isVisible: false
          },
        }
      } 
    }
  },

  incomingPackagesWithDeleteAction(state) {
    return {
      ...state.incomingPackages,
      data: { 
        content: state.incomingPackages.data.content.map((i) => {
          return {
            ...i,
            actions: [{
              action_id: 'delete',
              has_permission: true
            }]
          }
        }),
        headers: {
          ...state.incomingPackages.data.headers,
          actions: {
            id: 'actions',
            param: 'actions',
            label: 'Ações',
            method: false,
            actions: true,
            isVisible: true
          }
        }
      } 
    }
  },

  packagesReportsRequestedWithLoading(state){
    let headers = state.packagesReportsRequested.data.headers
    if (headers && headers.created_at && headers.created_at.method === false) {
      headers.created_at.method = 'millisecondsToDateHour'
    }
    return {
      data: {
        content: state.packagesReportsRequested.data.content.map((reportRequested) => {
          let i = reportRequested
          if (i.status.toLowerCase() === 'andamento') {
            i.actions = [{action_id: 'loading', has_permission: true}]
          }
          if (i.status.toLowerCase() === 'concluída com erros' || i.status.toLowerCase() === 'erro' || i.status.toLowerCase() === 'incompleto') {
            i.status = 'Erro'
            i.actions = [{action_id: 'visualizeErrors', has_permission: true}]
          }
          return i
        }),
        headers: headers       
      },
      pagination: state.packagesReportsRequested.pagination
    }
  },
}