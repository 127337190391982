<template>
  <div class="InputFile" :class="InputFileClass" :style="style">
    <!-- Label -->
    <label v-if="!!label" class="InputFile__Label" :class="labelClass">
      {{ label }}
    </label>

    <!-- Input -->
    <div class="InputFile__Content">
      <!-- Initial / Saving -->
      <div
        class="InputFile__Dropbox" 
        :class="InputClass"
      >
        <input
          v-if="displayFields.input"
          type="file"
          :accept="accept"
          class="InputFile__Input"
          :class="{'InputFile__Input--disabled': disabledFields.input}"
          :multiple="multiple"
          :name="_uid"
          :disabled="disabledFields.input"
          @input="onChange($event)"
        >
        <!-- Initial -->
        <template v-if="displayBox.initial">
          <div class="InputFile__Input__Box">
            <Icon name="upload-alt" class="InputFile__Icon" />
            <p>Procure seu arquivo</p>
          </div>
        </template>
        <!-- Saving -->
        <template v-if="status.saving">
          <p>
            Uploading files...
          </p>
        </template>
        <!-- Success -->
        <template v-if="displayBox.success">
          <BaseInputFileUploaded 
            :file="formDataFiles[0]" 
            :multiple="multiple" 
            :show-image-name="showImageName" 
            @visualize="handleVisualize"
            @confirmRemove="confirmRemove"
            @remove="handleRemove" 
          />
        </template>
      </div>

      <!-- Formats -->
      <div v-if="showFormats" class="InputFile__Formats">
        <p>Formatos aceitos: JPG, PNG, XLSX, XLS, PPT, PPTX e PDF</p>
      </div>
      
      <!-- List of Files -->
      <BaseInputFileList 
        v-if="list"
        :files="formDataFiles"
        :multiple="multiple"
        :list="true"
        @confirmRemove="confirmRemove"
        @remove="handleRemove"
      />

      <BaseButton
        v-if="confirm && formDataFiles.length > 0"
        filled
        color="dark"
        text-color="white"
        label="Confirmar Upload"
        class="InputFile__Confirm"
        :disabled="disabledFields.confirm"
        @click="handleSave"
      />
    </div>

    <!-- Visualize -->
    <BaseInputFileModal ref="visualize" />

    <!-- Dialog -->
    <BaseInputFileDialog ref="dialog" @remove="handleRemove" />
  </div>
</template>
<script>
// import { file } from '@/api'

import { BaseButton } from '@/components/atoms'
import { acceptFormats } from './utils/index.js'
import BaseInputFileMixin from '@/mixins/baseInputFileMixin'
import BaseInputFileList from './BaseInputFileList'
import BaseInputFileUploaded from './BaseInputFileUploaded'
import BaseInputFileModal from './BaseInputFileModal'
import BaseInputFileDialog from './BaseInputFileDialog'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  name: 'BaseInputFile',
  components: {
    BaseButton,
    BaseInputFileList,
    BaseInputFileUploaded,
    BaseInputFileModal,
    BaseInputFileDialog
  },
  mixins: [BaseInputFileMixin],
  props: {
    value:{
      type: FormData,
      default: null
    },
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: Array,
      default: () => acceptFormats.all
    },
    showFormats: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: Boolean,
      default: true
    },
    showImageName: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: function() {
        return this.list
      },
    },

    //Styles
    width: {
      type: String,
      description: 'Max width input',
      default: '100%',
    },
    maxWidth: {
      type: String,
      description: 'Width input',
      default: '100%',
    },
    height: {
      type: String,
      description: 'Height dropbox content',
      default: '150px',
    },
    maxHeight: {
      type: String,
      description: 'Max Height dropbox content',
      default: '150px',
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: STATUS_INITIAL,
    }
  },
  computed: {
    //Styles
    style: function() {
      return {
        '--width': this.width,
        '--max-width': this.maxWidth,
        '--height': this.height,
        '--max-height': this.maxHeight,
      }
    },

    //Class
    InputFileClass() {
      return []
    },
    labelClass() {
      return []
    },
    InputClass() {
      return [
        this.list || this.status.initial || this.status.saving ? 'InputFile__Dropbox--upload' : '',
        !this.list && (this.status.success || this.status.failed) ? 'InputFile__Dropbox--uploaded' : '',
      ]
    },

    status() {
      return {
        initial: this.currentStatus === STATUS_INITIAL,
        saving: this.currentStatus === STATUS_SAVING,
        success: this.currentStatus === STATUS_SUCCESS,
        failed: this.currentStatus === STATUS_FAILED
      }
    },

    //Fields
    disabledFields() {
      return {
        input: this.status.saving || (!this.multiple && this.formDataFiles.length === 1),
        confirm: this.status.saving
      }
    },
    displayFields() {
      return {
        input: this.list || (!this.status.success || !this.actions)
      }
    },
    displayBox() {
      return {
        initial: this.status.initial || this.list,
        success: this.status.success && !this.list,
      }
    },

  },
  methods: {

    //Actions
    onInput() {
      this.$emit('input', this.formDataModel, this.formDataName)
    },

    onChange(data) {
      const fileList = data.target.files
      if (!fileList.length) return
      this.populateFormData(fileList)   
      this.handleFile()
    },


    //Handlers
    handleFile() {
      this.currentStatus = STATUS_SAVING

      this.fakeUpload()
        .then(async () => {
          // this.uploadedFiles = [].concat(files)
          this.currentStatus = STATUS_SUCCESS
          this.onInput()
        })
        .catch((err) => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
        })

    },

    async fakeUpload() {
      const promises = await this.setFormDataFiles()
      return Promise.all(promises)
    },

    confirmRemove(data) {
      this.$refs.dialog.handlerOpen(data, 'remove')
    },

    handleRemove(data) {
      //Delete function on mixin
      this.deleteFileFormData(data)

      if (this.formDataFiles.length === 0) {
        this.reset()
      }

      this.onInput()
    },

    handleVisualize(data) {
      this.$refs.visualize.open(data)
    },

    handleSave() {
      this.$emit('save', this.formDataModel, this.formDataFiles)
    },
    
    reset() {
      this.currentStatus = STATUS_INITIAL
      // this.uploadedFiles = []
      this.uploadError = null
    },


  },
}
</script>
<style lang="scss" scoped>
.InputFile {

  display: flex;
  flex-direction: column;
  padding: 5px;
  width: var(--width);
  max-width: var(--max-width);

  &__Label {
    display: block;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
    pointer-events: none;
    margin-bottom: 0.5rem;
  }

  //reset
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__Content {
    height: 100%;
  }

  &__Dropbox {
    width: fit-content;
    width: 100%;
    height: var(--height);
    max-height: var(--max-height);
    aspect-ratio: 14/9;
    background: #FFF;
    position: relative;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    &--upload {
      outline: 2px dashed grey;
      cursor: pointer;

      &:hover {
        background: #ededed;
        opacity: 0.5;
      }
    }

    // &--uploaded {
    //   // height: 100%;
    // }
  }

  &__Input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    z-index: 1;
    
    &--disabled {
      cursor: no-drop;
    }

    &__Box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        margin-top: 10px;
        color: $color-neutral-stronger;
        font-weight: $font-weight-bold;
        font-size: $font-size-3xsmall;
      }
    }
  }

  &__Icon {
    width: 30px;
  }

  &__Formats {
    text-align: center;
    margin: 10px 0;
    color: $color-neutral-stronger;
  }

  &__Confirm {
    height: 30px;
    margin: 20px auto 0 auto;

    @media (min-width: $viewport-md) {
      max-width: 200px;
    }

    /deep/ span {
      margin-left: 0;
      
    }
  }

}
</style>
