<template>
  <div class="BaseCheckBoxGroup">
    <div v-if="label" class="BaseCheckBoxGroup__title">
      {{ label }}
    </div>
    <template v-for="item in list">
      <div :key="item.id" class="BaseCheckBoxGroup__container">
        <label class="BaseCheckBoxGroup__label">
          <input
            :id="item.id"
            :value="item"
            type="checkbox"
            class="BaseCheckBoxGroup__checkbox"
            :name="item.id"
            :checked="isChecked(item)"
            @change="onChange(item)"
          >
          {{ item.name }}</label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckBoxGroup',
  props: {
    label: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true,
      validator: items => {
        return (
          items.filter(item => {
            return Object.prototype.hasOwnProperty.call(item, 'name') && Object.prototype.hasOwnProperty.call(item, 'id')
          }).length === items.length
        )
      }
    },
    value: {
      type: Array,
      required: true
    }
  },
  methods: {
    onChange(val){
      const filteredValues = this.value.filter(v => v.id !== val.id)
      const selectedList = filteredValues.length < this.value.length ? filteredValues : [...filteredValues, val]
      this.$emit('input', selectedList)
    },
    isChecked(val) {
      return this.value.find(v => v.id === val.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.BaseCheckBoxGroup {
   &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
    color: $color-neutral-stronger;
    .InputGroup__input {
      background-color: $color-neutral-soft;
    }
  }
  &__title {
    font-size: $font-size-3xsmall;
    font-weight: $font-weight-bold;
    color: $color-neutral-stronger;
    margin-bottom: 10px;
  }
  &__label {
    color: $color-neutral-stronger;
    font-weight: $font-weight-regular;
    font-size: $font-size-mini;
    user-select: none;
  }
  &__checkbox {
    margin-right: 10px;
  }
}
</style>
