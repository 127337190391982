<template>
  <section class="BaseMultiselect" :class="BaseMultiselectClass">
    <label class="BaseMultiselect__label">{{ label }}</label>

    <!-- Tooltip -->
    <BaseTooltip
      v-if="tooltip.length > 0 || tooltipLabel"
      :tooltip="tooltip"
      :tooltip-label="tooltipLabel"
      :tooltip-icon="tooltipIcon"
      @tooltip="onTooltip"
    />

    <!-- Select -->
    <Treeselect
      ref="input"
      class="BaseMultiselect__input"
      :value="value"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled || readOnly"
      :tab-index="tabIndex"
      :loading="isLoading"

      :multiple="multiple"
      :options="options"

      :allow-clearing-disabled="allowClearingDisabled"
      :allow-selecting-disabled-descendants="allowSelectingDisabledDescendants"
      :always-open="alwaysOpen"
      :append-to-body="appendToBody"
      :async="async"
      :auto-focus="autoFocus"
      :auto-load-root-options="autoLoadRootOptions"
      :auto-deselect-ancestors="autoDeselectAncestors"
      :auto-deselect-descendants="autoDeselectDescendants"
      :auto-select-ancestors="autoSelectAncestors"
      :auto-select-descendants="autoSelectDescendants"
      :backspace-removes="backspaceRemoves"
      :before-clear-all="beforeClearAll"
      :branch-nodes-first="branchNodesFirst"
      :cache-options="cacheOptions"
      :clearable="clearable"
      :clear-all-text="clearAllText"
      :clear-on-select="clearOnSelect"
      :clear-value-text="clearValueText"
      :default-expand-level="defaultExpandLevel"
      :default-options="defaultOptions"
      :delete-removes="deleteRemoves"
      :delimiter="delimiter"
      :flatten-search-results="flattenSearchResults"
      :disable-branch-nodes="disableBranchNodes"
      :disable-fuzzy-matching="disableFuzzyMatching"
      :flat="flat"
      :join-values="joinValues"
      :limit="checkLimit"
      :limit-text="limitText"
      :loading-text="loadingText"
      :load-options="loadOptions"
      :match-keys="matchKeys"
      :max-height="maxHeight"
      :max-items="maxItems"
      :item-label="itemLabel"
      :no-children-text="noChildrenText"
      :no-options-text="noOptionsText"
      :no-results-text="noResultsText"
      :normalizer="normalizer"
      :open-direction="openDirection"
      :open-on-click="openOnClick"
      :open-on-focus="openOnFocus"
      :retry-text="retryText"
      :retry-title="retryTitle"
      :searchable="searchable"
      :search-nested="searchNested"
      :search-prompt-text="searchPromptText"
      :show-count="showCount"
      :show-count-of="showCountOf"
      :show-count-on-search="showCountOnSearch"
      :sort-value-by="sortValueBy"
      :value-consists-of="valueConsistsOf"
      :value-format="valueFormat"
      :z-index="zIndex"

      v-bind="$attrs"
      v-on="listeners"
      @paste.native="onPaste"
      @keydown.native="onKeydown"
    >
      <!-- Select All -->
      <template v-if="showSelectAll">
        <div slot="before-list" class="vue-treeselect__menu--select-all">
          <div class="vue-treeselect__list-item">
            <div class="vue-treeselect__option">
              <div class="vue-treeselect__label-container">
                <div class="vue-treeselect__checkbox-container">
                  <input
                    :id="`select-all-${_uid}`"
                    v-model="checkSelectAll"
                    type="checkbox"
                    class="vue-treeselect__checkbox vue-treeselect__checkbox--select-all"
                  >
                </div>
                <label
                  :for="`select-all-${_uid}`"
                  class="vue-treeselect__label vue-treeselect__label--select-all"
                >
                  {{ selectAllText }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- Select Value -->
      <template>
        <div slot="value-label" slot-scope="{node}">
          {{ node.raw.label }}
        </div>
      </template>
    </Treeselect>

    <p v-if="isInvalid" class="BaseMultiselect__invalidLabel">
      {{ invalidLabel }}
    </p>

    <template v-if="alert.message && hasAlert">
      <p
        class="BaseMultiselect__invalidLabel BaseMultiselect--alert"
        :class="`BaseMultiselect--alert--text-${alert.props.type}`"
      >
        {{ alert.message }}
      </p>
      <Icon
        :name="alert.props.icon"
        class="BaseMultiselect--alert--icon"
        :class="`BaseMultiselect--alert--icon-${alert.props.type}`"
      />
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { suggestions } from '@/api'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { ASYNC_SEARCH, LOAD_ROOT_OPTIONS  } from '@riophae/vue-treeselect'
import BaseTreeSelectMixin from './BaseTreeSelectMixin'
import { BaseTooltip } from '@/components/atoms'

export default {
  name: 'BaseTreeSelect',
  components: { BaseTooltip, Treeselect },
  mixins: [BaseTreeSelectMixin],
  props: {
    //General
    label: {
      type: String,
      description: 'Input label (text before input)',
      default: '',
    },
    infoMessage: {
      type: String,
      description: 'Information block text',
      default: '',
    },
    invalidLabel: {
      type: String,
      default: 'O campo está incorreto',
    },
    theme: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    hideSelectAll: {
      type: Boolean,
      default: false
    },

    //Tooltip
    tooltip: {
      type: [Array, Boolean, String],
      description: '',
      default: () => [],
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
    tooltipIcon: {
      type: String,
      default: '',
    },

    //Icons
    appendIcon: {
      type: String,
      description: 'Append icon (right)',
      default: '',
    },
    appendIconColor: {
      type: String,
      description: 'Append icon color (right)',
      default: null,
    },
    prependIcon: {
      type: String,
      description: 'Prepend icon (left)',
      default: '',
    },
    prependIconColor: {
      type: String,
      description: 'Prepend icon color (left)',
      default: null,
    },
    iconColor: {
      type: String,
      description: 'Icons color (left and right)',
      default: null,
    },
    iconGoogle: {
      type: Boolean,
      default: false,
    },

    //Input Default
    value: {
      type: [String, Number, Array, Date, Object],
      description: 'Input value',
      default: null,
    },
    name: {
      type: String,
      description: 'Input name',
      default: '',
    },
    placeholder: {
      type: String,
      description: 'Input placeholder',
      default: '',
    },
    maxItems: {
      type: Number,
      description: 'Max limit items',
      default: 0,
    },
    itemLabel: {
      type: String,
      description: 'Item limit label',
      default: 'pedidos',
    },
    size: {
      type: String,
      description: 'Input size',
      default: 'md',
      validator: (val) => ['sm', 'md', 'lg'].includes(val),
    },
    tabIndex: {
      type: Number,
      description: 'Tabindex value',
      default: 0,
    },
    readOnly: {
      type: [String, Boolean],
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },

    //Classes
    inputGroupCustomClasses: {
      type: String,
      description: 'Input group css classes',
      default: '',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
      default: '',
    },

    //Status
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)',
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasAlert: {
      type: Boolean,
      default: false,
    },


    //Styles
    bordered: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: '',
    },
    lined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    shaded: {
      type: Boolean,
      default: true,
    },
    filledColor: {
      type: String,
      default: '',
    },
    floating: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      //General
      focused: false,
      debounce: null,

      //Helpers
      options: [],
      enablePasteData: this.enablePaste,

      //Checkers
      tagsMultipleLines: false,
      tagsNotMultipleLines: false,
      tagsNotMultipleLinesCount: 0,
      selectHeightStart: 0,
      selectHeightStartMax: 55,
      hasSearchString: false,
      hasNoResultsOnSearch: false,
      previousTimestamp: null,

      //Key Codes
      KEY_CODES: {
        ENTER: 13,
        LEFT_WIN: 91,
        CTRL: 17,
        V: 86
      },
      //Alerts
      alert: {
        message: '',
        props: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      bippingSensitivity: 'userAgent/bippingSensitivity',
    }),

    //General
    listeners() {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        clear: this.onClear,
        close: this.onClose,
        deselect: this.onDeselect,
        focus: this.onFocus,
        input: this.onInput,
        open: this.onOpen,
        'search-change': this.onSearch,
        select: this.onSelect,
      }
    },

    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      }
    },

    checkSelectAll: {
      get: function() {
        const valuesLength = this.value ? this.value.length : 0
        const response = this.optionsLength === valuesLength

        //It is not a good solution, but the plugin does not work bind with named slot
        this.bindSelectAll(response)

        return response
      },
      set: function(newValue) {
        if (newValue) {
          this.setSelectAll()
        } else {
          this.$emit('input', [])
        }
      },
    },

    selectAllText() {
      return this.checkSelectAll === true
        ? 'Desselecionar Todos'
        : 'Selecionar Todos'
    },

    showSelectAll() {
      return !this.hideSelectAll && this.multiple && (this.options && this.options.length > 0 && this.selectAll && !this.hasSearchString)
    },

    checkLimit() {
      return this.tags && !this.tagsNotMultipleLines ? this.limit : 0
    },

    optionsLength() {
      let optionsLength = 0

      if (this.options && Array.isArray(this.options)) {
        optionsLength = this.options
          .map((el) => el.children?.length || 1)
          .reduce((total, current) => total + current, 0)
      }
      return optionsLength
    },

    //Styles
    BaseMultiselectClass() {
      return [
        `BaseMultiselect__${this._uid}`,
        this.disabled && 'BaseMultiselect--disabled',
        this.isInvalid && 'BaseMultiselect--invalid',
        this.readOnly && 'BaseMultiselect--read-only',
        this.preserveHeight && 'BaseMultiselect--preserve',
        this.rounded && 'BaseMultiselect--rounded',
        this.buttonType && 'BaseMultiselect--button-type',
        this.hasAlert && 'BaseMultiselect--alert',
        this.alert.message && 'BaseMultiselect--alert-message',
        this.theme && `BaseMultiselect--${this.theme}`
      ]
    }
  },
  watch: {
    value: function(val) {
      this.checkMaxOptions(val)
      this.checkerForTags()
    },
    selectOptions: {
      handler: function(val) {
        this.copyOption(val)
        this.checkAutoSelect(val)
      },
      deep: true
    },
    options: {
      handler: function(val) {
        this.checkAutoSelect(val)
      },
      deep: true
    },
    readOnly: {
      handler: function(val) {
        if (val) {
          this.copyOption(null)
        }
      },
      deep: true
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      setAlert: 'error/setAlert'
    }),

    //Init
    init() {
      if (this.tags) {
        this.checkMultiselectHeight()
      }
      this.checkerForTags()
      this.copyOption(this.selectOptions)
    },

    //Handlers
    onAddTag(evt) {
      this.$emit('addTag', evt)
    },

    onBlur(evt) {
      this.focused = false
      this.$emit('blur', evt)
    },

    onClear(evt) {
      this.$emit('input', this.multiple ? [] : null)
      this.$emit('clear', evt)
      this.cleanAlertMessage()
    },

    onClose(evt) {
      this.$emit('close', evt)
    },

    onDeselect(evt) {
      this.$emit('deselect', {tag: evt, all: this.value})
    },

    onFocus(evt) {
      this.focused = true
      this.$emit('focus', evt)
    },

    onInput(evt) {
      let value = evt
      this.$emit('input', value)

      this.cleanAlertMessage(value)
    },

    onOpen(evt) {
      this.$emit('open', evt)
      this.onFocus(evt)
    },

    onRemoveTag(evt) {
      this.$emit('removeTag', evt)
    },

    onSearch(evt) {
      this.$emit('search', evt)
      this.checkShowSelectAll(evt)
      this.$nextTick(() => {
        this.hasNoResultsOnSearch =
          !!document.querySelector(`.BaseMultiselect__${this._uid} .vue-treeselect__no-results-tip`)
          || !!document.querySelector(`.BaseMultiselect__${this._uid} .vue-treeselect__no-options-tip`)

      })
    },

    onSelect(evt) {
      this.$emit('select', evt)
      this.setAlertMessage(evt)
    },

    onPaste(evt) {
      if (!this.suggestions && this.enablePaste && this.allowCreate) {
        const clipboardArray = this.mountClipboardArray(evt.clipboardData.getData('text'))

        let limit = clipboardArray.length
        if(this.maxItems) {
          const totalItems = this.value?.length || 0
          limit = (this.maxItems - totalItems)

          if(clipboardArray.length > limit) {
            setTimeout(() => {
              this.setAlert(`O limite máximo de pesquisa é de ${this.maxItems} ${this.itemLabel} por vez`)
            }, 500)
          }
        }
        if(!limit) return

        this.handleClipboard(clipboardArray.slice(0, limit))
        this.onSelect(evt)
      }
    },

    onKeydown(evt) {
      const key = evt.keyCode
      this.handleBipping(evt)
      switch (key) {
      case this.KEY_CODES.ENTER:
        if (this.allowCreate && this.hasNoResultsOnSearch) {
          const totalItems = this.value?.length || 0
          if(!this.maxItems || this.maxItems > totalItems) {
            this.addTag(evt.target.value)
          }
        } else if (this.allowEmptyOptions) {
          this.addEmptyOptions(evt.target.value)
        }
        break
      case this.KEY_CODES.LEFT_WIN:
      case this.KEY_CODES.CTRL:
      case this.KEY_CODES.V:
        if (this.enablePaste) {
          this.handleEnablePaste(true)
        }
        break
      default:
        // this.handleBipping(evt)
        break
      }
    },

    onTooltip(action) {
      this.$emit(action, action)
    },

    handleBipping(evt) {
      if (!evt) return

      if (this.enableBipping) {
        if (evt.keyCode === this.KEY_CODES.ENTER) {
          let value = evt ? evt.target.value : this.value
          const dateNow = new Date().getTime()
          if (dateNow - this.previousTimestamp < this.bippingSensitivity) {
            this.$emit('bipping', value)
          } else {
            this.$emit('not-bipping', value)
          }
        } else {
          this.previousTimestamp = new Date().getTime()
        }
      }

    },

    //Tags
    addTag(evt) {
      if (!evt) return

      const value = evt
      const tag = { id: value, label: value, name: value}

      //Group Check
      const groupName = 'Adicionados'
      const group = this?.options?.find(el => el.name === groupName)

      //Create Group if doesnt exist
      if (!group) {
        let group = {
          id: groupName,
          name: groupName,
          label: groupName,
          children: []
        }
        this?.options?.unshift(group)
        if (this.options?.[0]?.children.filter(t => String(t.id).toLowerCase() === String(tag.id).toLowerCase()).length === 0) {
          group.children.unshift(tag)
        }

        //Handler Input
        this.checkAddTagType(tag)
      } else {
        const groupCreated = this.options.find(el => el.name === groupName)
        if (groupCreated) {
          //Verify if tag exist on group
          let hasOption = groupCreated.children.find(el => el.name === value)
          if (!hasOption) {
            if (this.options?.[0]?.children.filter(t => String(t.id).toLowerCase() === String(tag.id).toLowerCase()).length === 0) {
              groupCreated.children.unshift(tag)
            }
            //Handler Input
            this.checkAddTagType(tag)
          }

        }
      }
      //Reset Search String when add tag
      if (this.$refs.input.trigger.searchQuery) {
        this.$refs.input.resetSearchQuery()
      }
    },

    removeTag(evt) {
      this?.options?.map((option, x) => {
        option.children.map((el, y) => {
          if (el.id === evt) {
            this.options[x].children.splice(y, 1)
          }
        })

        //Remove group if has no children
        if (option.children.length === 0) {
          this.options.splice(x, 1)
        }
      })

      this.onRemoveTag()

    },

    addEmptyOptions(evt) {
      if (!evt) return

      const value = evt
      const tag = { id: value, label: value, name: value}
      this.checkAddTagType(tag)
    },

    checkAddTagType(tag) {
      if (this.multiple) {
        const tempValue = this.value
        tempValue.push(tag)
        this.onInput(tempValue)
      } else {
        this.onInput(tag)
      }

      this.onAddTag(tag)
    },

    //Async Load Option
    async loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {

        const searchLenghtChecker = searchQuery.length > this.minSearchLength
        if(!searchLenghtChecker) {
          callback(null, [])
          return
        }

        const options = await suggestions[this.suggestions](
          {
            params: this.suggestionsParams,
            search: searchQuery,
            routeName: this.$route.name,
          },
          (res) => res,
          (e) => e
        )
        callback(null, options)
      }
      if (action === LOAD_ROOT_OPTIONS && this.fetchOptions) {
        const result = await this.$store.dispatch(this.fetchOptions, this.fetchParams)
        if (Array.isArray(result)) {
          this.options = result
        }
        this.formatReturn()

        let currentValue = this.$refs.input.getInput().$el.querySelectorAll('input')[0].value

        await this.$refs.input.resetSearchQuery()
        await this.$refs.input.getInput().onInput({
          target: { value: currentValue },
        })
      }
    },

    //Helpers
    copyOption(optionsFromProp) {
      this.options = Array.isArray(optionsFromProp) ? Array.from(optionsFromProp) : optionsFromProp
      this.formatReturn()
    },

    formatReturn() {
      if (this.returnOnly.length > 0) {
        if (this.options && Array.isArray(this.options)) {
          this.options.map((store, i) => {
            if (store.children) {
              store.children.map((children, c) => {
                let obj = {}
                for (let i = 0; i < this.returnOnly.length; i++) {
                  obj[this.returnOnly[i]] = children[this.returnOnly[i]]
                }
                this.options[i].children[c] = obj
              })
            }
          })
        }
      }
    },

    //-- Select All
    setSelectAll() {
      let data = []
      this.options.map((el) => {
        //Check if element has children
        const hasChildren = Object.prototype.hasOwnProperty.call(el, 'children') && el.children.length > 0
        if (hasChildren) {
          data.push(...el.children)
        } else {
          data.push(el)
        }
      })
      this.onInput(data)
    },

    bindSelectAll(value) {
      const containerSelectAll = document.querySelector(`.BaseMultiselect__${this._uid} .vue-treeselect__menu--select-all`)
      const inputSelectAll = document.querySelector(`.BaseMultiselect__${this._uid} .vue-treeselect__checkbox--select-all`)
      const labelSelectAll = document.querySelector(`.BaseMultiselect__${this._uid} .vue-treeselect__label--select-all`)
      if (containerSelectAll) {
        inputSelectAll.checked = value
        labelSelectAll.innerHTML = value ? 'Desselecionar todos' : 'Selecionar todos'
        containerSelectAll.classList[value ? 'add' : 'remove']('vue-treeselect__menu--select-all-selected')
      }
    },

    checkShowSelectAll(searchString) {
      if (searchString.length === 0) {
        this.hasSearchString = false
      } else {
        this.hasSearchString = true
      }
    },

    //-- On Paste
    handleClipboard(data) {
      this.handleEnablePaste(false)
      data.forEach(element => {
        this.addTag(element)
      })
    },

    handleEnablePaste(bool) {
      this.enablePasteData = bool
    },

    mountClipboardArray(data) {
      return this
        .formatClipboardData(data)
        .reduce((arr, item) => [...arr, item], [])
        .filter((item) => item !== '')
    },

    formatClipboardData(data) {
      return [...new Set(data.split(/\r?\n/))]
    },

    //Checkers
    checkTagsMultipleLines() {
      this.$nextTick(() => {
        const heightRef = this.$refs.input.$el.clientHeight
        //Get Multiselect height
        if (heightRef <= this.selectHeightStart) {
          if (this.tagsMultipleLines) {
            this.tagsMultipleLines = false
          }
          this.checkTagsMultipleLinesCount()
          return
        }
        this.checkTagsMultipleLinesHandler()
      })
    },

    checkMultiselectHeight() {
      //If its the first time the component is mounted
      const heightRef = this.$refs.input.$el.clientHeight
      this.selectHeightStart =
        heightRef > 0 ? heightRef : this.selectHeightStartMax
      this.tagsNotMultipleLinesCount =
        this.value !== null ? this.value.length : 0
    },

    checkerForTags() {
      if (this.tags && !this.preserveHeight) {
        this.checkTagsMultipleLines()
      }
    },

    disableOptions(options, values, disable = true) {
      return options.map(opt => {
        if(!opt?.children) return opt

        const newChildren = opt.children.map(children => {
          if(values.some(({id}) => id === children.id)) return children

          return {...children, isDisabled: disable}
        })
        return {...opt, children: newChildren}
      })
    },

    checkMaxOptions(values) {
      if(this.maxItems && this.optionsLength && values?.length){
        const disable = values.length === this.maxItems
        this.options = this.disableOptions(this.options, values, disable)
      }
    },

    checkTagsMultipleLinesHandler() {
      if (this.persistTags) {
        this.tagsMultipleLines = true
      } else {
        this.tagsNotMultipleLines = true
        this.tagsNotMultipleLinesCount = this.value.length
      }
    },

    checkTagsMultipleLinesCount() {
      const valuesDifference =
        (this.value !== null ? this.value.length : 0) <
        this.tagsNotMultipleLinesCount
      if (valuesDifference && this.tagsNotMultipleLines) {
        this.tagsNotMultipleLines = false
      }
    },

    checkAutoSelect(options) {
      if (this.autoSelect && options !== null) {
        if (options.length === 1) {
          this.onInput(options)
        } else {
          this.onInput(options[0])
        }
      }
    },

    setAlertMessage(data) {
      if(!data?.alert) return

      const alertColor = {
        warning: {type: data.alert.type, icon: 'exclamation-circle'},
        error: {type: data.alert.type, icon: 'error'},
        success: {type: data.alert.type, icon: 'success'},
        info: {type: data.alert.type, icon: 'info'}
      }

      this.alert.message = data.alert.message
      this.alert.props = alertColor[data.alert.type]
    },

    cleanAlertMessage(input) {
      if(!this.alert.message || (input && input.alert)) return

      this.alert = { message: '', props: {} }
    }

  },
}

</script>

<style lang="scss" scoped>
.BaseMultiselect {
  width: 100%;
  position: relative;

  &--disabled {
    /deep/ .vue-treeselect {
      .vue-treeselect__control {
        background-color: $color-neutral-softer;
        .vue-treeselect__x-container {
          display: none;
        }
      }
    }
  }

  &--read-only {
    pointer-events: none;
    opacity: 0.5;
  }

  &--rounded {
    .BaseMultiselect__input {
      border-radius: 3px;
      border: 2px solid white;
      border-bottom: 2px solid unset;
    }

    /deep/ .vue-treeselect__control {
      border-bottom: 2px solid transparent;
      height: 40px;
    }

    /deep/.vue-treeselect__control:hover {
      border-color: transparent !important;
    }

    /deep/ .vue-treeselect__menu-container {
      .vue-treeselect__menu {
        border: 2px solid white;
        border-radius: 3px;
      }
    }
  }

  &--button-type {
    .BaseMultiselect__input {
      border: 2px solid $color-primary-base;
      text-align: center;
      text-transform: uppercase;
      width: 215px;

      /deep/ .vue-treeselect__control {
        background: $color-primary-base;
      }

      /deep/ .vue-treeselect__single-value,
      /deep/ .vue-treeselect__placeholder {
        color: $color-neutral-softest;
        font-weight: $font-weight-bold;
        margin-top: 2px;
      }

      /deep/ .vue-treeselect__control-arrow-container {
        filter: $filter-white;
      }

      /deep/ .vue-treeselect__menu-container {
        .vue-treeselect__menu {
          margin-top: 15px;
          box-shadow: $shadow-low;

          .vue-treeselect__list-item:not(:last-child) {
            border-bottom: solid 1px #d3d2d3;
            margin: 0 10px;
          }
          .vue-treeselect__option--highlight {
            background-color: $color-neutral-softest;
          }
        }
      }
    }
  }

  &--actionButton {

    /deep/ * {
      cursor: pointer;
      caret-color: transparent;
    }
    .BaseMultiselect__input {
      transition: all 0.15s ease 0s;

      @media #{$mobile-view} {
        text-align: center;
      }

      &:hover {
        box-shadow: $shadow-low;
      }

      /deep/ .vue-treeselect__control {
        background: none;
      }

      /deep/ .vue-treeselect__control-arrow-container {
        transform: rotate(180deg);
      }

      /deep/ .vue-treeselect__placeholder {
        font-weight: 600;
        text-transform: lowercase;
        font-size: 14px;
        letter-spacing: 0.001rem;
        font-family: soleil;
        line-height: 42px;
      }

      /deep/ .vue-treeselect__single-value,
      /deep/ .vue-treeselect__placeholder {
        font-weight: $font-weight-bold;
        /* margin-top: 2px; */
        line-height: 42px;
      }

      /deep/ .vue-treeselect__menu-container {
        .vue-treeselect__menu {
          margin-top: 10px;
          box-shadow: $shadow-low;
          padding: 12px 0;
          .vue-treeselect__list-item {
            padding: 8px 8px;
            font-size: 14px;

            &:hover, &:hover * {
              background: #f3f3f3;
            }
          }

          .vue-treeselect__option--highlight {
            background-color: $color-neutral-softest;
          }
        }
      }
    }

    .BaseMultiselect__input {
      border-radius: 3px;
      border: 1px solid $color-neutral-stronger;
      border-bottom: 2px solid unset;
    }

    /deep/ .vue-treeselect__control {
      border-bottom: 2px solid transparent;
      height: 42px;
    }

    /deep/.vue-treeselect__control:hover {
      border-color: transparent !important;
    }

    /deep/ .vue-treeselect__menu-container {
      .vue-treeselect__menu {
        border: none;
        border-radius: 6px;
      }
    }

    /deep/ .vue-treeselect__control-arrow-container {
      background-image: url('/img/elipses.545fbe3f.svg') !important;
      background-size: 16px;
    }
  }

  &--preserve {
    /deep/ .vue-treeselect {
      &--has-value {
        .vue-treeselect__control {
          .vue-treeselect__value-container {
            padding-right: 30px;
          }
          .vue-treeselect__x-container {
            height: 20px !important;
            position: absolute;
            top: auto;
            bottom: 9px;
            right: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            .vue-treeselect__x {
              top: calc(100% - 17px);
              position: relative;
            }
          }
          .vue-treeselect__control-arrow-container {
            background-position: 0 calc(100% - 5px);
            transition: none;
          }
        }

        &.vue-treeselect--open {
          .vue-treeselect__control {
            .vue-treeselect__control-arrow-container {
              background-position: 0 5px;
            }
          }
        }
      }


    }
  }

  &--invalid {
    /deep/ .vue-treeselect {
      .vue-treeselect__control {
        border-bottom-color: $color-alert-base !important;
      }
    }
  }

  &__invalidLabel {
    padding: 5px 10px;
    font-size: $font-size-1xmini;
    color: $color-alert-base;
    font-weight: $font-weight-bold;
    position: absolute;
  }

  &--alert {
    color: unset;
    font-size: $font-size-2xmini;
    font-weight: 600;

    &--icon {
      position: absolute;
      width: 18px;
      bottom: 20%;
      right: 42px;

      &-warning {
        filter: $filter-yellow invert(100%);
      }

      &-error {
        filter: $filter-red;
      }

      &-success {
        filter: $filter-green;
      }

      &-info {
        filter: $filter-primary-base;
      }
    }

    &--text {
      &-warning {
        color: $color-notice-base;
      }

      &-error {
        color: $color-alert-base;
      }

      &-success {
        color: $color-success-base;
      }

      &-info {
        color: $color-primary-base;
      }
    }
  }

  &--alert-message {
    .BaseMultiselect__input {
      /deep/ .vue-treeselect__single-value {
        width: calc(100% - 25px);
      }
    }
  }

  &__label {
    color: $color-neutral-stronger;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;

    .BaseMultiselect--invalid & {
      color: $color-alert-base;
    }
  }
}
</style>

<style lang="scss">
//Reset
.vue-treeselect {
  &--focused:not(.vue-treeselect--open) .vue-treeselect__control {
    border-color: $color-neutral-stronger;
    box-shadow: none;
  }

  //Container Input
  .vue-treeselect__control {
    border: 0;
    border-radius: 0;
  }

  //Container Options
  .vue-treeselect__menu-container {
    .vue-treeselect__menu {
      border: 0;
      border-radius: 0;
    }
  }
}


//Styling
.vue-treeselect {
  //Status
  &--open {
    .vue-treeselect__control {
      .vue-treeselect__value-container {
        .vue-treeselect__multi-value {
          .vue-treeselect__multi-value-item-container:not(:first-of-type) {
            //Tip
            .vue-treeselect__limit-tip {
              display: none;
            }
          }

          //Input Container
          .vue-treeselect__input-container {
            display: block !important;
          }
        }
      }
      .vue-treeselect__control-arrow-container {
        transform: rotate(180deg);
      }
    }
  }

  &__placeholder {
    color: $color-neutral-stronger;
  }

  //Hover
  &:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) {
    .vue-treeselect__control {
      &:hover {
        border-color: $color-neutral-stronger;
      }
    }
  }

  //Container Input
  .vue-treeselect__control {
    //Styling
    background: white;
    font-size: $font-size-3xsmall;
    padding-left: 8px;
    border-bottom: 2px solid $color-neutral-soft;

    //Input
    .vue-treeselect__value-container {
      .vue-treeselect__multi-value {
        display: flex;
        flex-wrap: wrap;
        //Placeholder
        .vue-treeselect__placeholder {
          font-size: $font-size-3xsmall;
          color: $color-neutral-stronger;
        }

        //Limit Text
        .vue-treeselect__limit-tip {
          .vue-treeselect__limit-tip-text {
            font-size: $font-size-3xsmall;
            font-weight: $font-weight-regular;
            color: $color-neutral-stronger;
          }
        }

        //Tags
        .vue-treeselect__multi-value-item-container {
          .vue-treeselect__multi-value-item {
            background-color: $color-primary-base;
            .vue-treeselect__multi-value-label {
              color: $color-neutral-softest;
            }
            .vue-treeselect__value-remove {
              color: $color-neutral-softest;
              border-color: $color-neutral-softest;
              &:hover {
                opacity: 0.5;
              }
            }
          }
        }

        //Input Container
        .vue-treeselect__input-container {
          display: none;
        }
      }
    }

    //Close
    .vue-treeselect__x-container {
      z-index: 1;
      .vue-treeselect__x {
        fill: #393939;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    //Arrow
    .vue-treeselect__control-arrow-container {
      width: 17px;
      background-image: url('/img/chevron-down.78a65aa9.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.2s ease-in-out;
      .vue-treeselect__control-arrow {
        display: none;
      }
    }
  }

  //Container Options
  .vue-treeselect__menu-container {
    .vue-treeselect__menu {
      // font-size: $font-size-3xsmall;
      font-size: $font-size-1xmini;
      font-weight: $font-weight-regular;
      color: $color-neutral-stronger;
      border: 2px solid #393939;
      border-top-width: 1px;
      padding-top: 0;
      padding-bottom: 0;

      &--select-all {
        border: 0;
        padding: 5px 0 5px 0;

         //Label
        .vue-treeselect__label {
          white-space: normal;
          line-height: $line-height-2xsmall;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        //  &-selected {
        //     background-color: $color-primary-base;
        //   }
      }

      //Tip
      .vue-treeselect__tip {
        padding-top: 3px;
        padding-bottom: 3px;
        .vue-treeselect__tip-text {
          white-space: normal;
        }
      }
    }

    //List
    .vue-treeselect__list {
      //Item
      .vue-treeselect__list-item {
        //Option
        .vue-treeselect__option {
          min-height: 30px;

          &:not(.vue-treeselect__option--hide) {
            display: flex;
            align-items: center;
          }

          //Status
          &--selected {
            background-color: $color-neutral-softer;
          }

          &--highlight {
            background-color: #eee;
          }

          //Arrow
          .vue-treeselect__option-arrow-container {
            .vue-treeselect__option-arrow {
              fill: #393939;

              &:hover {
                opacity: 0.5;
              }
            }
          }

          //Label Container
          .vue-treeselect__label-container {
            //Checkbox
            .vue-treeselect__checkbox-container {
              .vue-treeselect__checkbox {
                &--checked,
                &--indeterminate {
                  border-color: $color-primary-base;
                  background: $color-primary-base;
                }
              }
            }

            //Label
            .vue-treeselect__label {
              white-space: normal;
              line-height: $line-height-2xsmall;
              padding-top: 5px;
              padding-bottom: 5px;
              //Count
              .vue-treeselect__count {
                font-size: $font-size-2xmini;
              }
            }
          }
        }
      }
    }
  }
}
</style>
