export default {
  transporters: [],
  transportersTransfer: [],
  storesTransporter: [],
  transportersTable: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  transportersDrivers:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  transferHistoryTable: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
  },
  modalTransferHistory: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
  },
}