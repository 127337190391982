import { orders } from '@/api'

export default {

  //#region Orders
  async fetchOrders({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await orders.fetchOrders(params, (res) =>
      commit('SET_ORDERS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async getStatus({ commit }, params) {
    commit('SET_STATUS', [])
    return await orders.getStatus(params, (res) => commit('SET_STATUS', res.data), (e) => e, () => {})
  },

  async createNewOrderPaymentLink({ commit }, id) {
    return await orders.createNewOrderPaymentLink(id, () => commit, (e) => e, () => {})
  },

  async submitOrderLocationLock({ commit }, { id, check_location }) {
    this.dispatch('loading/setLoading', true)
    return await orders.submitOrderLocationLock({ id, check_location }, () =>
      commit('SUBMIT_ORDER_LOCATION_LOCK', { id, check_location }), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  //#endregion

  //#region Orders Form
  async deleteOrder({ commit }, id) {
    return await orders.deleteOrder(id, () => commit('DELETE_ORDER', id), (e) => e)
  },

  async fetchProductsSizes({ commit }){
    return await orders.fetchProductsSizes((res) =>
      commit('SET_PRODUCTS_SIZES', res), (e) => e, () => true, false)
  },

  async fetchOrderPackages({ commit }, id) {
    this.dispatch('loading/setLoading', true)
    return await orders.fetchOrderPackages(id, (res) =>
      commit('SET_ORDER_PACKAGES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  //#endregion

  //#region Orders Incoming
  async confirmIncomingOrders({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await orders.confirmIncomingOrders(params, (res) => {
      commit('SET_INCOMING_ORDERS', res)
      return res
    }, (e) => Promise.reject(e), () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchIncomingOrdersTypes({ commit }) {
    return await orders.fetchIncomingOrdersTypes((res) => commit('SET_INCOMING_ORDERS_TYPES', res.data) , (e) => e)
  },

  //#endregion

  //#region Change Status
  async getStatusOnly({ commit }, params) {
    commit('SET_STATUS_ONLY', [])
    return await orders.getStatusOnly(params, (res) => commit('SET_STATUS_ONLY', res.data), (e) => e)
  },

  async fetchImportStatusOrders({ commit }, params = {paginate:20, page:1}) {
    return await orders.fetchImportStatusOrders(params, (res) =>
      commit('SET_IMPORT_STATUS_ORDERS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchImportStatusErrors({commit}, id){
    this.dispatch('loading/setLoading', true)
    return await orders.fetchImportStatusErrors(id, (res) =>
      commit('SET_IMPORT_STATUS_ERROS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async importStatusOrders(ctx, ordersData){
    this.dispatch('loading/setLoading', true)
    const result = await orders.importStatusOrders(ordersData, (res) => res, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
    return result
  },

  //#endregion

  //#region Orders Export
  async fetchReportsRequested({ commit }, params = {paginate:20, page:1}) {
    // this.dispatch('loading/setLoading', true)
    return await orders.fetchReportsRequested(params, (res) =>
      commit('SET_REPORTS_REQUESTED', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  //#endregion

  //#region Orders Import
  async fetchImportOrders({ commit }, params = {paginate:20, page:1}) {
    return await orders.fetchImportOrders(params, (res) =>
      commit('SET_IMPORT_ORDERS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchImportErrors({commit}, id){
    this.dispatch('loading/setLoading', true)
    return await orders.fetchImportErrors(id, (res) =>
      commit('SET_IMPORT_ERROS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async importOrders(ctx, ordersData){
    this.dispatch('loading/setLoading', true)
    const result = await orders.importOrders(ordersData, (res) => res, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
    return result
  },
  //#endregion

  async fetchDeliveryFailures({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await orders.fetchDeliveryFailures(params, (res) =>
      commit('SET_DELIVERY_FAILURES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  //#region Error List
  async correctImportOrder({commit}, {id, body}) {
    this.dispatch('loading/setLoading', true)
    orders.correctImportOrder({id, body}, () =>
      commit('DELETE_IMPORT_ERROS', id), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },
  //#endregion

  //#region Financial
  async fetchImportStatusList({ commit }){
    return await orders.fetchImportStatusList((res) =>
      commit('SET_IMPORT_STATUS_LIST', res), (e) => e, () => true, false)
  },
  //#endregion

}