const Playground = () => import( /* webpackChunkName: 'PackagesIncoming' */ './Playground.vue')

const routes = [
  {
    name: 'Playground',
    meta:{
      label: 'playground',
    },
    path: '/playground',
    component: Playground
  },
]

export default routes