export default {

  //Used on TreeSelect Component
  treeSelectModel: (state, getters, rootState) => (stateName, storeName) => {
    let selectByGroup = []
    let stateObj = rootState[stateName][storeName]

    const itsStore = stateObj.some(store => Object.prototype.hasOwnProperty.call(store, 'parent_id'))

    if (itsStore) {
      selectByGroup = getters.storeModel(stateObj)
    } else {
      selectByGroup = getters.defaultModel(stateObj)
    }

    return selectByGroup
  },

  defaultModel: (state, getters) => (stateObj) => {
    let selectByGroup = []

    stateObj.map((store) => {
      selectByGroup.push({...store, id: store.id, label: store.name})
    })

    //Order by
    getters.orderByModel(selectByGroup)

    return selectByGroup
  },

  storeModel: (state, getters) => (stateObj) => {
    let selectByGroup = []

    //Check if has some store with children
    const hasSomeChildren = stateObj.some(store => store.parent_id !== null)

    // Create groups with stores without parent_id
    stateObj.map((store) => {
      if (store.parent_id) {
        const storeGroupNotCreated = selectByGroup.filter(el => el.id === store.parent_id.id).length === 0
        if (storeGroupNotCreated) {
          selectByGroup.push({
            id: store.parent_id.id,
            name: store.parent_id.name,
            label: store.parent_id.name,
            ...(hasSomeChildren && {children: []})
          })
        }
      }
    })

    // Fill stores in groups
    stateObj.map((store) => {
      if (store.parent_id) {
        selectByGroup.map((group, index) => {
          if (group.id === store.parent_id.id) {
            selectByGroup[index].children.push({...store, label: store.name})
          }
        })
      }
    })

    //Order by
    getters.orderByModel(selectByGroup)

    // Create HUB group if exist
    const hasHubs = stateObj.some((store) => store.schedule)
    if (hasHubs) {
      if (selectByGroup.length > 0) {
        selectByGroup.unshift({
          id: 'hub',
          name: 'HUBs',
          label: 'HUBs',
          children: []
        })

        stateObj.map((store) => {
          if (store.schedule) {
            selectByGroup.map((group, index) => {
              if (group.id === 'hub') {
                selectByGroup[index].children.push({...store, id: 'H-' + store.id, label: store.name})
              }
            })
          }
        })
      } else {
        stateObj.map((store) => {
          if (store.schedule) {
            selectByGroup.push({...store, id: store.id, label: store.name})
          }
        })
      }     
    }

    // Return only parent stores
    if (!hasSomeChildren && !hasHubs) {
      stateObj.map((store) => {
        selectByGroup.push({...store, id: store.id, label: store.name})
      })
    }

    return selectByGroup

  },

  orderByModel: () => (select) => {
    select.sort(function (a, b) {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      // a must be equal to b
      return 0
    })
  },
    
}