const NotFound = () => import( /* webpackChunkName: 'NotFound' */  './NotFound/NotFound.vue')

const routes = [
  {
    name: 'notfound',
    path: '/error/notFound',
    meta: { public: true, noHeader: false, theme: 'blue' },
    component: NotFound
  }
]

export default routes
