export default {
  trackingCommentsWithStatus(state) {
    return state.trackingComments.map((t) => {
      let filtered = state.ordersHistory?.data?.content.filter(o => o.id === t?.tracking?.id)
      let status = filtered.length > 0 ? filtered[0]?.['status.name'] : null
      return {
        status,
        ...t
      }
    })
  },
}