export default {
  dropoffsReportsRequestedWithLoading(state){
    let headers = state.dropoffsReportsRequested.data.headers
    if (headers && headers.created_at && headers.created_at.method === false) {
      headers.created_at.method = 'millisecondsToDateHour'
    }
    return {
      data: {
        content: state.dropoffsReportsRequested.data.content.map((reportRequested) => {
          let i = reportRequested
          if (i.status.toLowerCase() === 'andamento') {
            i.actions = [{action_id: 'loading', has_permission: true}]
          }
          if (i.status.toLowerCase() === 'concluída com erros' || i.status.toLowerCase() === 'erro' || i.status.toLowerCase() === 'incompleto') {
            i.status = 'Erro'
            i.actions = [{action_id: 'visualizeErrors', has_permission: true}]
          }
          return i
        }),
        headers: headers
      },
      pagination: state.dropoffsReportsRequested.pagination
    }
  },
}