const BaseInputFileMixin = {
  props: {
    formDataName: {
      type: String,
      default: 'files[]'
    }
  },
  data() {
    return {
      formDataModel: new FormData(),
      formDataFiles: [],
    }
  },
  methods: {

    populateFormData(fileList) {
      //append the files to FormData
      Array.from(Array(fileList.length).keys()).map((file) => {
        if (!this.checkIfFileIsAlreadyUploaded(fileList[file])) {
          this.formDataModel.append(this.formDataName, fileList[file], fileList[file].name)
        }
      })
      this.createFileObject()
    },

    deleteFileFormData(data) {
      //Remove From Array of Files
      const index = this.formDataFiles.findIndex(el => el.name === data.name)
      this.formDataFiles.splice(index, 1)

      //Remove From FormData Files
      const fileList = this.formDataFiles.map(el => el.file)
      this.formDataModel.delete(this.formDataName)
      Array.from(Array(fileList.length).keys()).map((file) => {
        this.formDataModel.append(this.formDataName, fileList[file], fileList[file].name)
      })
    },

    setFormDataFiles() {
      return new Promise((resolve) => {
        const files = this.formDataModel.getAll(this.formDataName)
        const promises = files.map((file) => file)
        resolve(promises)
      })
    },

    createFileObject() {
      const files = this.formDataModel.getAll(this.formDataName)
      const promisses = files.map((file) => this.createImage(file)
        .then(img => {
          const obj = this.mountFileObject(file, img)
          if (!this.checkIfFileIsAlreadyUploaded(obj)) {
            this.formDataFiles.push(obj)
          }
          return obj
        }))
      return Promise.all(promisses)
    },

    createImage(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader()
    
        fReader.onload = (e) => {
          const base64 = e.target.result
          resolve(base64)
        }
    
        fReader.readAsDataURL(file)
      })
    },

    mountFileObject(file, img) {
      const id = file.name.replaceAll(' ', '').toLowerCase()
      const tempType = file.type.split('/')
      const obj = {
        id: id,
        originalName: file.name,
        name: file.name,
        type: tempType[0],
        ext: tempType[1],
        size: file.size > 0 ? file.size : 1,
        file: file,
        img: img
      }
    
      return obj
    },

    checkIfFileIsAlreadyUploaded(obj) {
      const index = this.formDataFiles.findIndex(el => el.name === obj.name && el.size === obj.size)
      return index !== -1
    }
  }
}

export default BaseInputFileMixin