<template>
  <div class="FiltersTag">
    <div v-if="header" class="FiltersTag__header">
      <span class="FiltersTag__title">{{ label }} {{ tags.length > 0 ? `(${tags.length} selecionado${tags.length > 1 ? 's' : ''})` : '' }}</span>
    </div>
    <div class="FiltersTag__allTags">
      <BaseTag v-for="(tag, i) in tags" :key="i" class="FiltersTag__tag" :class="TagsHiddenStyle(i)" :value="tag.name" :read-only="readOnly" @close="removeTag(tag)" /> 
      <div class="FiltersTag__actions">
        <div v-if="!readOnly" class="FiltersTag__clear" @click="clearTags">
          <Icon name="close" class="FiltersTag__icon" />
          <span>Limpar</span>
        </div>
        <div v-if="tags.length > 4" class="FiltersTag__toggle" @click="toggleTags">
          <Icon :name="tagsHidden ? 'add' : 'remove'" class="FiltersTag__icon" />
          <span>Ver {{ tagsHidden ? 'mais' : 'menos' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTag } from '@/components/atoms'

export default {
  name: 'FiltersTag',
  components: {
    BaseTag
  },
  props: {
    tags:{
      type: Array,
      required: true,
    },
    header: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'filtros'
    }
  },
  data() {
    return {
      tagsHidden: true
    }
  },
  methods: {
    clearTags(){
      this.$emit('clear')
    },
    removeTag(tag){
      this.$emit('removeTag', tag.name, tag.id)
    },
    toggleTags() {
      this.tagsHidden = !this.tagsHidden
    },
    TagsHiddenStyle(i) {
      return {
        'FiltersTag__tag--hidden': i > 3 && this.tagsHidden,
        'FiltersTag__tag--active': !this.tagsHidden,
        'FiltersTag__tag--read-only': this.readOnly
      }
    }
  }
}
</script>

<style lang="scss">
.FiltersTag{
  &__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &__title{
    font-size: $font-size-mini;
  }
  &__clear, &__toggle {
    font-size: $font-size-mini;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 3px;
    border: 1px solid $color-neutral-stronger;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: $border-radius-1xsmall;
    
    &:hover{
      border-color: $color-primary-base;
    }
    @media (max-width: $viewport-sm) {
      margin-right: 0;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  &__toggle {
    margin-right: 0;
  }
  &__icon{
    width: 10px;
    margin-right: 5px;
  }
  &__actions {
    display: inline-flex;
    @media (max-width: $viewport-sm) {
      display: flex;
      width: 100%;
      max-width: initial;
      flex-direction: column;
    }
  }
  &__allTags {
    width: 100%;
    position: relative;
  }
  &__tag{
    margin-right: 10px;
    margin-bottom: 5px;
    max-width: calc((100% / 3) - 144px);
    vertical-align: top;
    position: relative;
    height: 29px;
    padding: 0 28px 0 0;
    overflow: hidden;
    border-radius: $border-radius-1xsmall;

    &--active {
      max-width: initial;
    }

    &--read-only {
      padding: 0;
    }

    @media (max-width: $viewport-sm) {
      width: 100%;
      max-width: calc(100vw - 60px) !important;
      margin-right: 0;
    }

    &--hidden {
      display: none !important;
    }
    
    .BaseTag__value {
      position: relative;
      width: 100%;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
    .BaseTag__close {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
