export default {
  SET_VEHICLES(state, vehicles) {
    state.vehicles = vehicles
  },

  DELETE_VEHICLE(state, deletedVehicle){
    state.vehicles.data.content = state.vehicles.data.content.filter((vehicle) => vehicle.id !== deletedVehicle.id)
  },
  
}
