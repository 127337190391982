const image = ['image/*']
const audio = ['audio/*']
const video = ['video/*']
const docs = [
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
const all = [
  ...image,
  ...audio,
  ...video,
  ...docs
]

const order = [
  '.jpg',
  '.png',
  '.xlsx',
  '.xls',
  '.ppt',
  '.pptx',
  '.pdf'
]

const acceptFormats = {
  all: all,
  image: image,
  audio: audio,
  video: video,
  docs: docs,
  order: order,
}

export {
  acceptFormats
}