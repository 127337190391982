import request from '@/api/plugins/request'
import download from '@/api/plugins/download'
import objToQuery from '@/api/plugins/query'

export default {

  async fetchPackages({obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')
    return await request({
      method: 'get',
      url: `order-products?is_table=order_products&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchPackageDetail(id, success, error, done) {
    return await request({
      method: 'get',
      url: `order-products/${id}`
    }, success, error, done)
  },  

  async exportPackagesResult(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'order-products/export',
      body
    }, success, error, done)
  },

  async downloadPackagesResult(id, success, error, done) {
    return await download({
      method: 'get',
      url: `order-products/export/${id}/download`
    }, success, error, done)
  },
  
  async fetchIncomingPackages(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'packages/search',
      body
    }, success, error, done)
  },

  async sendIncomingPackages(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'receive-packages',
      body
    }, success, error, done)
  },

  async fetchOrdersWithConfirmedPackages({obj}, success, error, done) {
    const orders = objToQuery.parseAll(obj, 'name')

    return await request({
      method: 'get',
      url: `orders?is_table=confirmed_packages&paginate=999&page=1&${orders}&sort=desc`
    }, success, error, done)
  },

  async fetchMissingPackages(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'missing-packages',
      body
    }, success, error, done)
  },

  async downloadOrderWithPackageReport({obj}, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await download({
      method: 'get',
      url: `orders-export/confirmed_packages?${query}`
    }, success, error, done)
  },

  async downloadOrderWithConfirmedPackageReport(body, success, error, done) {
    return await download({
      method: 'post',
      url: 'orders-export/confirmed_packages',
      body
    }, success, error, done)
  },

  async fetchPackagesReportsRequested({ paginate, page }, success, error, done) {
    return await request({
      method: 'get',
      url: `packages-export?is_table=packages_export&paginate=${paginate}&page=${page}&sort=desc`
    }, success, error, done)

  },

  async fetchCachedPackagesByDriver(id, success, error, done) {
    return await request({
      method: 'get',
      url: `packages-by-driver/${id}`
    }, success, error, done)
  },

  async exportPackagesReport(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'packages-export',
      body
    }, success, error, done)
  },

  async downloadPackagesReport(id, success, error, done) {
    return await download({
      method: 'get',
      url: `packages-export/${id}`
    }, success, error, done)
  },

  async downloadPackagesList(id, success, error, done) {
    return await download({
      method: 'get',
      url: `packages-export-romaneio/${id}`
    }, success, error, done)
  },

  async fetchPackagesHistory({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')
    return await request({
      method: 'get',
      url: `products_reception?is_table=products_reception&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchPackagesReceived({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')
    return await request({
      method: 'get',
      url: `products-reception/packing-lists?is_table=order_product_reception_packing_lists&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchPackagesReceivedDetail(id, success, error, done) {
    return await request({
      method: 'get',
      url: `products-reception/packing-lists/${id}/products?paginate=9999`
    }, success, error, done)
  },

  async downloadPackagesLabel(id, success, error, done) {
    return await download({
      method: 'get',
      url:  `orders/${id}/label`
    }, success, error, done)
  },

  async checkIfPackagesListExists(id, success, error, done) {
    return await request({
      method: 'get',
      url: `products-reception/packing-lists/${id}`
    }, success, error, done)
  },

  async confirmPackagesDispatch(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'products-reception/packing-lists/dispatch',
      body
    }, success, error, done)
  },

  async removePackage({ file_id, package_id }, success, error, done) {
    return await request({
      method: 'delete',
      url: `products-reception/packing-lists/${file_id}/products/${package_id}`
    }, success, error, done)
  },

  async removePackages({ packingListId, packages }, success, error, done) {
    return await request({
      method: 'delete',
      url: `products-reception/packing-lists/${packingListId}/products`,
      body: { data: { packages } }
    }, success, error, done)
  },
}