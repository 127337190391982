<template>
  <div :id="`style-${type}`" class="scrollbar" :style="style">
    <div class="force-overflow">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Scrollbar',
  props: {
    minHeight: {
      type: [String],
      description: 'Min Height to setup force overflow',
      default: '0vh',
    },
    maxHeight: {
      type: [String],
      description: 'Max Height to setup force overflow',
      default: '100vh',
    },
    height: {
      type: [String],
      description: 'Height to setup force overflow',
      default: '100%',
    },
    minWidth: {
      type: [String],
      description: 'Min Width to setup force overflow',
      default: '0vw',
    },
    maxWidth: {
      type: [String],
      description: 'Max Width to setup force overflow',
      default: '100%',
    },
    width: {
      type: [String],
      description: 'Width to setup force overflow',
      default: '12px',
    },
    type: {
      type: [String],
      description: 'Number to overflow stylish',
      default: 'custom',
      validator: (value) => {
        const acceptedValues = [
          'custom',
        ]
        return acceptedValues.indexOf(value) !== -1
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    style: function() {
      return {
        '--width': this.width,
        '--height': this.height,
        '--min-height': this.minHeight,
        '--max-height': this.maxHeight,
        '--min-width': this.minWidth,
        '--max-width': this.maxWidth,
        '--white-space': this.maxWidth === '100%' ? 'initial' : 'nowrap',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.scrollbar {
  overflow: auto;
  position: relative;
  margin: auto;
  width: calc(100%);
  height: calc(100%);
  transition: all 0.3s ease-in-out;
  min-height: var(--min-height);
  max-height: var(--max-height);
  min-width: var(--min-width);
  max-width: var(--max-width);
  white-space: var(--white-space);
}

.force-overflow {
  height: 100%;
  //   padding-right: 15px;
}

/*
 *  STYLE Custom
 */
#style-custom::-webkit-scrollbar-track {

  border-radius: 10px;
  background-color: rgba(245, 245, 245, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgba(255, 204, 6, 0.2);
  }
}

#style-custom::-webkit-scrollbar {
  width: var(--width);
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#style-custom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-primary-base;
}
</style>
