export default {
  stores: [],
  status: [],
  statusOnly: [],
  orders:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  incomingOrders:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      per_page: 0
    }
  },
  incomingOrdersTypes: [],
  reportsRequested:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  importOrders:{
    data:{
      headers: {},
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  importStatusOrders: {
    data:{
      headers: {},
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  orderPackages:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  deliveryFailures:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 200,
      per_page: 200
    }
  },
  importErros: [],
  importStatusErros: [],
  ordersTracking: [],
  vehiclesSizes: [],
  productsSizes: [],
  importStatusList: []
}