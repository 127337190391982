export default {
  SET_HAS_PRINTER(state, payload) {
    state.zebraPrinter = payload
  },
  SET_CHECK_PRINTER(state, payload) {
    state.checkPrinter = payload
  },
  SET__PRINTER_CONNECTED(state, payload) {
    state.printerConnected = payload
  },
}