<template>
  <div class="BaseList">
    <div class="BaseList__Title">
      <p>
        {{ title }}
      </p>
    </div>
    <div class="BaseList__Content">
      <BaseScrollbar
        v-if="scrollbar"
        :max-height="scrollbarHeight"
        :width="scrollbarWidth"
      >
        <ul class="BaseList__List">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="BaseList__List--item"
          >
            <span>{{ item }}</span>
          </li>
        </ul>
      </BaseScrollbar>

      <ul v-else class="BaseList__List">
        <li
          v-for="(item, index) in list"
          :key="index"
          class="BaseList__List--item"
        >
          <span>{{ item }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {BaseScrollbar} from '@/components/atoms'

export default {
  name: 'BaseList',
  components: {
    BaseScrollbar,
  },
  props: {
    list: {
      type: [Array],
      description: '',
      default: () => [],
    },
    title: {
      type: [String],
      description: '',
      default: 'Listagem',
    },

    //Scrollbar
    scrollbar: {
      type: [Boolean],
      description: '',
      default: false,
    },
    scrollbarHeight: {
      type: [String],
      description: '',
      default: '100%',
    },
    scrollbarWidth: {
      type: [String],
      description: '',
      default: '8px',
    },
  },
}
</script>
<style lang="scss" scoped>
.BaseList {
  width: 100%;

  &__Title {
    text-align: center;
    font-size: $font-size-mini;
    color: $color-neutral-stronger;
    font-weight: $font-weight-bold;
    margin: 10px 0;
  }

  &__List {
    list-style: none;
    padding: 0;
    margin: 0;

    &--item {
      height: $line-height-large;
      text-align: center;
      line-height: $line-height-large;
      font-size: $font-size-mini;
      text-transform: uppercase;
      width: 100%;
      position: relative;

      span {
        max-width: 512px;
        width: calc(100vw - 90px);
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
      }

      &:nth-child(even) {
        background: #fff;
      }

      &:nth-child(odd) {
        background-color: rgba(242, 242, 242, 50%);
      }
    }
  }
}
</style>
