import state from './state.pagination'
import getters from './getters.pagination'
import mutations from './mutations.pagination'
import actions from './actions.pagination'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}