export default [
  // {
  //   id: 0,
  //   class: 'Pagination__Arrow',
  //   click: 'first',
  //   icon: 'chevron-left-alt',
  //   lengthIcons: 2,
  // },
  {
    id: 1,
    class: 'Pagination__Arrow',
    click: 'prev',
    icon: 'chevron-left-alt',
    lengthIcons: 1,
  }
]