<template>
  <Dialog
    v-if="show"
    :warning="message"
    label-cancel="Não"
    label-confirm="Sim"
    @confirm="handlerConfirm"
    @cancel="show = false"
  />
</template>
<script>
import Dialog from '@/components/molecules/Dialog/Dialog.vue'

export default {
  name: 'BaseInputFileDialog',
  components: {
    Dialog
  },
  data() {
    return {
      data: null,
      show: false,
      message: '',
      trigger: null,
    }
  },
  
  methods: {
    async handlerConfirm() {
      switch (this.trigger) {
      case 'remove':
        this.$emit('remove', this.data)
        break
      default:
        break
      }

      this.show = false
    },

    //Handlers
    handlerOpen(data, type) {
      this.show = true

      if (type) {
        const funcName = `handler${this.capitalize(type)}`
        this[funcName](data)
      }
    },

    handlerRemove(data) {
      this.message = 'Tem certeza que deseja deletar esse arquivo?'
      this.trigger = 'remove'
      this.data = data
    },

    capitalize(s) {
      return s && s[0].toUpperCase() + s.slice(1)
    }

  },
}
</script>