import ctemanagement from './ctemanagement/state.cte'

export default {
  routes: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  routeHistory: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  routeErrors: null,
  transitPointImport: [],
  transitPoints: {
    data:{
      headers: {
        actions: {}
      },
      content: [
      ]
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  routesStores: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  ordersWithoutRoutes: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  ordersWithoutRoutesTransfer: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  routesNameByStore: [],

  //CTe Management
  ...ctemanagement
}
