export default class Utility {
  static convertModelToFormData(model, form = null, namespace = '') {
    let formData = form || new FormData()
    const commonType = ['File', 'String', 'Boolean', 'Number']

    for (let propertyName in model) {
      
      
      if (Object.prototype.hasOwnProperty.call(model, propertyName) && model[propertyName] !==  null && model[propertyName] !== undefined) {
        let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName
        const constructorName = model[propertyName].constructor.name
  
        if (constructorName === 'Array') this.arrayType({model, propertyName, formKey, formData})
        else if (commonType.includes(constructorName)) formData.append(formKey, model[propertyName])
        else this.convertModelToFormData(model[propertyName], formData, formKey)
      }
    }
    return formData
  }

  static arrayType({model, propertyName, formKey, formData}) {
    model[propertyName].forEach((element, index) => {
      const tempFormKey = `${formKey}[${index}]`
      this.convertModelToFormData(element, formData, tempFormKey)
    })
  }
}
