export default {
  SET_STORES(state, stores) {
    state.stores = stores
  },

  SET_ROUTEASY_STORES(state, stores) {
    state.routEasyStores = stores
  },

  SET_HUBS(state, hubs) {
    state.hubs = hubs
  },

  SET_STORES_IMPORT(state, storesImport) {
    state.storesImport = storesImport
  },

  SET_STORES_TO_IMPORT(state, storesToImport) {
    state.storesToImport = storesToImport
  },

  SET_STORES_TABLE(state, storesTable) {
    state.storesTable = storesTable
  },

  SET_STORES_INFO(state, stores) {
    state.storesWithInfo = stores
  },

  SET_STORES_PARENTS(state, storesParents) {
    state.storesParents = storesParents
  },

  SET_STORES_TRANSPORTERS(state, storesTransporters) {
    state.storesTransporters = storesTransporters
  },

  UNSHIFT_STORES_TRANSPORTERS(state, storeTransporter) {
    state.storesTransporters.data.content.unshift(storeTransporter)
  },

  REMOVE_STORES_TRANSPORTERS(state, storeTransporterId) {
    state.storesTransporters.data.content = state.storesTransporters.data.content.filter((i) => i.id !== storeTransporterId)
  },

  SET_STORE_CHILDREN(state, children) {
    state.storeChildren = children
  }
}
