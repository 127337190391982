<template>
  <div
    v-scroll-lock="warning"
    class="Dialog"
    :class="additionalActive ? 'Dialog--additionalActive' : ''"
    tabindex="0"
    @keyup.esc="keyUpCancel"
  >
    <div class="Dialog__Shadow" />
    <div class="Dialog__Bg" />
    <div v-if="labelCancel" class="Dialog__Container-icon">
      <Icon name="close" color="dark" class="Dialog__Icon" @click="onCancel" />
    </div>
    <div class="Dialog__Texts">
      <h1 v-if="title" class="Dialog__Title" v-html="title" />
      <span v-if="questions" class="Dialog__Questions" v-html="questions" />

      <div class="Dialog__Body">
        <div
          v-if="warning"
          class="Dialog__Warning"
          @click="toggleAdditional"
          v-html="warning"
        />
        <div
          v-if="additional"
          class="Dialog__Additional"
          :class="additionalActive ? 'Dialog__Additional--active' : ''"
          v-html="additional"
        />
      </div>

      <slot />
    </div>
    <div class="Dialog__Buttons">
      <BaseButton
        v-if="labelCancel"
        :label="labelCancel"
        class="Dialog__Button"
        color="white"
        text-color="dark"
        :disabled="disabledCancel"
        @click="onCancel"
      />
      <BaseButton
        v-if="labelConfirm"
        :label="labelConfirm"
        class="Dialog__Button"
        color="primary-base"
        text-color="white"
        filled
        :disabled="disabledConfirm"
        @click="onConfirm"
      />
    </div>
  </div>
</template>

<script>
import { BaseButton } from '@/components/atoms'

export default {
  name: 'Dialog',
  components: {
    BaseButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    questions: {
      type: String,
      default: ''
    },
    warning: {
      type: String,
      default: ''
    },
    additional: {
      type: String,
      default: ''
    },
    labelCancel: {
      type: [String, Boolean],
      default: 'Cancelar'
    },
    labelConfirm: {
      type: [String, Boolean],
      default: 'Ok'
    },
    listenerKeyup: {
      type: Boolean,
      default: true
    },
    disabledCancel: {
      type: Boolean,
      default: false
    },
    disabledConfirm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      additionalActive: false
    }
  },

  methods: {
    onCancel() {
      this.$emit('cancel')
    },

    onConfirm() {
      this.$emit('confirm')
    },

    toggleAdditional() {
      if (this.additional) {
        this.additionalActive = !this.additionalActive
      }
    },

    keyUpCancel() {
      this.listenerKeyup && this.onCancel()
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  background-color: red;
}
.Dialog {
  max-width: 576px;
  width: 100%;
  background-color: white;
  box-shadow: $shadow-low, 0 0 0 100vw rgba($color-primary-stronger, 0.8);
  display: flex;
  flex-direction: column;
  padding: 32px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 80vh;
  height: fit-content;
  z-index: 999999999;
  border-radius: $border-radius-large;

  @media #{$mobile-view} {
    margin: unset;
    top: unset;
    bottom: -1px;
    border-radius: unset;
    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
  }

  &__Shadow {
    position: absolute;
    top: calc(50% - 250vh);
    left: calc(50% - 250vw);
    width: 500vw;
    height: 500vh;
    z-index: -1;
  }

  &__Bg {
    grid-area: bg;
    background-color: $color-primary-base;
    border-radius: $border-radius-medium 0 0 $border-radius-medium;
    transform: scale(1.011);
  }
  /deep/ .Icon--dark {
    filter: unset;
  }
  &__Container-icon {
    width: 48px;
    height: 48px;
    justify-content: center;
    align-self: end;
    display: flex;
    border-radius: 50%;
    margin-top: -15px;
    margin-right: -15px;
    transition: all 400ms ease 0s;
    border: none;
    z-index: 999999;
    &:hover {
      background-color: rgb(243, 243, 252);
      color: rgb(49, 47, 79);
    }
    &:active {
      background-color: rgb(209, 209, 227);
      color: rgb(49, 47, 79);
    }
  }

  &__Icon {
    cursor: pointer;
    width: 23px;
    cursor: pointer;
  }
  &__Texts {
    margin-top: -30px;
    width: 100%;
    justify-self: center;
    align-self: center;
    overflow: auto;
  }
  &__Title {
    font-size: $font-size-3xlarge;
    line-height: $line-height-1xlarge;
    letter-spacing: $letter-spacing-100;
    font-weight: $font-weight-bold;
    margin-top: 15px;

    @media #{$mobile-view} {
      font-size: $font-size-large;
      line-height: $line-height-medium;
    }
  }
  &__Questions {
    font-size: $font-size-3xsmall;
    font-weight: $font-weight-regular;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-100;

    @media #{$mobile-view} {
      font-size: $font-size-mini;
      line-height: $line-height-1xsmall;
    }
  }
  &__Body {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: auto;
  }
  &__Warning {
    line-height: 24px;
  }
  &__Additional {
    font-weight: $font-weight-regular;
    font-style: normal;
    font-size: $font-size-2xmini;
    color: $color-neutral-stronger;
    height: 0;
    text-align: center;
    overflow: hidden;
    word-break: break-all;

    &--active {
      margin-top: 15px;
      height: auto;
      border: 1px solid $color-neutral-stronger;
      padding: 10px;
      position: relative;
      overflow: initial;

      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        left: calc(50% - 5px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $color-neutral-stronger
          transparent;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: calc(100% - 2px);
        left: calc(50% - 5px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent;
      }
    }
  }
  &__Buttons {
    display: flex;
  }

  .BaseButton--white {
    border: 1px solid rgb(209, 209, 227);
    &:hover {
      background-color: rgb(243, 243, 252);
    }
  }

  &__Button {
    width: auto;
    border-radius: $border-radius-small;
    &:last-child {
      margin-left: auto;
    }
  }
}
</style>
<style lang="scss">
.Dialog {
  &__Additional {
    pre {
      white-space: pre-wrap;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
