import { controlTower } from '@/api'

export default {
  async fetchOcurrencesRealTime({ commit }, params = {paginate:20, page:1}) {
    return await controlTower.fetchOcurrencesRealTime(params, (res) =>
      commit('SET_OCCURRENCES_REAL_TIME', res), (e) => e)
  },

  async fetchOcurrencesHistory({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await controlTower.fetchOcurrencesHistory(params, (res) =>
      commit('SET_OCCURRENCES_HISTORY', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchOcurrencesStatusList({commit}, is_finisher) {
    this.dispatch('loading/setLoading', true)
    return await controlTower.fetchOcurrencesStatusList(is_finisher, (res) =>
      commit('SET_OCCURRENCES_STATUS_LIST', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  setStandardAttendanceTime({ commit }, timeValue) {
    commit('SET_STANDARD_ATTENDANCE_TIME', timeValue)
  },

  async fetchStandardAttendanceTime({ dispatch }) {
    return await controlTower.fetchStandardAttendanceTime(
      (res) => {
        const timeValue = res?.standard_time
        dispatch('setStandardAttendanceTime', timeValue)
        return timeValue
      } , (e) => e
    )
  },

  async fetchOccurrencesConfig({ commit }) {
    return await controlTower.fetchOccurrencesConfig(
      (res) => commit('SET_OCCURENCES_CONFIG', res) , (e) => e
    )
  },

  async setOccurrencesConfigContent({ commit }, data = []) {
    commit('SET_OCCURENCES_CONFIG_TABLE_CONTENT', data)
  },

  async removeOccurrencesConfigItem({ commit }, id) {
    commit('REMOVE_OCCURENCES_CONFIG_TABLE_ITEM', id)
  },

  async clearOccurrencesConfigContent({ commit }) {
    commit('CLEAR_OCCURENCES_CONFIG_TABLE_CONTENT')
  },
}