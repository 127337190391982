import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchGroups({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')
    // provisório
    return await request({
      method: 'get',
      url: `groups?is_table=groups&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async deleteGroup({ id }, success, error, done) {
    return await request({
      method: 'delete',
      url: `group/${id}`
    }, success, error, done)
  },

  async fetchGroupsList({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')
    return await request({
      method: 'get',
      url: `acl/roles?is_table=roles&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchGroupSelect(success, error) {
    return await request({
      method: 'get',
      url: 'acl/roles/all'
    }, success, error)
  },

  async fetchPermissions(success, error, done) {
    return await request({
      method: 'get',
      url: 'acl/permissions'
    }, success, error, done)
  },

  async fetchGroupPermissions(id, success, error, done) {
    return await request({
      method: 'get',
      url: `acl/roles/${id}`
    }, success, error, done)
  },

  async createGroup(params, success, error, done) {
    const body = params.body
    return await request({
      method: 'post',
      url: 'acl/roles',
      body
    }, success, error, done)
  },

  async updateGroup(params, success, error, done) {
    const body = params.body
    return await request({
      method: 'put',
      url: `acl/roles/${params.id}`,
      body
    }, success, error, done)
  }
}