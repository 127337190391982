import { notification } from '@/api'

export default {
  async fetchPushHistory({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await notification.fetchPushHistory(params, (res) => 
      commit('SET_PUSH_HISTORY', res), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },
  async getPush(ctx, id) {
    this.dispatch('loading/setLoading', true)
    return await notification.getPush(id, (res) => res, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async createPush(ctx, params) {
    this.dispatch('loading/setLoading', true)
    return await notification.createPush(params, (res) => res, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async updatePush(ctx, params) {
    this.dispatch('loading/setLoading', true)
    return await notification.updatePush(params, (res) => res, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async deletePush({ commit }, id) {
    this.dispatch('loading/setLoading', true)
    return await notification.deletePush(id, () => commit('DELETE_PUSH', id), (e) => e, () => this.dispatch('loading/setLoading', false))
  },
}