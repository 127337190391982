export default {
  visualize:{
    id: 1,
    emitter: 'see',
    icon: 'eye',
    name: 'Visualizar'
  },
  edit:{
    id: 2,
    emitter: 'edit',
    icon: 'edit',
    name: 'Editar'
  },
  delete:{
    id: 3,
    emitter: 'remove',
    icon: 'trash',
    name: 'Deletar'
  },
  print:{
    id: 4,
    emitter: 'print',
    icon: 'print',
    name: 'Imprimir Romaneio'
  },
  upload:{
    id: 5,
    emitter: 'upload',
    icon: 'upload-alt',
    name: 'Enviar Romaneio'
  },
  download:{
    id: 6,
    emitter: 'download',
    icon: 'download',
    name: 'Baixar'
  },
  notify:{
    id: 7,
    emitter: 'notify',
    icon: 'notify',
    name: 'Enviar'
  },
  trace:{
    id: 8,
    emitter: 'trace',
    icon: 'pin',
    name: 'Rastrear'
  },
  pdf:{
    id: 9,
    emitter: 'pdf',
    icon: 'pdf',
    name: 'PDF'
  },
  track:{
    id: 10,
    emitter: 'track',
    icon: 'truck-location',
    name: 'Rastrear'
  },
  calendar:{
    id: 11,
    emitter: 'calendar',
    icon: 'calendar',
    name: 'Calendário'
  },
  payment:{
    id: 12,
    emitter: 'payment',
    icon: 'money',
    name: 'Gerar novo link de pagamento'
  },
  loading:{
    id: 13,
    emitter: 'loading',
    icon: 'loading',
    name: '...'
  },
  downloadCollect: {
    id: 14,
    emitter: 'downloadCollect',
    icon: 'barcode',
    name: 'Baixar Códigos'
  },
  visualizeErrors:{
    id: 15,
    emitter: 'seeErrors',
    icon: 'exclamation-alt',
    name: 'Visualizar Erros'
  },
  linkDriver:{
    id: 16,
    emitter: 'linkDriver',
    icon: 'link',
    name: 'Vincular Motorista'
  },
  unlinkDriver:{
    id: 17,
    emitter: 'unlinkDriver',
    icon: 'unlink',
    name: 'Desvincular Motorista'
  },
  addDriver:{
    id: 17,
    emitter: 'addDriver',
    icon: 'add-driver',
    name: 'Cadastrar Motorista'
  },
  showDriver:{
    id: 18,
    emitter: 'showDriver',
    icon: 'profile-driver',
    name: 'Visualizar Motorista'
  },
  showStores: {
    id: 19,
    emitter: 'showStores',
    icon: 'store-alt',
    name: 'Visualizar Lojas'
  },
  showStoresRoute: {
    id: 20,
    emitter: 'showStoresRoute',
    icon: 'store-alt',
    name: 'Visualizar Lojas'
  },
  downloadReport: {
    id: 21,
    emitter: 'downloadReport',
    icon: 'download',
    name: 'Baixar Relatório'
  },
  resend:{
    id: 22,
    emitter: 'resend',
    icon: 'notify',
    name: 'Reenviar'
  },
  downloadLabel: {
    id: 23,
    emitter: 'downloadLabel',
    icon: 'barcode',
    name: 'Baixar Etiqueta'
  },
  receipt: {
    id: 24,
    emitter: 'receipt',
    icon: 'note',
    name: 'Visualizar Comprovante'
  },
  disableLocationLock: {
    id: 25,
    emitter: 'disableLocationLock',
    icon: 'pin-uncheck',
    name: 'Desabilitar Localização'
  },
  enableLocationLock: {
    id: 26,
    emitter: 'enableLocationLock',
    icon: 'pin-check',
    name: 'Habilitar Localização'
  },
  changeLocation : {
    id: 27,
    emitter: 'changeLocation',
    icon: 'map-pin-alt',
    name: 'Alterar localização'
  },
  zpl:{
    id: 28,
    emitter: 'zpl',
    icon: 'print',
    name: 'Imprimir Etiqueta Zebra'
  },
  comment: {
    id: 29, 
    emitter: 'comment',
    icon: 'comment',
    name: 'Comentário'
  },
  btnPreapproved: {
    id: 30, 
    emitter: 'btnPreapproved',
    name: 'envio'
  },
  accessFortnight: {
    id: 31, 
    emitter: 'accessFortnight',
    name: 'Quinzena'
  },
  downloadConsolidate:{
    id: 32,
    emitter: 'downloadConsolidate',
    icon: 'download',
    name: 'Baixar'
  },
  history:{
    id: 33,
    emitter: 'history',
    icon: 'status-alt',
    name: 'Visualizar Histórico'
  },
  download_xml:{
    id: 34,
    emitter: 'downloadXml',
    icon: 'xml',
    name: 'Baixar XML'
  },
  releaseDevice: {
    id: 35,
    emitter: 'releaseDevice',
    icon: 'mobile-unlock',
    name: 'Liberação de login'
  },
  regenerateCte:{
    id: 36,
    emitter: 'regenerateCte',
    icon: 'document',
    name: 'Gerar CTe novamente'
  },
  enableAutoSyncRoutEasy:{
    id: 37,
    emitter: 'enableAutoSyncRoutEasy',
    icon: 'pin-check',
    name: 'Ativar sincronização RoutEasy'
  },
  disableAutoSyncRoutEasy:{
    id: 38,
    emitter: 'disableAutoSyncRoutEasy',
    icon: 'pin-uncheck',
    name: 'Desativar sincronização RoutEasy'
  },
  generateReceipt:{
    id: 39,
    emitter: 'generateReceipt',
    name: 'Gerar Comprovante'  
  },
  generateExtract:{
    id: 40,
    emitter: 'generateExtract',
    name: 'Gerar Extrato'  
  },
  btnNewJustification:{
    id: 41,
    emmiter: 'btnNewJustification',
    name: 'Refazer Justificativa'
  },
  requestCollect:{
    id: 42,
    emitter: 'requestCollect',
    icon: 'truck-ongoing',
    name: 'Solicitar coleta avulsa'
  },
  warning:{
    id: 43,
    emitter: 'warning',
    icon: 'exclamation-alt',
    name: 'Atenção'
  },
  verificationSign:{
    id: 44,
    emitter: 'verificationSign',
    icon: 'signature',
    name: 'Visualizar Assinatura'
  },
  unlinkVehicle: {
    id: 45,
    emitter: 'unlinkVehicle',
    icon: 'truck-remove',
    name: 'Remover Veículo'
  },
  nf: {
    id: 46,
    emitter: 'nf',
    icon: 'nfe',
    name: 'Baixar Nota Fiscal'
  },
  linkOperatorInOccurrence: {
    id: 47,
    emitter: 'linkOperatorInOccurrence',
    icon: 'link',
    name: 'Vinculação de Ocorrência'
  },
  unlinkOperatorInOccurrence: {
    id: 48,
    emitter: 'unlinkOperatorInOccurrence',
    icon: 'unlink',
    name: 'Desvinculação de Ocorrência'
  },
  concludeOccurrence: {
    id: 49,
    emitter: 'concludeOccurrence',
    icon: 'arrow-right',
    name: 'Concluir Ocorrência'
  },
  checkConfirm: {
    id: 50,
    emitter: 'checkConfirm',
    icon: 'check',
    name: 'Confirmar'
  }
}
