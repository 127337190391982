<template>
  <section class="app" :class="[appNoHeader, appNoHeaderFooter, appAppleStyle]">
    <TheHeader v-if="!noHeader" />
    <router-view :key="$route.fullPath" class="router" />
    <TheFooter v-if="!noFooter" class="footer" />
    <TheError :error="message" :error-additional="errorAdditional" @removeError="removeError" />
    <TheLoading :show="loading" />

    <Dialog
      v-if="dialogShowHide"
      warning="Sua sessão expirou! Por favor realize o login novamente."
      :label-cancel="false"
      label-confirm="Ok"
      @confirm="confirmDialog"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { TheHeader, TheFooter, TheError, TheLoading } from '@/components/organisms'
import { Dialog } from '@/components/molecules'
import intercomMixin from '@/mixins/intercom'
import analyticsMixin from '@/mixins/analytics'

export default {
  name: 'App',

  components: {
    Dialog,
    TheHeader,
    TheFooter,
    TheError,
    TheLoading,
  },

  mixins: [intercomMixin, analyticsMixin],

  computed: {
    ...mapState({
      error: state => state.error.error,
      errorAdditional: state => state.error.errorAdditional,
      expired: state => state.auth.expired,
      loading: state => state.loading.loading,
      alert: state => state.error.alert,
      hasPermissionChanges: state => state.auth.hasPermissionChanges
    }),

    ...mapGetters({
      isSafari: 'userAgent/isSafari'
    }),

    noHeader() {
      return this.$route.meta.noHeader
    },

    noFooter() {
      return this.$route.meta.noFooter
    },

    appNoHeader() {
      return { 'app--no-header': this.noHeader }
    },

    appNoHeaderFooter() {
      return { 'app--no-header-footer': this.noHeader && this.noFooter }
    },

    appAppleStyle() {
      return { 'app--apple-style': this.isSafari }
    },

    dialogShowHide() {
      const excludedExpiredRoutes = ['tracking']
      if (excludedExpiredRoutes.indexOf(this.$route.name) >= 0) {
        this.setExpired(false)
      }
      return this.expired
    },

    message() {
      return this.error || this.alert
    },

    userId() {
      return JSON.parse(localStorage.getItem('user'))?.id
    },

    userRoleName() {
      return JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name
    },
  },

  watch: {
    hasPermissionChanges(changed) {
      if(changed) this.hasChangedPermissions()
    }
  },

  mounted() {
    if(this.userId) {
      this.fetchModulesAndRoleByUser().then(() => {
        this.listenChangesOnUserRole(this.userId)
        this.listenChangesOnRolePermissions(this.userRoleName)
      })
    }
  },

  destroyed() {
    this.unlistenChangesOnUserRole(this.userId)
    this.unlistenChangesOnRolePermissions(this.userRoleName)
  },

  methods: {
    ...mapActions({
      setError: 'error/setError',
      setErrorAdditional: 'error/setErrorAdditional',
      setExpired: 'auth/setExpired',
      signOut: 'auth/signOut',
      setAlert: 'error/setAlert',
      fetchModulesAndRoleByUser: 'auth/fetchModulesAndRoleByUser',
      listenChangesOnUserRole: 'auth/listenChangesOnUserRole',
      listenChangesOnRolePermissions: 'auth/listenChangesOnRolePermissions',
      unlistenChangesOnUserRole: 'auth/unlistenChangesOnUserRole',
      unlistenChangesOnRolePermissions: 'auth/unlistenChangesOnRolePermissions',
      changedPermissions: 'auth/changedPermissions',
    }),

    removeError() {
      this.setError('')
      this.setErrorAdditional('')
      this.setAlert('')
    },

    confirmDialog(){
      this.signOut().then(() => {
        this.setExpired(false)
        this.$router.push('/auth/signin')
      })
    },

    hasChangedPermissions() {
      this.changedPermissions(false)
      this.$router.go(this.$router.currentRoute)
    },
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>

<style lang="scss" scoped>
.app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 74px 1fr 40px;
  grid-template-areas: "." "router" "footer";
  @media (max-width: $viewport-sm) {
    grid-template-rows: 74px 1fr 40px;
  }
  .router {
    grid-area: router;
  }
  .footer {
    grid-area: footer;
  }

  &--no-header {
    grid-template-rows: 1fr 40px;
    grid-template-areas: "router" "footer";
    @media (max-width: $viewport-sm) {
      grid-template-rows: 1fr 40px;
    }
  }

  &--no-header-footer {
    grid-template-rows: 1fr;
    grid-template-areas: "router";
    @media (max-width: $viewport-sm) {
      grid-template-rows: 1fr;
    }
  }
}
</style>
<style lang="scss">
.app {
  &--apple-style {
    input {
      font-size: $font-size-1xsmall !important;
    }
  }
}
 @media #{$mobile-view} {
   #jsd-widget{
     right: auto !important;
   }
  }
</style>