import { dropoff } from '@/api'

export default {

  async fetchDropoffReportsRequested({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await dropoff.fetchDropoffReportsRequested(params, (res) =>
      commit('SET_DROPOFFS_REPORTS_REQUESTED', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },
}