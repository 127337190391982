<template>
  <!-- Table -->
  <div class="DataTableWrapper">
    <table class="DataTable">
      <!-- Title -->
      <caption v-show="title" class="DataTableTitle">
        {{ title }}
      </caption>

      <!-- Checkbox Select All (Responsive) -->
      <label v-if="DisplaySelectBulkActions" v-show="isResponsive" class="DataTable__selectAll">
        <input
          type="checkbox"
          class="DataTable__selectall"
          @click="selectAllTableItems"
        >
        <span>Selecionar tudo</span>
      </label>

      <!-- Table Header -->
      <thead v-show="!isResponsive && table.length">
        <tr class="DataTable__tr">
          <!-- Checkbox Select All -->
          <th v-if="DisplaySelectBulkActions" class="DataTableTd">
            <label>
              <input
                type="checkbox"
                class="DataTable__selectall"
                @click="selectAllTableItems"
              >
              <span>Selecionar tudo</span>
            </label>
          </th>
          <!-- Headers -->
          <template v-for="(th, i) in header">
            <Cell
              :key="i"
              :item="th"
              :header="header[i]"
              :position="GetPosition"
              :table="table"
              :sort-columns="sortColumns"
            />
          </template>
        </tr>
      </thead>

      <!-- Table Body -->
      <tbody class="DataTable__tbody">
        <template v-if="table.length">
          <tr
            v-for="tr in table"
            :key="tr.id"
            class="DataTable__tr"
            :class="{
              ...trClass,
              'DataTable__tr--featured': featured.includes(tr.id)
            }"
          >
            <!-- Checkbox -->
            <td v-if="DisplaySelectBulkActions" class="DataTableTd">
              <label>
                <input
                  type="checkbox"
                  :checked="isChecked(tr)"
                  :value="value"
                  @change="selectRow(tr)"
                >
                <span>Selecionar</span>
              </label>
            </td>

            <!-- Content -->
            <template v-for="(item, i) in tr">
              <!-- Has Action -->
              <template v-if="header[i] && header[i].actions && header[i].id === 'actions'">
                <!-- Actions -->
                <td
                  :key="i"
                  class="DataTableActions"
                  :class="DataTableActionsClasses"
                >
                  <!-- More then one action with permission -->
                  <div v-if="showDataTableActions(item, 'multiple')" class="DataTableActions__event--multiple">
                    <Icon v-show="!isResponsive" name="elipses" class="DataTableActions__elipses" color="dark" />
                    <section
                      class="DataTableActions__list"
                      :class="DataTableActionsListClasses"
                    >
                      <span
                        v-for="(action, y) in removeMissingActions(item)"
                        v-show="action && action.has_permission"
                        :key="y"
                        class="DataTableActions__event"
                        :class="[
                          actionsList[action.action_id].icon,
                          action.disabled ? 'DataTableActions__event--disabled' : '',
                          action.opacity ? 'DataTableActions__event--opacity' : ''
                        ]"
                        @click="
                          trigger(actionsList[action.action_id].emitter, tr)
                        "
                      >
                        <Icon
                          :title="actionsList[action.action_id].name"
                          :name="actionsList[action.action_id].icon"
                          :disabled="action.disabled"
                          color="white"
                        />
                        <span
                          v-show="isResponsive"
                          class="DataTableActions__Name"
                        >{{ actionsList[action.action_id].name }}</span>
                      </span>
                    </section>
                  </div>

                  <!-- One action with permission -->
                  <template v-else-if="showDataTableActions(item)">
                    <div
                      v-for="(action, y) in removeMissingActions(item)"
                      :key="y"
                      class="DataTableActions__event--single"
                      :class="[checkActionHasPermission(action)]"
                    >
                      <span
                        class="DataTableActions__event"
                        :class="[
                          actionsList[action.action_id].icon,
                          action.disabled ? 'DataTableActions__event--disabled' : '',
                          action.opacity ? 'DataTableActions__event--opacity' : ''
                        ]"
                        @click="trigger(actionsList[action.action_id].emitter, tr)"
                      >
                        <Icon
                          :title="actionsList[action.action_id].name"
                          :name="actionsList[action.action_id].icon"
                          :disabled="action.disabled"
                          color="dark"
                        />
                        <span
                          v-show="isResponsive"
                          class="DataTableActions__Name"
                        >{{ actionsList[action.action_id].name }}</span>
                      </span>
                    </div>
                  </template>
                </td>
              </template>
              
              <!-- Buttons -->
              <template v-else-if="header[i] && header[i].id === 'buttons'">
                <td
                  v-if="header[i]"
                  v-show="header[i].isVisible"
                  :key="i"
                  class="DataTableButtons"
                >
                  <div class="DataTableButtons__Content">
                    <BaseButton
                      v-for="(button) in item"
                      :key="button.buttons_id"
                      :class="[checkActionHasPermission(button)]"
                      color="primary-base"
                      :label="button.name"
                      :disabled="button.disabled"
                      @click="trigger(button.buttons_id, tr)"
                    />
                  </div>
                </td>
              </template>

              <!-- Inputs and Selects -->
              <template v-else-if="header[i] && header[i].id === 'inputs'">
                <td v-if="header[i].type === 'select'" :key="`select${i}`" class="DataTableSelect">
                  <div class="DataTableSelect__Content">
                    <BaseTreeSelect
                      v-for="(select) in item"
                      :key="select.select_id"
                      v-model="select.optionSelected"
                      :class="[checkActionHasPermission(select)]"
                      :read-only="select.disabled"
                      :enable-paste="false"
                      :select-options="select.selectOptions"
                      placeholder="Selecione"
                      :append-to-body="true"
                      open-direction="below"
                      @input="trigger('select', tr)"
                    />
                  </div>
                </td>

                <td v-if="header[i].type === 'value'" :key="`value${i}`" class="DataTableInputValue">
                  <div class="DataTableInputValue__Content">
                    <div v-for="(input) in item" :key="input.select_id" class="inputValue" :class="[checkActionHasPermission(input)]">
                      <InputGroup
                        v-model="input.optionSelected"
                        type="text"
                        mask="money"
                        :read-only="input.disabled"
                        placeholder="R$00,00"
                        @input="valueInputChange(input)"
                      />

                      <div class="controls">
                        <Icon
                          v-if="!input.disabled"
                          name="chevron-up"
                          class="icon icon-up"
                          @click="valueInputChange(input, 'plus')"
                        />
                        <Icon
                          v-if="!input.disabled"
                          name="chevron-down"
                          class="icon icon-down"
                          @click="valueInputChange(input, 'minus')"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </template>

              <!-- Datepicker-->
              <template v-else-if="header[i] && header[i].method === 'datepicker'">
                <td
                  v-if="header[i]"
                  v-show="header[i].isVisible"
                  :key="i"
                  class="DataTableTd"
                  :class="header[i].id"
                >
                  <template v-for="(datepicker, index) in item">
                    <div v-if="datepicker.input" :key="index" class="DataTableTd__Content">
                      <BaseDatePicker
                        v-model="datepicker.value"
                        :style="datepicker.style"
                        :type="datepicker.type"
                        :format="datepicker.format"
                        :time-picker-default="false"
                        :formatter="datepicker.formatter"
                        :show-hour="datepicker.showHour"
                        :range="datepicker.range"
                        :placeholder="datepicker.placeholder"
                        :prepend-icon="datepicker.prependIcon"
                        :clearable="datepicker.clearable"
                        :custom-open="datepicker.customOpen"
                        :open="datepicker.open"
                      />
                    </div>
                    <span v-else :key="index" class="DataTableTd__Content" :style="datepicker.style">
                      {{ datepicker.timelabel }}
                    </span>
                  </template>
                </td>
              </template>

              <!-- Toggle -->
              <template v-else-if="header[i] && header[i].toggle">
                <td :key="i" class="active">
                  <BaseToggle
                    :id="tr.id"
                    v-model="tr.active"
                    label-able="Sim"
                    label-disable="Não"
                    @input="toggleChange(tr)"
                  />
                </td>
              </template>

              <!-- Circle / Warning / AssignLineColor -->
              <template v-else-if="header[i] && header[i].method === 'warning' || header[i] && header[i].method === 'assignLineColor'">
                <td
                  v-if="header[i]"
                  v-show="header[i].isVisible"
                  :key="i"
                  class="DataTableTd"
                  :class="header[i].id"
                >
                  <div
                    class="circle"
                    :class="{'circle-green': item}"
                    :style="`background-color: ${item}`"
                  />
                </td>
              </template>
              
              <!-- Row -->
              <Cell
                v-else
                :key="i"
                :item="item"
                :header="header[i] || {}"
                :row="tr"
                :size-limit="SizeLimit"
                :is-responsive="isResponsive"
              />
            </template>
          </tr>
        </template>
        <!-- No Content -->
        <template v-else>
          <tr>
            <td :colspan="countCol" class="DataTableTd__NoContent">
              {{ noContentText }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import actionsList from './utils/actionsList'
import { BaseToggle, BaseDatePicker, BaseTreeSelect, BaseButton } from '@/components/atoms'
import InputGroup from '@/components/molecules/InputGroup/InputGroup'
import Cell from '@/components/molecules/DataTable/DataTableCell'

export default {
  name: 'DataTable',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseToggle,
    BaseTreeSelect,
    InputGroup,
    Cell
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    header: {
      type: Object,
      required: true,
    },
    table: {
      type: Array,
      required: true
    },
    responsive: {
      type: Number,
      default: 960,
    },
    value: {
      type: Array,
      default: null,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Boolean,
      default: true,
    },
    noContentText: {
      type: String,
      default: 'Nenhum dado encontrado'
    },
    featured: {
      type: Array,
      default: () => []
    },
    sortColumns: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isResponsive: false,
      actionsList,
      toggleFlag: true,
    }
  },
  computed: {
    DisplaySelectBulkActions() {
      return this.value && this.table.length > 0
    },
    DataTableActionsClasses() {
      return { 'DataTableActions--responsive': this.isResponsive }
    },
    DataTableActionsListClasses() {
      return { 'DataTableActions__list--responsive': this.isResponsive }
    },
    trClass() {
      return { 'DataTable__tr--responsive': this.isResponsive }
    },
    GetPosition() {
      const cssObj = {}
      if (this.isSticky) {
        cssObj.position = 'sticky'
        cssObj.top = '74px'
      } else {
        cssObj.position = 'initial'
        cssObj.top = '0'
      }

      return cssObj
    },
    countCol() {
      const columns = []

      for (const key in this.header) {
        const col = this.header[key]
        if (col.isVisible) {
          columns.push(key)
        }
      }

      return columns.length
    },

    SizeLimit() {
      return this.maxSize ? 'DataTable--limit' : 'DataTable--noLimit'
    }

  },
  mounted() {
    this.getIsResponsive()
    window.addEventListener('resize', this.getIsResponsive)
  },

  destroyed() {
    window.removeEventListener('resize', this.getIsResponsive)
  },
  methods: {
    removeMissingActions(data) {
      return data.filter(d => this.actionsList[d.action_id])
    },

    getIsResponsive() {
      this.$nextTick(() => {
        const width = window.innerWidth
        return width <= this.responsive
          ? (this.isResponsive = true)
          : (this.isResponsive = false)
      })
    },
    trigger(action, value) {
      this.$emit(action, value)
    },
    toggleChange(value) {
      this.$emit('toggled', value)
    },
    selectAllTableItems(e) {
      e.target.checked
        ? this.$emit('input', this.table)
        : this.$emit('input', [])
    },

    selectRow(val) {
      const filteredValues = this.value.filter((v) => v.id !== val.id)
      const selectedList =
        filteredValues.length < this.value.length
          ? filteredValues
          : [...filteredValues, val]
      this.$emit('input', selectedList)
    },

    valueInputChange(tr, control) {
      const numberPattern = /[^0-9-]/g
      /* eslint-disable */
      const string = JSON.stringify(tr.optionSelected)
      const value = string.replace(numberPattern, '')
      if(control === 'plus') {
        tr.optionSelected =  parseInt(value) + 5000
      } else if(control === 'minus') {
        tr.optionSelected = parseInt(value) - 5000
      }
      this.$emit('inputValueChange', tr)
    },

    isChecked(val) {
      return this.value.find((v) => v.id === val.id)
    },

    checkActionHasPermission(action) {
      if (!action.has_permission) {
        return 'DataTable--hide'
      }
    },

    showDataTableActions(item, type) {
      let show = false
      if (type === 'multiple') {
        const hasMoreThenOne = Object.values(item).filter(el => el.has_permission === true).length > 1
        show = item && item.length > 1 && item.some(el => el.has_permission) && hasMoreThenOne
      } else {
        show = item && item.length > 0 && item.some(el => el.has_permission)
      }

      return show
    }
  },
}
</script>

<style lang="scss" scoped>
// Global Variables
$clipInit: inset(50% 0 50% 100%);
$clipDuring: inset(0 0 0 90%);
$clipEnd: inset(0 0 0 0);

.circle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: $color-neutral-soft;
  &-green {
    background-color: $color-success-soft;
  }
}

//Keyframe Animations
@keyframes actionsHeightIn {
  from {
    clip-path: $clipInit;
    transform: translateX(-40px);
  }
  to {
    clip-path: $clipDuring;
    transform: translateX(-40px);
  }
}

@keyframes actionsWidthIn {
  from {
    clip-path: $clipDuring;
    transform: translateX(-40px);
  }
  to {
    clip-path: $clipEnd;
    transform: translateX(0px);
  }
}

@keyframes actionsHeightOut {
  from {
    clip-path: $clipDuring;
  }
  to {
    clip-path: $clipInit;
  }
}

@keyframes actionsWidthOut {
  from {
    clip-path: $clipEnd;
    transform: translateX(0px);
  }
  to {
    clip-path: $clipDuring;
    transform: translateX(-40px);
  }
}

.DataTableWrapper {
  border-radius: $border-radius-small;
  border: 1px solid $color-neutral-softer;
}

.DataTable {
  /* Positioning */
  position: relative;

  input[type="checkbox"] + span {
    display: none;
    @media #{$mobile-view} {
      display: inline-block;
      margin-left: 5px;
      font-size: $font-size-3xsmall;
    }
  }

  thead {
    /deep/ .DataTable__tr {
      border-left: $spacing-400 solid transparent;
      border-right: $spacing-400 solid transparent;
    }
    /deep/ .DataTable__tr td {
      z-index: 10;
      position: relative;
      background: #fff;
      &:first-of-type {
        border-top-left-radius: $border-radius-small;
      }
      &:last-of-type {
        border-top-right-radius: $border-radius-small;
      }
    }
    /deep/ .DataTableTd__Content {
      z-index: $z-overlay;
      font-size: $font-size-mini;
      color: $color-neutral-stronger;
      font-weight: bold;;
      text-align: initial;
      text-transform: lowercase;
      word-break: normal;
    }
  }

  /* Box model */
  width: 100%;

  /* Typographic */
  &--center {
    text-align: center !important;
  }
  &--left {
    text-align: left !important;
  }
  &--hide {
    display: none;
  }

  /* Visual */
  border-collapse: collapse;
  border-spacing: 0;

  .DataTable__selectAll {
    width: auto;
    padding: 15px;
    display: block;
    border: 1px solid #C0C0C0;
    margin: 0 0 20px;
    text-align: center;
  }

  &__tbody {
    .DataTable__tr {
      border-top: 1px solid $color-neutral-softer;
      border-left: $spacing-400 solid transparent;
      border-right: $spacing-400 solid transparent;

      @media #{$mobile-view} {
        border-left: $spacing-200 solid transparent;
        border-right: $spacing-200 solid transparent;
      }

      &--disabled {
        /* Visual */
        opacity: 0.2;

        /* Misc */
        cursor: not-allowed;
        pointer-events: none;

      }
    }
  }
  
  
}
 

//Buttons
.DataTableButtons {
  &__Content {
    display: flex;

    /deep/.BaseButton {
      height: 25px;
      margin: 0 10px;

      &__Text {
        text-transform: capitalize;
        margin-left: 0;
      }
    }

    &--no-pointer {
      pointer-events: none;
    }
  }
}

//Actions
.DataTableActions {
  /* Positioning */
  position: relative;
  vertical-align: middle;
  text-align: center;

  /* Box model */
  padding: 0.6rem;

  &:hover {
    .DataTableActions__list {
      border-radius: $border-radius-1xsmall;
      animation: actionsHeightIn 0.1s ease forwards,
        actionsWidthIn 0.1s 0.15s ease forwards;
    }
  }

  &--responsive {
    &:hover {
      .DataTableActions__list {
        animation: none;
      }
    }
  }
  img {
    /* Box model */
    height: 1rem;

    /* Misc */
    cursor: pointer;
  }
  &__list {
    /* Positioning */
    position: absolute;
    z-index: $z-label;

    /* Box model */
    display: flex;
    overflow: visible;
    padding: 0.5rem 0.25rem;
    height: auto;

    /* Visual */
    background: $color-neutral-stronger;
    clip-path: $clipInit;

    /* Animation */
    animation: actionsWidthOut 0.1s 0.15s ease forwards,
      actionsHeightOut 0.1s ease forwards;

    &--responsive {
      /* Positioning */
      position: static;
      transform: translateX(0);

      /* Box model */
      flex-wrap: wrap;

      /* Visual */
      clip-path: $clipEnd;
      background: transparent;

      /* Animation */
      animation: none;
      width: 100%;
      @media (max-width: $viewport-xs) {
        padding: 0;
      }

      .DataTableActions__event {
        /* Box model */
        padding: 0.5rem;
        margin: 0.5rem 0.5rem 0 0;

        @media (max-width: $viewport-xs) {
          width: 100%;
          margin: 0.25rem 0;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius-1xsmall;
          text-transform: lowercase;
          display: flex;
        }

        /* Typographic */
        &:hover {
          color: $color-neutral-strong;
        }

        /* Visual */
        border: 2px solid $color-neutral-stronger;
        img {
          filter: $filter-dark;
        }
        &:hover {
          img {
            filter: $filter-gray;
          }
        }
      }
    }
  }
  &__elipses {
    transform: rotate(-90deg);
  }
  &__event {
    /* Box model */
    padding: 0 0.25rem;

    /* Typographic */
    font-weight: $font-weight-bold;
    font-size: $font-size-2xmini;
    color: $color-neutral-strong;
    text-transform: uppercase;

    /* Misc */
    cursor: pointer;

    &.loading {
      cursor: default;
    }

    img {
      opacity: $opacity-base;

      @media #{$mobile-view} {
        opacity: 1;
      }
    }

    &:hover {
      img {
        opacity: 1;
      }
    }

    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.2;

      &:hover {
        img {
          filter: none;
        }
      }
    }

    &--opacity {
      opacity: 0.5;
    }

    &--multiple {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--single {
      padding: 5px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        opacity: 1;
      }
      @media #{$mobile-view} {
        border: 2px solid $color-neutral-stronger;
      }
      @media (max-width: $viewport-xs) {
        width: 100%;
        margin: 0.25rem 0;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        color: $color-neutral-strong;
        img {
          filter: $filter-gray;
        }
      }
    }
  }
}

// Select
.DataTableSelect {
  &__Content {
    display: flex;
    width: 150px;

    /deep/ .BaseMultiselect {
      margin: 0 10px;
    }

    /deep/ .vue-treeselect__control {
      background: transparent;
    }
  }
}

// InputValue
.DataTableInputValue {
  &__Content {
    display: flex;

    .inputValue {
      display: flex;
      margin: 0 10px;
      align-items: center;
      position: relative;
      min-width: 100px;

      input {
        width: 75px;
        height: 20px;
        background-color: transparent;
        border-bottom: 2px solid #000000;
        padding: 0 10px 0 3px;
        font-size: $font-size-2xmini;
      }

      .controls {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 5px;
        bottom: 5px;
        .icon {
          width: 9px;
          cursor: pointer;
        }
      }
    }
  }
}

.DataTable__tbody .DataTable__tr--featured {
  background: $color-notice-softer;
}

.DataTableTd {
  position: relative;
  z-index: $z-label;
  padding: $spacing-400 $spacing-200;
  
  &__NoContent {
    text-align: center;
    padding: $spacing-inset-small;
  }
}

//Responsive
.DataTable__tbody .DataTable__tr--responsive {
  /* Box model */
  display: flex;
  flex-flow: column;
  padding: 0.5rem;
  height: auto;
  &:before {
    display: none;
  }

  .DataTableTd {
    /* Box model */
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 0.5rem;
    border-bottom: 1px dashed rgba(#000, 0.2);

    &__Title {
      /* Typographic */
      font-size: $font-size-1xmini;
      font-weight: $font-weight-bold;
      text-align: right;
      @media #{$mobile-view} {
        text-align: left;
        &:after {
          content: ":";
        }
      }
    }
    &__Content {
      max-width: 100%;
    }
  }

  //Actions
  .DataTableActions {
    justify-content: flex-start;
  }
  .DataTableActions {
    img {
      margin-right: 0.5rem;
    }
  }

  //Buttons
  .DataTableButtons {

    @media (max-width: $viewport-xs) {
      padding: 0 0.6rem 0.6rem 0.6rem;
    }

    &__Content {
      @media (max-width: $viewport-xs) {
        flex-direction: column;
      }
      /deep/.BaseButton {
        height: 40px;

        @media (max-width: $viewport-xs) {
          margin: 0;
          margin-bottom: 0.5rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.DataTableTitle {
  font-size: $font-size-1xsmall;
  text-align: center;
  margin-bottom: 10px;
}
</style>