export default {

  transitPointImportWithoutParentStores(state) {
    return state.transitPointImport.filter(t => t.schedule || t.parent_id)
  },

  transitPointImportWithoutStores(state) {
    return state.transitPointImport.filter(t => t.schedule)
  },

  routesWithDriverActions(state){

    let headers = state.routes.data.headers
    let content = state.routes.data.content
    let hasLoadingAction = false

    content.map((el) => {      
      hasLoadingAction = el.actions.some(action => action.action_id === 'loading' && action.has_permission === true)
      if (hasLoadingAction) {
        el.actions = []
        el.actions.push({action_id: 'loading', has_permission: true})
      }
    })

    if (hasLoadingAction) {
      return state.routes
    } else {
      return {
        data: {
          content: content.map((route) => {
            let i = route
            if (!route.route_transfer_transporter_id) {
              if (route.warning && JSON.stringify(i.actions).indexOf('unlinkDriver') === -1) {
                i.actions = [...i.actions, {
                  action_id: 'unlinkDriver', has_permission: true
                }]
              }
              if (!route.warning && JSON.stringify(i.actions).indexOf('linkDriver') === -1) {
                i.actions = [...i.actions, {
                  action_id: 'linkDriver', has_permission: true
                }]
              }
              i.actions = i.actions.map((action) =>{
                if (action.action_id === 'linkDriver' && route.warning) {
                  action.has_permission = false              
                }
                if (action.action_id === 'unlinkDriver' && !route.warning) {
                  action.has_permission = false              
                }
                return action
              })          
            }
            return i
          }),
          headers: headers       
        },
        pagination: state.routes.pagination
      }

    }

  },

}
