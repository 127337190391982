<template>
  <Modal v-if="modal" class="BarcodeScanner" @close="close">
    <template #header>
      <div class="BarcodeScanner__Header">
        Configuração de Bipagem
      </div>
    </template>
    <template #body>
      <div class="BarcodeScanner__Body">
        <div class="BarcodeScanner__Text">
          <div class="BarcodeScanner__Sound">
            <BaseButton
              v-if="isMobile"
              :label="beepingSound.label"
              theme="icon-left"
              :color="beepingSound.color"
              filled
              :icon="beepingSound.icon"
              :text-color="beepingSound.textColor"
              @click="handleBeepingSound"
            />
            <Icon
              v-else
              v-tooltip=""
              class="BarcodeScanner__Tooltip"
              :name="beepingSound.icon || 'faq'"
              @click="handleBeepingSound"
            />
          </div>
          <p v-if="storesOptions.length > 1">
            Escolha a quantidade de números para retirar<br>
            a esquerda e a direita do código com base<br>
            na loja selecionada abaixo.
          </p>
          <p v-else>
            Escolha a quantidade de números para retirar<br>
            a esquerda e a direita do código.
          </p>
        </div>
        <div v-if="storesOptions.length > 1" class="BarcodeScanner__Store">
          <BaseTreeSelect
            v-model="storesSelected"
            name="Lojas"
            label="Lojas"
            placeholder="Selecione"
            :clearable="false"
            :select-options="storesOptions"
          />
        </div>
        <div class="BarcodeScanner__Code">
          <ul class="BarcodeScanner__NumberList">
            <li
              v-for="(number, index) in codeSplited"
              :key="index"
              class="number-list-item"
              :class="[handlerDisableNumber(index)]"
            >
              {{ number }}
            </li>
          </ul>
        </div>
        <div class="BarcodeScanner__Settings">
          <VueSlider
            v-if="value.length > 0"
            ref="slider"
            v-model="value"
            :min="0"
            :max="codeSplited.length"
            :process="process"
            :dot-options="dotOptions"
            :order="true"
            :enable-cross="false"
          >
            <!-- eslint-disable-next-line -->
            <template #tooltip="{value, index}">
              <div
                :class="[
                  'custom-tooltip',
                  'vue-slider-dot-tooltip-inner',
                  'vue-slider-dot-tooltip-inner-top'
                ]"
              >
                <div v-if="index === 2">
                  {{ codeSplited.length - value }}
                </div>
                <div v-else>
                  {{ value }}
                </div>
              </div>
            </template>
          </VueSlider>
        </div>

        <div class="BarcodeScanner__Actions">
          <BaseButton
            v-if="handlerHideButtons('restore')"
            class="BarcodeScanner__Actions--restore"
            label="Restaurar Padrão"
            theme="icon-left"
            color="dark"
            filled
            icon="refresh"
            text-color="white"
            @click="restore()"
          />
          <BaseButton
            v-if="handlerHideButtons('delete')"
            class="BarcodeScanner__Actions--restore--all"
            label="Apagar todas configurações"
            theme="icon-left"
            color="dark"
            filled
            icon="trash"
            text-color="white"
            @click="erase()"
          />
          <BaseButton
            label="Salvar"
            theme="icon-left"
            color="primary-base"
            filled
            icon="save"
            text-color="white"
            @click="save()"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import {mapGetters} from 'vuex'
import {BaseButton, BaseTreeSelect} from '@/components/atoms'
import {Modal} from '@/components/molecules'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
export default {
  name: 'BarcodeScannerSettings',
  components: {
    BaseButton,
    BaseTreeSelect,
    Modal,
    VueSlider,
  },
  props: {
    code: {
      type: [Number, String],
      description: '',
      default: 'XXY0123456780YXX',
    },
    stores: {
      type: [Array],
      description: '',
      default: () => [],
    },
  },
  data() {
    return {
      modal: false,
      localStorageName: 'barcode-settings',
      localStorageBeepingSound: 'beeping-settings',

      storesSelected: null,
      beepingSoundIcon: true,

      //Slider
      value: [],
      process: (val) => [
        [val[0], val[1]],
        [val[1], val[2]],
      ],
      dotOptions: [
        {
          disabled: false,
          tooltip: 'always',
        },
        {
          disabled: true,
          tooltip: 'none',
        },
        {
          disabled: false,
          tooltip: 'always',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'userAgent/isMobile',
    }),

    codeSplited() {
      return this.code !== null ? this.code.split('') : []
    },
    barCodeSlice() {
      return [
        this.value[0],
        this.codeSplited.length - this.value[this.value.length - 1],
      ]
    },
    storesOptions() {
      return this.stores.map((el) => {
        return {
          id: el.id,
          name: el.name,
          slice:
            el.info.bar_code_slice_ls !== null
              ? el.info.bar_code_slice_ls
              : [0, 0],
          hub: !!el.schedule,
        }
      })
    },
    beepingSound() {
      return {
        label: this.beepingSoundIcon
          ? 'Desative o som de bipagem'
          : 'Ative o som de bipagem',
        icon: this.beepingSoundIcon ? 'unmuted' : 'muted',
        textColor: this.beepingSoundIcon ? 'dark' : 'white',
        color: this.beepingSoundIcon ? 'primary-base' : 'dark',
      }
    },
  },
  watch: {
    stores: {
      handler: function(val) {
        this.$nextTick(() => {
          if (val.length > 0) {
            const store = val[val.length - 1]
            this.setStoreSelected(store)
          } else {
            this.storesSelected = null
          }
        })
      },
      deep: true,
    },
    storesSelected: function() {
      this.$nextTick(() => {
        this.setBipingRangeValues()
      })
    },
    code: function() {
      this.$nextTick(() => {
        this.setBipingRangeValues()
      })
    },
  },
  mounted() {
    this.init()
  },

  methods: {
    //Init
    init() {
      this.setBipingRangeValues()
      this.handleBeepingSoundIcon()
    },

    setStoreSelected(store) {
      this.storesSelected = {
        id: store.id,
        name: store.name,
        slice: store.info.bar_code_slice_ls,
        hub: !!store.schedule,
      }
    },

    updateStoreSelected(val) {
      this.storesSelected.slice = val
    },

    setBipingRangeValues() {
      const hasNoSelected = !this.storesSelected
      const leftValue =
        !hasNoSelected && this.storesSelected && this.storesSelected.slice !== null
          ? this.storesSelected.slice[0]
          : 0

      const middleValue = this.codeSplited.length / 2

      const rightValue =
        !hasNoSelected && this.storesSelected && this.storesSelected.slice !== null
          ? this.codeSplited.length - this.storesSelected.slice[1]
          : this.codeSplited.length

      this.value = [
        parseInt(leftValue),
        parseInt(middleValue),
        parseInt(rightValue),
      ]
    },

    handlerDisableNumber(index) {
      const valueBefore = this.value[0] > index
      const valueAfter = this.value[this.value.length - 1] < index + 1
      return valueBefore || valueAfter ? 'disable' : ''
    },

    //Handlers
    open() {
      this.modal = true
      this.$emit('open')
    },
    close() {
      this.modal = false
      this.$emit('close')
    },
    save() {
      this.updateStoreSelected(this.barCodeSlice)
      this.handlerCodeOnLocalStorage()
      this.$emit('save', this.barCodeSlice)
    },
    restore() {
      this.handlerCodeOnLocalStorage('restore')
      this.$emit('restore', this.storesSelected)
    },
    erase() {
      this.handlerCodeOnLocalStorage('delete')
      this.storesSelected = null
      this.$emit('delete', [0, 0])
    },

    handlerCodeOnLocalStorage(action) {
      const hasNoSelected = !this.storesSelected
      if (hasNoSelected) return

      //Checkers
      const itsRestore = action === 'restore'
      const itsDelete = action === 'delete'

      //Element to store
      const barcodeScannerSliceLocalStorage = {
        storeId: this.storesSelected.id,
        codeSlice: this.storesSelected.slice,
        hub: this.storesSelected.hub,
      }

      //Check if exist on local storage
      const codeOnLocalStorage = localStorage.getItem(this.localStorageName)

      if (itsDelete) {
        this.deleteCodeLocalStorage()
      } else if (codeOnLocalStorage) {
        const codesFromLocalStorage = JSON.parse(codeOnLocalStorage)
        const hasCodeSettings = codesFromLocalStorage.find(
          (el) =>
            el.storeId === this.storesSelected.id &&
            this.storesSelected.hub === el.hub
        )

        if (!hasCodeSettings && !itsRestore) {
          this.addCodeLocalStorage(
            codesFromLocalStorage,
            barcodeScannerSliceLocalStorage
          )
        } else {
          if (itsRestore) {
            this.restoreCodeLocalStorage(codesFromLocalStorage)
          } else {
            this.updateCodeLocalStorage(
              codesFromLocalStorage,
              barcodeScannerSliceLocalStorage
            )
          }
        }
      } else {
        if (!itsRestore) {
          this.createCodeLocalStorage(barcodeScannerSliceLocalStorage)
        }
      }

      //Exit modal
      if (!itsRestore) {
        this.close()
      }
    },

    createCodeLocalStorage(newValue) {
      this.setLocalStorage([newValue])
    },

    addCodeLocalStorage(array, newValue) {
      array.push(newValue)
      this.setLocalStorage(array)
    },

    updateCodeLocalStorage(array, newValue) {
      const index = array.findIndex(
        (el) =>
          el.storeId === this.storesSelected.id &&
          this.storesSelected.hub === el.hub
      )
      array.splice(index, 1, newValue)
      this.setLocalStorage(array)
    },

    restoreCodeLocalStorage(array) {
      const index = array.findIndex(
        (el) =>
          el.storeId === this.storesSelected.id &&
          this.storesSelected.hub === el.hub
      )
      if (index > -1) {
        if (array.length === 1) {
          localStorage.removeItem(this.localStorageName)
        } else {
          array.splice(index, 1)
          this.setLocalStorage(array)
        }
      }
    },

    deleteCodeLocalStorage() {
      localStorage.removeItem(this.localStorageName)
    },

    setLocalStorage(codes) {
      localStorage.setItem(this.localStorageName, JSON.stringify(codes))
    },

    getCodeLocalStorage(value) {
      const codeOnLocalStorage = localStorage.getItem(this.localStorageName)
      if (codeOnLocalStorage) {
        const codesFromLocalStorage = JSON.parse(codeOnLocalStorage)
        const hasCodeSettings = codesFromLocalStorage.find(
          (el) => el.storeId === value.id && !!value.schedule === el.hub
        )
        return hasCodeSettings
      }
      return null
    },

    handlerHideButtons(type) {
      const codeOnLocalStorage = localStorage.getItem(this.localStorageName)
      const codesFromLocalStorage = JSON.parse(codeOnLocalStorage)

      if (codeOnLocalStorage) {
        switch (type) {
        case 'restore':
          return codesFromLocalStorage.length > 0
        case 'delete':
          return codesFromLocalStorage.length > 1
        default:
          return true
        }
      }
      return false
    },

    //Beeping Sound
    handleBeepingSound() {
      this.beepingSoundIcon = !this.beepingSoundIcon
      localStorage.setItem(this.localStorageBeepingSound, this.beepingSoundIcon)
    },

    handleBeepingSoundIcon() {
      this.beepingSoundIcon = localStorage.getItem(
        this.localStorageBeepingSound
      )
        ? localStorage.getItem(this.localStorageBeepingSound) === 'true'
        : true
    },
  },
}
</script>
<style lang="scss" scoped>
.BarcodeScanner {
  &__Header {
    text-align: center;
    padding: 4px 3rem 4px;
  }

  &__Body {
    padding: 20px;
  }

  &__Text {
    text-align: center;
    position: relative;

    @media #{$mobile-view} {
      br {
        display: none;
      }
    }
  }

  &__Sound {
    @media #{$mobile-view} {
      margin-bottom: 20px;
    }
  }

  &__Tooltip {
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  &__Store {
    margin: 20px auto 0;
  }

  &__Code {
    padding: 30px 0;
  }

  &__NumberList {
    width: fit-content;
    list-style: none;
    margin: auto;
    padding: 0;
    border: 2px solid #777777;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    li.number-list-item {
      padding: 0 5px;
      height: 50px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease;

      @media #{$mobile-view} {
        width: auto;
        font-size: $font-size-mini;
      }

      &.disable {
        background-color: rgba(119, 119, 119, 0.16);
        text-decoration: line-through;
      }
    }
  }

  &__Settings {
    margin: 45px 12px;
  }

  &__Actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 30px;

    &--restore,
    &--restore--all {
      width: 50%;
      flex: 1;

      @media #{$mobile-view} {
        width: 100%;
        flex: auto;
      }
    }

    &--restore {
      margin-right: 5px;

      @media #{$mobile-view} {
        margin-right: 0;
      }
    }

    &--restore--all {
      margin-left: 5px;

      @media #{$mobile-view} {
        margin-left: 0;
      }
    }

    button {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

/deep/ .Modal__container {
  height: auto;
  max-height: calc(100vh - 40px);
  width: calc(100% - 40px);
  max-width: 50%;
  overflow: initial;
  max-width: 600px;
}
/deep/ .Modal__containerBody {
  height: auto;
  max-height: calc(100% - 32px);
  overflow: auto;
}
/deep/ .Modal__containerHeader {
  min-height: 32px;
  height: auto;
}

//Slider
/deep/ .vue-slider {
  .vue-slider-process {
    background-color: $color-primary-base;
  }
  .vue-slider-dot {
    width: 25px !important;
    height: 25px !important;

    &.vue-slider-dot-disabled {
      width: 2px !important;
      height: 15px !important;
      z-index: 4;

      .vue-slider-dot-handle {
        border-radius: 0;
      }
    }
  }
}
/deep/ .custom-tooltip {
  border-radius: 0;
  background-color: $color-primary-base;
  border-color: $color-primary-base;
  color: $color-neutral-softest;
}
</style>
