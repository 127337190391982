const DriversMap = () => import( /* webpackChunkName: 'DriversMap' */ './DriversMap.vue')

const routes = [
  {
    name: 'map',
    meta:{
      module: 'module_map'
    },
    path: '/map',
    component: DriversMap
  }
]

export default routes
