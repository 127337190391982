export default {

  isFirstPage: state => state.page === 1,

  hasPagination: state => !(!state.next_page_url && state.page === 1),

  totalOfPages: state => {
    if (!state.lengthItems || !state.paginate) return 0
    return Math.ceil(state.lengthItems / state.paginate)
  },

  isLastPage: (state) => state.next_page_url === null,

  minParsedOptions: state => [{id: state.lengthItems, name: state.lengthItems }],

  halfPagesInPagination: state => Math.ceil(state.maxPagesInPagination / 2),

  pageFrom: (state, getters) => state.page - getters.halfPagesInPagination,

  pageTo: (state, getters) => state.page <= getters.totalOfPages ? state.page + getters.halfPagesInPagination : getters.totalOfPages,

  currentPage: state => state.page,
  nextPage: state => state.next_page_url,
  prevPage: state => state.prev_page_url,

}