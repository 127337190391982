import { address } from '@/api'

export default {
  async fetchStates({ commit }) {
    return await address.fetchStates((res) => {
      const states = res.map(i => {
        return { id: i.id, name: i.abbreviation }
      })
      commit('SET_STATES', states)
      return states
    }, (e) => e)
  },

  async fetchCities({ commit }, params) {
    return await address.fetchCities({params}, (res) => {
      commit('SET_CITIES', res)
      return res
    }, (e) => e)
  }
}
