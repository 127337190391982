const Transporters = () => import( /* webpackChunkName: 'Transporters' */ './Transporters.vue')
const TransportersForm = () => import( /* webpackChunkName: 'TransportersForm' */ './Form/TransportersForm.vue')
const TransporterTransfer = () => import( /* webpackChunkName: 'TransporterTransfer' */ './transfer/TransporterTransfer.vue')
const TransporterTransferHistory = () => import( /* webpackChunkName: 'TransporterTransferHistory' */ './transfer/TransporterTransferHistory.vue')

const routes = [
  {
    name: 'transporters',
    meta:{
      label: 'Novo',
      module: 'module_transporters'
    },
    path: '/transporters',
    component: Transporters
  },
  {
    name: 'transportersAdd',
    meta:{
      label: 'Novo',
      module: 'module_transporters'
    },
    path: '/transporters/add',
    component: TransportersForm
  },
  {
    name: 'transportersTransferOrders',
    meta:{
      label: 'Redespacho e Transferência',
      module: 'module_transporters'
    },
    path: '/transporters/transferOrders',
    component: TransporterTransfer
  },
  {
    name: 'transportersTransferHistory',
    meta:{
      label: 'Histórico de redespacho e transferência',
      module: 'module_transporters'
    },
    path: '/transporters/transferHistory',
    component: TransporterTransferHistory
  },
  {
    name: 'transportersDetail',
    meta:{
      label: 'Novo',
      module: 'module_transporters'
    },
    path: '/transporters/:id',
    component: TransportersForm
  },
]

export default routes