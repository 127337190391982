export default {
  setHasPrinter({commit}, payload) {
    commit('SET_HAS_PRINTER', payload)
  },
  setCheckPrinter({commit}, payload) {
    commit('SET_CHECK_PRINTER', payload)
  },
  setPrinterConnected({commit}, payload) {
    commit('SET__PRINTER_CONNECTED', payload)
  },
}