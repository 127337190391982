import { sellers } from '@/api'

export default {
  async fetchSellers({ commit }) {
    return await sellers.fetchSellers((res) => commit('SET_SELLERS', res.data), (e) => e)
  },

  async fetchSellersTable({ commit }, params = {paginate:20, page:1}) {
    return await sellers.fetchSellersTable(params, (res) =>
      commit('SET_SELLERS_TABLE', res), (e) => e, () => {})
  },

  async fetchSellersVipsImportTable({ commit }, params = {paginate:20, page:1}) {
    return await sellers.fetchSellersVipsImportTable(params, (res) =>
      commit('SET_SELLERS_VIPS_IMPORT_TABLE', res), (e) => e, () => {})
  },

  async fetchSellersVipsImportErrors({commit}, data){
    this.dispatch('loading/setLoading', true)
    return await sellers.fetchSellersVipsImportErrors(data.id, (res) => 
      commit('SET_SELLERS_VIPS_IMPORT_ERRORS', {
        file_name: data.file_name,
        headers: {
          'id': { 'id': 'id', 'param': 'id', 'label': 'ID', 'method': false, 'isVisible': false },
          'seller_code': { 'id': 'seller_code', 'label': 'Código do Seller', 'method': false, 'isVisible': true },
          'field': { 'id': 'field', 'label': 'Campo', 'method': false, 'isVisible': true },
          'message': { 'id': 'message', 'label': 'Erro', 'method': false, 'isVisible': true },
        },
        content: res
      }), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async importSellersVips(ctx, data){
    this.dispatch('loading/setLoading', true)
    const result = await sellers.importSellersVips(data, (res) => res, (e) => e, () => 
      this.dispatch('loading/setLoading', false))
    return result
  },
} 