import request from '@/api/plugins/request'
import download from '@/api/plugins/download'
import objToQuery from '@/api/plugins/query'
// import { httpRequestMock, RoutesMock } from '@/api/mocks/mocks'

export default {
  async fetchRoutes({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `routes?is_table=routes&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchRouteHistory({ obj, paginate, page }, success, error, done) {
    return await request({
      method: 'get',
      url: `route-history/${obj.route_id}?is_table=route_logs&paginate=${paginate}&page=${page}&sort=desc`
    }, success, error, done)
  },

  async fetchRouteErrors({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `route-error/${id}`
    }, success, error, done)
  },

  async fetchRoutesStores({id}, success, error, done) {
    return await request({
      method: 'get',
      url: `orders?is_table=route_orders&route_id=${id}&orderBy=sequence&paginate=999`
    }, success, error, done)
  },

  async fetchStoresByRoute({id}, success, error, done) {
    return await request({
      method: 'get',
      url: `routes/${id}/stores`
    }, success, error, done)
  },

  async routeAddOrder({routeId, orderNumber, storeId}, success, error, done){
    return await request({
      method: 'post',
      url: `routes-orders/${routeId}?order_number=${orderNumber}&store_id=${storeId}`
    }, success, error, done)
  },

  async bindDriver({routeId, driverId}, success, error, done){
    return await request({
      method: 'post',
      url: `routes/${routeId}?driver_id=${driverId}`
    }, success, error, done)
  },

  async routeDeleteOrder({routeId, orderId}, success, error, done){
    return await request({
      method: 'delete',
      url: `routes-orders/${routeId}/${orderId}`
    }, success, error, done)
  },

  async deleteRoute({ id }, success, error) {
    return await request({
      method: 'delete',
      url: `routes/${id}`
    }, success, error)
  },

  async sendRomaneio(id, success, error, done) {
    const body = {id: id}
    return await request({
      method: 'post',
      url: 'sendRomaneio',
      body
    }, success, error, done)
  },

  async sendNotification(id, success, error, done) {
    const body = {
      id: id
    }
    return await request({
      method: 'post',
      url: 'driver-notification',
      body
    }, success, error, done)
  },

  async fetchOrdersWithoutRoutes({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `orders?is_table=orders_without_route&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchRoutesNameByStores({type, id}, success, error, done) {
    return await request({
      method: 'get',
      url: `route-title-by-id/${type}/${id}`
    }, success, error, done)
  },

  async fetchOrdersWithoutRoutesTransfer({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `orders?is_table=orders_without_route_transfer&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async createRoute(body, success, error, done) {
    return await request({
      method: 'post',
      // url: 'routes-job',
      url: 'routes',
      body
    }, success, error, done)
  },

  async printRoute(id, success, error, done) {
    return await download({
      method: 'get',
      url: `orders-export-pdf/romaneio?route_id=${id}`
    }, success, error, done)
  },

  async createTransitPoint(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'transit_points',
      body
    }, success, error, done)
  },

  async fetchTransitPoint({id}, success, error, done){
    return await request({
      method: 'get',
      url: `transit_points/${id}?with[]=stores&with[]=address&with[]=routeInfo`,
    }, success, error, done)
  },

  async updateTransitPoint({ id, body }, success, error, done) {
    return await request({
      method: 'post',
      url: `transit_points/${id}`,
      body
    }, success, error, done)
  },

  async fetchTransitPoints({ hubs, stores, paginate, page }, success, error, done) {
    const queryHubs = objToQuery.parseAll(hubs, 'name')
    const queryStores = objToQuery.parseAll(stores, 'id')
    return await request({
      method: 'get',
      url: `transit_points?is_table=transit_points&paginate=${paginate}&page=${page}&${queryHubs}&${queryStores}&sort=desc`
    }, success, error, done)
  },

  async deleteTransitPoint({ id }, success, error) {
    return await request({
      method: 'delete',
      url: `transit_points/${id}`
    }, success, error)
  },

  async fetchTransitPointImport(success, error, done){
    return await request({
      method: 'get',
      url: 'stores_transit_point?parent_id[signal]=!=&paginate=9999&parent_id[value]=null&with[]=stores',
    }, success, error, done)
  },

  async getPermissionLinkDriver({ id },success, error, done){
    return await request({
      method: 'get',
      url: `transporters_transfer?transporter_id=${id}`,
    }, success, error, done)
  },

  async routEasySync(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'routeasy/sync-orders',
      body
    }, success, error, done)
  },

  async fetchOrderRoute(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'orders/sorting',
      body
    }, success, error, done)
  },

  async fetchSortingHistory({ is_beeped, route_id }, success, error, done) {
    route_id = route_id ? `&route_id=${route_id}` : ''
    return await request({
      method: 'get',
      url: `orders/sorting?is_beeped=${is_beeped}${route_id}&hideErrors`
    }, success, error, done)
  },

  async fetchHubRoutes(success, error, done) {
    return await request({
      method: 'get',
      url: '/orders/sorting/routes'
    }, success, error, done)
  }
}