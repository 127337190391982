<template>
  <BaseButton
    :label="properties.label"
    :color="color"
    :text-color="textColor"
    :icon="properties.icon"
    :filled="filled"
    :disabled="disabled || properties.disabled"
    :track="track"
    @click="exportFile"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { BaseButton } from '@/components/atoms'
import { asyncExport } from '@/api'

export default {
  name: 'AsyncExportButton',
  components: { BaseButton },
  props: {
    name: {
      required: true,
      type: String,
    },
    nameReport: {
      required: true,
      type: String,
    },
    params: {
      type: Object,
      default: () => {}
    },
    label: {
      required: true,
      type: String,
    },
    labelLoading: {
      required: true,
      type: String,
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    textColor: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: null
    },
    track: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    properties() {
      return {
        label: this.isLoading ? this.labelLoading : this.label,
        icon: this.isLoading ? 'loading' : this.icon,
        disabled: this.isLoading,
        url: this.url || `reports-${this.name}`
      }
    }
  },
  
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      setError: 'error/setError'
    }),

    async exportFile() {
      this.setLoading(true)
      let body = { nameReport: this.nameReport }
      
      await asyncExport.exportFile(this.properties.url, this.params ?  
        {...body, ...this.params} : body, (res) => {
        this.getFile(res.id)
      }, () => this.isLoading = false, () => this.setLoading(false))
    },

    async getFile(id) {
      this.isLoading = true
      await asyncExport.getFile({url: this.properties.url, id}, (res) => {
        if (res) {
          if (res?.result?.status === 'Erro') {
            this.setError('Ops... Houve um problema com a exportação. Tente novamente.')
            this.isLoading = false
          } else {
            setTimeout(() => {
              this.getFile(id)
            }, 10000)
          }
        } else {
          this.isLoading = false
        }
      }, () => {
        this.isLoading = false
      })
    },
  }
}
</script>
