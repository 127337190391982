import state from './state.address'
import getters from './getters.address'
import mutations from './mutations.address'
import actions from './actions.address'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}