import state from './state.financial'
import getters from './getters.financial'
import mutations from './mutations.financial'
import actions from './actions.financial'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}