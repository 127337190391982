import state from './state.transporters'
import getters from './getters.transporters'
import mutations from './mutations.transporters'
import actions from './actions.transporters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}