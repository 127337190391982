export default {
  SET_TRANSPORTERS(state, transporters) {
    state.transporters = transporters
  },

  SET_TRANSPORTERS_TRANSFER(state, transporters) {
    state.transportersTransfer = transporters
  },

  SET_STORES_TRANSPORTERS(state, storesTransporter) {
    state.storesTransporter = storesTransporter
  },

  SET_TRANSPORTERS_TABLE(state, transportersTable) {
    state.transportersTable = transportersTable
  },

  SET_TRANSPORTERS_DRIVERS(state, transportersDrivers) {
    state.transportersDrivers = transportersDrivers
  },

  UNSHIFT_TRANSPORTERS_DRIVERS(state, storeTransporter) {
    state.transportersDrivers.data.content = [ 
      ...storeTransporter,
      ...state.transportersDrivers.data.content
    ]
  },

  REMOVE_TRANSPORTERS_DRIVERS(state, storeTransporterId) {
    state.transportersDrivers.data.content = state.transportersDrivers.data.content.filter((i) => i.id !== storeTransporterId)
  },

  SET_TRANSPORTER_TRANSFER_HISTORY_TABLE(state, transferHistoryTable) {
    state.transferHistoryTable = transferHistoryTable
  },

  SET_MODAL_TRANSFER_HISTORY(state, modalTransferHistory) {
    state.modalTransferHistory = modalTransferHistory
  },
}
