export default {
  SET_ATTACHMENTS(state, data) {
    state.attachments = data
  },

  CLEAR_ATTACHMENTS(state) {
    state.attachments = []
  },

  REMOVE_ATTACHMENTS(state, data) {
    const index = state.attachments.findIndex(el => el.original_name === data.original_name)
    state.attachments.splice(index, 1)
  }
}
  