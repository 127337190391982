/* eslint-disable */
import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'
import download from '@/api/plugins/download'
import { httpRequestMock } from '@/api/mocks/mocks'
import {
  fetchMdfesMock,
  fetchMdfeMock,
} from './mocks'

export default {
  //Lists
  async fetchCtesRoutes({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj)
    return await request({
      method: 'get',
      url: `routes?is_table=routes_transfer&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchCtes({ routeId, paginate, page }, success, error, done) {
    return await request({
      method: 'get',
      url: `cte?is_table=cte&paginate=${paginate}&page=${page}&sort=desc&route_id=${routeId}`
    }, success, error, done)
  },

  //Forms
  async fetchCte({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `routes/${id}`
    }, success, error, done)
  },

  async fetchCteDetails({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `cte/${id}/payload`
    }, success, error, done)
  },

  async putCteDetailsEdit({ id, body }, success, error, done) {
    return await request({
      method: 'put',
      url: `cte/${id}/payload`,
      body,
    }, success, error, done)
  },

  //Actions
  async deleteCtes({ id }, success, error, done) {
    return await request({
      method: 'delete',
      url: `cte/${id}/all`
    }, success, error, done)
  },

  async deleteCte({ id }, success, error, done) {
    return await request({
      method: 'delete',
      url: `cte/${id}`
    }, success, error, done)
  },

  async erroCteFocos({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `cte-focos-error/${id}`
    }, success, error, done)
  },

  //Downlodas 
  async downloadDacteZip({ id }, success, error, done) {
    return await download({
      method: 'get',
      url: `routes/${id}/dacte`
    }, success, error, done)
  },

  async downloadDacte({ id }, success, error, done) {
    return await download({
      method: 'get',
      url: `cte/${id}/dacte`
    }, success, error, done)
  },

  async downloadDacteXmlZip({ id }, success, error, done) {
    return await download({
      method: 'get',
      url: `routes/${id}/xml`
    }, success, error, done)
  },

  async downloadDacteXml({ id }, success, error, done) {
    return await download({
      method: 'get',
      url: `cte/${id}/xml`
    }, success, error, done)
  },

  //Generate
  async generateCtes(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'cte/send',
      body,
    }, success, error, done)
  },

  async regenerateCtes({ route_id }, success, error, done) {
    return await request({
      method: 'post',
      url: `cte/${route_id}/payload/regenerate`,
    }, success, error, done)
  },

  async generateCte(body, success, error, done) {
    return await request({
      method: 'post',
      url: `cte/send/${body}`,
    }, success, error, done)
  },

  // ##########
  // MDFe
  // ##########

  //Lists
  async fetchMdfes({ obj, paginate, page }, success, error, done) {
    return await httpRequestMock({status: true, response: fetchMdfesMock}, success, error, done)
  },

  //Forms
  async fetchMdfe({ id }, success, error, done) {
    return await httpRequestMock({status: true, response: fetchMdfeMock}, success, error, done)
  },

  async putMdfeDetails({ id, body }, success, error, done) {
    return await httpRequestMock({status: true}, success, error, done)
  },

  //Downloads
  async downloadMdfe({ id }, success, error, done) {
    return await httpRequestMock({status: true}, success, error, done)
  },

  //Generate
  async generateMdfe({ id }, success, error, done) {
    return await httpRequestMock({status: true}, success, error, done)
  },
}
