<template>
  <div v-tooltip="tooltipProps" class="TextCopy" @click="copy">
    {{ value }} <Icon name="copy" class="TextCopy__Icon" />
  </div>
</template>
<script>
export default {
  name: 'TextCopy',
  props: {
    value: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      copiedLabel: false,
      timeout: null
    }
  },
  computed: {
    tooltipProps() {
      return {
        content: this.copiedLabel ? 'Texto copiado!' : 'Copiar',
        delay: {
          hide: this.copiedLabel ? 1000 : 0
        }
      }
    }
  },
  methods: {
    copy() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(this.value)
      this.copiedLabel = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.copiedLabel = false
      }, 1500)
    }
  },
}
</script>
<style lang="scss" scoped>
.TextCopy {
  background: rgba(208, 208, 208, 0.42);
  display: inline-block;
  padding: 2px 4px;
  font-size: inherit;
  cursor: copy;

  &__Icon {
    font-size: inherit;
    display: inline-block;
    vertical-align: text-bottom;
    top: -2px;
    position: relative;
    width: 16px;
  }
}
</style>
