export default {
  SET_HISTORY(state, history) {
    state.ordersHistory = history
  },
  SET_ORDERS_BY_DOCUMENT(state, orders) {
    state.ordersByDocument = orders
  },
  SET_TRACKING_COMMENTS(state, data) {
    state.trackingComments = data
  }
}
