<template>
  <section class="TheRouteStores">
    <Modal @close="closeRouteStores">
      <template #header>
        <div class="TheRouteStores__ModalTitle">
          <div class="TheRouteStores__title TheRouteStores__titleName">
            <span class="TheRouteStores__title--name">Título da Rota: </span>
            <span class="TheRouteStores__title--info">{{ routeInfo.name }} </span>
          </div>
          <div v-if="routeInfo['driver.name']" class="TheRouteStores__title TheRouteStores__driverName">
            <span class="TheRouteStores__title--separator"> | </span>
            <span class="TheRouteStores__title--name">Motorista Vinculado: </span> 
            <span class="TheRouteStores__title--info">{{ $options.filters.parsedDriver(routeInfo['driver.name']) }}</span> 
            <Icon v-if="showActionsButtons" name="close" color="red" class="TheRouteStores__title--icon" @click="removeDriver" />
          </div>
        </div>
      </template>
      <template #body>
        <div class="TheRouteStores__controller">
          <!-- Pedido -->
          <div v-if="showActionsButtons" class="TheRouteStores_button TheRouteStores__addOrder">
            <BaseButton v-if="!addStoreStatus" class="TheRouteStores__addOrderButton" label="Adicionar Pedido" icon="add" theme="icon-right" color="primary-base" @click="addStore" />
            <div v-else class="TheRouteStores__addOrderField">
              <BaseTreeSelect
                v-if="!routeInfo['store_id']"
                ref="storeInput"
                v-model="storeSelected"
                class="TheRouteStores__addOrderInput"
                name="Lojista"
                label="Lojista"
                placeholder="Selecione"
                :select-options="routeStores"
              />
              <InputGroup v-model="storeValue" label="Nº pedido" class="TheRouteStores__addOrderInput" :read-only="addStoreDisabled" />
              <BaseButton class="TheRouteStores__addOrderConfirm" label="Confirmar" icon="check" theme="icon-right" color="dark" color-text="dark" icon-color="green" :disabled="addStoreDisabled" @click="confirmOrder" />
              <BaseButton class="TheRouteStores__addOrderCancel" label="Cancelar" icon="close" theme="icon-right" color="dark" text-color="dark" icon-color="red" @click="cancelOrder" />
            </div>
          </div>

          <!-- Motoristas -->
          <div v-if="checkPermissionLinkDriver && !routeInfo['driver.name'] || showActionsButtons && !routeInfo['driver.name']" class="TheRouteStores_button TheRouteStores__addDriver">
            <BaseButton v-if="!addDriverStatus" label="Vincular Motorista" class="TheRouteStores__addDriverButton" icon="add-driver" theme="icon-right" color="primary-base" @click="addDriver" />
            <div v-else class="TheRouteStores__addDriverField">
              <!-- Driver -->
              <BaseTreeSelect
                ref="driverInput"
                v-model="driverValue"
                class="TheRouteStores__addOrderInput"
                name="Motorista"
                label="Motorista"
                placeholder="Pesquisar"
                has-alert
                :suggestions="routeInfo['store_id'] ? 'searchDriversWithStores' : 'searchDriversWithTransitPoint'"
                :suggestions-params="routeInfo['store_id'] ? routeInfo['store_id'] : routeInfo['transit_id']"
                invalid="Motorista inválido"
              />
              <BaseButton class="TheRouteStores__addDriverConfirm" label="Confirmar" icon="check" theme="icon-right" color="dark" text-color="dark" icon-color="green" @click="confirmDrive" />
              <BaseButton class="TheRouteStores__addDriverCancel" label="Cancelar" icon="close" theme="icon-right" color="dark" text-color="dark" icon-color="red" @click="cancelDrive" />
            </div>
          </div>
        </div>

        <div class="TheRouteStores__list">
          <section class="TheRouteStores__table">
            <DataTable
              :header="list.data.headers"
              :table="list.data.content"
              @remove="removeOrder"
            />
          </section>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import { BaseButton, BaseTreeSelect } from '@/components/atoms'
import { InputGroup, Modal, DataTable } from '@/components/molecules'
import { mapActions } from 'vuex'
import { routeStatus } from '@/views/routes/CteManagement/utils/utils.js'
import { routes } from '@/api'

export default {
  name: 'TheRouteStores',
  components:{ DataTable, BaseButton, BaseTreeSelect, InputGroup, Modal},
  props: {
    id:{
      type: Number,
      default: null
    },
    transporterId:{
      type: [Number, String],
      default: null
    },
    store:{
      type: String,
      default: null
    },
    list:{
      type: Object,
      default: () => {}
    },
    routeInfo:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      addStoreStatus: false,
      addDriverStatus: false,
      storeValue: '',
      driverValue: null,
      hasPermissionLinkDriver: false,
      routeStores: [],
      storeSelected: null
    }
  },
  computed: {
    checkPermissionLinkDriver() {
      return (this.hasPermissionLinkDriver || this.transporterId === null) && !this.$options.filters.parsedDriver(this.routeInfo['driver.name'])
    },

    showActionsButtons() {
      return this.routeInfo.status_route !== routeStatus.CONCLUIDO
    },

    addStoreDisabled() {
      return !this.routeInfo['store_id'] ? !this.storeSelected : false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      routeAddOrder: 'routes/routeAddOrder',
      routeDeleteOrder: 'routes/routeDeleteOrder',
      bindDriver: 'routes/bindDriver',
      fetchRoutesStores: 'routes/fetchRoutesStores',
      getPermissionLinkDriver: 'routes/getPermissionLinkDriver',
      setLoading: 'loading/setLoading'
    }),

    async init() {
      if (this.transporterId !== null) {
        this.setGetPermissionLinkDriver()
      }
      if (!this.routeInfo.store_id) {
        this.routeStores = []
        this.storeSelected = null
        await routes.fetchStoresByRoute(
          {id: this.routeInfo.id},
          (res) => this.routeStores = res,
          (e) => e,
          () => this.setLoading(false)
        )
      }
    },

    async setGetPermissionLinkDriver() {
      const data = await this.getPermissionLinkDriver(this.transporterId)
      this.hasPermissionLinkDriver = data.find(el => el.hasDrive === true) ? true : false
    },

    addStore(){
      this.addStoreStatus = true
    },

    async confirmOrder(){
      if (!this.storeValue) return
      this.setLoading(true)
      await this.routeAddOrder({routeId: this.id, orderNumber: this.storeValue, storeId: this.routeInfo.store_id || this.storeSelected.id })
      await this.fetchRoutesStores(this.id)
      this.addStoreStatus = false
      this.storeValue = ''
      this.setLoading(false)
    },

    cancelOrder(){
      this.addStoreStatus = false
      this.storeValue = ''
    },

    addDriver(){
      this.addDriverStatus = true
    },

    async confirmDrive(){
      if (!this.driverValue || typeof(this.driverValue) === 'object' && this.driverValue.length === 0) return
      let res = await this.bindDriver({routeId: this.id, driverId: this.driverValue.id, driverName: this.driverValue.name})
      if (res) {
        this.$refs?.driverInput?.setError(true)
      } else {
        this.addDriverStatus = false
        this.driverValue = null
      }
    },

    cancelDrive(){
      this.addDriverStatus = false
      this.driverValue = null
    },

    closeRouteStores(){
      this.$emit('close')
    },

    async removeOrder(data){
      await this.routeDeleteOrder({routeId: this.id, orderId: data.id})
      if (!this.list?.data?.content?.length) this.$emit('close')
    },

    removeDriver(){
      this.bindDriver({routeId: this.id, driverId:''})
    }
  }
}
</script>

<style lang="scss" scoped>
  .TheRouteStores{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: $z-modal-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container{
      background-color: $color-neutral-softest;
          width: 95%;
          height: auto;
          max-height: 95%;
          min-height: 50%;
    }
    &__title{
      &--separator{
        color: $color-neutral-softest;
      }
      &--name{
        color: $color-neutral-softest;
        font-weight: $font-weight-bold;
        font-size: $font-size-1xsmall;
        margin-left: 10px;
      }
      &--info{
        font-size: $font-size-3xsmall;
      }
      &--icon{
        width: 13px;
        position: relative;
        top: 1px;
        margin-left: 10px;
        cursor: pointer;  
      }
      &:hover{
        color: $color-alert-soft;
      }
    }

    &__titleName{
      margin-right: 10px;
    }
   
    &__list{
      overflow: auto;
    }
    &__driverContent{
      display: flex;
      margin: 1%;
      align-items: center;
    }
    &__iconDriver{
      width: 15px;
      margin-right: 10px;
    }
    &__driverLabel{
      font-size: $font-size-mini;
      span{
        font-weight: $font-weight-bold;
      }
    }
    &__controller{
      display: flex;
      padding: 20px 10px;
      bottom: 0;
      background-color: $color-neutral-softest;
      z-index: $z-overlay;
      text-align: center;

      @media #{$mobile-view} {
        width: 100%;
        flex-direction: column;
      }
    }
   
    &__addOrder, &__addDriver {
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      margin: 0 auto;
      padding: 0 10px;
      min-height: 58px;
      @media #{$mobile-view} {
        padding: 0;
        width: 100%;
      }
      .InputGroup {
        text-align: left;
      }
    }
    &__addDriver {
      max-width: 50%;
      @media #{$mobile-view} {
        max-width: 100%;
      }
    }
    &__addOrderButton, &__addDriverButton {
      width: 342px;
    }
    &__addOrderField, &__addDriverField{
      text-align: left;
      display: inline-flex;
      justify-content: space-around;
      align-items: flex-end;
      gap: 10px;
      @media #{$mobile-view} {
        flex-direction: column;
      }
    }
    &__addOrderTitle, &__addDriverTitle{
      color: $color-neutral-stronger;
      font-weight: $font-weight-bold;
      font-size: $font-size-3xsmall;
    }
    &__addOrderInput, &__addDriverInput{
      width: 40%;
        @media #{$mobile-view} {
          width: 100%;
        }
      z-index: 10;
    }
    &__addOrderConfirm, &__addOrderCancel, &__addDriverConfirm, &__addDriverCancel{
      width: 147px;
    }
    &__ModalTitle{
      height: 100%;
      display: flex;
      align-items: center;
    }

  @media #{$mobile-view} {
    &_button{
    margin: 0;
    margin-bottom: 20px;
    &:last-child{
      margin: 0;
    }
    .BaseButton{
      width: 100%;
    }
    }
    .TheRouteStores{
      &__addOrderConfirm, &__addDriverConfirm{
      margin-bottom: 20px;
    }
    &__addOrderField, &__addDriverField{
      align-items: flex-start;
    }
    }
    .BaseMultiselect{
      margin-bottom: 20px;
    }
    &__title--name{
    margin-left: 0px;
  }
  }

  }
// 
</style>

<style lang="scss">
.TheRouteStores{
  .Modal__containerBody{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media #{$mobile-view} {
    .Modal__containerHeader{
      height: auto;
    }
    &__ModalTitle{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    padding: 10px 0px;
    padding-left: 20px;
    }
    &__title--separator{
      display: none;
    }
  
  }
}
</style>
