<template>
  <div class="BaseThumbnail" :class="BaseThumbnailDisabled">
    <label :for="parseName">
      <div class="BaseThumbnail__name">{{ label }}</div>
      <div class="BaseThumbnail__imageContainer">
        <Icon v-if="!image && !disabled" name="upload" color="dark" class="BaseThumbnail__icon" />
        <input v-if="!disabled" :id="parseName" type="file" :name="parseName" class="BaseThumbnail__input" 
               :accept="BaseThumbnailParse" 
               @change="onChange"
        >
        <img v-if="image" :src="previewImage" :alt="parseName" class="BaseThumbnail__preview" @error="setBrokenImage">
        <div v-if="image" class="BaseThumbnail__overlay">
          <slot />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import defaultThumbnail from '@/assets/images/defaultThumbnail.png'

export default {
  name: 'BaseThumbnail',
  props: {
    label:{
      type: String,
      default: ''
    },
    name:{
      type: String,
      required: true
    },
    image:{
      type: [String, File],
      required: true
    },
    accept:{
      type: Array,
      default: () => ['png', 'jpeg']
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      previewImage: '',
      brokenImage: false,
      defaultThumbnail
    }
  },
  computed: {
    BaseThumbnailParse(){
      let parsed = ''
      this.accept.forEach(item => {
        parsed += 'image/' + item + ', '
      })

      return parsed.replace(/[1,].$/g, '')
    },

    BaseThumbnailDisabled() {
      return this.disabled ? 'BaseThumbnail__Disabled' : ''
    },

    parseName(){
      return this.name.replace(/\s/g, '').toLowerCase()
    }
  },

  watch: {
    image: {
      immediate: true,
      handler(val) {
        this.setPreviewImage(val)
      },
    }
  },

  methods: {
    onChange(input){
      if (input.target.files) {
        this.$emit('upload', input.target.files[0])
      }
    },

    setBrokenImage() {
      this.brokenImage = true
      this.previewImage = this.defaultThumbnail
    },

    async readFile(image) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(image)
      })
    },

    async setPreviewImage(val) {
      this.brokenImage = false
      this.previewImage = this.image instanceof File ? await this.readFile(this.image) : val
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseThumbnail {
    position: relative;
    &__Disabled {
      .BaseThumbnail__imageContainer {
        background: $color-neutral-soft;
        border: 2px solid $color-neutral-strong;
        opacity: 0.75;
        cursor: not-allowed;
      }
    }
    &__name{
      color: $color-neutral-stronger;
      font-weight: $font-weight-bold;
      font-size: $font-size-3xsmall;
      margin-bottom: 10px;
    }
    &__imageContainer{
      width: 100%;
      height: 120px;
      border: 2px solid $color-neutral-stronger;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }
    &__icon{
      width: 48px;
      margin: 0 10px;
      transition: opacity .3s;
      opacity: 1;
      &:hover {
        opacity: .5;
      }
    }
    &__input{
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
    &__preview{
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &__overlay {
      background-color: rgba(255, 255, 255, .75);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: $z-overlay;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity .3s;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>