<template>
  <div class="BaseInfoCard" :class="`BaseInfoCard--${theme}`">
    <div v-if="title" class="BaseInfoCard__Title" v-text="title" />
    <div v-if="!items.length" class="BaseInfoCard__NoItems" v-text="noItemsMessage" />
    <div v-else class="BaseInfoCard__Content">
      <table class="BaseInfoCard__Items">
        <tr v-for="(item, i) in items" :key="i" class="BaseInfoCard__Item">
          <td class="BaseInfoCard__ItemName">
            {{ item.name }}
          </td>
          <td
            class="BaseInfoCard__ItemValue"
            :class="`BaseInfoCard__ItemValue--${item.theme}`"
          >
            {{ item.value }}
          </td>
        </tr>
      </table>
      <div v-if="icon" class="BaseInfoCard__Icon">
        <div class="BaseInfoCard__IconWrapper">
          <Icon :name="icon" />
        </div>
        <div v-if="iconMessage" class="BaseInfoCard__IconMessage">
          {{ iconMessage }}
        </div>
      </div>
    </div>
    <div v-if="pillMessage" class="BaseInfoCard__PillMessage" v-text="pillMessage" />
  </div>
</template>

<script>
export default {
  name: 'BaseInfoCard',
  props: {
    theme: {
      type: String,
      default: 'neutral',
      validator: (val) => ['neutral', 'primary', 'success', 'alert', 'notice'].includes(val),
    },
    title: {
      type: String,
      default: ''
    },
    noItemsMessage: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    pillMessage: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.BaseInfoCard {
  display: flex;
  box-shadow: $shadow-high;
  padding: $spacing-inset-medium;
  border-radius: $border-radius-small;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-neutral-stronger;
  gap: $spacing-inset-small;
  background-color: #F3F3FC;

  &--primary {
    background-color: $color-primary-softer;
    .BaseInfoCard {
      &__PillMessage,
      &__IconWrapper {
        background-color: $color-primary-soft;
      }
    }
  }

  &--success {
    background-color: $color-success-softer;
    .BaseInfoCard {
      &__PillMessage,
      &__IconWrapper {
        background-color: $color-success-soft;
      }
    }
  }

  &--alert {
    background-color: $color-alert-softer;
    .BaseInfoCard {
      &__PillMessage,
      &__IconWrapper {
        background-color: $color-alert-soft;
      }
    }
  }

  &--notice {
    background-color: $color-notice-softer;
    .BaseInfoCard {
      &__PillMessage,
      &__IconWrapper {
        background-color: $color-notice-base;
      }
    }
  }

  &__Title {
    font-weight: bold;
  }

  &__Content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Items {
    margin: 0;
    padding: 0;
    border: 0;
  }

  &__ItemName, &__ItemValue {
    padding: $spacing-inset-2xsmall	0;
  }

  &__ItemValue {
    font-weight: bold;
    padding-left: $spacing-inset-1xsmall;

    &--success {
      color: $color-success-base;
    }

    &--alert {
      color: $color-alert-base;
    }

    &--notice {
      color: $color-notice-base;
    }
  }

  &__Icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  &__IconWrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-neutral-base;

    img {
      width: 20px;
      filter: $filter-white;
    }
  }

  &__IconMessage {
    font-size: $font-size-3xsmall;
  }

  &__PillMessage {
    border-radius: $border-radius-pill;
    color: $color-white;
    padding: $spacing-inset-2xsmall $spacing-inset-small;
    font-size: $font-size-3xsmall;
    background-color: $color-neutral-base;
  }
  
}
</style>
