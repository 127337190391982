const Welcome = () => import( /* webpackChunkName: 'Welcome' */ './Welcome.vue')

const routes = [
  {
    name: 'welcome',
    path: '/',
    component: Welcome
  },
  
]

export default routes