const Users = () => import( /* webpackChunkName: 'Users' */ './Users.vue')
const UsersForm = () => import( /* webpackChunkName: 'UsersForm' */ './Form/UsersForm.vue')

const routes = [
  {
    name: 'users',
    meta:{
      label: 'Novo',
      module: 'module_users'
    },
    path: '/users',
    component: Users
  },
  {
    name: 'usersAdd',
    meta:{
      module: 'module_users'
    },
    path: '/users/add',
    component: UsersForm
  },
  {
    name: 'usersDetail',
    meta:{
      module: 'module_users'
    },
    path: '/users/:id',
    component: UsersForm
  }
]

export default routes