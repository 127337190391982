export default {
  SET_PACKAGES(state, packages){
    state.packages = packages
  },

  CLEAR_PACKAGES(state){
    state.packages = {
      data:{
        headers: {
          actions: {}
        },
        content: []
      },
      pagination:{
        last_page: 0,
        per_page: 0
      }
    }
  },


  SET_INCOMING_PACKAGES(state, incomingPackages) {
    let codesTemp = state.incomingPackages.data.content
    if (codesTemp.length > 0) {
      incomingPackages.data.content.push(...codesTemp)
    }
    state.incomingPackages = incomingPackages
  },

  SET_ORDERS_WITH_CONFIRMED_PACKAGES(state, ordersWithConfirmedPackages) {
    state.ordersWithConfirmedPackages = ordersWithConfirmedPackages
  },

  SET_PACKAGES_REPORTS_REQUESTED(state, packagesReportsRequested) {
    state.packagesReportsRequested = packagesReportsRequested
  },

  REMOVE_INCOMING_PACKAGES(state, codes) {
    let newIncomingPackagesCodes = []
    state.incomingPackages.data.content.map((packages) => {
      codes.map((code) => {
        if (code.id.toLowerCase() === packages.code.toLowerCase()) {
          newIncomingPackagesCodes.push(packages)
        }
      })
    })
    state.incomingPackages.data.content = newIncomingPackagesCodes
  },

  REMOVE_INCOMING_PACKAGES_WITH_STORES(state, stores) {
    state.incomingPackages.data.content = state.incomingPackages.data.content.filter(packages => {
      return stores.includes(packages.storeName)
    })
  },

  RESET_INCOMING_PACKAGES(state) {
    state.incomingPackages = {
      data:{
        headers: {
          actions: {}
        },
        content: []
      },
      pagination:{
        last_page: 0,
        per_page: 0
      }
    }
  },

  SET_PACKAGES_HISTORY(state, history) {
    state.packagesHistory = history
  },

  SET_PACKAGES_RECEIVED(state, packagesReceived) {
    state.packagesReceived = packagesReceived
  },

  SET_PACKAGES_RECEIVED_DETAIL(state, packagesReceivedDetail) {
    state.packagesReceivedDetail = packagesReceivedDetail
  },

  REMOVE_PACKAGE(state, removedPackage){
    state.packagesReceivedDetail.data.content = state.packagesReceivedDetail.data.content.filter((p) => p.id !== removedPackage.id)
  },

  REMOVE_PACKAGES(state, removedPackages){
    state.packagesReceivedDetail.data.content = state.packagesReceivedDetail.data.content
      .filter((p) => !removedPackages.some(removedId => removedId === p.id))
  },

  SET_MODAL_MISSING_DATA(state, packagesData){
    state.modalMissingPackages.data = packagesData
  },

  SET_MODAL_MISSING_DRIVER(state, driverName){
    state.modalMissingPackages.driver = driverName
  },

  SET_SHOW_MODAL_MISSING(state, show){
    state.showModalMissingPackages = show
  },

  CLEAR_MODAL_MISSING_DATA(state){
    state.showModalMissingPackages = false
    state.modalMissingPackages = {
      driver: '',
      data: {
        headers: {},
        content: [],
      }
    }
  },

  SET_CACHED_PACKAGES(state, cachedPackages) {
    state.cachedPackages = cachedPackages
  },

  ADD_CACHED_PACKAGES(state, cachedPackages) {
    const currentCachedPackages = state.cachedPackages.data.content
    const newCachedPackages = cachedPackages.packages.filter(p => {
      if (currentCachedPackages.filter(c => c.id === p.id).length === 0) return p
    })
    state.cachedPackages = {
      data: {
        headers: cachedPackages?.data?.headers || {},
        content: [
          ...currentCachedPackages,
          ...newCachedPackages
        ]
      }
    }
  },

  RESET_CACHED_PACKAGES(state) {
    state.cachedPackages = {
      data: {
        headers: {
          actions: {}
        },
        content: []
      }
    }
  },
}
