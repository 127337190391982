export default {
  setPage({commit}, payload) {
    commit('SET_PAGE', payload)
  },
  setPagination({commit}, payload) {
    commit('SET_LENGTH_ITEMS', payload.data?.content?.length)
    commit('SET_PAGINATION', payload.pagination)
  },
  setPaginate({commit}, payload) {
    commit('SET_PAGINATE', payload)
  },
  setLengthItems({commit}, payload) {
    commit('SET_LENGTH_ITEMS', payload)
  },
  clearPagination({commit}, payload) {
    commit('CLEAR_PAGE')
    commit('CLEAR_PAGINATE', payload)
  }
}