<template>
  <div class="Map" :style="mapStyle" />
</template>

<script>
/*global google*/ 
import Vue from 'vue'
import gmapsInit from './utils/gmaps'
import theme from './utils/theme'
import MarkerClusterer from '@google/markerclustererplus'
import BaseMapWindow from '@/components/atoms/BaseMapWindow/BaseMapWindow'

export default {
  name: 'BaseMap',
  props: {
    zoom: {
      type: Number,
      default: 18
    },
    hideDetailsButton: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    lat: {
      type: Number,
      default: -23.50567
    },
    lng: {
      type: Number,
      default: -46.63840
    },
    markers: {
      type: Array,
      default: () => []
    },
    route: {
      type: Object,
      default: () => {}
    },
    forceRoute: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'driver'
    }
  },
  data() {
    return {
      map: {},
      bounds: null,
      markersCluster: null,
      onScreenMarkers: [],
      onScreenRoutes: [],
      mapOverlay: null,
      directionsService: null,
      directionsDisplay: null,
      infoWindow: null,
      trucks: 0,
      mapOptions: {
        zoom: this.zoom,
        center: { lat: this.lat, lng: this.lng },
        styles: theme,
        disableDefaultUI: true
      },
      assets: {
        driver: require('@/assets/images/maps/pin-driver.svg'),
        truck: require('@/assets/images/maps/pin-truck.svg'),
        car: require('@/assets/images/maps/pin-car.svg'),
        motorcycle: require('@/assets/images/maps/pin-motorcycle.svg'),
        bau: require('@/assets/images/maps/pin-bau.svg'),
        seller: require('@/assets/images/maps/pin-seller.svg'),
        cluster: require('@/assets/images/maps/pin-cluster.svg'),
        pinStart: require('@/assets/images/maps/pin_saida_pax.png'),
        pinEnd: require('@/assets/images/maps/pin_destino_pax.png')
      }
    }
  },
  computed: {
    routeMarkers() {
      let waypointsPosition = [], origin = [], destination = []
      if (this.route && this.route.waypoints) {
        waypointsPosition = [this.route.waypoints].map(waypoint => ({ 
          position: waypoint.location,
          info: waypoint.info,
          driver: true,
          icon: this.assets.truck
        }))
        origin = {
          position: this.route.origin,
          icon: this.assets.pinStart
        }
        destination = {
          position: this.route.destination,
          icon: this.assets.pinEnd
        }
      }
      return { waypointsPosition, origin, destination }
    },

    mapStyle() {
      return this.markers.length < 1 ? 'pointer-events: none' : ''
    },

    markerWithIcon() {
      return this.markers.map(marker => {
        let icon = this.assets.driver
        if (marker.info.vehicle_type === 'carros') { icon = this.assets.car }
        if (marker.info.vehicle_type === 'caminhoes') { icon = this.assets.truck }
        if (marker.info.vehicle_type === 'motos') { icon = this.assets.motorcycle }
        if (marker.info.vehicle_type === 'bau') { icon = this.assets.bau }
        if (marker.info.vehicle_type === 'seller') { icon = this.assets.seller }
        return {
          ...marker,
          icon: {
            url: icon,
            scaledSize: new google.maps.Size(50, 50)
          }
        }
      })
    },

    markerList() {
      return [
        ...this.markerWithIcon, 
        ...this.routeMarkers.waypointsPosition,
        this.routeMarkers.origin,
        this.routeMarkers.destination
      ]
    }
  },
  watch: {
    'route': {
      handler: function() {
        this.moveMarker()
      },
      deep: true
    },

    markers: {
      handler: function() {
        this.resetMarkers()
        this.initMarkers()
      },
      deep: true
    },
  },

  mounted() {
    this.initMap()
    setTimeout(() => {
      if (this.route) {
        this.initRouteService()
      }
    }, 3000)
  },

  methods: {
    async initMap() {
      await gmapsInit()

      this.map = await new google.maps.Map(this.$el, this.mapOptions)
      await this.initMarkers()
      this.mapOverlay = new google.maps.OverlayView()
      if (this.markers.length > 0 && this.type === 'driver') {
        this.fitBounds()
      }
    },

    fitBounds() {
      if (this.markers.length > 0) {
        this.map.fitBounds(this.bounds, {top: 85})
      }
    },

    initMarkers() {
      this.bounds = new google.maps.LatLngBounds()
      this.markerList.map(marker => {
        if (marker.length !== 0) {
          let newMarker = new google.maps.Marker({
            position: new google.maps.LatLng(marker.position.lat,marker.position.lng),
            icon: marker.icon,
            optimized: false,
            map: this.map,
            draggable: this.draggable,
          })

          if (marker.radius) {
            newMarker.circle = new google.maps.Circle({
              map: this.map,
              radius: marker.radius,
              fillColor: '#0c29d055',
              strokeColor: '#0c29d066'
            })
            newMarker.circle.bindTo('center', newMarker, 'position')
          }

          this.onScreenMarkers.push(newMarker)
          if (marker.info) {
            this.setInfoWindows(newMarker, marker.info)
          }
        }
      })

      this.markersCluster = new MarkerClusterer(this.map, this.onScreenMarkers, {
        maxZoom: 15,
        styles: [MarkerClusterer.withDefaultStyle({
          width: 40,
          height: 50,
          url: this.assets.cluster,
          textColor: '#000',
          textSize: 15,
          anchorText: [-5, 0],
          anchorIcon: [40, 25],
          fontWeight: 'bold'
        })],
      })

      if (this.markers.length && this.trucks !== this.markers.length) {
        this.trucks = this.markers.length
      }

      if (this.markers.length === 1 && this.type === 'driver') {
        this.fitBounds()
      }

      if (this.markers.length === 1 && this.type === 'seller') {
        this.map.setCenter(new google.maps.LatLng(this.markers[0].position.lat, this.markers[0].position.lng))
      }

    },

    drawOverlay(id) {
      this.mapOverlay.draw = function () {
        const markerLayer = this.getPanes().markerLayer
        markerLayer.id = id
      }
      this.mapOverlay.setMap(this.map)
    },

    setInfoWindows(marker, content) {
      if (marker.position) {
        var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng())
      }
      this.bounds.extend(loc)

      if (this.type === 'driver') {
        const InfoWindow = Vue.extend(BaseMapWindow)
        const instance = new InfoWindow({
          propsData: {
            driverId: content.driverId,
            driver: content.driver,
            plate: content.plate,
            phone: content.phone,
            hideDetailsButton: this.hideDetailsButton
          }
        })
        instance.$mount()

        const infoWindow = new google.maps.InfoWindow({
          content: instance.$el,
        })

        marker.addListener('click', () => {
          if (this.type === 'driver') {
            infoWindow.open(this.map, marker)
          }
        })
      }

      marker.addListener('dragend', () => {
        let position = {
          lat: marker.position.lat(),
          lng: marker.position.lng()
        }
        this.map.setCenter(new google.maps.LatLng(marker.position.lat(), marker.position.lng()))
        this.$emit('dragend', position)
      })
    },

    resetDriversRoutes() {
      this.directionsDisplay.setMap(null)
      this.onScreenRoutes = []
    },

    initRouteService() {
      if (!this.route.origin) {
        return
      }
      if (this.forceRoute && this.directionsDisplay) {
        this.resetDriversRoutes()
      }

      this.directionsService = new google.maps.DirectionsService()
      this.directionsDisplay = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#393939'
        }
      })

      this.directionsDisplay.setMap(this.map)
      this.calcRoute()
    },

    calcRoute() {
      const request = {
        origin: this.route.origin,
        destination: this.route.destination,
        waypoints: [{ location: this.route.waypoints.location }],
        travelMode: google.maps.TravelMode.DRIVING
      }

      this.directionsService.route(request, (response, status) => {
        if (status === 'OK') {
          const newRoute = this.directionsDisplay.setDirections(response)
          this.onScreenRoutes.push(newRoute)
        }
      })
    },
    
    moveMarker() {
      for (const marker of this.onScreenMarkers) {
        if (marker.driver) {
          this.drawOverlay('driverMarker')
          marker.setPosition(this.route.waypoints.location)
          setTimeout(() => {
            this.drawOverlay('')
          }, 500)
        }
      }
    },

    resetMarkers(){
      for (var i = 0; i < this.onScreenMarkers.length; i++) {
        if (this.onScreenMarkers[i].circle) {
          this.onScreenMarkers[i].circle.setMap(null)
        }
        this.onScreenMarkers[i].setMap(null)
      }
      this.onScreenMarkers = []
      this.markersCluster.clearMarkers()
    }
    
  }
}
</script>

<style lang="scss" scoped>
.Map {
  width: 100%;
  height: 100%;
  background: gray;
}
</style>

<style>
#driverMarker div {
  transition: all .2s;
}
</style>
