<template>
  <div class="BaseRadioGroup">
    <div :class="{'BaseRadioGroup__flex': flex}">
      <div v-if="label" class="BaseRadioGroup__title">
        {{ label }}
      </div>
      <div class="BaseRadioGroup__container">
        <template v-for="item in list">
          <label :key="item.id" class="BaseRadioGroup__label">
            <input
              :id="item.id"
              :value="item.value"
              type="radio"
              class="BaseRadioGroup__radio"
              :name="name"
              :checked="isChecked(item)"
              @change="onChange(item)"
            >
            {{ item.name }}</label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioGroup',
  props: {
    flex: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true,
      validator: items => {
        return (
          items.filter(item => {
            return Object.prototype.hasOwnProperty.call(item, 'id') && Object.prototype.hasOwnProperty.call(item, 'name') && Object.prototype.hasOwnProperty.call(item, 'value')
          }).length === items.length
        )
      }
    },
    value: {
      type: [Boolean, Number, Object, String],
      required: true
    }
  },
  methods: {
    onChange(val){
      this.$emit('input', val.value)
    },
    isChecked(val) {
      return this.value === val.value
    }
  }
}
</script>

<style lang="scss" scoped>
.BaseRadioGroup {
  &:last-of-type {
    &__label {
      margin-bottom: 0;
    }
  }

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
    color: $color-neutral-stronger;
    .InputGroup__input {
      background-color: $color-neutral-soft;
    }
  }
  &__title {
    font-size: $font-size-3xsmall;
    font-weight: $font-weight-bold;
    color: $color-neutral-stronger;
    margin-bottom: $spacing-300;
    text-align: left;
  }
  &__label {
    color: $color-neutral-stronger;
    font-weight: $font-weight-regular;
    font-size: $font-size-mini;
    user-select: none;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__radio {
    margin-right: 10px;
  }
}
.BaseRadioGroup__flex {
  .BaseRadioGroup__container {
    width: 100%;
    display: flex;
    gap: $spacing-500;
  }

  .BaseRadioGroup__label {
    margin-bottom: 0;
  }
}
</style>
