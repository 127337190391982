export default {
  users: {
    data:{
      headers: {},
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  userTypes: [],
  userEnableCreate: []
}