<template>
  <div class="Modal">
    <div class="Modal__container">
      <div class="Modal__containerHeader">
        <slot name="header" />
        <div class="Modal__close" @click="close">
          <Icon name="close-alt" class="Modal__icon" />
        </div>
      </div>
      <div class="Modal__containerBody">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show:{
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyUp)
  },
  destroyed() {
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onKeyUp(e) {
      if (e.keyCode === 27) this.close()
    },
    clickOff(e) {
      if(e.target.classList[0] === 'Modal') this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.Modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba($color-primary-stronger, $opacity-strong);
  z-index: $z-modal-bg;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  &__container{
    width: 90%;
    height: 90%;
    background-color: $color-neutral-softest;
    box-shadow: $shadow-low, inset 0 10px 0 0 $color-primary-base;
    overflow-y: auto;
    border-radius: $border-radius-medium;
  }
  &__containerHeader{
    background-color: $color-primary-base;
    height: 32px;
    position: sticky;
    top: 0;
    z-index: $z-nav;
    color: $color-neutral-softest;
  }
  &__close{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 0;
    cursor: pointer;
    z-index: $z-overlay;
    opacity: $opacity-base;
    transition: all 0.5s ease 0s, 0.35s ease 0s;
    &:hover{
      opacity: 1;
    }
  }
  &__icon{
    width: 40%;
    filter: $filter-white;
  }
  &__containerBody{
    height: calc(100% - 32px);
  }
}
</style>
