import { mapGetters, mapMutations } from 'vuex'
const intercom = {
  data() {
    return {
      intercomButton: {
        isVisible: false,
        color: 'primary-base',
        textColor: 'dark'
      }
    }
  },
  created() {
    this.initIntercomChat()
  },

  computed: {
    ...mapGetters({
      isMobile: 'userAgent/isMobile'
    }),

    dynamicMarginAdjust() {
      return { 'help-button--dynamic-margin' : this.isMobile && this.$route.name === 'orders'}
    }
  },

  watch: {
    '$route': function(to) {
      this.showIntercomChat(to)
    }
  },

  methods: {
    ...mapMutations({
      setIntercom: 'SET_INTERCOM',
    }), 

    getUser(){
      return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))  : ''
    },

    getUserType(){
      return this.getUser() !== '' && this.getUser().roles?.[0] ? this.getUser().roles?.[0].name.toLowerCase() : false 
    },

    initIntercomChat() {
      const user = this.getUser()
      this.$intercom.boot({
        name: user.name,
        email: user.email,
        created_at: parseInt((new Date()).getTime()/1000),
        'olist-products': 'olist-pax'
      })

      this.setIntercom(this.intercomButton.isVisible)
    },
  
    showIntercomChat(to) {
      const user = this.getUser()
      const userType = this.getUserType()
      const isLoggedIn = !!user.name
      this.intercomButton.color = '#0C29D0'
  
      // Exibe o botão do chat para o perfil Lojista
      if (to.name !== 'signin' && userType === 'lojista') {
        this.intercomButton.isVisible = true
      }
  
      // Exibe o botão do chat na tela de Tracking caso o usuário esteja deslogado
      if (to.name === 'tracking' && !isLoggedIn) {
        this.intercomButton.isVisible = true
      }
      if (to.name === 'tracking') {
        this.intercomButton.color = 'white'
        this.intercomButton.textColor = 'blue'
      }
  
      // Remove o botão do chat na tela de login
      if (to.name === 'signin') {
        this.intercomButton.isVisible = false
      }

      this.updateIntercomChat()
    },
  
    openIntercomChat() {      
      this.updateIntercomChat()
      this.$intercom.show()
    },
  
    updateIntercomChat() {
      const user = this.getUser()
      let data = {
        name: user.name,
        email: user.email,
        'olist-products': 'olist-pax',
        hide_default_launcher: !this.intercomButton.isVisible,
        action_color: this.intercomButton.color
      }
      this.$intercom.update(data)
      this.setIntercom(this.intercomButton.isVisible)
    },
  },
}

export default intercom