export default {
  SET_PAYMENT_CLOSING_HISTORY(state, data) {
    state.paymentClosingHistory = data
  },
  SET_PAYMENT_IMPORT_ERRORS(state, data) {
    state.paymentImportErrors = data
  },
  SET_PAYMENT_UPLOAD_HISTORY(state, data) {
    state.paymentUploadHistory = data
  },
  SET_OPERATION_CONSOLIDATE(state, data) {
    state.operationConsolidate = data
  },
  SET_MASTER_MANAGER_APPROVE(state, data) {
    state.masterManagerApprove = data
  },
  SET_ANALYSTS_COMMENTS(state, data) {
    state.analystsComments = data
  },
  SET_FINANCIAL_CONSOLIDATE_HISTORY(state, data) {
    state.financialConsolidate = data
  },
  SET_ANALYST_REVIEW(state, data) {
    state.analystReview = data
  },
  SET_FORTNIGHT(state, data) {
    state.fortnight = data
  },
  SET_UPLOAD_HISTORY_EDIT(state, data) {
    state.uploadHistoryEdit = data
  },
  SET_DUPLICATE_HISTORY_LINES(state, data) {
    state.duplicateHistoryLines = data
  },
  SET_STATUS_LIST(state, data) {
    state.statusList = data
  },
  SET_ONLINE_ERRORS(state, data) {
    state.onlineErrors = data    
  },
  SET_RECEIPTS(state, data) {
    state.receipts = data
  },
  SET_VEHICLES_EDIT(state, data) {
    state.vehiclesEdit = data
  },
  SET_VEHICLES_TYPES(state, data) {
    state.vehiclesTypes = data
  },
  SET_FIELD_ERRORS_LIST(state, data) {
    state.fieldErrorsList = data
  },
  SET_FINANCIAL_APPROVE(state, data) {
    state.financialApprove = data
  }
}
