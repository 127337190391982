import request from '@/api/plugins/request'

export default {
  async fetchStates(success, error) {
    return await request({
      method: 'get',
      url: 'states'
    }, success, error)
  },

  async fetchCities({params}, success, error, done) {
    return await request({
      method: 'get',
      url: `states/${params}`
    }, success, error, done)
  },

  async fetchAddress({ cep, hideErrors }, success, error) {
    return await request({
      method: 'get',
      url: `orders-viacep/${cep}?${hideErrors ? 'hideErrors' : ''}`
    }, success, error)
  },

  async fetchStoreAddress({ id }, success, error) {
    return await request({
      method: 'get',
      url: `store-address/${id}`
    }, success, error)
  }
}