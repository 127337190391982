<template>
  <div class="BaseCheckBox" :class="BaseCheckBoxClass">
    <div v-if="label" class="BaseCheckBox__title">
      {{ label }}
    </div>
    <div class="BaseCheckBox__container">
      <label class="BaseCheckBox__label">
        <input
          type="checkbox"
          class="BaseCheckBox__checkbox"
          :checked="value"
          @change="onChange"
        >
        {{ name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckBox',
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [Boolean, Number],
      required: true,
      default: false,
    },
    valueIsBoolean: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: ''
    },
    marker: {
      type: String,
      default: 'square'
    },
    align: {
      type: String,
      default: null,
      validator: (val) => ['top', 'center', 'bottom', 'right'].includes(val),
    },
  },
  computed: {
    BaseCheckBoxClass() {
      return [
        this.color !== '' ? `BaseCheckBox--color-${this.color}` : '',
        this.marker !== '' ? `BaseCheckBox--marker-${this.marker}` : '',
        this.align ? `BaseCheckBox--align-${this.align}` : null
      ]
    },
  },  
  methods: {
    onChange(e){
      this.$emit('input', this.valueIsBoolean ? e.target.checked : (e.target.checked ? 1 : 0))
    }
  }
}
</script>

<style lang="scss" scoped>
$colors: (
  "yellow": $color-notice-base,
  "primary-base": $color-primary-base,
  "white": $color-neutral-softest,
  "gray": $color-neutral-strong
);

//Styles for checkboxes
$checkbox-size: 16px;
input[type="checkbox"] {
  /* Positioning */
  transform: translateY(2px);

  /* Box model */
  width: $checkbox-size;
  height: $checkbox-size;

  /* Misc */
  appearance: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  &:before {
    display: block;
    width: inherit;
    height: inherit;
    box-sizing: border-box;
    border: 2px solid $color-neutral-stronger;
  }
}

.BaseCheckBox {
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
    color: $color-neutral-stronger;
    .InputGroup__input {
      background-color: $color-neutral-soft;
    }
  }

  //Position
  &--align {
    &-top,
    &-center,
    &-bottom,
    &-right {
      display: flex;
    }

    &-top {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-bottom {
      align-items: flex-end;
    }
    &-right {
      justify-content: flex-end;
    }
  }
  
  &__title {
    font-size: $font-size-3xsmall;
    font-weight: $font-weight-bold;
    color: $color-neutral-stronger;
    margin-bottom: 10px;
  }
  &__label {
    color: $color-neutral-stronger;
    font-weight: $font-weight-regular;
    font-size: $font-size-mini;
    user-select: none;
  }
  &__checkbox {
    margin-right: 10px;
  }

  &--color{
    @each $type, $color in $colors {
      &-#{$type}{
        .BaseCheckBox {
          &__checkbox {
            &::before {
              border-color: $color
            }
          }
        }
      }
    }
  }

  &--marker {
    &-square {
      .BaseCheckBox {
          &__checkbox {
            &::before {
              border: 2px solid $color-neutral-stronger;
            }
            &::after {
              transform: translate3d($checkbox-size / 4, $checkbox-size / 4, 0);
              display: block;
              width: $checkbox-size / 2;
              height: $checkbox-size / 2;
              background: transparent;
            }
            &:checked {
              &:after { background: $color-neutral-stronger }
            }
          }
        }
    }
    &-check {
       .BaseCheckBox {
          &__checkbox {
            &::after {
              display: flex;
              justify-content: center;
              align-items: center;
              transform: scale(1.5) translate3d(3px, -3px, 0) rotate(20deg);
              width: 100%;
              height: 100%
            }
            &:checked {
              &:after {
                content: '\2713';
                background-color: transparent;
              }
            }
          }
        }
    }
  }
}
</style>