export default {
  isAuthenticated(state) {
    return !!state.access_token
  },

  permissionsParsed(state) {
    let permissions = {}
    if(state.permissionsList.length) {
      state.permissionsList.forEach(element => {

        const splitName = element.name.split('.')

        if(splitName[1] === 'isVisible') {
          let visible = { [splitName[1]]: true }

          const visiblePermission = {
            [splitName[0]]: visible
          }
          permissions = {...permissions, ...visiblePermission}
        } else {
          const childrenPermission = { [splitName[1]]: true }
          permissions[splitName[0]] = {...permissions[splitName[0]], ...childrenPermission}
        }
      })
      return permissions
    } else {
      return {}
    }
  },

  routePermissionList(state) {
    const permissionList = []
    if (!state.permissions) {
      return permissionList
    }
    for (const index in state.permissions) {
      const permissionObject = state.permissions[index]
      if (permissionObject.isVisible) {
        permissionList.push(index)
      }
    }
    return permissionList
  },
}
