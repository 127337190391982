import { groups } from '@/api'

export default {
  async fetchGroupsList({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await groups.fetchGroupsList(params, (res) =>
      commit('SET_GROUP', res) , (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async deleteGroup({ commit }, id) {
    return await groups.deleteGroup(id, () => commit('DELETE_GROUP', id), (e) => e)
  },

  async fetchGroupSelect({ commit }) {
    return await groups.fetchGroupSelect((res) => commit('SET_GROUP_SELECT', res) , (e) => e)
  }
}