import * as Sentry from '@sentry/vue'
import Cookies from 'js-cookie'

export default (router, store) => {
  router.beforeEach(async (to, from, next) => {
    store.dispatch('userAgent/responsiveMenuOpen', false)

    const logout = async () => {
      await store.dispatch('auth/signOut')
      return next('/auth/signin')
    }

    if (!localStorage.getItem('modules') || localStorage.getItem('modules') === 'null') {
      logout()
    } else {
      await store.dispatch('auth/setModules', JSON.parse(localStorage.getItem('modules')))
    }
    
    if (to.meta.public) {
      return next()
    }

    if(to.meta.module) {
      await store.dispatch('auth/fetchPermissionsByModules', to.meta.module)
      await store.dispatch('auth/setPermissions', store.getters['auth/permissionsParsed'])      
    }

    if (!store.getters['auth/isAuthenticated'] && Cookies.get('access_token')) {
      await store.dispatch('auth/setAccessToken', Cookies.get('access_token'))
    }

    const hasPermission = store.getters['auth/permissionsParsed'][to.name]
    
    window.scrollTo(0,0)

    //Playground for Dev
    const isDevPlayground = (process.env.VUE_APP_MODE === 'local' || process.env.VUE_APP_MODE === 'develop') && to.name === 'Playground'

    if (store.getters['auth/isAuthenticated']) {
      if (to && to.name === 'welcome' || isDevPlayground) {
        return next()
      }
      return hasPermission?.isVisible ? next() : next({ name: 'notfound' })
    } else {
      return hasPermission?.isVisible ? next() : logout()
    } 
  })
  router.onError(error => {
    if (/loading chunk/i.test(error.message) || /loading css chunk/i.test(error.message)) {
      store.dispatch('error/setError', 'Uma nova atualização do painel está disponível. Por favor recarregue a página para continuar utilizando.')
    } else {
      store.dispatch('error/setError', 'Ops... Ocorreu algum erro. Por favor<br> recarregue a página ou tente novamente.')
      Sentry.captureException(error)
    }
  })
}