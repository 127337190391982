import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchVehicles({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `vehicles?is_table=vehicles_owner&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchVehicle({ id }, success, error) {
    return await request({
      method: 'get',
      url: `vehicles/${id}`
    }, success, error)
  },

  async createVehicle(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'vehicles',
      body
    }, success, error, done)
  },

  async deleteVehicle({ id }, success, error) {
    return await request({
      method: 'delete',
      url: `vehicles/${id}`
    }, success, error)
  },

  async linkVehicle(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'vehicle-link/',
      body
    }, success, error, done)
  },

  async unlinkVehicle(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'vehicle-unlink/',
      body
    }, success, error, done)
  },

  async updateVehicle({ id, body }, success, error, done) {
    return await request({
      method: 'patch',
      url: `vehicles/${id}`,
      body
    }, success, error, done)
  },

}