<template>
  <div class="ErrorList">
    <Modal @close="modalController">
      <template #header>
        <div class="ErrorList_TitleModal">
          <div class="ErrorList__HeaderTextCenter">
            Número do Pedido
          </div>
          <div class="ErrorList__HeaderText">
            Problema encontrado
          </div>
        </div>
      </template>
      <template #body>
        <div v-for="(error, i) in list" :key="i" class="ErrorList__ContainerBody-td">
          <div class="ErrorList__numberOrder">
            {{ error.numero_do_pedido ? error.numero_do_pedido : error.order_number ? error.order_number : '-' }}
          </div>
          <div v-if="hasEdit === i" class="ErrorList__AddressFields">
            <InputAddress 
              ref="inputAddress" 
              :model="form" 
              :fields="inputAddressFields"
              grid="Zip Zip City City Neighborhood Neighborhood Address Address Address Number Complement State"
              class="ErrorList__InputAddress"
              @setAddress="addressUpdate"
            />
            <BaseButton label="Corrigir" class="ErrorList__CorrectAddress" theme="simple" filled color="primary-base" text-color="white" @click="correctAddress" />
          </div>
          <div v-else class="ErrorList__message">
            <span>{{ error.message }}</span>
            <Icon v-if="error.rectify" class="ErrorList__IconEdit" name="edit" @click="edit(error, i)" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Utility from '@/plugins/formData'
import { InputAddress, Modal } from '@/components/molecules'
import { BaseButton } from '@/components/atoms'
import { mapActions } from 'vuex'

export default {
  name: 'ErrorList',
  components: {
    InputAddress,
    BaseButton,
    Modal
  },
  props: {
    list: {
      type: Array,
      required: true,
      validator: items =>
        items.filter(item => Object.prototype.hasOwnProperty.call(item, 'item')).length === items.length
    },
  },
  data(){
    return{
      data: null,
      hasEdit: null,
      form:{
        destiny_zip: '',
        destiny_address: '',
        destiny_number: '',
        destiny_complement: '',
        destiny_neighborhood: '',
        destiny_city: '',
        destiny_state_id: null
      }
    }
  },

  computed:{
    inputAddressFields(){
      return {
        zip: 'destiny_zip',
        address: 'destiny_address',
        number: 'destiny_number',
        complement: 'destiny_complement',
        neighborhood: 'destiny_neighborhood',
        city: 'destiny_city',
        state_id: 'destiny_state_id'
      }
    }
  },
  
  methods: {
    ...mapActions({
      correctImportOrder: 'orders/correctImportOrder'
    }),

    edit(data, i){
      this.data = data
      this.hasEdit = i

      for (const key in this.form) {
        this.form[key] = this.data.item[key] || ''
      }
    },

    correctAddress(){
      const formData = Utility.convertModelToFormData(this.form)
      formData.append('destiny_state', this.form.destiny_state_id.id)

      this.correctImportOrder({id: this.data.id, body: formData})
      this.hasEdit = null
    },

    addressUpdate(data){
      this.form = {...this.form, ...data}
    },

    modalController(){
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
$columnsSize: 160px 1fr;
@mixin fontDisplay($color, $weight: $font-weight-bold){
  font-weight: $weight;
  font-size: $font-size-1xmini;
  color: $color;
}

.ErrorList{
  &_TitleModal{
    height: 100%;
    display: grid;
    grid-template-columns: $columnsSize;
    align-items: center;
    position: sticky;
    top: 0;
  }
  &__HeaderText{
    @include fontDisplay($color-neutral-softest);
    padding-left: 10px;
  }
  &__HeaderTextCenter{
    @include fontDisplay($color-neutral-softest);
    justify-self: center;
  }
  &__ContainerBody{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    &-td{
      display: grid;
      grid-template-columns: $columnsSize;
      height: 70px;
      &:nth-child(odd) {
        background: $gradient-primary;
      }
    }
  }
  &__numberOrder{
    @include fontDisplay($color-neutral-strong, 400);
    justify-self: center;
    align-self: center;
  }
  &__message{
    @include fontDisplay($color-neutral-strong, 400);
    padding-left: 10px;
    align-self: center;
    display: flex;
    align-items: center;
  }
  &__IconEdit{
    width: 15px;
    cursor: pointer;
    justify-self: center;
    align-self: center;
    margin-left: 20px;
  }
  &__AddressFields{
    display: flex;
    align-self: center;
    justify-content: space-around;
    align-items: flex-end;
  }
  &__InputAddress{
    width: 85%;
  }
  &__CorrectAddress{
    width: 10%;
  }
}
</style>