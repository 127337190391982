<template>
  <td
    v-if="!isHeaderCell && header || isHeaderCell && item.isVisible"
    v-show="!isHeaderCell && header.isVisible || isHeaderCell && item.isVisible"
    class="DataTableTd"
    :class="!isHeaderCell ? header.id : {
      [header.id]: true,
      'DataTable__th--none': header.method === 'warning',
      [alignText(item.id)]: true
    }"
    :style="position"
  >
    <span v-show="isResponsive && !isHeaderCell" class="DataTableTd__Title">{{
      header.label
    }}</span>

    <div
      v-tooltip="tooltipProps"
      class="DataTableTd__Content__Items"
      :class="cellClass"
      @click="cellEvents"
    >
      <div
        v-if="isFeatureVisible('warning')"
        class="DataTableTd__Content__Warning"
        :class="`DataTableTd__Content__Warning--${cell.features.warning.value}`"
      />

      <!-- Value -->
      <span
        class="DataTableTd__Content"
        :class="sizeLimit"
        :title="formatValue(cell, !isHeaderCell ? header : false)"
        v-text="formatValue(cell, !isHeaderCell ? header : false)"
      />

      <!-- Icon -->
      <Icon
        v-if="isFeatureVisible('icon')"
        :title="cell.features.icon.value"
        :name="cell.features.icon.value"
        class="DataTableTd__Content"
        :class="iconClass"
      />
    </div>
  </td>
</template>
<script>

export default {
  name: 'ContentCell',
  components: {
  },
  props: {
    item: {
      type: [Object, String, Number, Array, Boolean],
      default: null
    },
    header: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      default: () => {}
    },
    sizeLimit: {
      type: String,
      default: ''
    },
    isResponsive: {
      type: Boolean,
      default: false
    },
    position: {
      type: Object,
      default: () => {}
    },
    table: {
      type: Array,
      default: () => []
    },
    sortColumns: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      copiedLabel: false,
      timeout: null
    }
  },
  computed: {
    isHeaderCell() {
      return this.table.length > 0
    },
    cell() {
      // Header Cell
      if (this.isHeaderCell) {
        let data = JSON.parse(JSON.stringify({
          features: {},
          ...this.header
        }))

        // Sort Items
        let sort = this.sortColumns?.[this.item.id]
        if (sort) {
          data.features.icon = {
            value: `chevron-${sort.direction === 'asc' ? 'up' : 'down'}`,
            position: 'left',
            sections: ['header'],
            options: [
              {
                event: 'sort'
              }
            ]
          }
        }
        return data
      }

      let data = this.formatTableData(this.item)
      
      // Adds content features into cell
      if (this.header.features && !this.isHeaderCell) {
        let contentFeatures = {}
        Object.keys(this.header.features).forEach(f => {
          if (this.header.features[f]?.sections.includes('content')) {
            contentFeatures[f] = this.header.features[f]
          }
        })
        data.features = {
          ...contentFeatures,
          ...data.features
        }
      }

      // Sanitizes old icon param
      if (data.icon) {
        data.features = {
          ...data.features,
          icon: {
            value: data.icon,
            position: 'left'
          }
        }
        delete data.icon
      }

      return data
    },
    cellClass() {
      let className = 'DataTableTd__Content__Items'
      let classes = []
      if (this.cell?.features) {
        Object.keys(this.cell.features).forEach(c => {
          if (this.isHeaderCell && this.cell.features[c].sections?.includes('header') || !this.isHeaderCell) {
            classes.push(
              `${className}--${c}`,
              `${className}${typeof this.cell.features[c].value === 'string' ? `--${this.cell.features[c].value}` : ''}`
            )
            this.cell.features[c]?.options?.forEach(e => {
              classes.push(
                `${className}--${e.event}`
              )
            })
          }
        })
      }
      return classes
    },
    cellTooltip() {
      let tooltip = null
      if (this.cell.features) {
        Object.keys(this.cell.features).forEach((f) => {
          let hover = this.cell.features[f]?.options?.filter(e => e.event === 'hover')?.[0]?.content
          if (hover) tooltip = hover
        })
      }
      return tooltip
    },
    tooltipProps() {
      return {
        content: this.copiedLabel ? 'Texto copiado!' : this.cellTooltip,
        delay: {
          hide: this.copiedLabel ? 1000 : 0
        }
      }
    },
    iconClass() {
      let classes = [
        `Icon--${this.cell?.features?.icon?.position}`
      ]
      return classes
    }
  },
  methods: {
    isFeatureVisible(el) {
      let element = this.cell.features?.[el]
      let hasElement = element?.value
      let hasValue = this.cell.value
      return (
        hasElement &&
        hasValue &&
        !this.isHeaderCell
      ) || (
        hasElement &&
        this.isHeaderCell &&
        element?.sections?.includes('header')
      )
    },
    formatTableData(item) {
      let data = {}
      if (typeof item === 'object' && item != null && !Array.isArray(item)) {
        data = {
          ...this.item
        }
      } else {
        data = {
          value: this.item || ''
        }
      }
      return data
    },

    formatValue(cell, header) {
      if (!header) return cell.label
      return !header.method ? cell.value : this.$options.filters?.[header.method](cell.value)
    },

    cellEvents() {
      if (this.cell.features) Object.keys(this.cell.features).forEach(f => {
        if (this.isHeaderCell && this.cell.features[f].sections?.includes('header') || !this.isHeaderCell) {
          Object.keys(this.cell.features[f]).forEach(i => {
            if (i === 'options') {
              // Options / Events
              this.cell.features[f][i].forEach(e => {
                // Copy Event
                if (e.event === 'copy')  {
                  const clipboardData =
                    event.clipboardData ||
                    window.clipboardData ||
                    event.originalEvent?.clipboardData ||
                    navigator.clipboard

                  if (this.isHeaderCell) {
                    clipboardData.writeText(this.table
                      .map(e => e[this.cell.id])
                      .filter(e => e != null)
                      .map(e => this.formatValue({ value: e }, this.header))
                      .join('\n'))
                  } else {
                    clipboardData.writeText(this.formatValue(this.cell, this.header))
                  }

                  this.copiedLabel = true
                  clearTimeout(this.timeout)
                  this.timeout = setTimeout(() => {
                    this.copiedLabel = false
                  }, 1500)
                // Other Events
                } else {
                  let data = {
                    cell: this.cell,
                    data: e,
                    type: this.isHeaderCell ? 'header' : 'content'
                  }
                  if (!this.isHeaderCell) data.row = this.row
                  this.$parent.$emit(e.event, data)
                }
              })
            }
          })
        }
      })
    },

    alignText(id) {
      return id === 'actions' ? 'DataTable--center' : 'DataTable--left'
    }    
  }
}
</script>
<style lang="scss" scoped>
.DataTable {
  &--limit {
    max-width: 120px;
    @media #{$mobile-view} {
      max-width: initial;
    }
  }
  &--noLimit {
    max-width: initial;
  }
}
.DataTableTd {

  &__Title {
    /* Typographic */
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
  }
  &__Content {
    /* Box model */
    display: -webkit-box;
    overflow: hidden;
    order: 1;

    /* Typographic */
    font-size: $font-size-1xmini;
    color: $color-neutral-base;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: $line-height-2xsmall;

    &__Warning {
      width: 12px;
      height: 12px;
      background: $color-neutral-base;
      border-radius: 50%;

      &--primary { background: $color-primary-base; }
      &--accent { background: $color-accent-base; }
      &--informative { background: $color-informative-base; }
      &--alert { background: $color-alert-base; }
      &--notice { background: $color-notice-base; }
      &--success { background: $color-success-base; }
    }

    &__Items {
      display: inline-flex;
      align-items: center;
      gap: 8px;

      &--hover {
        cursor: default;
      }
      &--sort, &--copy, &--goTo {
        cursor: pointer
      }

      &--goTo {
        span {
          color: $color-primary-strong;
          font-weight: bold;
          padding-bottom: 3px;
          border-bottom: 1px solid $color-primary-strong;
        }
      }

      &--dark {
        border-radius: 40px;
        padding: 8px 16px;
        justify-content: center;
      }

      &--light {
        border-radius: 40px;
        padding: 8px 16px;
        justify-content: center;
        background: rgba($color-neutral-softer, 0.5);
        font-weight: bold;
      }

      &--success {
        border-radius: 40px;
        padding: 8px 16px;
        justify-content: center;

        background: $color-accent-strong;

        * {
          color: #fff;
        }
      }

      &--error {
        border-radius: 40px;
        padding: 8px 16px;
        justify-content: center;

        background: $color-notice-base;

        * {
          color: #fff;
        }
      }

      &--dark {
        background: $color-blue-800;

        * {
          color: #fff;
        }

        .Icon {
          filter: $filter-white;
        }
      }

      .Icon {
        height: 1rem;
        flex-shrink: 0;

        &--left {
          order: 0;
        }
      }     
    }
  }
}
</style>



