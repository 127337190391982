<template>
  <div class="Profile" :title="name" @click="onClick">
    <Icon name="user" color="white" class="Profile__icon" />
  </div>
</template>

<script>
export default {
  name: 'Profile',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  methods:{
    onClick(){
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.Profile {
  &__icon{
    width: 17px;
    cursor: pointer;
  }
  &:hover{
    .Profile__icon{
      filter: $filter-light
    }
  }
}
</style>
