<template>
  <Modal v-if="modal" class="InputFileGroup__Modal" @close="close">
    <template #header>
      <div class="InputFileGroup__Modal__Header">
        <span>Upload de arquivo</span>
      </div>
    </template>
    <template #body>
      <div class="InputFileGroup__Modal__Body">
        <BaseInputFile ref="input" v-model="formDataModel" :show-formats="showFormats" :multiple="multiple" :accept="accept" @save="save" />
      </div>
    </template>
  </Modal>
</template>
<script>
import { acceptFormats } from './utils/index.js'
export default {
  name: 'BaseInputFileGroupModal',
  components: {
    Modal: () => import('@/components/molecules/Modal/Modal.vue'),
    BaseInputFile: () => import('@/components/atoms/BaseInputFile/BaseInputFile.vue'),
  },
  props: {
    value:{
      type: FormData,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: Array,
      default: () => acceptFormats.all
    },
    showFormats: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modal: false,
      formDataModel: new FormData()
    }
  },
  methods: {

    //Handlers
    open() {
      this.modal = true
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

    save(formDataModel, formDataFiles) {
      this.modal = false
      this.formDataModel = formDataModel
      this.$emit('save', formDataModel, formDataFiles)
    },

    delete(data) {
      this.$refs.input.handleRemove(data)
    },    

  }
}
</script>
<style lang="scss" scoped>
.InputFileGroup {
  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      min-width: 30vw;
      max-width: 500px;
      max-height: 95vh;
    }

    &__Header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      padding: 1rem 1rem;
    }

    &__Image {
      width: 100%;
      height: 100%;
      border: 1px solid #808080;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &__Actions {
      display: flex;
      align-self: flex-end;
      margin-top: 2rem;

      /deep/ .BaseButton  {
        height: 25px;
        margin: 0 10px;
        min-width: 120px;

        &__Text {
          text-transform: capitalize;
          margin-left: 0;
        }

      }
    }

  }


}
</style>
