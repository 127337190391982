import request from '@/api/plugins/request'
import download from '@/api/plugins/download'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchDrivers({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `users?is_table=drivers&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async getDriverCanEditProfile(success, error, done) {
    return await request({
      method: 'get',
      url: 'driver-can-edit-profile'
    }, success, error, done)
  },

  async setDriverCanEditProfile(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'driver-can-edit-profile',
      body
    }, success, error, done)
  },

  async setDriverEnableDeviceLogin(driver, body, success, error, done) {
    return await request({
      method: 'post',
      url: `drivers/${driver.id}/login-release-device`,
      body
    }, success, error, done)
  },

  async fetchDriver({ id }, success, error) {
    return await request({
      method: 'get',
      url: `drivers/${id}`
    }, success, error)
  },

  async createDrivers(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'driversAuth',
      body
    }, success, error, done)
  },

  async deleteDriver({ id }, success, error) {
    return await request({
      method: 'delete',
      url: `users/${id}`
    }, success, error)
  },


  async updateDriver({ id, body }, success, error, done) {
    return await request({
      method: 'post',
      url: `drivers/${id}`,
      body
    }, success, error, done)
  },

  async fetchBankTypes(success, error, done) {
    return await request({
      method: 'get',
      url: 'banks-types'
    }, success, error, done)
  },

  async validateDriverBankData({ id, body }, success, error, done) {
    return await request({
      method: 'post',
      url: `oxpay/account-driver-id-validate/${id}`,
      body
    }, success, error, done)
  },

  async fetchDriversObservations(success, error) {
    return await request({
      method: 'get',
      url: 'drivers-observations'
    }, success, error)
  },

  async fetchDriverVehicles(id, success, error, done) {
    return await request({
      method: 'get',
      url: `vehicles?is_table=vehicles_driver&user_id=${id}`
    }, success, error, done)
  },

  async checkDeleteStore({ driver_id, store_id }, success, error, done) {
    return await request({
      method: 'get',
      url: `drivers/checkDeleteStore/${driver_id}/${store_id}`,
    }, success, error, done)
  },

  async downloadDocs(id, success, error, done) {
    return await download({
      method: 'get',
      url: `drivers/downloadDocs/${id}`
    }, success, error, done)
  },

  async exportDrivers({query}, success, error, done) {
    return await download({
      method: 'get',
      url: `drivers-report?filter[roles][id]=14&fileName=Motoristas&with[]=vehicle.model&with[]=transporters&with[]=storesPivot.store&with[]=profile.state&with[]=documents&with[]=bankData.bank&with[]=bankData.type&${query}`
    }, success, error, done)
  },

  async fetchDriversTracking(queryString, success, error, done) {
    let query = ''
    if(queryString){
      const a = objToQuery.parseAll(queryString, 'id')
      query = `drivers-tracking?${a}&paginate=999`
    }else{
      query = 'drivers-tracking?paginate=999'
    }

    return await request({
      method: 'get',
      url: query,
    }, success, error, done)
  },

  //Selects
  async fetchVehicleBrands(success, error, done) {
    return await request({
      method: 'get',
      url: 'search/vehicles/brands?search[]='
    }, success, error, done)
  },
  async fetchVehicleModels({ params }, success, error,  done) {
    return await request({
      method: 'get',
      url: `search/vehicles/models/${params}?search[]=`
    }, success, error, done)
  },
  async fetchBanks(success, error, done) {
    return await request({
      method: 'get',
      url: 'search/banks?search[]='
    }, success, error, done)
  },
}