import state from './state.queryString'
import getters from './getters.queryString'
import mutations from './mutations.queryString'
import actions from './actions.queryString'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}