const Groups = () => import( /* webpackChunkName: 'Groups' */ './Groups.vue')
const GroupsForm = () => import( /* webpackChunkName: 'Groups' */ './Form/GroupsForm.vue')

const groups = [
  {
    name: 'groups',
    meta: {
      label: 'Novo Grupo',
      module: 'module_groups'
    },
    path: '/groups',
    component: Groups,
  },
  {
    name: 'groupsAdd',
    meta: {
      module: 'module_groups'
    },
    path: '/groups/add',
    component: GroupsForm,
  },
  {
    name: 'groupsDetail',
    meta: {
      module: 'module_groups'
    },
    path: '/groups/:id',
    component: GroupsForm,
  }
]

export default groups