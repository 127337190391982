import state from './state.routes'
import getters from './getters.routes'
import mutations from './mutations.routes'
import actions from './actions.routes'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}