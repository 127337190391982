<template>
  <BaseButton
    :label="label"
    theme="icon-right"
    color="black"
    text-color="black"
    :icon-color="iconColor"
    :icon="icon"
    @click="toggleStatus"
  />
</template>

<script>
import { BaseButton } from '@/components/atoms'

export default {
  name: 'ButtonToggle',
  components: { BaseButton },
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    status() {
      return this.value.id === 1 || false
    },
    label() {
      return this.status ? `Inativar ${this.name}` : `Ativar ${this.name}`
    },
    iconColor() {
      return this.status ? 'red' : 'green'
    },
    icon() {
      return this.status ? 'close' : 'check'
    }
  },
  
  methods: {
    toggleStatus() {
      const active = { id: 1, name:'Ativo' }
      const inActive = { id: 2, name:'Inativo' }

      this.$emit('input', this.status ? inActive : active)
    }
  }
}
</script>
