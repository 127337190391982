import * as Sentry from '@sentry/vue'
const analytics = {
  watch: {
    '$route': function() {
      this.identifyAndSendUserData()
    }
  },
  methods: {
    getUser(){
      return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))  : ''
    },

    getUserType(){
      return this.getUser() !== '' && this.getUser().roles?.[0] ? this.getUser().roles?.[0].name.toLowerCase() : false 
    },

    async identifyAndSendUserData() {
      // Identify user
      const user = this.getUser()
      if (user.email) {
        // Send user data to Google Analytics
        await this.$gtag.set({
          'user_id': user.email,
        })
        // Send user data to Sentry
        await Sentry.setUser({
          email: user.email,
          id: user.id,
          name: user.name
        })
        // Send user data to Mixpanel
        this.$mixpanel.identify(user.id)
        this.$mixpanel.people.set({
          '$email': user.email,
          '$name': user.name
        })
      } else {
        Sentry.configureScope(scope => scope.setUser(null))
      }
    }
  },
}

export default analytics