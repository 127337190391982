<template>
  <svg class="BaseBarcodeAnimation" :class="`BaseBarcodeAnimation--${type}`" viewBox="0 0 636 208">
    <g class="BaseBarcodeAnimation__CodeArea">
      <path d="m611 131v26c0 0 0 26-26 26-26 0-26 0-26 0" />
      <path d="m77 183h-26c0 0-26 0-26-26 0-26 0-26 0-26" />
      <path d="m559 25h26c0 0 26 0 26 26 0 26 0 26 0 26" />
      <path d="m25 77v-26c0 0 0-26 26-26 26 0 26 0 26 0" />
    </g>

    <g class="BaseBarcodeAnimation__Lines">
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m96 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m122 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m175 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m228 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m254 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m306 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m332 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m358 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m411 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m464 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m489 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-1" d="m542 62.5v89.6" />
      <path class="BaseBarcodeAnimation__Lines--type-2" d="m516 61.6v92.1" />
      <path class="BaseBarcodeAnimation__Lines--type-2" d="m439 61.6v92.1" />
      <path class="BaseBarcodeAnimation__Lines--type-2" d="m281 61.6v92.1" />
      <path class="BaseBarcodeAnimation__Lines--type-2" d="m204 61.6v92.1" />
    </g>
    <path class="BaseBarcodeAnimation__ReadLine" d="m70 107h496.8" />

    <circle class="BaseBarcodeAnimation__Circle" cx="317.5" cy="106.5" r="39.5" />
    
    <path class="BaseBarcodeAnimation__Icon BaseBarcodeAnimation__Icon--success" d="m328.8 99.5l-14.2 14.2-7.2-7.2" />

    <path class="BaseBarcodeAnimation__Icon BaseBarcodeAnimation__Icon--alert" d="m309 98l18 18" />
    <path class="BaseBarcodeAnimation__Icon BaseBarcodeAnimation__Icon--alert" d="m309 116l18-18" />

    <path class="BaseBarcodeAnimation__Icon BaseBarcodeAnimation__Icon--notice" d="m318 93.7v14.3" />
    <path class="BaseBarcodeAnimation__Icon BaseBarcodeAnimation__Icon--notice" d="m318 111.7v6.5" />
  </svg>
</template>
<script>
export default {
  name: 'BaseBarcodeAnimation',
  props: {
    type: {
      type: String,
      default: 'waiting',
      validator: (val) => ['waiting', 'success', 'alert', 'notice'].includes(val),
    },
  }
}
</script>
<style lang="scss" scoped>
.BaseBarcodeAnimation {
  &--waiting {
    .BaseBarcodeAnimation {
      &__ReadLine {
        animation: move 2s linear infinite;
      }
      &__CodeArea {
        animation: fade 1s linear infinite;
      }
    }
  }

  &--success,
  &--alert,
  &--notice {
    .BaseBarcodeAnimation {
      &__ReadLine {
        animation: fade 0.25s linear infinite;
      }
      &__Circle {
        opacity: 1
      }
    }
  }

  &--success {
    .BaseBarcodeAnimation {
      &__ReadLine {
        stroke: $color-success-soft;
        animation: fade 0.5s linear infinite;
      }
      &__Circle {
        fill: $color-success-soft;
      }
      &__Icon--success {
        opacity: 1
      }
    }
  }

  &--alert {
    .BaseBarcodeAnimation {
      &__ReadLine {
        stroke: $color-alert-soft;
      }
      &__Circle {
        fill: $color-alert-soft;
      }
      &__Icon--alert {
        opacity: 1
      }
    }
  }

  &--notice {
    .BaseBarcodeAnimation {
      &__ReadLine {
        stroke: $color-notice-soft;
      }
      &__Circle {
        fill: $color-notice-soft;
      }
      &__Icon--notice {
        opacity: 1
      }
    }
  }

  &__ReadLine {
    stroke: $color-alert-base;
    stroke-linecap: round;
    stroke-linejoin: bevel;
    stroke-width: 10;
    transition: all 0.3s ease;
  }

  &__CodeArea {
    transition: all 0.3s ease;
    path {
      fill: none;
      stroke: $color-neutral-softer;
      stroke-linecap: round;
      stroke-width: 10
    }
  }

  &__Lines {
    &--type-1, &--type-2 {
    stroke: $color-neutral-stronger;
      stroke-linecap: round;
      stroke-linejoin: bevel;
      stroke-width: 10
      } 
    &--type-2 {
      stroke-width: 6
    } 
  }

  &__Circle {
    transition: all 0.3s ease;
    opacity: 0;
  }

  &__Icon {
    transition: all 0.3s ease;
    opacity: 0;
    fill: none;
    stroke: #ffffff;
    stroke-width: 6
  } 

}

@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes move  { 
  0%, 100%{
    transform: translateY(-15%)
  } 
  50% {
    transform: translateY(15%)
  }
}
</style>
