import { ctes } from '@/api'

export default {
  // ##########
  // CTes
  // ##########
  async fetchCtesRoutes({ commit }, params = {paginate:20, page:1}) {
    return await ctes.fetchCtesRoutes(params, (res) =>
      commit('SET_CTES_ROUTES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchCtes({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await ctes.fetchCtes(params, (res) =>
      commit('SET_CTES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  // ##########
  // MDFe
  // ##########
  async fetchMdfes({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await ctes.fetchMdfes(params, (res) =>
      commit('SET_MDFES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

}