import state from './state.vehicles'
import getters from './getters.vehicles'
import mutations from './mutations.vehicles'
import actions from './actions.vehicles'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}