<template>
  <div v-if="file" class="InputFile__Uploaded" :class="InputFileIconClass" :style="style">
    <template v-if="isImageType">
      <div class="InputFile__ImageBox" :style="`background-image: url(${file.img || file.path})`" />
    </template>

    <template v-else>
      <Icon :name="iconType" class="InputFile__Icon" />
    </template>

    <div v-if="actions" class="InputFile__Actions">
      <div v-if="showImageName" class="InputFile__Actions--text">
        <p>{{ file.original_name }}</p>
      </div>
      

      <div class="InputFile__Actions--buttons">
        <Icon v-if="isImageType" name="eye" color="white" class="InputFile__Icon" @click="handleVisualize" />

        <a v-else :download="file.original_name" :href="file.path">
          <Icon name="download" color="white" class="InputFile__Icon" />
        </a>
        
        <Icon v-if="canRemoveFile" name="trash" color="white" class="InputFile__Icon" @click="confirmRemove" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseInputFileUploaded',
  props: {
    file: {
      type: Object,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: function () {
        return this.actions && !this.multiple
      },
    },
    showImageName: {
      type: Boolean,
      default: false
    },
    canRemoveFile: {
      type: [Boolean, String],
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },

    //Styles
    iconWidth: {
      type: String,
      default: function () {
        return this.list ? 'auto' : '100%'
      },
    },
    iconHeight: {
      type: String,
      default: function () {
        return this.list ? '75px' : '100%'
      },
    },
    iconPadding: {
      type: String,
      default: function () {
        return this.list ? '0 10px' : '0'
      },
    },
    imgWidth: {
      type: String,
      default: function () {
        return this.list ? '65px' : '100%'
      },
    },
    imgHeight: {
      type: String,
      default: function () {
        return this.list ? 'auto' : '100%'
      },
    },
  },
  data() {
    return {
      iconTypes: [
        'pdf',
        'ppt',
        'pptx',
        'xls',
        'xlsx'
      ],
    }
  },
  computed: {
    //Styles
    style: function() {
      return {
        '--icon-width': this.iconWidth,
        '--icon-height': this.iconHeight,
        '--icon-padding': this.iconPadding,
        '--img-width': this.imgWidth,
        '--img-height': this.imgHeight
      }
    },
    
    //Class
    InputFileIconClass() {
      return [
        this.showImageName ? 'InputFile__Uploaded--image-name' : ''
      ]
    },

    iconType() {
      const prefix = 'file-'
      const icon = this.iconTypes.includes(this.file.ext) ? this.file.ext : 'default'
      return prefix + icon
    },

    isImageType() {
      return this.file.type === 'image' || this.file.mimetype === 'image/png' || this.file.mimetype === 'image/jpeg'
    }
  },
  methods: {

    handleVisualize() {
      this.$emit('visualize', this.file)
    },

    confirmRemove() {
      this.$emit('confirmRemove', this.file)
    },

    handleRemove() {
      this.$emit('remove', this.file)
    },
  }
}
</script>
<style lang="scss" scoped>
.InputFile {
  &__Uploaded {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--icon-width) !important;
    max-width: var(--icon-height);
    height: var(--icon-height);
    position: relative;
    padding: var(--icon-padding);

    &--image-name {
      .InputFile__Actions {
        background-color: rgba($color-primary-stronger, $opacity-base);
      }
    }

    .Icon {
      height: 100%;
      width: auto;
    }
  }

  &__ImageBox {
    width: var(--img-width);
    max-width: var(--img-width);
    height: var(--icon-height);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #808080;
  }

  &__Image {
    position: relative;
    width: auto;
    max-width: var(--img-width);
    height: var(--img-height);
    border: 1px solid #808080;
  }

  &__Actions {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-out;

    &:hover {
      .InputFile__Actions--buttons {
        opacity: 1;
      }
      .InputFile__Actions--text {
        opacity: 0;
      }
    }

    &--buttons, &--text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 10px;
      transition: all 0.2s ease-out;
    }

    &--buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      opacity: 0;
    }

    &--text {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      opacity: 1;

      p {
        color: $color-neutral-softest;
      }
    }

    .Icon {
      width: 35px !important;
      cursor: pointer;
      margin: 0 10px;
    }
  }
}
</style>