import state from './state.root'
import getters from './getters.root'
import mutations from './mutations.root'
import actions from './actions.root'

export default {
  state,
  getters,
  mutations,
  actions
}