import Vue from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'
import VueBarcodeScanner from 'vue-barcode-scanner'
import VTooltip from 'v-tooltip'
import router from './plugins/router/'
import store from './store'
import maskFilters from '@/plugins/mask/maskFilters.js'
import VueIntercom from '@mathieustan/vue-intercom'
import GoogleAuth from '@/plugins/google_oAuth'
import VScrollLock from 'v-scroll-lock'
import Echo from 'laravel-echo'
import VueMixpanel from 'vue-mixpanel'
import helpers from '@/mixins/helpers'
import Toast from 'vue-toastification'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'


// Photons
import { Icon } from '@/components/photons'

// Create Masks
maskFilters.noSpecialCharacters()
maskFilters.setFilters()

// Global Components
Vue.component('Icon', Icon)
Vue.use(VueBarcodeScanner)
Vue.use(VTooltip)
Vue.use(VScrollLock)
Vue.use(Toast, {
  timeout: 6000,
  position: 'bottom-right',
  transitionDuration: 500,
  transition: {
    enter: 'Vue-Toastification__fade-enter-active',
    leave: 'Vue-Toastification__fade-leave-active',
    move: 'Vue-Toastification__fade-move'
  },
  hideProgressBar: true,
  closeButton: false
})

// VueGtag
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
  }
}, router)

// Sentry
Sentry.init({
  Vue,
  environment: process.env.VUE_APP_MODE,
  release: process.env.VUE_APP_VERSION,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    })
  ],
  // debug: process.env.VUE_APP_MODE !== 'production',
  debug: false,
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true
  },
  logErrors: process.env.VUE_APP_MODE === 'production' ? false : true,
  attachProps: true,
  attachStacktrace: true

})

// Intercom
Vue.use(VueIntercom, {
  appId: process.env.VUE_APP_INTERCOM_CHAT,
  'olist-products': 'olist-pax'
})

// GoogleAuth
const gauthOption = {
  clientId: '338742798358-4mh6gk71aefng34eer1kh4fsfpgoo30s.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)

// Laravel Echo
window.io = require('socket.io-client')
const echoOptions = {
  broadcaster: 'socket.io',
  key: process.env.VUE_APP_MSOCKET_KEY,
  appId: process.env.VUE_APP_MSOCKET_APP_ID,
  host: process.env.VUE_APP_MSOCKET_HOST,
  forceTLS: process.env.VUE_APP_MSOCKET_FORCE_TLS,
  cluster: process.env.VUE_APP_MSOCKET_CLUSTER,
  port: 6001
}
const echo = new Echo(echoOptions)
Vue.prototype.$echo = echo
window.echo = echo

// Mixpanel
Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_ID,
  config: {
    debug: process.env.VUE_APP_MODE === 'local'
  }
})
Vue.mixin(helpers)

// Init Vue
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')