import permissions from '@/store/modules/auth/state.auth.js'

export default {
  readyDrivers(state) {    
    return { ...state.drivers,
      data: { 
        ...state.drivers.data, 
        headers: {
          ...state.drivers.data.headers,
          actions: false
        }
      } 
    }
  },

  mappedTracking(state){
    return state.driversTracking.data ? state.driversTracking.data.map((driver) => {
      return {
        info: {
          driverId: driver.id,
          driver: driver.name,
          plate: driver.vehicle ? driver.vehicle.plate : '',
          phone: driver.phone ? driver.phone.toString() : '',
          vehicle_type: driver.vehicle && driver.vehicle.model ? driver.vehicle.model.type.name : ''
        },
        position:{
          lat: driver.tracking ? driver.tracking.coordinate.lat : '',
          lng: driver.tracking ? driver.tracking.coordinate.lng : ''
        },
      }
    }) : []
  },

  drivers(state) {
    return {
      ...state.drivers,
      data: {
        ...state.drivers.data,
        content: state.drivers.data.content.map((item) => {
          item.actions.map((action) => {
            let unlinkVehiclePermission = permissions.permissions?.drivers?.unlinkVehicle
            if (action.action_id === 'unlinkVehicle' && !unlinkVehiclePermission) {
              action.has_permission = false
            }
          })
          return item
        })
      }
    }
  },
}