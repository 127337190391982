export default {
  SET_OCCURRENCES_REAL_TIME(state, occurrencesRealTime) {
    state.occurrencesRealTime = occurrencesRealTime
  },
  SET_OCCURRENCES_HISTORY(state, occurrencesHistory) {
    state.occurrencesHistory = occurrencesHistory
  },

  SET_OCCURRENCES_STATUS_LIST(state, occurrencesStatusList) {
    state.occurrencesStatusList = occurrencesStatusList?.data || []
  },
  SET_STANDARD_ATTENDANCE_TIME(state, standardAttendanceTime) {
    state.occurrencesConfig.standardAttendanceTime = new Date().setHours(0, 0, standardAttendanceTime)
  },

  SET_OCCURENCES_CONFIG(state, storesConfig) {
    state.occurrencesConfig.storesConfig = storesConfig
  },

  SET_OCCURENCES_CONFIG_TABLE_CONTENT(state, occurrencesConfigData) {
    state.occurrencesConfig.storesConfigTable.content = occurrencesConfigData
  },

  REMOVE_OCCURENCES_CONFIG_TABLE_ITEM(state, storeId) {
    const newContent = state.occurrencesConfig.storesConfigTable.content.filter(({ id }) => id !== storeId)
    state.occurrencesConfig.storesConfigTable.content = [...newContent]
  },

  CLEAR_OCCURENCES_CONFIG_TABLE_CONTENT(state) {
    state.occurrencesConfig.storesConfigTable.content = []
  },
}