export default {
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_PAGINATION(state, payload) {
    state.next_page_url = payload.next_page_url
    state.prev_page_url = payload.prev_page_url
  },
  SET_PAGINATE(state, payload) {
    state.paginate = payload
  },
  SET_LENGTH_ITEMS(state, payload) {
    state.lengthItems = payload
  },
  CLEAR_PAGE(state,) {
    state.page = 1
  },
  CLEAR_PAGINATE(state, payload) {
    state.paginate = payload ? payload : 10
  }
}