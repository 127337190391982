export default {
  stores: [],
  routEasyStores: [],
  hubs: [],
  storesImport: [],
  storesToImport: [],
  storesTable:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  storesParents: [],
  storesWithInfo: [],
  storesTransporters:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  storeHolidays: []
}