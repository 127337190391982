<template>
  <img
    class="Icon"
    :alt="name"
    :class="iconClasses"
    v-bind="$attrs"
    :src="src"
    @click="$emit('click')"
  >
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'black',
      validator: val => ['yellow', 'primary-base', 'red', 'green', 'white', 'light', 'dark', 'black', 'blue'].includes(val)
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    src() {
      return require(`@/assets/icons/${this.name}.svg`)
    },
    iconClasses() {
      return [
        `Icon--${this.color}`,
        `Icon--${this.name}`,
        this.disabled ? 'Icon--disabled' : ''
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.Icon {
  //Status
  &--disabled {
    // opacity: 0.1;
    cursor: not-allowed !important;
    pointer-events: none;
  }

  //Colors
  &--yellow {
    filter: $filter-yellow;
  }
  &--primary-base {
    filter: $filter-primary-base;
  }
  &--red {
    filter: $filter-red;
  }
  &--green {
    filter: $filter-green;
  }
  &--white {
    filter: $filter-white;
  }
  &--dark {
    filter: $filter-dark;
  }
  &--light {
    filter: $filter-light;
  }
  &--blue {
    filter: $filter-blue;
  }
  &--loading {
    animation: rotating 0.75s linear infinite;
    cursor: default !important;
  }
}
</style>
