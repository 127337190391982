import { packages } from '@/api'

export default {

  async fetchPackages({ commit }, params = { paginate: 20, page: 1 }) {
    this.dispatch('loading/setLoading', true)
    return await packages.fetchPackages(params, (res) => {
      commit('SET_PACKAGES', res)
    }, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async clearPackages({commit}) {
    commit('CLEAR_PACKAGES')
  },

  async fetchIncomingPackages({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await packages.fetchIncomingPackages(params, (res) => {
      commit('SET_INCOMING_PACKAGES', res)
      return res
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  addIncomingPackages({ commit }, res) {
    commit('SET_INCOMING_PACKAGES', res)
  },

  async fetchOrdersWithConfirmedPackages({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await packages.fetchOrdersWithConfirmedPackages(params, (res) => {
      commit('SET_ORDERS_WITH_CONFIRMED_PACKAGES', res)
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async fetchPackagesReportsRequested({ commit }, params = {paginate:20, page:1}) {
    // this.dispatch('loading/setLoading', true)
    return await packages.fetchPackagesReportsRequested(params, (res) =>
      commit('SET_PACKAGES_REPORTS_REQUESTED', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchCachedPackagesByDriver({commit}, id) {
    this.dispatch('loading/setLoading', true)
    return await packages.fetchCachedPackagesByDriver(id, (res) =>
      commit('SET_CACHED_PACKAGES', res), (e) => e,
    () => this.dispatch('loading/setLoading', false))
  },

  resetCachedPackages({commit}) {
    commit('RESET_CACHED_PACKAGES')
  },

  addCachedPackages({commit}, data) {
    commit('ADD_CACHED_PACKAGES', data)
  },

  removeIncomingPackages({ commit }, codes) {
    this.dispatch('loading/setLoading', true)
    commit('REMOVE_INCOMING_PACKAGES', codes)
    this.dispatch('loading/setLoading', false)
  },

  removeIncomingPackagesWithStores({ commit }, codes) {
    this.dispatch('loading/setLoading', true)
    commit('REMOVE_INCOMING_PACKAGES_WITH_STORES', codes)
    this.dispatch('loading/setLoading', false)
  },

  resetIncomingPackages({commit}) {
    commit('RESET_INCOMING_PACKAGES')
  },

  async fetchPackagesHistory({ commit }, params = {paginate:10, page:1}) {
    this.dispatch('loading/setLoading', true)

    return await packages.fetchPackagesHistory(params, (res) => {
      commit('SET_PACKAGES_HISTORY', res)
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async fetchPackagesReceived({ commit }, params = {paginate:10, page:1}) {
    this.dispatch('loading/setLoading', true)

    return await packages.fetchPackagesReceived(params, (res) => {
      commit('SET_PACKAGES_RECEIVED', res)
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async fetchPackagesReceivedDetail({ commit }, params = {paginate:10, page:1}) {
    this.dispatch('loading/setLoading', true)

    return await packages.fetchPackagesReceivedDetail(params, (res) => {
      commit('SET_PACKAGES_RECEIVED_DETAIL', res)
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async removePackage({ commit }, id) {
    this.dispatch('loading/setLoading', true)
    return await packages.removePackage(id, () => commit('REMOVE_PACKAGE', id), (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async removePackages({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await packages.removePackages(params,
      () => commit('REMOVE_PACKAGES', packages),
      (e) => e, () => this.dispatch('loading/setLoading', false)
    )
  },

  async fetchModalMissingPackages({ commit }, params) {
    this.dispatch('loading/setLoading', true)

    return await packages.fetchMissingPackages(params,
      (res) => {
        commit('SET_MODAL_MISSING_DATA', res.data)
        if(res.data?.content?.length) commit('SET_SHOW_MODAL_MISSING', true)
      },
      (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  setModalMissingDriver({ commit }, name) {
    commit('SET_MODAL_MISSING_DRIVER', name)
  },

  showModalMissing({ commit }, show) {
    commit('SET_SHOW_MODAL_MISSING', show)
  },

  clearModalMissingData({ commit }) {
    commit('CLEAR_MODAL_MISSING_DATA')
  },
}