import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchPushHistory(params, success, error, done) {
    const query = objToQuery.parseAll(params.obj, 'id')
    return await request({
      method: 'get',
      url: `schedule?is_table=schedules&page=${params.page}&paginate=${params.paginate}${query ? '&' + query : ''}&sort=desc${params.obj['name_schedule[value]'] !== '' ? '&name_schedule[signal]=like' : ''}`
    }, success, error, done)
  },
  
  async getPush({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `schedule/${id}?with[]=users&with[]=stores`,
    }, success, error, done)
  },

  async createPush(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'schedule',
      body
    }, success, error, done)
  },

  async updatePush(body, success, error, done) {
    const id = body.id
    return await request({
      method: 'post',
      url: `schedule/${id}`,
      body
    }, success, error, done)
  },

  async deletePush({ id }, success, error, done) {
    return await request({
      method: 'delete',
      url: `schedule/${id}`
    }, success, error, done)
  },
}