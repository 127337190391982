const Drivers = () => import( /* webpackChunkName: 'Drivers' */ './Drivers.vue')
const DriversForm = () => import( /* webpackChunkName: 'DriversForm' */ './Form/DriversForm.vue')
const DriversExport = () => import( /* webpackChunkName: 'DriversExport' */ './Export/DriversExport.vue')

const routes = [
  {
    name: 'drivers',
    meta:{
      label: 'Novo',
      module: 'module_drivers'
    },
    path: '/drivers',
    component: Drivers
  },
  {
    name: 'driversAdd',
    meta:{
      label: 'Motorista',
      module: 'module_drivers'
    },
    path: '/drivers/add',
    component: DriversForm
  },
  {
    name: 'driversExport',
    meta:{
      label: 'Motorista',
      module: 'module_drivers'
    },
    path: '/drivers/export',
    component: DriversExport
  },
  {
    name: 'driversDetail',
    meta:{
      label: 'Motorista',
      module: 'module_drivers'
    },
    path: '/drivers/:id',
    component: DriversForm
  }
]

export default routes