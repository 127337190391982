import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'

export default {

  async fetchOcurrencesRealTime({ obj, paginate, page }, success, error, done) {
    let query = objToQuery.parseAll(obj, 'id')
    query = query ? `&${query}` : ''

    return await request({
      method: 'get',
      url: `occurrences?is_table=occurrences&paginate=${paginate}&page=${page}${query}`
    }, success, error, done)
  },

  async fetchOcurrencesHistory({ obj, paginate, page }, success, error, done) {
    let query = objToQuery.parseAll(obj, 'id')
    query = query ? `&${query}` : ''
    
    return await request({
      method: 'get',
      url: `occurrences/history?is_table=occurrences_history&paginate=${paginate}&page=${page}${query}`
    }, success, error, done)
  },

  async fetchOcurrencesStatusList(is_finisher, success, error, done) {
    return await request({
      method: 'get',
      url: `occurrences/status?is_finisher=${is_finisher}`
    }, success, error, done)
  },

  async finishOccurrence(id, body, success, error, done) {
    return await request({
      method: 'patch',
      url: `occurrences/${id}/finish`,
      body
    }, success, error, done)
  },

  async linkOccurrence(id, body, success, error, done) {
    return await request({
      method: 'patch',
      body,
      url: `occurrences/${id}/link-unlink-responsible`,
    }, success, error, done)
  },

  async fetchStandardAttendanceTime(success, error, done) {
    return await request({
      method: 'get',
      url: 'occurrences/configuration'
    }, success, error, done)
  },

  async fetchOccurrencesConfig(success, error, done) {
    return await request({
      method: 'get',
      url: 'occurrences/stores'
    }, success, error, done)
  },

  async saveOccurrencesConfig(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'occurrences/stores/recreate',
      body
    }, success, error, done)
  },
}