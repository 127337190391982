import request from '@/api/plugins/request'

export default {
  async signIn(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'auth/login',
      headers: {
        'origin-application' : 'dashboard'
      },
      body,
    }, success, error, done)
  },

  async signInGoogle(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'auth/google',
      headers: {
        'origin-application' : 'dashboard'
      },
      body,
    }, success, error, done)
  },

  async passwordRecover(mail, success, error, done){
    return await request({
      method: 'get',
      url: `password-recover/${mail}`,
    }, success, error, done)
  },

  async resetPassword(body, success, error, done){
    return await request({
      method: 'post',
      url: 'password-recover',
      body
    }, success, error, done)
  },

  async permissionsByModules(module, success, error, done) {
    return await request({
      method: 'get',
      url: `acl/user/permissions/${module}`
    }, success, error, done)
  },

  async modulesAndRoleByUser(success, error, done) {
    return await request({
      method: 'get',
      url: 'acl/user/modules-and-role'
    }, success, error, done)
  }
}