const cteStatus = {
  PENDENTE: 'pendente',
  ERRO_REQUISICAO: 'erro_requisicao',
  AUTORIZADO: 'autorizado',
  ERRO_AUTORIZACAO: 'erro_autorizacao',
  PROCESSANDO: 'processando_autorizacao',
  CANCELADO: 'cancelado',
  NEGADO: 'denegado',
}

const routeStatus = {
  CONCLUIDO: 'Concluído',
  CONCLUIDO_COM_ERROS: 'Concluído com erros',
  EMITIR: 'A Emitir',
  PROCESSANDO: 'Processando',
  NAO_APLICA: 'Não se aplica',
}

const getStatus = (status, predicate) => {
  let result
  Object.entries(eval(predicate)).forEach(([key, value]) => {
    if(status === value) result = key
  })
  return result
}

const populateForm = (res, form, initial) => {
  
  for (let field in form) {
    if (res[field]) form[field] = res[field]
    if (res[field]) initial[field] = res[field]
  }

  return form
}

export {
  routeStatus,
  cteStatus,
  getStatus,
  populateForm
}