import root from './root'
import address from './address'
import auth from './auth'
import drivers from './drivers'
import error from './error'
import loading from './loading'
import notification from './notification'
import orders from './orders'
import queryString from './queryString'
import pagination from './pagination'
import routes from './routes'
import stores from './stores'
import sellers from './sellers'
import tracking from './tracking'
import transporters from './transporters'
import userAgent from './userAgent'
import users from './users'
import financial from './financial'
import vehicles from './vehicles'
import packages from './packages'
import printer from './printer'
import controlTower from './controlTower'
import file from './file'
import groups from './groups'
import dropoff from './dropoff'

export default {
  root,
  address,
  auth,
  drivers,
  error,
  loading,
  notification,
  orders,
  queryString,
  pagination,
  routes,
  stores,
  sellers,
  tracking,
  transporters,
  userAgent,
  users,
  financial,
  vehicles,
  packages,
  printer,
  file,
  groups,
  dropoff,
  controlTower
}