import request from '@/api/plugins/request'
import download from '@/api/plugins/download'
import objToQuery from '@/api/plugins/query'

export default {

  //#region Orders
  async fetchOrders({obj, driver_id, client_id, paginate, page }, success, error, done) {

    const query = objToQuery.parseAll(obj, 'id')
    const driverId = driver_id ? `&driver_id[]=${driver_id}` : ''
    const clientId = client_id ? `&filter[estimatedReceiver][name]=${encodeURIComponent(client_id)}&like=true` : ''

    return await request({
      method: 'get',
      url: `orders?is_table=orders&paginate=${paginate}&page=${page}${driverId}${clientId}&${query}&sort=desc`
    }, success, error, done)
  },

  async getStatus({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `store-status?store_id=${id}&paginate=999`
    }, success, error, done)
  },

  async createNewOrderPaymentLink({ id }, success, error, done) {
    return await request({
      method: 'post',
      url: `payment-new-link/${id}`
    }, success, error, done)
  },

  async submitOrderLocationLock(body, success, error, done) {
    let data = {
      check_location: body.check_location ? 1 : 0
    }
    return await request({
      method: 'put',
      url: `orders/${body.id}/location`,
      body: data
    }, success, error, done)
  },

  async downloadCollectCodes(id, success, error, done) {
    return await download({
      method: 'get',
      url: `orders/codes/${id}`
    }, success, error, done)
  },

  async downloadNfe(order_number, success, error, done) {
    return await download({
      method: 'get',
      url: `orders/${order_number}/invoice/pdf`
    }, success, error, done)
  },

  async getZPLCode(id, success, error, done) {
    return await request({
      method: 'get',
      url: `orders/${id}/label-zpl`,
    }, success, error, done)
  },

  //#endregion

  //#region Orders Form
  async fetchOrder({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `orders/${id}?with[]=estimatedReceiver&with[]=receiver&with[]=products&with[]=lastStatusHistory.status.type&with[]=info.type`
      + '&with[]=info.client.documents&with[]=store.fare&with[]=lastPagarMe&append[]=deliveryWindowInit&append[]=deliveryWindowEnd'
      + '&with[]=schedule&with[]=phones&with[]=dimension&with[]=invoice'
    }, success, error, done)
  },

  async fetchOccurrenceOrderDetail({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `orders/${id}?with[]=estimatedReceiver&with[]=invoice`
      + '&with[]=driver.vehicle&with[]=route.route&with[]=lastStatusHistory.status'
    }, success, error, done)
  },

  async fetchProductsSizes(success, error, done){
    return await request({
      method: 'get',
      url: 'products-sizes',
    }, success, error, done)
  },

  async createOrders(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'orders',
      body
    }, success, error, done)
  },

  async updateOrders(id, body, success, error, done) {
    return await request({
      method: 'put',
      url: `orders/update-delivery-window/${id}`,
      body
    }, success, error, done)
  },


  async deleteOrder({ id }, success, error, done) {
    return await request({
      method: 'delete',
      url: `orders/${id}`
    }, success, error, done)
  },

  async cancelOrder({ order_id, store_id }, success, error, done) {
    return await request({
      method: 'post',
      url: 'orders-cancel',
      body: { order_id, store_id }
    }, success, error, done)
  },

  async fetchOrderPackages(id, success, error, done){
    return await request({
      method: 'get',
      url: `orders/${id}/packages`,
    }, success, error, done)
  },

  //#endregion

  //#region Orders Incoming
  async confirmIncomingOrders(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'orders_incoming',
      body
    }, success, error, done)
  },

  async fetchIncomingOrdersTypes(success, error, done) {
    return await request({
      method: 'get',
      url: 'search_incoming_orders'
    }, success, error, done)
  },

  //#endregion

  //#region Change Status
  async getStatusOnly({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `store-status-change-status/${id}`
    }, success, error, done)
  },

  async fetchImportStatusOrders({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj)
    return await request({
      method: 'get',
      url: `import-orders-status?is_table=import_order_status&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchImportStatusErrors(id, success, error, done) {
    return await request({
      method: 'get',
      url: `import-orders-status/${id}`
    }, success, error, done)
  },

  async importStatusOrders(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'import-orders-status',
      body
    }, success, error, done)
  },

  async importedStatusOrders(id, success, error, done) {
    return await download({
      method: 'get',
      url: `imported-orders-status-download/${id}`
    }, success, error, done)
  },

  //#endregion

  //#region Orders Export
  async fetchReportsRequested({ paginate, page }, success, error, done) {
    return await request({
      method: 'get',
      url: `reports-requested?is_table=reports&paginate=${paginate}&page=${page}&sort=desc`
    }, success, error, done)
  },

  async exportReport(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'reports-requested',
      body
    }, success, error, done)
  },

  async downloadReport(id, success, error, done) {
    return await download({
      method: 'get',
      url: `reports-requested/${id}`
    }, success, error, done)
  },

  //#endregion

  //#region Orders Import
  async fetchImportOrders({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj)

    return await request({
      method: 'get',
      url: `orders-import?is_table=orders_import&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchImportErrors(id, success, error, done) {
    return await request({
      method: 'get',
      url: `orders-import-errors/${id}`
    }, success, error, done)
  },

  async importOrders(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'orders-import',
      body
    }, success, error, done)
  },

  async importedOrders(id, success, error, done) {
    return await download({
      method: 'get',
      url: `orders-imported/${id}`
    }, success, error, done)
  },

  //#endregion

  async fetchDeliveryFailures({ obj, paginate, page, sort }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `order-delivery-occurrences?paginate=${paginate}&page=${page}&${query}&sort=${sort}`
    }, success, error, done)
  },

  //#region Error List
  async correctImportOrder({id, body}, success, error, done) {
    return await request({
      method: 'post',
      url: `orders-import-errors/${id}`,
      body
    }, success, error, done)
  },
  //#endregion

  //#region Financial
  async fetchImportStatusList(success, error, done){
    return await request({
      method: 'get',
      url: 'orders-status',
    }, success, error, done)
  },
  //#endregion

}