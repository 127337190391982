<template functional>
  <transition name="TheLoading--anim">
    <div v-if="props.show" class="TheLoading">
      <div class="TheLoading__wrapper">
        <div class="TheLoading__circle" />
        <div class="TheLoading__circle" />
        <div class="TheLoading__circle" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TheLoading',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.TheLoading {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color-primary-stronger, $opacity-strong);
  z-index: $z-loading;
  position: fixed;

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-loading;
  }

  &__circle {
    animation: 1.4s ease-in-out 0s infinite normal both running sk-bouncedelay;
    background-color: $color-white;
    border-radius: 100%;
    display: inline-block;
    height: 10px;
    margin: 0px 4px;
    width: 10px;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  &--anim {
    &-enter-active {
      transition: all .2s ease-out;
    }
    &-leave-active {
      transition: all .2s ease-out;
    }
    &-enter {
      opacity: 0;
    }
    &-leave-to {
      opacity: 0;
    }
  }
}
</style>
