import ctemanagement from './ctemanagement/mutations.cte'

export default {
  SET_ROUTES(state, routes){
    state.routes = routes
  },

  SET_ROUTE_HISTORY(state, playload){
    state.routeHistory = playload
  },

  SET_ROUTE_ERRORS(state, playload){
    state.routeErrors = playload
  },

  SET_TRANSITPOINT_IMPORT(state, transitPointImport) {
    state.transitPointImport = transitPointImport
  },


  SET_ROUTES_STORES(state, routesStores){
    state.routesStores = routesStores
  },

  CLEAR_ROUTES_STORES(state){
    state.routesStores.data.content = []
  },

  DELETE_ROUTE(state, routeId){
    state.routes.data.content = state.routes.data.content.filter((route) => route.id !== routeId)
  },

  DELETE_ROUTES_STORES(state, routeId){
    state.routesStores.data.content = state.routesStores.data.content.filter((route) => route.id !== routeId)
  },

  UPDATE_ROUTES_DRIVERS(state, routeId){
    state.routes.data.content.map((route) => {
      if (route.id === routeId) {
        route.orders_count = state.routesStores.data.content.length
      }
      return route
    })
  },

  UPDATE_ROUTES_COUNT(state, {routeId, driverName}){
    state.routes.data.content.map((route) =>{
      if(route.id === routeId){
        route['driver.name'] = driverName
        route['warning'] = !!driverName
        route.actions = route.actions.map((action) =>{
          if (action.action_id === 'upload') {
            action.has_permission = true
          }
          return action
        })
      }
      return route
    })
  },

  SET_ORDERS_WITHOUT_ROUTES(state, ordersWithoutRoutes){
    state.ordersWithoutRoutes = ordersWithoutRoutes
  },

  SET_ORDERS_WITHOUT_ROUTES_TRANSFER(state, ordersWithoutRoutesTransfer){
    state.ordersWithoutRoutesTransfer = ordersWithoutRoutesTransfer
  },

  SET_ROUTES_NAME_BY_STORE(state, payload){
    state.routesNameByStore = payload
  },

  UPDATE_ROUTE_ROMANEIO(state, {id}){
    state.routes.data.content.map((route) =>{
      if(route.id === id){
        route.romaneio = 'Enviado'
        route.actions = route.actions.map((action) =>{
          if (action.action_id === 'upload') {
            action.has_permission = false
          }
          return action
        })
      }
      return route
    })
    
  },

  SET_TRANSITPOINTS(state, transitPoints){
    state.transitPoints = transitPoints
  },

  DELETE_TRANSITPOINT(state, transitPointId){
    state.transitPoints.data.content = state.transitPoints.data.content.filter((route) => route.id !== transitPointId)
  },


  //CTe Managements
  ...ctemanagement

}
