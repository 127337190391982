export default [
  {
    id: 0,
    class: 'Pagination__Arrow',
    click: 'next',
    icon: 'chevron-right-alt',
    lengthIcons: 1,
  },
  // {
  //   id: 1,
  //   class: 'Pagination__Arrow',
  //   click: 'last',
  //   icon: 'chevron-right-alt',
  //   lengthIcons: 2,
  // }
]