export default {
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_ERROR_ADDITIONAL(state, errorAdditional) {
    state.errorAdditional = errorAdditional
  },
  SET_ALERT(state, alert) {
    state.alert = alert
  }
}
