import request from '@/api/plugins/request'

export default {
  async searchStores({ search, routeName }, success, error) {
    return await request({
      method: 'get',
      url: `search/stores?search[]=${search}&routeName=${routeName}`
    }, success, error)
  },

  async searchSellers({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/sellers?search[]=${encodeURIComponent(search)}`
    }, success, error)
  },

  async searchDrivers({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/drivers?search[]=${search}`
    }, success, error)
  },

  async searchDriversWithoutVehicle({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/driversLink?search[]=${search}`
    }, success, error)
  },

  async searchDriversTransporterNot({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/drivers?search[]=${search}&transporter_not=true`
    }, success, error)
  },

  async searchDriversWithStores({params, search }, success, error) {
    return await request({
      method: 'get',
      url: `search/drivers?store_id=${params}&search[]=${search}&transporter_in[]&vehiclesIn[]`
    }, success, error)
  },

  async searchDriversWithTransitPoint({params, search }, success, error) {
    return await request({
      method: 'get',
      url: `search/drivers?transit_point_id=${params}&search[]=${search}&transporter_in[]&vehiclesIn[]`
    }, success, error)
  },

  async searchVehicleBrand({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/vehicles/brands?search[]=${search}`
    }, success, error)
  },

  async searchVehicleModel({ params, search }, success, error) {
    const brandId = params
    return await request({
      method: 'get',
      url: `search/vehicles/models/${brandId}?search[]=${search}`
    }, success, error)
  },

  async searchTransporters({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/transporters?search[]=${search}`
    }, success, error)
  },

  async searchTransportersByStore({ params, search }, success, error) {
    const storeId = params
    return await request({
      method: 'get',
      url: `search/transporters/${storeId}?search[]=${search}`
    }, success, error)
  },

  async searchDriversUnbindedToTransporters({ params, search }, success, error) {
    const transporterId = params
    return await request({
      method: 'get',
      url: `search/drivers?transporter_id=${transporterId}&search[]=${search}`
    }, success, error)
  },

  async searchUsers({ params, search }, success, error) {
    return await request({
      method: 'get',
      url: `search/users?search[]=${search}${params ? `&${params}` : ''}`
    }, success, error)
  },

  async searchUsersWithGroups({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/users?search[]=${search}&groupname=1`
    }, success, error)
  },

  async searchBanks({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/banks?search[]=${search}`
    }, success, error)
  },

  async searchRoutes({ search }, success, error) {
    return await request({
      method: 'get',
      url: `search/routes?search[]=${search}`
    }, success, error)
  },

  async searchVehiclePlates({ search }, success, error) {
    const plateNoHyphen = search.replaceAll('-', '')
    return await request({
      method: 'get',
      url: `search/plate?search[]=${plateNoHyphen}`
    }, success, error)
  }
}