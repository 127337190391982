<template>
  <div class="BaseCarousel" :class="componentClasses">
    <div class="BaseCarousel__ImageContainer" :class="list.length > 1 ? 'BaseCarousel__ImageContainer--grid' : ''">
      <div v-if="title" class="BaseCarousel__Title">
        {{ title }}
      </div>
      <div ref="image" class="BaseCarousel__Image">
        <div class="BaseCarousel__ImageWraper" :style="BaseCarouselMoving">
          <img v-for="(item, i) in list" :key="i" :src="item" class="BaseCarousel__ImageItem">
        </div>
      </div>
      <Icon
        v-if="list.length > 1 && arrows"
        name="chevron-left-alt"
        class="BaseCarousel__Arrow BaseCarousel__Arrow--Left"
        :class="previusStatus"
        @click="previus"
      />
      <Icon
        v-if="list.length > 1 && arrows"
        name="chevron-right-alt"
        class="BaseCarousel__Arrow BaseCarousel__Arrow--Right"
        :class="nextStatus"
        @click="next"
      />
      <div v-if="list.length > 1 && pagination" class="BaseCarousel__Pagination">
        {{ index + 1 }} / {{ list.length }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCarousel',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    arrows: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    page:{
      type: Number,
      default: 0
    },
    theme:{
      type: String,
      default: 'dark',
      validator: val => ['light', 'dark'].includes(val)
    }
  },
  data() {
    return {
      index: this.page,
      move: 0
    }
  },
  computed: {
    componentClasses(){
      return [`BaseCarousel--${this.theme}`]
    },

    previusStatus() {
      return this.index <= 0 ? 'BaseCarousel--disable' : 'BaseCarousel--able'
    },

    nextStatus() {
      return this.index >= this.list.length - 1
        ? 'BaseCarousel--disable'
        : 'BaseCarousel--able'
    },

    BaseCarouselMoving(){
      return {transform: 'translateX(' + this.move + 'px)', gridTemplateColumns: 'repeat(' + this.list.length + ', 100%)'}
    }
  },

  watch:{
    index(val){
      const infos = this.$refs.image.getBoundingClientRect()
      const move = (infos.width * val) * -1
      this.move = move
    }
  },

  mounted(){
    window.addEventListener('resize', this.onResize)
  },

  destroyed(){
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize(){
      const infos = this.$refs.image.getBoundingClientRect()
      const move = (infos.width * this.index) * -1
      this.move = move
    },

    previus() {
      this.index--
    },

    next() {
      this.index++
    }
  }
}
</script>

<style lang="scss" scoped>
.BaseCarousel {
  position: relative;
  min-height: 300px;
  &__ImageContainer {
    height: 100%;
    align-items: center;

    &--grid {
      display: grid;
    }
  }
  &__Title {
    grid-area: 1 / 2 / 1 / 2;
    font-size: $font-size-2xlarge;
  }
  &__Image {
    height: 100%;
    grid-area: 1 / 2 / 1 / 2;
    overflow: hidden;
    position: relative;
    
  }
  &__ImageWraper{
    position: relative;
    display: flex;
    transition: transform 300ms;
    height: 100%;
  }
  &__ImageItem{
    display: block;
    object-fit: contain;
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__Arrow {
    width: 30px;
    cursor: pointer;
    &--Left {
      margin-right: 15px;
      grid-area: 1 / 1 / 1 / 1;
    }
    &--Right {
      margin-left: 15px;
      grid-area: 1 / 3 / 1 / 3;
    }
  }
  &__Pagination {
    grid-area: 2 / 2 / 2 / 2;
    justify-self: center;
  }

  &--hide {
    display: none;
  }

  &--show {
    display: flex;
  }

  &--able {
    opacity: 1;
  }

  &--disable {
    opacity: 0.5;
    pointer-events: none;
  }
}

//Themes
.BaseCarousel{
  &--dark{
    .BaseCarousel__Title{
      color: $color-neutral-stronger;
    }
    .BaseCarousel__Arrow{
      filter: $filter-black;
      &:hover {
        filter: $filter-primary-base;
      }
    }
    .BaseCarousel__Pagination{
      color: $color-neutral-stronger;
    }
  }

  &--light{
    .BaseCarousel__Title{
      color: $color-neutral-softest;
    }
    .BaseCarousel__Arrow{
      filter: $filter-white;
      &:hover {
        filter: $filter-primary-base;
      }
    }
    .BaseCarousel__Pagination{
      color: $color-neutral-softest;
    }
  }
}
</style>
