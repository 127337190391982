<template>
  <div class="BaseToggle">
    <span class="BaseToggle__Disable">{{ labelDisable }} </span>
    <div class="BaseToggle__Toggle">
      <input :id="`toggle-${id}`" class="BaseToggle__Input" type="checkbox" 
             :checked="value" 
             @change="onInput($event)"
      >
      <label class="BaseToggle__Btn" :for="`toggle-${id}`" />
    </div>
    <span class="BaseToggle__Able">{{ labelAble }} </span>
  </div>
</template>

<script>
export default {
  name: 'BaseToggle',
  props: {
    id:{
      type: [String, Number],
      required: true
    },
    value: {
      type: [Boolean, Number],
      default: 0
    },
    labelAble: {
      type: String,
      default: ''
    },
    labelDisable: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
$toggleSpace: 7px;

.BaseToggle{
  display: flex;
  align-items: center;

  &__Disable, &__Able{
    font-size: $font-size-1xmini;
  }

  &__Disable{
    margin-right: $toggleSpace;
  }

  &__Able{
    margin-left: $toggleSpace;
  }

  &__Input{
    display: none;
    + .BaseToggle__Btn {
      outline: 0;
      display: block;
      width: 2em;
      height: 1em;
      position: relative;
      cursor: pointer;
      user-select: none;
      background: $color-neutral-strong;
      border-radius: 2em;
      padding: 2px;
      transition: background .4s ease;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }
      
      &:after {
        left: 0;
        border-radius: 50%;
        background: $color-neutral-softest;
        transition: left .2s ease
      }
      
      &:before {
        display: none;
      }
    }

    &:checked + .BaseToggle__Btn:after {
      left: 50%;
    }

    &:checked + .BaseToggle__Btn{
      background: $color-primary-base;
    }
  }
}
</style>