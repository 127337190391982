import state from './state.packages'
import getters from './getters.packages'
import mutations from './mutations.packages'
import actions from './actions.packages'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}