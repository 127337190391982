<template>
  <Modal v-if="modal" class="InputFile__Modal" @close="close">
    <template #header>
      <div class="InputFile__Modal__Header">
        <span>{{ file.original_name }}</span>
      </div>
    </template>
    <template #body>
      <div class="InputFile__Modal__Body">
        <div class="InputFile__Modal__Image">
          <BaseScrollbar
            :max-height="'80vh'"
            :width="'8px'"
          >
            <p>
              <!-- eslint-disable-next-line -->
              <img :alt="file.original_name" :src="file.path" />
            </p>
          </BaseScrollbar>
        </div>

        <div class="InputFile__Modal__Actions">
          <BaseButton
            label="Download"
            filled
            color="dark"
            text-color="white"
            tag="a"
            :download="file.original_name"
            :href="file.path"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { BaseButton, BaseScrollbar } from '@/components/atoms'
export default {
  name: 'BaseInputFileModal',
  components: {
    BaseButton,
    BaseScrollbar,
    Modal: () => import('@/components/molecules/Modal/Modal.vue'),
  },
  data() {
    return {
      modal: false,
      file: null
    }
  },
  methods: {

    //Handlers
    open(data) {
      this.modal = true
      this.file = data
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

  }
}
</script>
<style lang="scss" scoped>
.InputFile {
  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      min-width: 500px;
      max-width: 90vw;
      @media #{$mobile-view} {
        min-width: 100%;
      }
    }

    &__Header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      margin-left: 10px;
      padding: 0 45px 0 0;

      span {
        font-weight: $font-weight-bold;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      padding: 1rem 1rem;
    }

    &__Image {
      width: 100%;
      height: 100%;
      border: 1px solid #808080;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &__Actions {
      display: flex;
      align-self: flex-end;
      margin-top: 2rem;

      /deep/ .BaseButton  {
        height: 25px;
        margin: 0 10px;
        min-width: 120px;

        &__Text {
          text-transform: capitalize;
          margin-left: 0;
        }

      }
    }

  }


}
</style>
