<template>
  <div class="Pagination">
    <div class="Pagination__SelectContainer">
      <BaseTreeSelect 
        v-model="localPaginate"
        name="Exibir"
        label="Exibir"
        :clearable="false"
        :select-options="parseOptions"
        :searchable="false"
        @select="totalChange"
      />
    </div>
    <div class="Pagination__Slot">
      <slot />
    </div>
    <div v-if="page > 1 || lengthItems > 0" class="Pagination__Container">
      <p>Página {{ page }}</p>
      <ul>
        <li v-for="item in prevPaginationButtons" :key="`PrevBtn${item.id}`">
          <button :class="item.class" :disabled="isFirstPage" @click="jumpTo(item.click)">
            <Icon v-for="i in item.lengthIcons" :key="`PrevIcon${i}`" :name="item.icon" />
          </button>
        </li>

        <!-- <li>
          <button :class="selected(page)" @click="setCurrentPage(page)">
            {{ page }}
          </button>
        </li> -->

        <!-- <li class="break" /> -->

        <!-- <li v-for="item in prevPaginationButtons" :key="`PrevBtnAlt${item.id}`">
          <button :class="item.class" :disabled="isFirstPage" @click="jumpTo(item.click)">
            <Icon v-for="i in item.lengthIcons" :key="`PrevIconAlt${i}`" :name="item.icon" />
          </button>
        </li> -->

        <li v-for="item in nextPaginationButtons" :key="`NextBtn${item.id}`">
          <button :disabled="lengthItems === 0 || lengthItems < paginate" :class="item.class" @click="jumpTo(item.click)">
            <Icon v-for="i in item.lengthIcons" :key="`NextIcon${i}`" :name="item.icon" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { BaseTreeSelect } from '@/components/atoms'
import { Icon } from '@/components/photons'
import { defaultOptions, prevPaginationButtons, nextPaginationButtons } from './utils'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Pagination',

  components: {
    BaseTreeSelect,
    Icon
  },

  props: {
    displayOpt:{
      type: Array,
      default: () => defaultOptions
    }
  },

  data() {
    return {
      localPaginate: {id: 10, name:'10'},
      defaultOptions,
      nextPaginationButtons,
      prevPaginationButtons
    }
  },


  computed: {

    ...mapState({
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      lengthItems: state => state.pagination.lengthItems,
      maxPagesInPagination: state => state.pagination.maxPagesInPagination
    }),

    ...mapGetters({
      isFirstPage: 'pagination/isFirstPage',
      hasPagination: 'pagination/hasPagination',
      totalOfPages: 'pagination/totalOfPages',
      isLastPage: 'pagination/isLastPage',
      minParsedOptions: 'pagination/minParsedOptions',
      halfPagesInPagination: 'pagination/halfPagesInPagination',
      pageFrom: 'pagination/pageFrom',
      pageTo: 'pagination/pageTo',
      currentPage: 'pagination/currentPage',
      nextPage: 'pagination/nextPage',
      prevPage: 'pagination/prevPage'
    }),

    result() {
      return Array.from({length: this.pageTo + 1}, (v, i) => i)
        .filter( item => this.filterResults(item))
    },

    completingResult() {
      return Array.from({length: this.maxPagesInPagination - this.result.length}, (v, i) => i + 1)
    },

    reducedUp() {
      let up = this.result[this.result.length - 1]

      return (up + 1 < this.totalOfPages) 
        ? this.completingResult.reduce((arr, value) => [...arr, up + value], [])
        : []
    },

    reducedDown() {
      let down = this.result[0]

      return (down - 1 >= 1) 
        ? this.completingResult.reduce((arr, value) => [...arr, down - value], [])
        : []
    },

    show() {
      return [...this.result, ...this.reducedUp, ...this.reducedDown]
        .sort((a, b) => a - b)
    },
  
    parseOptions(){
      return this.displayOpt

      // if (this.displayOpt[0].id > this.lengthItems) return this.minParsedOptions

      // return this.displayOpt.filter((opt, i) => {
      //   let val = this.displayOpt[i - 1] ? this.displayOpt[i - 1].id : 0
      //   return opt.id < this.lengthItems || this.lengthItems > val && this.lengthItems < opt.id
      // })
    }

  }, 

  watch: {
    paginate: {
      immediate: true,
      handler(val) {
        this.setLocalPaginate(val)
      }
    }
  },

  mounted() {
    this.setLocalPaginate(this.paginate)
  },

  methods: {
    ...mapActions({
      setPage: 'pagination/setPage',
      setPaginate: 'pagination/setPaginate'
    }),

    scrollWindowToTop() {
      window.scrollTo(0, 0)
    },

    filterResults(item) {
      return item >= this.pageFrom 
        && item > 0 
        && item < this.pageTo 
        && item <= this.totalOfPages
    },

    setCurrentPage(value) {
      this.setPage(value)
      this.$emit('page')
      this.scrollWindowToTop()
    },

    setLocalPaginate(value) {
      this.localPaginate = {id: value, name: String(value)}
    },

    jumpTo(position) {

      let positionState = {
        first: () => { this.setCurrentPage(1) } ,
        last: () => { this.setCurrentPage(this.totalOfPages) },
        prev: () => { this.setPrevPage(this.page) },
        next: () => { this.setNextPage(this.page) }
      }

      return positionState[position]()
    },

    setPrevPage(value) {
      value--
      this.setCurrentPage(value)
    },

    setNextPage(value) {
      value++
      this.setCurrentPage(value)
    },

    selected(i){
      return {selected: this.page === i}
    },
 
    totalChange(e){
      this.setPage(1)
      this.setPaginate(e.id)
      this.$emit('paginate')
      this.scrollWindowToTop()
    },

  }
}
</script>

<style lang="scss" scoped>
.Pagination {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 30px;

  @media #{$mobile-view} {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &__Slot {
    @media #{$mobile-view} {
      order: 0;
      max-width: calc(100% - 30px);
      margin-bottom: 1rem;
    }
  }

  &__SelectContainer {
    width: 150px;
    @media #{$mobile-view} {
      margin-bottom: 1rem;
      order: 1;
      width: calc(100% - 30px);
    }
  }
 
  &__Container{
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    @media #{$mobile-view} {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      order: 2;
      width: calc(100% - 30px);
    }

    p {
      margin-right: 1rem;
      font-size: $font-size-1xmini;
      @media #{$mobile-view} {
        margin-right: 0;
        margin-top: 1rem;
        text-align: right;
        width: 100%;
      }
    }
    
      &__icon {
        margin: auto;
      }
      
      ul {
        display: flex;
        align-items: center;
        align-content: center;
        list-style-type: none;
        padding: 0;
        margin: 0;
        @media #{$mobile-view} {
          width: 100%;
          flex-flow: wrap;
        }
        li{
          margin-right: 10px;

          &.break {
            flex-basis: 100%;
            height: 0;
            margin: 0;
            padding: 0;
          }

          // &:nth-last-child(-n+4) {
          //   display: none;
          // }
          // &:nth-last-child(-n+2) {
          //   display: block;
          // }

          @media #{$mobile-view} {
            margin-bottom: 10px;
            margin: 2px;
            width: calc((100% / 2) - 4px);

            // &:nth-last-child(-n+4) {
            //   display: block;
            // }

            // &:not(:nth-last-child(-n+4)) {
            //   width: calc((100% / 10) - 4px);
            // }

            // &:nth-of-type(1),
            // &:nth-of-type(2)
            // {
            //   display: none;
            // }
          }
          &:last-child{
            margin-right: 0;
          }
          button {
            height: 40px;
            width: 40px;
            text-align: center;
            outline: none;
            border: 1px solid;
            border-color: transparent;
            font-weight: $font-weight-bold;
            font-size: $font-size-3xsmall;
            background-color: transparent;
            border-radius: $border-radius-1xsmall;
            box-shadow: $shadow-low;
            cursor: pointer;
            @media #{$mobile-view} {
              width: 100%;
              // height: 30px;
              font-size: $font-size-1xmini;
            }

            &.selected, &.Pagination__Arrow {
              border-color: $color-primary-base;
              &:focus {
                box-shadow: $shadow-low, $color-primary-base 0px 0px 6px;
              }
            }
            &:hover {
              &:not([disabled]) {
                background-color: $color-primary-base;
                img {
                  filter: $filter-white;
                }
              }
            }
            &:disabled {
              filter: grayscale(1);
              border-color: $color-neutral-stronger;
              opacity: .3;
              cursor: default;
            }
            img{
              width: 8px;
              filter: $filter-primary-base;
            }
          }
        }
      }
  }

}
</style>