<template>
  <div class="BaseTile" :class="componentClasses">
    <Icon :name="icon" class="BaseTile__icon" />
    <span class="BaseTile__title">{{ label }}</span>
    <div class="BaseTile__number">
      {{ formatted() }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTile',
  props: {
    type: {
      type: String,
      default: 'horizontal',
      validator: val => ['vertical', 'horizontal'].includes(val)
    },
    theme:{
      type: String,
      default: 'light',
      validator: val => ['light', 'dark', 'yellow', 'gray'].includes(val)
    },
    icon: {
      type: String,
      default: 'truck-ongoing'
    },
    label: {
      type: String,
      default: 'Total de Ocorrencias'
    },
    total:{
      type: Number,
      default: 101
    },
    format: {
      type:Object,
      default: () => {}
    }
  },
  computed:{
    componentClasses() {
      return [`BaseTile--${this.type}`, `BaseTile--${this.theme}`]
    }
  },
  methods:{
    formatted() {
      if (!this.format) {
        return this.value
      }
      let value = this.total.toFixed(this.format.decimals)
      if (this.format.prefix) {
        value = this.format.prefix + value
      }
      if (this.format.suffix) {
        value = value + this.format.suffix
      }
      return value
    }
  }
}
</script>

<style lang="scss" scoped>

.BaseTile {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  
  &--vertical{
    flex-direction: column;
  }

  &--horizontal{
    flex-direction: row;
  }

  &__container {
    display: flex;
    flex-direction: inherit;
    align-items: center;
  }
  
  &__icon {
    width: 25px;
  }
  
  &__title {
    font-size: $font-size-mini;
    letter-spacing: 1px;
    font-weight: $font-weight-bold;
  }

  &__number {
    font-size: $font-size-1xhuge;
    letter-spacing: 000000.1rem;
    font-weight: $font-weight-bold;
  }
}

//Types
.BaseTile{
    &--vertical{
      padding: 1rem;
       .BaseTile__title{
            margin-bottom: .5em;
        }
       .BaseTile__icon{
           margin: .5em 0;
       }
       
    }

    &--horizontal{
        .BaseTile__title{
            margin-right: 1em;
        }
        .BaseTile__icon{
            margin: 0 .7em;
        }
    }
}

//Themes
.BaseTile{
    &--light{
        background-color: transparent;
        .BaseTile__icon{
            filter: $filter-dark;
        }
        .BaseTile__title{
            color: $color-neutral-stronger;
        }
        .BaseTile__number{
            color: $color-neutral-strong;
        }
    }

    &--dark{
        background-color: $color-neutral-stronger;
        .BaseTile__icon{
            filter: $filter-white;
        }
        .BaseTile__title, .BaseTile__number{
            color: $color-neutral-softest;
        }
    }

    &--yellow{
        background-color: $color-notice-base;
        .BaseTile__icon{
            filter: $filter-dark;
        }
        .BaseTile__title, .BaseTile__number{
            color: $color-neutral-stronger;
        }
    }

    &--gray{
        background-color: $color-neutral-strong;
        .BaseTile__icon{
            filter: $filter-white;
        }
        .BaseTile__title{
            color: $color-neutral-softest;
        }
        .BaseTile__number{
            color: $color-neutral-softest;
        }
    }
}

</style>