export default {
  //Table
  drivers: {
    data:{
      headers: {},
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  driversTracking: {},
  driversObservations: [],
  vehicles: {
    data: {
      headers: {},
      content: []
    }
  },

  //Select Options
  vehicleBrands: [],
  vehicleModels: [],
  banks: [],
  bankTypes: []
}