import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchTransporters(status_id, success, error) {
    status_id = status_id ? `&status_id=${status_id}`: ''
    return await request({
      method: 'get',
      url: `transporters?paginate=999${status_id}`
    }, success, error)
  },

  async fetchTransportersTransfer(success, error) {
    return await request({
      method: 'get',
      url: 'transporters_transfer'
    }, success, error)
  },

  async fetchStoresTransporter({id}, success, error, done) {
    return await request({
      method: 'get',
      url: `transporters_by_store/${id}`
    }, success, error, done)
  },

  async fetchTransporter({id}, success, error, done) {
    return await request({
      method: 'get',
      url: `transporters/${id}?with[]=info&with[]=type`
    }, success, error, done)
  },

  async bindDriver(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'transporters-users',
      body
    }, success, error, done)
  },

  async unBindDriver({transporterId, driverId}, success, error, done) {
    return await request({
      method: 'delete',
      url: `transporters-users/${transporterId}/${driverId}`,
    }, success, error, done)
  },

  async fetchTransportersTable({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')

    return await request({
      method: 'get',
      url: `transporters?is_table=transporters&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchTransferHistoryTable({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `transporters/changes?is_table=transporter_changes&paginate=${paginate}&page=${page}${query && `&${query}`}&sort=desc`
    }, success, error, done)
  },

  async fetchModalTransferHistory({id, paginate, page}, success, error, done) {
    return await request({
      method: 'get',
      url: `transporters/changes/${id}?is_table=order_transporter_changes&paginate=${paginate}&page=${page}`
    }, success, error, done)
  },

  async changeResponsibility(body, type, success, error, done) {
    return await request({
      method: 'post',
      url: type === 'transferencia_interestadual' ? 'transporters/changes/transfer' : 'transporters/changes',
      body
    }, success, error, done)
  },

  async fetchTransportersDrivers({id, obj, paginate, page}, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id')

    return await request({
      method: 'get',
      url: `users?is_table=transporter_drivers&filter[transportersPivot][transporter_id]=${id}&paginate=${paginate}&page=${page}&sort=desc&${query}`
    }, success, error, done)
  },

  async createTransporter(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'transporters',
      body
    }, success, error, done)
  },

  async updateTransporter({ id, body }, success, error, done) {
    return await request({
      method: 'post',
      url: `transporters/${id}`,
      body
    }, success, error, done)
  }
}