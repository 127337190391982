<template>
  <figure class="BaseLogo" :class="BaseLogoHasHref">
    <router-link v-if="href" :to="href" class="BaseLogo__link" />
    <img
      class="BaseLogo__img"
      :class="logoClasses"
      v-bind="$attrs"
      title="Olist Pax"
      alt="Olist Pax"
      :src="src"
    >
  </figure>
</template>

<script>
export default {
  name: 'BaseLogo',
  props: {
    variation: {
      type: String,
      default: 'default'
    },
    color: {
      type: String,
      default: 'white',
      validator: val => ['yellow', 'primary-base', 'white', 'light', 'dark', 'black'].includes(val)
    },
    href: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      variations: {
        default: 'logo',
        olistEnvios: 'olist-envios',
        olistPax: 'olist-pax',
        olist: 'olist'
      }
    }
  },
  computed: {
    src() {
      return require('@/assets/images/logo/' + this.variations[this.variation] + '.svg')
    },
    logoClasses() {
      return `BaseLogo--${this.color}`
    },
    BaseLogoHasHref() {
      return this.href ? 'BaseLogo--clickable' : ''
    }
  },

  methods: {
  }

}
</script>

<style lang="scss" scoped>
.BaseLogo {
  height: inherit;
  margin: 0;
  position: relative;
  &__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &__img {
    width: 100%;
    height: 100%;
  }
  &--yellow {
    filter: $filter-yellow;
  }
  &--primary-base {
    filter: $filter-primary-base;
  }
  &--white {
    filter: $filter-white;
  }
  &--dark {
    filter: $filter-dark;
  }
  &--light {
    filter: $filter-light;
  }
  &--clickable {
    cursor: pointer;
  }
}
</style>
