export default {
  SET_ACCESS_TOKEN(state, access_token) {
    state.access_token = access_token
  },

  SET_EXPIRES_AT(state, expires_at) {
    state.expires_at = expires_at
  },

  SET_EXPIRED(state, flag) {
    state.expired = flag
  },

  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions
  },

  SET_USER(state, userData) {
    state.user.name = userData
  },

  SET_MODULES(state, modules) {
    state.permissionsModules = modules
  },

  SET_PERMISSIONS_BY_MODULES(state, modules) {
    modules.map(el => {
      state.permissionsList = state.permissionsList.filter((v) => v.name !== el.name)
    })
    state.permissionsList = [...state.permissionsList, ...modules]
  },

  RESET_PERMISSIONS(state) {
    state.permissions = {}
    state.permissionsList = []
  },

  SET_CHANGED_PREMISSION(state, changed) {
    state.hasPermissionChanges = changed
  }
}