export default {
  packages:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      per_page: 0
    }
  },
  incomingPackages:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      per_page: 0
    }
  },
  ordersWithConfirmedPackages:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      per_page: 0
    }
  },
  packagesReportsRequested:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  packagesHistory:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  packagesReceived:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  packagesReceivedDetail:{
    data:{
      headers: {
        actions: {}
      },
      content: []
    },
    pagination:{
      last_page: 0,
      total: 20,
      per_page: 0
    }
  },
  showModalMissingPackages: false,
  modalMissingPackages: {
    driver: '',
    data: {
      headers: {},
      content: [],
    }
  },
  cachedPackages: {
    data:{
      headers: {
        actions: {}
      },
      content: []
    }
  }
}