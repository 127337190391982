import { render, staticRenderFns } from "./BarcodeScannerSettings.vue?vue&type=template&id=0962a760&scoped=true&"
import script from "./BarcodeScannerSettings.vue?vue&type=script&lang=js&"
export * from "./BarcodeScannerSettings.vue?vue&type=script&lang=js&"
import style0 from "./BarcodeScannerSettings.vue?vue&type=style&index=0&id=0962a760&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0962a760",
  null
  
)

export default component.exports