<template>
  <div class="InputFileGroup" :style="style">
    <!-- Input List -->
    <div class="InputFileGroup__Content">
      <BaseInputFileUploaded
        v-for="file in files" 
        :key="file.id"
        class="InputFileGroup__Item"
        :file="file"
        :actions="true"
        :multiple="false" 
        :show-image-name="true"
        :can-remove-file="canRemoveFile"
        @visualize="handleVisualize" 
        @confirmRemove="$refs.dialog.handlerOpen($event, 'remove')"
      />

      <div class="InputFileGroup__Dropbox InputFileGroup__Dropbox--upload" @click="handleUpload">
        <div class="InputFileGroup__Input__Box">
          <Icon name="upload-alt" class="InputFileGroup__Icon" />
        </div>
      </div>
    </div>

    <!-- InputFile Modal -->
    <BaseInputFileGroupModal ref="modal" :value="formDataModel" :multiple="multiple" :accept="accept" @save="handleSave" />

    <!-- Visualize uploaded file -->
    <BaseInputFileModal ref="visualize" />

    <!-- Dialog -->
    <BaseInputFileDialog ref="dialog" @remove="handleRemove" />
  </div>
</template>
<script>
import BaseInputFileUploaded from './BaseInputFileUploaded'
import BaseInputFileModal from './BaseInputFileModal'
import BaseInputFileGroupModal from './BaseInputFileGroupModal'
import { acceptFormats } from './utils/index.js'

export default {
  name: 'BaseInputFileGroup',
  components: {
    BaseInputFileUploaded,
    BaseInputFileModal,
    BaseInputFileGroupModal,
    BaseInputFileDialog: () => import('./BaseInputFileDialog.vue')
  },
  props: {
    //Styles
    width: {
      type: String,
      description: 'Max width input',
      default: 'calc(100% / 5 - 20px)',
    },
    maxWidth: {
      type: String,
      description: 'Width input',
      default: '236px',
    },
    height: {
      type: String,
      description: 'Height dropbox content',
      default: '150px',
    },
    maxHeight: {
      type: String,
      description: 'Max Height dropbox content',
      default: '150px',
    },
    files: {
      type: Array,
      description: 'Array of files',
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: Array,
      default: () => acceptFormats.all
    },
    canRemoveFile: {
      type: [Boolean, String],
      default: false
    },
  },
  data() {
    return {
      formDataModel: new FormData(),
      formDataName: 'files[]',
      // files: [],
      downloads: null
    }
  },
  computed: {
    //Styles
    style: function() {
      return {
        '--width': this.width,
        '--max-width': this.maxWidth,
        '--height': this.height,
        '--max-height': this.maxHeight,
      }
    },
  },
  methods: {
    handleUpload() {
      this.$refs.modal.open()
    },
    handleVisualize(data) {
      this.$refs.visualize.open(data)
    },
    handleSave(formDataModel, formDataFiles) {
      this.formDataModel = formDataModel
      // this.files = formDataFiles
      // this.$emit('updateFiles', formDataFiles)
      this.$emit('postFiles', formDataFiles)
      // chamar enpoint de upload
    },
  
    handleRemove(data) {
      this.$emit('removeFile', data)
      // chamar enpoint de exclusão
    }
  }
}
</script>
<style lang="scss" scoped>
.InputFileGroup {

  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;

  &__Content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    gap: 20px;

    @media (max-width: $viewport-sm) {
      flex-direction: column-reverse;
    }
  }

  &__Item {
    width: 100%;
    max-width: var(--max-width);
    height: var(--height);
    max-height: var(--max-height);

    @media (max-width: $viewport-sm) {
      max-width: 100%;
    }
  }

  &__Dropbox {
    width: fit-content;
    width: 100%;
    max-width: var(--max-width);
    height: var(--height);
    max-height: var(--max-height);
    aspect-ratio: 14/9;
    background: #fff;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $viewport-sm) {
      max-width: 100%;
    }

    &--upload {
      outline: 2px dashed grey;
      cursor: pointer;

      &:hover {
        background: #ededed;
        opacity: 0.5;
      }
    }
  }

  &__Input {
    &__Box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        margin-top: 10px;
        color: $color-neutral-stronger;
        font-weight: $font-weight-bold;
        font-size: $font-size-3xsmall;
      }
    }
  }

  &__Icon {
    width: 30px;
  }
}
</style>
