export default {
  SET_CTES_ROUTES(state, payload){
    state.ctesRoutes = payload
  },
  SET_CTES(state, payload){
    state.ctes = payload
  },
  SET_MDFES(state, payload){
    state.mdfes = payload
  },
}