import objToQuery from '@/api/plugins/query'

export default {
  setParameters({ commit }, parameter) {
    commit('SET_OBJ_PARAMETER', parameter)
    commit('SET_QUERY_PARAMETER', objToQuery.parseAll(parameter, 'id'))
  },

  clearParameters({ commit }){
    commit('CLEAR_PARAMETERS')
  }
}