<template>
  <div class="Organizer">
    <div class="Organizer__filter" :style="attrStyles">
      <slot name="filter" />
    </div>
    <div v-if="hasTags()" class="Organizer__tags" :style="attrStyles">
      <slot name="tag" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Organizer',
  props: {
    rows: {
      type: String,
      default: '1fr'
    },
    responsiveRows: {
      type: String,
      default: '1fr'
    },
    columns: {
      type: String,
      default: '1fr'
    },
    gap: {
      type: String,
      default: ''
    },
    alignItems: {
      type: String,
      default: 'end'
    }
  },
  data() {
    return {
      dataColumns: '1fr'
    }
  },
  computed: {
    attrStyles() {
      return {
        gridTemplateColumns: this.dataColumns,
        gridGap: this.gap,
        gridTemplateRows: this.rows,
        alignItems: this.alignItems
      }
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  destroyed(){
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      return (window.innerWidth > 870) 
        ? this.dataColumns = this.columns 
        : this.dataColumns = '1fr'
    },

    hasTags(){
      return this.$slots.tag
    }
  }
}
</script>

<style lang="scss" scoped>
.Organizer {
  /* Positioning */
  position: relative;

  /* Box model */
  display: grid;
  gap: $spacing-inset-small;
  padding: $spacing-inset-small;
  width: 100%;
  min-height: 80px;
  z-index: $z-dropdown;

  /* Visual */
  background-color: $color-neutral-softest;
  box-shadow: $shadow-low;
  border-radius: $border-radius-1xsmall;

  &__filter{
    display: grid;
    align-items: center;
    gap: 1rem;
  }

  @media #{$mobile-view} {
    /* Box model */
    display: flex;
    flex-direction: column;
    padding: $spacing-inset-small;
    width: 100vw;

    & > * {
      /* Box model */
      width: 100%;
      padding: 5px 0;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>