<template>
  <component :is="tag" type="button" v-bind="$attrs" class="BaseButton" :class="componentClasses" :disabled="disabled" :read-only="readOnly" @click="click">
    <span v-if="!!label" class="BaseButton__Text" :class="spanClasses">
      {{ label }}
    </span>
    <Icon v-if="!!icon" :name="icon" :color="setIconColor" class="BaseButton__Icon" />
  </component>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    tag: {
      type: String,
      default: 'button',
      validator: val => ['button', 'a'].includes(val)
    },
    label:{
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: ''
    },
    filled: {
      type: [Boolean, Number],
      default: false
    },
    theme: {
      type: String,
      default: 'simple',
      validator: val => ['simple', 'icon-left', 'icon-right'].includes(val)
    },
    color: {
      type: String,
      default: 'dark',
      validator: val => ['primary', 'alternate', 'default', 'danger', 'yellow', 'primary-base', 'red', 'light-red', 'green', 'light-green', 'white', 'light', 'dark', 'black' ].includes(val)
    },
    textColor: {
      type: String,
      default: '',
      validator: val => ['primary', 'alternate', 'default', 'danger', 'yellow', 'primary-base', 'red', 'light-red', 'green', 'light-green', 'white', 'light', 'dark', 'black', ''].includes(val)
    },
    iconColor: {
      type: String,
      default: '',
      validator: val => ['primary', 'alternate', 'default', 'danger', 'yellow', 'primary-base', 'red', 'light-red', 'green', 'light-green', 'white', 'light', 'dark', 'black', ''].includes(val)
    },
    disabled:{
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    track: {
      type: String,
      default: ''
    }
  },
  computed: {
    componentClasses() {
      return [
        `BaseButton--${this.color}`,  
        `BaseButton--${this.theme}`, 
        {'BaseButton--filled': this.filled}, 
        {'BaseButton--disabled': this.disabled},
        {'BaseButton--readOnly': this.readOnly}
      ]
    },

    spanClasses() {
      return this.textColor ? `BaseButton--${this.textColor}-Text` : `BaseButton--${this.color}-Text`
    },

    setIconColor() {
      if(this.iconColor) {
        return this.iconColor
      }
      return this.textColor ? this.textColor : this.color
    }
  },
  
  methods: {
    click(e) {
      if (this.track) this.$mixpanel.track(this.track)
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
$colors: (
  "primary": $color-accent-base,
  "alternate": $color-primary-base,
  "default": $color-neutral-base,
  "danger": $color-alert-base,
  "yellow": $color-notice-base,
  "primary-base": $color-primary-base,
  "red": $color-alert-base,
  "light-red": $color-alert-soft,
  "green": $color-success-base,
  "light-green": $color-success-soft,
  "white": $color-neutral-softest,
  "light": $color-neutral-base,
  "dark": $color-neutral-stronger,
  "black": $color-neutral-stronger
);

@function filterIcon($type) {
  @if $type == "black" {
    @return $filter-primary-base;
  }
  @else if $type == "dark" {
    @return $filter-primary-base;
  }
  @else {
    @return $filter-dark;
  }
}

@function filterSpan($type) {
  @if $type == "black" {
    @return $color-primary-base;
  }
  @else if $type == "dark" {
    @return $color-primary-base;
  }
  @else {
    @return $color-neutral-stronger;
  }
}

//Simple Animation
@mixin simple-animation {
  transition: filter .1s;
  &:hover {
    background-color: red;
    filter: brightness(110%);
    transition: filter .1s;
  }
}

//Filled Animation
@mixin filled-animation {
  transition: filter .1s;
  &:hover {
    filter: brightness(110%);
    transition: filter .1s;
  }
}

//Wireframe
.BaseButton {
  width: 100%;
  height: 44px;
  -webkit-appearance: none;
  display: flex;
  background: inherit;
  padding: 0px 16px;
  cursor: pointer;
  align-items: center;
  position: relative;
  white-space: nowrap;
  border: 1px solid rgb(229, 229, 242);
  border-radius: $border-radius-1xsmall;
  transition: all 0.15s ease 0s, background 0.45s ease 0s;

  &__Text {
    z-index: $z-label;
    position: relative;
  }

  &__Text {
    font-weight: $font-weight-bold;
    text-transform: lowercase;
    font-size: $font-size-3xsmall;
    letter-spacing: 0.001rem;
    font-family: $font-family-soleil;
  }

  &__Icon {
    z-index: $z-label;
    position: relative;
    height: 16px;
  }
}

//Types
.BaseButton {
  justify-content: center;
  gap: 0.5rem;

  //Secondary
  &--icon-left {
    flex-flow: row-reverse;
  }
  
}

//Colors
@each $type, $color in $colors {
  .BaseButton {
    &--#{$type} {
      border-color: $color;
      &-Text {
        color: $color;
      }
      &:after {
        background: $color;
      }
      &:hover {
        box-shadow: $shadow-low;
      }
      &:focus {
        box-shadow: $color 0px 0px 6px;
      }
    }

    &--filled {
      @include filled-animation;
      &.BaseButton--#{$type} {
        background: $color;
        &:hover {
          // box-shadow: $shadow-low;
          background-color: $color;
          .BaseButton__Text {
            -webkit-text-fill-color: unset;
          }
        }
        &:focus {
          box-shadow: $color 0px 0px 6px;
        }
      }
    }

    &--disabled {
      // filter: brightness(110%);
      pointer-events: none;
      // background: rgb(229, 229, 242);
      // border-color: rgb(229, 229, 242);
      // color: rgb(105, 109, 140);
      opacity: 0.45;
      background: $color-neutral-softer !important;
      border: 1px solid $color-neutral-softer;
      .BaseButton__Text {
        color: $color-neutral-strong !important;
      }
      .BaseButton__Icon {
        filter: $filter-disabled;
      }
    }

    &--readOnly {
      // filter: brightness(110%);
      pointer-events: none;
      // opacity: .5;
    }

  
  }
}

</style>