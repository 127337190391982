const DropoffsExport = () => import( /* webpackChunkName: 'DropoffsExport' */ './Export/DropoffsExport.vue')

const routes = [
  {
    name: 'dropoffsExport',
    meta:{
      module: 'module_packages'
    },
    path: '/dropoff/export',
    component: DropoffsExport
  }
]

export default routes