import state from './state.drivers'
import getters from './getters.drivers'
import mutations from './mutations.drivers'
import actions from './actions.drivers'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}