import state from './state.orders'
import getters from './getters.orders'
import mutations from './mutations.orders'
import actions from './actions.orders'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}