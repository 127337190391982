<template>
  <div class="BaseTooltip">
    <ul v-if="type && tooltip.length > 0" class="BaseTooltip__List">
      <li
        v-for="(item, index) in tooltip"
        :key="index"
        :class="[
          'BaseTooltip__Item',
          !item && 'BaseTooltip__Item--noValue'
        ]"
      >
        <a
          v-if="item"
          href=""
          :class="itemClass(item)"
          @click.prevent="item.action && !item.disabled ? onClick(item.action) : ''"
        >
          <Icon
            v-tooltip="item.label"
            class="BaseTooltip__Tooltip"
            :name="item.icon || 'faq'"
          />
        </a>
      </li>
    </ul>
    <div v-else>
      <Icon
        v-if="tooltipLabel"
        v-tooltip="tooltipLabel"
        class="BaseTooltip__Tooltip"
        :name="tooltipIcon || 'faq'"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseTooltip',
  props: {
    tooltip: {
      type: [String, Array],
      description: '',
      default: () => [],
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
    tooltipIcon: {
      type: String,
      default: '',
    },
  },
  computed: {
    type() {
      return Array.isArray(this.tooltip)
    },
  },
  methods: {
    onClick(action) {
      this.$emit('tooltip', action)
    },

    itemClass(item) {
      return [
        item.action !== undefined ? 'BaseTooltip--hasAction' : '',
        item.disabled ? 'BaseTooltip--disabled' : '',
        item.active ? 'BaseTooltip--active' : ''
      ]
    },
  },
}
</script>
<style lang="scss" scoped>
.BaseTooltip {
  position: absolute;
  top: 0;
  right: 0;

  //General
  a {
    display: block;
    cursor: default;
  }

  &__List {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__Item {
    margin: 0 3px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &--noValue {
      margin: 0;
    }
  }
  &__Tooltip {
    height: 20px;
  }

  &--hasAction {
    cursor: pointer !important;
  }

  &--disabled {
    filter: invert(0.5);
  }

  &--active {
    filter: $filter-primary-base;
  }

 
}
</style>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;
  max-width: 255px;
  filter: drop-shadow(0 4px 8px #00000033);

  .tooltip-inner {
    background: #fff;
    color: #000;
    padding: 0.8rem;
    font-size: $font-size-mini;
    border-radius: $border-radius-1xsmall;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 8px;
    border-color: #fff;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 10px;

    .tooltip-arrow {
      border-width: 8px 8px 0 8px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -8px;
      left: calc(50% - 8px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 8px;

    .tooltip-arrow {
      border-width: 0 8px 8px 8px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -8px;
      left: calc(50% - 8px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 8px;

    .tooltip-arrow {
      border-width: 8px 8px 8px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -8px;
      top: calc(50% - 8px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 8px;

    .tooltip-arrow {
      border-width: 8px 0 8px 8px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -8px;
      top: calc(50% - 8px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: #fff;
      padding: 24px;
      border-radius: 8px;
      box-shadow: $shadow-low;
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
