import { file } from '@/api'

export default {

  async fetchAttachments({ commit }, params) {
    return await file.fetchFiles(params, (res) =>
      commit('SET_ATTACHMENTS', res.attachments), (e) => e
    )
  },

  async clearAttachments({commit}) {
    commit('CLEAR_ATTACHMENTS')
  },

  async deleteAttachments({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await file.deleteFiles(params, () =>
      commit('REMOVE_ATTACHMENTS', params), (e) => e, () =>
      this.dispatch('loading/setLoading', false)
    )
  }
}