export default {
  paymentImportErrorsSorted(state){
    
    const errorsSorted = state.paymentImportErrors.sort(function(a, b){
      var oldValue = a['rectify']
      var newValue = b['rectify']
      return ((oldValue < newValue) ? (oldValue > newValue) ? 1 : 0 : -1)
    })
        
    return errorsSorted
  },

  fortnightTableWithId(state) {
    const table = state.fortnight.data.content.map((el, index) => {
      let id = Math.floor(Date.now() / 1000) + index
      return {...el, id}
    })
    return table
  }
}
