const TrackingForm = () => import( /* webpackChunkName: 'TrackingForm' */ './Form/TrackingForm.vue')
const TrackingOrder = () => import( /* webpackChunkName: 'TrackingOrder' */ './Order/TrackingOrder.vue')

const routes = [
  {
    name: 'tracking',
    path: '/tracking/',
    component: TrackingForm,
    meta: { public: true, noHeader: true, theme: 'blue' },
  },
  {
    name: 'tracking',
    path: '/tracking/:trackingCode',
    component: TrackingOrder,
    meta: { public: true, noHeader: true, theme: 'blue' },
  },
  {
    name: 'tracking',
    path: '/tracking/:storeId/:orderNumber',
    component: TrackingOrder,
    meta: { public: true, noHeader: true, theme: 'blue' },
  }
]

export default routes