<template>
  <div class="Recaptcha">
    <div id="g-recaptcha" class="g-recaptcha" :style="isInvalidStyle" />
    <p v-if="isInvalid" class="Recaptcha__invalidLabel">
      {{ invalidLabel }}
    </p>
  </div>
</template>

<script>
import recaptchaInit from './utils/recaptcha'

export default {
  name: 'Recaptcha',
  props: {
    isInvalid: {
      type: Boolean,
      default: false
    },
    invalidLabel: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      sitekey: '6LdXZPYUAAAAAM5JkzCYXIXP8dtx-DfQqOad9_VS',
      widgetId: 0
    }
  },
  computed: {
    isInvalidStyle() {
      return this.isInvalid ? 'box-shadow: -1px -1px 0px 1px red; background: red; border-radius: 4px;' : ''
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    execute () {
      window.grecaptcha.execute(this.widgetId)
    },
    reset() {
      window.grecaptcha.reset(this.widgetId)
    },
    async render() {
      await recaptchaInit()
      
      this.widgetId = window.grecaptcha.render('g-recaptcha', {
        sitekey: this.sitekey,
        // the callback executed when the user solve the recaptcha
        callback: (response) => {
          // emit an event called verify with the response as payload
          this.$emit('verify', response)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .Recaptcha {
    &__invalidLabel {
      padding: 5px 10px;
      font-size: $font-size-1xmini;
      color: $color-alert-base;
      font-weight: $font-weight-bold;
      position: absolute;
    }
  }
</style>