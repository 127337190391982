import state from './state.notification'
import getters from './getters.notification'
import mutations from './mutations.notification'
import actions from './actions.notification'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}