import request from '@/api/plugins/request'
import download from '@/api/plugins/download'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchSellers(success, error) {
    return await request({
      method: 'get',
      url: 'sellers?paginate=999'
    }, success, error)
  },

  async fetchSellersTable({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')

    return await request({
      method: 'get',
      url: `sellers?is_table=sellers&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchSellersVipsImportTable({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')

    return await request({
      method: 'get',
      url: `sellers-vips-import?is_table=sellers_vips_import&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async importSellersVips(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'import-sellers-vips',
      body
    }, success, error, done)
  },

  async downloadSellerVipFile(id, success, error, done) {
    return await download({
      method: 'get',
      url: `sellers-vips-imported/${id}`,
    }, success, error, done)
  },

  async fetchSeller({ id }, success, error) {
    return await request({
      method: 'get',
      url: `users/${id}?with[]=sellerInfo.vipInfo&with[]=profile&with[]=documents&with[]=stores&with[]=phones`
    }, success, error)
  },

  async fetchSellersVipsImportErrors(id, success, error, done) {
    return await request({
      method: 'get',
      url: `sellers-vips-import-errors/${id}`
    }, success, error, done)
  },

  async saveSellerLocation(body, success, error, done) {
    let data = {
      lat: body.lat,
      lng: body.lng,
      geolocation_radius: body.geolocation_radius
    }
    return await request({
      method: 'put',
      url: `sellers/${body.seller_code}/location`,
      body: data
    }, success, error, done)
  },

  async requestCollect(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'olist/create-collect-and-run-route-creation',
      body
    }, success, error, done)
  },

  async updateSeller(data, success, error, done) {
    return await request({
      method: 'put',
      url: `sellers/${data.sellerCode}/info`,
      body: { 
        ...data.body
      }
    }, success, error, done)
  }

}