import request from '@/api/plugins/request'
import download from '@/api/plugins/download'

export default {

  async exportDropoffsReport(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'dropoffs-export',
      body
    }, success, error, done)
  },

  async downloadDropoffsReport(id, success, error, done) {
    return await download({
      method: 'get',
      url: `dropoffs-exports/${id}`
    }, success, error, done)
  },

  async fetchDropoffReportsRequested({ paginate, page }, success, error, done) {
    return await request({
      method: 'get',
      url: `dropoffs-exports?is_table=dropoffs_exports&paginate=${paginate}&page=${page}&sort=desc`
    }, success, error, done)
  },
}