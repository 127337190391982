<template>
  <div class="BaseDatePicker" :class="BaseDatePickerClass">
    <div v-if="label" class="BaseDatePicker__Label">
      {{ label }}
    </div>
    <div class="BaseDatePicker__Content">
      <div class="BaseDatePicker__Base">
        <div v-if="prependIcon || $slots.prepend" class="BaseDatePicker__Icon">
          <slot name="prepend">
            <Icon :name="prependIcon" class="BaseDatePicker__Icon-svg" />
          </slot>
        </div>
        <DatePicker
          ref="datepicker"
          :open="open || opened"
          :custom-open="customOpen"
          :clearable="clearable"
          :confirm="confirm"
          :default-value="defaultValue"
          :disabled="disabled"
          :disabled-date="disabledDates"
          :disabled-time="disabledTimes"
          :format="format"
          :hour-options="hourOptions"
          :hour-step="hourStep"
          :minute-options="minuteOptions"
          :minute-step="minuteStep"
          :placeholder="placeholder"
          :range="range"
          :range-separator="rangeSeparator"
          :second-options="secondOptions"
          :second-step="secondStep"
          :shortcuts="shortcuts"
          :show-hour="showHour"
          :show-minute="showMinute"
          :show-second="showSecond"
          :show-week-number="showWeekNumber"
          :time-picker-options="timePickerOptions"
          :type="type"
          :value="value"
          :value-type="valueType"
          :time-picker-default="timePickerDefault"
          v-bind="$attrs"
          v-on="listeners"
        >
          <!-- Sidebar Slot -->
          <template v-if="$slots.sidebar" #sidebar>
            <slot name="sidebar" />
          </template>
          <!-- Header Slot -->
          <template v-if="$slots.header" #header>
            <slot name="header" />
          </template>
          <!-- Footer Slot -->
          <template v-if="$slots.footer" #footer>
            <slot name="footer" />
          </template>
        </DatePicker>

        <p v-if="isInvalid" class="BaseDatePicker__invalidLabel">
          {{ invalidLabel }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/pt-br'

export default {
  name: 'BaseDatePicker',
  components: {
    DatePicker,
  },
  props: {
    appendIcon: {
      type: [String, Array, Boolean],
      description: 'AppendIcon icon (right)',
      default: false,
    },
    clearable: {
      type: Boolean,
      description: 'If false, dont show the clear icon',
      default: true,
    },
    open: {
      type: Boolean,
      description: 'If true, open datepicker',
      default: false,
    },
    customOpen: {
      type: Boolean,
      description: 'If true, prevent open methods',
      default: false,
    },
    confirm: {
      type: Boolean,
      description: 'If true, need click the button to change value',
      default: false,
    },
    defaultValue: {
      type: [Number, String, Date],
      description: 'Default value for input',
      default: '',
    },
    disabled: {
      type: Boolean,
      description: 'Input disabled',
      default: false,
    },
    readOnly: {
      type: Boolean,
      description: 'Input ready only',
      default: false
    },
    disableWeekends: {
      type: Boolean,
      description: 'Set disable weekends or not',
      default: false,
    },
    isInvalid: {
      type: Boolean,
      description: 'Input is valid or not',
      default: false,
    },
    invalidLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
      default: '',
    },
    maxRange: {
      type: [Number, String],
      description: 'Max range to select',
      default: null,
    },
    notAfter: {
      type: [Date, Number, String, Boolean],
      description: 'Date value to block all others date after',
      default: false,
    },
    notAfterClear: {
      type: [Date, Number, String, Boolean],
      description:
        'Date value to block all others date after without hours and minutes',
      default: function() {
        const date = new Date(this.notAfter)
        date.setHours(0, 0, 0, 0)
        return Date.parse(date)
      },
    },
    notBefore: {
      type: [Date, Number, String, Boolean],
      description:
        'Date value to block all others date before without hours and minutes',
      default: false,
    },
    notBeforeClear: {
      type: [Date, Number, String, Boolean],
      description: 'Date value to block all others date before',
      default: function() {
        const date = new Date(this.notBefore)
        date.setHours(0, 0, 0, 0)
        return Date.parse(date)
      },
    },
    placeholder: {
      type: String,
      description: 'Input placeholder (text inside input)',
      default: 'início ~ fim',
    },
    prependIcon: {
      type: [String, Array, Boolean],
      description: 'Prepend icon (left)',
      default: 'calendar-alt',
    },
    range: {
      type: Boolean,
      description: 'Input type is range or not',
      default: true,
    },
    rangeSeparator: {
      type: String,
      description: 'Text of range separator',
      default: '',
    },
    setDateToBackend: {
      type: Boolean,
      description:
        'If component use computed prop dateToBackend to return on input',
      default: true,
    },
    shortcuts: {
      type: Array,
      description: 'Set shortcuts to select "Array<{text, onClick}>"',
      default: () => [],
    },

    timePickerDefault: {
      type: Boolean,
      description: 'Set default time list or not',
      default: true,
    },
    timePickerOptions: {
      type: [Array, Object],
      description: 'Set fixed time list to select',
      default: function() {
        return this.timePickerDefault === true ? this.timeOptionsDefault : null
      },
    },
    type: {
      type: String,
      description: 'Select the type of picker',
      default: 'date',
      validator: (val) =>
        ['date', 'datetime', 'year', 'month', 'time', 'week'].includes(val),
    },
    value: {
      type: [String, Number, Array, Date, Object],
      description: 'Input value',
      default: null,
    },
    valueType: {
      type: String,
      description: 'Set the format of binding value',
      default: 'timestamp',
      validator: (val) =>
        ['', 'date', 'timestamp', 'format', 'token(MM/DD/YYYY)'].includes(val),
    },

    //Custom Options
    hourStep: {
      type: Number,
      description: 'Interval between hours in time picker',
      default: 1,
    },
    minuteStep: {
      type: Number,
      description: 'Interval between minutes in time picker',
      default: 1,
    },

    secondStep: {
      type: Number,
      description: 'Interval between seconds in time picker',
      default: 1,
    },
    hourOptions: {
      type: Array,
      description: 'custom hour column',
      default: null,
    },
    minuteOptions: {
      type: Array,
      description: 'custom minute column',
      default: null,
    },
    secondOptions: {
      type: Array,
      description: 'custom second column',
      default: null,
    },

    //Need others props value
    format: {
      type: String,
      description: 'Input set the date format',
      default: function() {
        const format = {
          date: 'DD/MM/YYYY',
          datetime:'DD/MM/YYYY HH:mm',
          year: 'YYYY',
          month: 'MM/YYYY',
          time: 'HH:mm',
          week: 'w'
        }
        return format[this.type]
      },
    },
    showHour: {
      type: [Boolean, String],
      description: 'Whether show hour column',
      default: function() {
        return this.type === 'datetime' ||
          this.type === 'time' ||
          this.format.includes('hh')
          ? true
          : false
      },
    },
    showMinute: {
      type: [Boolean, String],
      description: 'Whether show minute column',
      default: function() {
        return this.type === 'datetime' ||
          this.type === 'time' ||
          this.format.includes('mm')
          ? true
          : false
      },
    },
    showSecond: {
      type: [Boolean, String],
      description: 'Whether show second column',
      default: function() {
        return this.type === 'datetime' ||
          this.type === 'time' ||
          this.format.includes('ss')
          ? true
          : false
      },
    },
    showWeekNumber: {
      type: Boolean,
      description: 'Determine whether show week number',
      default: function() {
        return this.type === 'week' ? true : false
      },
    },
  },
  data() {
    return {
      focused: false,
      opened: false,
      rangeDate: {
        start: null,
        end: null,
      },

      //Props
      timeOptionsDefault: {
        start: '00:00',
        step: '00:15',
        end: '23:45',
        format: 'HH:mm a',
      },
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        close: this.onClose,
        focus: this.onFocus,
        input: this.onInput,
        open: this.onOpen,
        pick: this.onPick,
      }
    },

    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      }
    },

    hasIcon() {
      const {append, prepend} = this.$slots
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== false ||
        this.prependIcon !== false
      )
    },

    hasSomeDisable() {
      const hasBefore = this.notBefore !== false
      const hasAfter = this.notAfter !== false
      const hasDisableOnWeekends = this.disableWeekends !== false
      const hasDisableByRange = this.maxRange !== null && this.range !== false

      return hasBefore || hasAfter || hasDisableOnWeekends || hasDisableByRange
    },

    BaseDatePickerClass() {
      return [
        this.readOnly ? 'BaseDatePicker--read-only': '',
        this.isInvalid ? 'BaseDatePicker--invalid' : '',
        this.hasIcon ? 'BaseDatePicker__hasIcon' : '',
        !this.clearable ? 'BaseDatePicker__notClearable' : '',
      ]
    },

    dateToBackend() {
      const isArray = Array.isArray(this.value)
      return isArray ? {init: this.value[0], end: this.value[1]} : this.value
    },
  },
  methods: {
    //Handlers
    onBlur(evt) {
      this.focused = false
      this.$emit('blur', evt)
    },
    onClose(evt) {
      if (!this.customOpen) this.opened = false
      this.$emit('close', evt)
    },
    onFocus(evt) {
      this.focused = true
      this.$emit('focus', evt)
    },
    onInput(evt) {
      if(this.range && this.maxRange) {
        if(!this.validDatesMaxRange(evt))
          return
      }
      this.$emit('input', evt)
    },
    onOpen(evt) {
      if (!this.customOpen) this.opened = true
      this.$emit('open', evt)
    },
    onPick(evt) {
      if (this.range) {
        this.handlerDateRange(evt)
      }
      this.$emit('pick', evt)
    },

    //Models
    getDateToBackend(date) {
      const isArray = Array.isArray(date)
      return isArray ? {init: date[0], end: date[1]} : date
    },

    //Validation
    validDatesMaxRange(evt) {
      const [startDate, endDate] = evt
      let minDate, maxDate = null
      let isValid = true

      if (startDate) {
        maxDate = new Date(startDate)
        maxDate.setDate(maxDate.getDate() + this.maxRange)
        maxDate = maxDate.getTime()
      }

      if (endDate) {
        minDate = new Date(endDate)
        minDate.setDate(minDate.getDate() - this.maxRange)
        minDate = minDate.getTime()
      }

      if((startDate && minDate && startDate < minDate)
        || (endDate && maxDate && endDate > maxDate)) {
        isValid = false
      }

      return isValid
    },

    disabledDates(date) {
      //Check if component has some props for disable dates
      if (!this.hasSomeDisable) {
        return false
      }

      const disableBefore = this.notBefore !== false ? date < this.notBeforeClear : false

      const disableAfter = this.notAfter !== false ? date > this.notAfterClear : false

      const disableOnWeekends = this.disableWeekends
        ? this.getDisableWeekends(date)
        : false

      const disableByRange =
        this.maxRange !== null && this.range
          ? this.getDisableRangeWithMaxRange(date)
          : false

      return (
        disableBefore || disableAfter || disableOnWeekends || disableByRange
      )
    },

    disabledTimes(date) {
      //Check if component has some props for disable dates
      if (!this.hasSomeDisable || this.type !== 'datetime') {
        return false
      }

      const disableBefore = this.notBefore !== false ? date < this.notBefore : false

      const disableAfter = this.notAfter !== false ? date > this.notAfter : false

      return disableBefore || disableAfter
    },

    getDisableWeekends(date) {
      const day = new Date(date).getDay()
      if (this.disableWeekends) {
        return day === 0 || day === 6
      } else {
        return false
      }
    },

    getDisableRangeWithMaxRange(date) {
      let minDate, maxDate = null

      if (this.rangeDate.start) {
        maxDate = new Date(this.rangeDate.start)
        maxDate.setDate(maxDate.getDate() + this.maxRange)
        maxDate = maxDate.getTime()
      }

      if (this.rangeDate.end) {
        minDate = new Date(this.rangeDate.end)
        minDate.setDate(minDate.getDate() - this.maxRange)
        minDate = minDate.getTime()
      }

      return !!((minDate && date?.getTime() < minDate)
        || (maxDate && date?.getTime() > maxDate))
    },

    //Helpers
    handlerDateRange(date) {
      //Check if has value on start and end
      if (this.rangeDate.start !== null && this.rangeDate.end !== null) {
        this.resetRangeDate()
      }

      //Date Range Constructor
      if (this.rangeDate.start === null && this.rangeDate.end === null) {
        this.rangeDate.start = date
      } else if (this.rangeDate.end === null) {
        if (this.rangeDate.start > date) {
          this.rangeDate.end = this.rangeDate.start
          this.rangeDate.start = date
        } else {
          this.rangeDate.end = date
        }
      }
    },

    resetRangeDate() {
      this.rangeDate = {
        start: null,
        end: null,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.BaseDatePicker {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  //Status
  &--read-only {
    pointer-events: none;
    opacity: 0.5;
  }

  &__Label {
    color: $color-neutral-stronger;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;

    .BaseDatePicker--invalid & {
      color: $color-alert-base;
    }
  }
  &__Content {
    width: 100%;
    display: flex;
    height: 36px;
    align-items: center;
    justify-content: space-around;
  }
  &__Base {
    display: flex;
    width: 100%;
    border-bottom: 2px solid $color-neutral-soft;
    &--disabled {
      pointer-events: none;
    }
    /deep/ .disabled {
      input {
        background: $color-neutral-softer;
        color: $color-neutral-base
      }
    }
    &--focus {
      border-color: $color-neutral-soft;
    }
  }
  &__Icon {
    background-color: $color-neutral-soft;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &-svg {
      filter: $filter-white;
    }
  }
  &__Icon-svg {
    width: 15px;
  }

  //Error
  &__hasIcon {
    .BaseDatePicker__Content {
      height: 40px;
      // border-bottom: none;
    }
    .BaseDatePicker__Label {
      margin-bottom: 10px;
    }
    .BaseDatePicker__Base {
      border: 2px solid $color-neutral-soft;
      /deep/ .mx-datepicker {
        .mx-input-wrapper {
          .mx-input {
            padding-left: 10px;
          }
        }
      }
    }
  }

  &__invalidLabel {
    padding: 5px 10px;
    font-size: $font-size-1xmini;
    color: $color-alert-base;
    font-weight: $font-weight-bold;
    position: absolute;
  }

  &__notClearable {
    .BaseDatePicker__Content {
      height: 40px;
    }

    /deep/ .mx-input {
      padding: 6px 10px;
    }
  }
}
</style>
<style lang="scss">
.mx-datepicker {
  font: inherit;
  color: $color-neutral-stronger;
  width: 100% !important;
  height: 100%;
  z-index: 0;

  .mx-input-wrapper {
    height: 100%;
    ::placeholder {
      color: $color-neutral-stronger;
      text-transform: lowercase;
      font-size: $font-size-2xmini;
      letter-spacing: 0.7px;
      font-weight: $font-weight-regular;
      font-family: $font-family-soleil;
    }

    .mx-input {
      border: 0;
      box-shadow: none;
      border-radius: 0;
      background: unset;
      &::placeholder {
        font-family: inherit;
      }
    }

    .mx-icon-calendar {
      display: none;
    }
  }
}
.mx-datepicker-popup {
  z-index: $z-calendar !important;

  //Calendar
  .mx-calendar {
    width: 240px;
  }

  .mx-time {
    width: 240px;
  }

  .mx-calendar,
  .mx-time,
  .mx-calendar-time {
    font-size: inherit;
    padding: 0;
    color: $color-neutral-stronger;

    //Header
    .mx-calendar-header,
    .mx-time-header {
      padding: 0;
      background-color: $color-primary-base;

      .mx-btn-text {
        color: $color-neutral-softest;
        line-height: $line-height-large;
        i {
          &::before,
          &::after {
            width: 13px;
            height: 13px;
            border-width: 4px 0 0 4px;
          }
        }
      }

      .mx-btn-icon-double-left,
      .mx-btn-icon-double-right {
        color: $color-neutral-soft;
        font-size: $font-size-1xhuge;
      }

      .mx-btn-icon-left,
      .mx-btn-icon-right {
        color: $color-neutral-soft;
        font-size: $font-size-1xhuge;
      }

      .mx-calendar-header-label {
        color: $color-neutral-softest;
        .mx-btn-current-month,
        .mx-btn-current-year {
          font-size: $font-size-1xsmall;
          pointer-events: none;
          color: $color-neutral-softest;
        }
      }
    }

    //Content
    .mx-calendar-content,
    .mx-time-content {
      margin: 0 auto;
      thead {
        color: $color-primary-base;
        tr {
          th {
            font-weight: $font-weight-bold;
            font-size: $font-size-1xmini;
            width: 32px;
            height: 32px;
            padding: 0;
            overflow: hidden;
            text-align: center;
          }
        }
      }

      .cell:hover {
        background-color: rgba($color-blue-100, 0.5);
        &.active {
          background-color: $color-blue-700;
        }
      }

      .today {
        background-color: $color-blue-200;
        color: $color-neutral-stronger;
        font-weight: $font-weight-bold;
      }

      .not-current-month {
        color: $color-blue-400;
      }

      .in-range {
        color: $color-blue-600;
      }

      .in-range, .hover-in-range {
        background-color: $color-blue-100;
      }

      .hover-in-range.not-current-month {
        color: $color-blue-400
      }

      .active {
        background-color: $color-primary-base;
        color: $color-neutral-softest;
        font-weight: $font-weight-bold;
      }
    }
  }
}
</style>
