import { stores } from '@/api'

export default {
  async fetchStoresAsync({ commit, rootGetters }) {
    return await stores.fetchStores((res) => {
      commit('SET_STORES', res.data)
      return rootGetters.treeSelectModel('stores', 'stores')
    }, (e) => e)
  },

  async fetchRoutEasyStoresAsync({ commit, rootGetters }) {
    return await stores.fetchRoutEasyStores((res) => {
      commit('SET_ROUTEASY_STORES', res)
      return rootGetters.treeSelectModel('stores', 'routEasyStores')
    }, (e) => e)
  },

  async fetchStores({ commit }) {
    return await stores.fetchStores((res) => commit('SET_STORES', res.data), (e) => e)
  },

  async fetchHubsAsync({ commit, rootGetters }) {
    return await stores.fetchHubs((res) => {
      commit('SET_HUBS', res.data)
      return rootGetters.treeSelectModel('stores', 'hubs')
    }, (e) => e)
  },

  async fetchStoresImportAsync({ commit, rootGetters }) {
    return await stores.fetchStoresImport((res) => {
      commit('SET_STORES_IMPORT', res.data)
      return rootGetters.treeSelectModel('stores', 'storesImport')
    }, (e) => e)
  },

  async fetchStoresImport({ commit }) {
    return await stores.fetchStoresImport((res) => commit('SET_STORES_IMPORT', res.data), (e) => e)
  },

  //Orders Import
  async fetchStoresToImportAsync({ commit, rootGetters }) {
    return await stores.fetchStoresToImport((res) => {
      commit('SET_STORES_TO_IMPORT', res.data)
      return rootGetters.treeSelectModel('stores', 'storesToImport')
    }, (e) => e)
  },

  async fetchStoresToImport({ commit }) {
    return await stores.fetchStoresToImport((res) => commit('SET_STORES_TO_IMPORT', res.data), (e) => e)
  },

  async fetchStoresTable({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await stores.fetchStoresTable(params, (res) =>
      commit('SET_STORES_TABLE', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchStoresWithInfo({ commit }) {
    return await stores.fetchStoresWithInfo((res) => commit('SET_STORES_INFO', res.data), (e) => e)
  },

  async fetchStoresWithInfoAsync({ commit, rootGetters }) {
    return await stores.fetchStoresWithInfo((res) => {
      commit('SET_STORES_INFO', res.data)
      return rootGetters.treeSelectModel('stores', 'storesWithInfo')
    }, (e) => e)
  },

  async fetchStoresParents({state, commit }) {
    if (state.storesParents.length) return
    return await stores.fetchStoresParents((res) => commit('SET_STORES_PARENTS', res), (e) => e)
  },

  async fetchStoresParentsAsync({ commit, rootGetters }) {
    return await stores.fetchStoresParents((res) => {
      commit('SET_STORES_PARENTS', res)
      return rootGetters.treeSelectModel('stores', 'storesParents')
    }, (e) => e)
  },

  async fetchStoresTransporters({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await stores.fetchStoresTransporters(params, (res) => 
      commit('SET_STORES_TRANSPORTERS', res), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async bindStoreTransporter({ commit }, body) {
    this.dispatch('loading/setLoading', true)
    return await stores.bindStoreTransporter(body, (res) => 
      commit('UNSHIFT_STORES_TRANSPORTERS', res), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async removeStoreTransporter({ commit }, body) {
    this.dispatch('loading/setLoading', true)
    return await stores.removeStoreTransporter(body, () => 
      commit('REMOVE_STORES_TRANSPORTERS', body.transporterId), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async fetchStoresChildren({ commit }, id) {
    this.dispatch('loading/setLoading', true)

    return await stores.fetchStoresChildren(id, (res) =>
      commit('SET_STORE_CHILDREN', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },
}