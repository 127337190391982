<template>
  <ul class="BaseMenu">
    <li v-for="(item, index) in menu" :key="index" class="BaseMenu__li">
      <template v-if="item.children">
        <span
          class="BaseMenuLink"
          :class="activeParent(item.name)"
        >
          {{ item.name }}
          <Icon name="chevron-down" class="BaseMenuLink__Icon" />
        </span>
        <ul class="BaseMenu__ulParent">
          <li v-for="(children, childrenIndex) in item.children" :key="childrenIndex" class="BaseMenu__liChildren" @click="tag(children.tagName)">
            <router-link
              class="BaseMenuLink BaseMenuLink__Children"
              :class="activeItem(children.to)"
              :to="children.to"
            >
              {{ children.name }}
            </router-link>
          </li>
        </ul>
      </template>
      <router-link
        v-else
        class="BaseMenuLink"
        :class="activeItem(item.to)"
        :to="item.to"
        @click.native="tag(item.tagName)"
      >
        {{ item.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BaseMenu',
  props: {
    menu: {
      type: Array,
      required: true,
      validator: items => {
        return (
          items.filter(item => {
            return Object.prototype.hasOwnProperty.call(item, 'name')
          }).length === items.length
        )
      }
    }
  },
  methods: {
    ...mapActions({
      clearParameters: 'queryString/clearParameters',
      clearPagination: 'pagination/clearPagination'
    }),

    activeItem(to){
      return this.$route.path.split('/')[1] === to.split('/')[1] ? 'BaseMenuLink--active' : null
    },

    activeParent(name){
      const parent = this.menu.find(parent=> parent.name === name)
      return parent?.children.some(children => this.$route.path.split('/')[1] === children.to.split('/')[1]) ? 'BaseMenuLink--active' : null
    },

    

    tag(tagName){
      this.clearPagination()
      this.clearParameters()
      if (tagName) this.$mixpanel.track(`Header Menu - Clicked on ${tagName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseMenu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    &__li {
      position: relative;
      height: inherit;
      margin-right: $spacing-stack-medium;
      white-space: nowrap;
      &:last-child{
        margin-right: 0;
      }
      &:hover .BaseMenu__ulParent {
        display: flex;
      }
    }
    &__ulParent {
      position: fixed;
      display: none;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin-top: -$spacing-500;
      background: $color-neutral-softest;
      box-shadow: $shadow-low;
      border-radius: $border-radius-200;
    }
    &__liChildren {
      padding: $spacing-300 $spacing-500;
      cursor: pointer;
      &:hover {
        background: $color-neutral-softer;
      }
      &:first-child{
        border-radius: $border-radius-200 $border-radius-200 0 0;
      }
      &:last-child{
        border-radius: 0 0 $border-radius-200 $border-radius-200;
      }
    }
    @media (max-width: $viewport-lg) {
      &__li {
        margin-right: $spacing-stack-small;
      }
    }
    @media (max-width: $viewport-md) {
      &__li {
        white-space: normal;
      }
    }
    @media #{$mobile-view} {
      flex-flow: column;
      justify-content: center;
      &__li {
        height: auto;
        .BaseMenuLink--active {
          &:before {
            display: none;
          }
        }
        .BaseMenuLink__Children {
          color: $color-neutral-softest;
          &:hover {
            color: $color-neutral-softer;
          }
        }
      }
      &__liChildren {
        padding: 0;
        &:hover {
          background: unset;
        }
      }
      &__ulParent {
        display: flex;
        position: unset;
        background: unset;
        box-shadow: unset;
        margin: $spacing-stack-2xsmall $spacing-500 $spacing-stack-2xsmall;
        gap: 0;
      }
    }
  }
  .BaseMenuLink {
    color: $color-neutral-softest;
    height: inherit;
    font-size: $font-size-1xsmall;
    font-weight: $font-weight-regular;
    text-transform: lowercase;
    letter-spacing: .00001rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    &__Children {
      font-size: $font-size-3xsmall;
      color: $color-neutral-stronger;
      &:before {
        display: none;
      }
    }
    &__Icon {
      height: 14px;
      filter: $filter-white;
      margin-left: $spacing-200;
      margin-top: $spacing-100;
    }
    &:hover :not(.BaseMenuLink__Children) {
      color: $color-neutral-softer;
      .BaseMenuLink__Icon {
        filter: $filter-white;
      }
    }
    @media #{$mobile-view} {
      font-size: $font-size-2xlarge;

      &__Icon {
        display: none;
      }
    }
    &--active {
      font-weight: $font-weight-bold;
      position: relative;
      &:before {
        content: "";
        height: 3px;
        background: $color-neutral-softest;
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
  }
</style>