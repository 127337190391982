const Notification = () => import( /* webpackChunkName: "Notification" */ './Notification.vue')

const routes = [
  {
    name: 'notification',
    meta:{
      module: 'module_notification'
    },
    path: '/notification',
    component: Notification
  }
]

export default routes