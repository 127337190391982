export default {
  // readyOrders(state) {    
  //   return { ...state.orders,
  //     data: { 
  //       ...state.orders.data, 
  //       headers: {
  //         ...state.orders.data.headers,
  //         actions: false
  //       }
  //     } 
  //   }
  // },

  readyIncomingOrders(state) {    
    const headers = state.incomingOrders.data.headers
    const deletedHeaders = ['actions', 'driver.name', 'route.route.name']
        
    for (let i = 0; i < deletedHeaders.length; i++) {
      delete headers[deletedHeaders[i]]
    }
    
    return { ...state.incomingOrders,
      data: { 
        ...state.incomingOrders.data, 
        headers: {
          ...headers,
          actions: false
        }
      } 
    }
  },

  errorSort(state){
    
    const errorsSorted = state.importErros.sort(function(a, b){
      var oldValue = a['rectify']
      var newValue = b['rectify']
      return ((oldValue < newValue) ? (oldValue > newValue) ? 1 : 0 : -1)
    })
    
    return errorsSorted
  },

  errorStatusSort(state){
    
    const errorsStatusSorted = state.importStatusErros.sort(function(a, b){
      var oldValue = a['rectify']
      var newValue = b['rectify']
      return ((oldValue < newValue) ? (oldValue > newValue) ? 1 : 0 : -1)
    })
    
    return errorsStatusSorted
  },

  importedOrdersWithLoading(state){
    return state.importOrders.data.content.map((importedOrder) => {
      const i = importedOrder
      if (i['status.name'] === 'Andamento') {
        i.actions = [{action_id: 'loading', has_permission: true}]
      }
      return i
    })
  },

  importedStatusOrdersWithLoading(state){
    return state.importStatusOrders.data.content.map((importedStatusOrder) => {
      const i = importedStatusOrder
      if (i['status_import.name'] === 'Andamento') {
        i.actions = [{action_id: 'loading', has_permission: true}]
      }
      return i
    })
  },

  reportsRequestedWithLoading(state){
    const headers = state.reportsRequested.data.headers
    if (headers && headers.created_at && headers.created_at.method === false) {
      headers.created_at.method = 'millisecondsToDateHour'
    }
    return {
      data: {
        content: state.reportsRequested.data.content.map((reportRequested) => {
          const i = reportRequested
          if (i.status.toLowerCase() === 'andamento') {
            i.actions = [{action_id: 'loading', has_permission: true}]
          }
          if (i.status.toLowerCase() === 'erro' || i.status.toLowerCase() === 'incompleto') {
            i.status = 'Erro'
            i.actions = [{action_id: 'visualizeErrors', has_permission: true}]
          }
          return i
        }),
        headers: headers       
      },
      pagination: state.reportsRequested.pagination
    }
  },

  mappedTracking(state){
    return state.ordersTracking.map((order) => {
      return {
        position:{
          lat: order.driver.tracking ? order.driver.tracking.coordinate.lat : '',
          lng: order.driver.tracking ? order.driver.tracking.coordinate.lng : ''
        },
        info: {
          trackingCode: order.tracking_code,
          driverId: order.driver_id,
          driver: order.driver.name,
          plate: order.driver.vehicle ? order.driver.vehicle.plate : '',
          phone: order.driver.phone ? order.driver.phone.toString() : '',
          vehicle_type: order.driver.vehicle.model ? order.driver.vehicle.model.type.name : ''
        }
      }
    })
  },

  incomingOrdersTypes(state) {
    return state.incomingOrdersTypes.map((type) => {
      return {
        id: type.value,
        name: type.name
      }
    })
  }
}