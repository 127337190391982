<template>
  <div :class="!hideDetailsButton ? 'BaseMapWindow showButton' : 'BaseMapWindow'">
    <div class="BaseMapWindow__item BaseMapWindow__driver">
      <Icon name="driver" color="white" class="BaseMapWindow__icon" />
      <label class="BaseMapWindow__label">Motorista</label>
      <span class="BaseMapWindow__info">{{ driver }}</span>
    </div>

    <div class="BaseMapWindow__item BaseMapWindow__plate">
      <Icon name="truck-plate" color="white" class="BaseMapWindow__icon" />
      <label class="BaseMapWindow__label">Placa</label>
      <span class="BaseMapWindow__info">{{ plate | carPlate }}</span>
    </div>

    <div class="BaseMapWindow__item BaseMapWindow__phone">
      <Icon name="whatsapp" color="white" class="BaseMapWindow__icon" />
      <label class="BaseMapWindow__label">Telefone</label>
      <span class="BaseMapWindow__info">
        <a class="BaseMapWindow__link" :href="parsePhone" target="_blank">{{ phone | phone }}</a>
      </span>
    </div>
    <BaseButton
      label="Ver Detalhes"
      class="BaseMapWindow__button"
      color="dark"
      text-color="white"
      filled
      @click="goTo(`/orders?driverId=${driverId}`)"
    />
  </div>
</template>

<script>
import { BaseButton } from '@/components/atoms'

export default {
  name: 'BaseMapWindow',
  components: {
    BaseButton
  },
  props: {
    driverId: {
      type: Number,
      required: true
    },
    hideDetailsButton: {
      type: Boolean,
      default: false
    },
    driver: {
      type: String,
      required: true
    },
    plate: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
  },
  computed: {
    parsePhone(){
      return `https://wa.me/55${this.phone}`
    }
  },
  methods: {
    goTo(route) {
      window.open(route, '_blank')
    },
  }
}
</script>

<style lang="scss">
// reset mapWindow defaults
.gm-style {
  .gm-style-iw {
    max-height: calc(100vh - 80px) !important;
  }
  .gm-style-iw-d {
    overflow: unset !important;
    max-height: calc(100vh - 80px) !important;
    border-radius: $border-radius-small;
  }
  .gm-style-iw.gm-style-iw-c {
    border-radius: 0;
    padding: 0;
    border-radius: $border-radius-small;
  }
  .gm-ui-hover-effect {
    color: $color-neutral-stronger !important;
    filter: $filter-white $filter-primary-base brightness(50%);
    opacity: 1 !important;
    top: 0 !important;
    right: 0px !important;
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-small;
    img {
      zoom: 1.25;
      filter: $filter-white;
      margin: 0 !important;
    }
  }
  .gm-style-iw-t::after {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
$margin: 20px;
$label: 80px;

.BaseMapWindow {
  padding-bottom: 15px;
  padding-top: 5px;
  background: white;
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, 30px) 40px;
  grid-template-columns: $margin 1fr repeat(2, $margin);
  grid-template-areas:
    "driver driver . ."
    "plate plate . ."
    "phone phone . .";
  border-radius: $border-radius-small;

  &.showButton {
    grid-template-rows: repeat(3, 30px) 40px;
    grid-template-areas:
    "driver driver . ."
    "plate plate . ."
    "phone phone . ."
    ". btn btn .";
  }

  &:before {
    content: "";
    width: $margin * 2;
    height: 100%;
    position: absolute;
    background: $color-primary-base;
    z-index: $z-base;
  }
  &:after {
    content: "";
    width: $label;
    transform: translateX($margin * 2);
    height: 100%;
    position: absolute;
    // background: linear-gradient(white 30%, $color-neutral-base);
    z-index: $z-base;
  }

  &__link{
    &:hover{
      color: $color-success-base;
    }
  }

  &__item {
    z-index: $z-label;
    position: relative;
    display: grid;
    grid-template-columns: 40px $label 1fr; 
  }

  &__driver {
    grid-area: driver;
  }
  &__plate {
    grid-area: plate;
  }
  &__phone {
    grid-area: phone;
  }
  
  &__icon {
    width: 15px;
    justify-self: center;
    align-self: center;
    z-index: $z-label;
    position: relative;
  }

  &__label, &__info {
    justify-self: left;
    align-self: center;
    padding-left: 10px;
    font-weight: $font-weight-bold;
    @media #{$mobile-view} {
      font-size: $font-size-2xmini;
    }
  }

  &__label {
    z-index: $z-label;
    position: relative;
    color: $color-neutral-strong;
  }
  &__info {
    color: $color-neutral-stronger;
  }

  &__button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-weight: $font-weight-bold;
    text-align: center;
    font-size: $font-size-2xmini;
    text-transform: uppercase;
    color: $color-neutral-softest;
    background-color: $color-neutral-stronger;
    cursor: pointer;
    grid-area: btn;
    z-index: $z-label;
    position: relative;
    box-sizing: border-box;
  }
}
</style>
