import { drivers } from '@/api'

export default {
  async fetchDrivers({ commit }, params = {paginate:20, page:1}) {
    this.dispatch('loading/setLoading', true)
    return await drivers.fetchDrivers(params, (res) =>
      commit('SET_DRIVERS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async deleteDriver({ commit }, id) {
    return await drivers.deleteDriver(id, () => commit('DELETE_DRIVER', id), (e) => e)
  },

  async fetchDriversObservations({ state, commit }) {
    if (state.driversObservations.length) {
      return
    }
    return await drivers.fetchDriversObservations((res) => commit('SET_DRIVERS_OBSERVATIONS', res), (e) => e)
  },

  async fetchDriverVehicles({commit}, id){
    return await drivers.fetchDriverVehicles(id, (res) => {
      commit('SET_DRIVERS_VEHICLES', res)
    }, (e) => e)
  },

  async fetchDriversTracking({commit}, queryString){
    return await drivers.fetchDriversTracking(queryString, (res) => 
      commit('SET_DRIVERS_TRACKING', res), (e) => e, () => true, false)
  },

  async checkDeleteStore( ctx , {driver_id, store_id }) {
    this.dispatch('loading/setLoading', true)
    const result =  await drivers.checkDeleteStore({driver_id, store_id}, (res) =>{
      return res
    }, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
    return result
  },

  //Selects
  async fetchVehicleBrands({ commit, rootGetters }) {
    return await drivers.fetchVehicleBrands((res) => {
      commit('SET_VEHICLE_BRANDS', res)
      return rootGetters.treeSelectModel('drivers', 'vehicleBrands')
    }, (e) => e)
  },
  async fetchVehicleModels({ commit, rootGetters }, params) {
    return await drivers.fetchVehicleModels({params}, (res) => {
      commit('SET_VEHICLE_MODELS', res)
      return rootGetters.treeSelectModel('drivers', 'vehicleModels')
    }, (e) => e)
  },
  async fetchBanks({ commit, rootGetters }) {
    return await drivers.fetchBanks((res) => {
      commit('SET_BANKS', res)
      return rootGetters.treeSelectModel('drivers', 'banks')
    }, (e) => e)
  },
  async fetchBankTypes({ commit, rootGetters }) {
    return await drivers.fetchBankTypes((res) => {
      commit('SET_BANK_TYPES', res.data)
      return rootGetters.treeSelectModel('drivers', 'bankTypes')
    }, (e) => e)
  },
}