const Vehicles = () => import( /* webpackChunkName: 'Vehicles' */ './Vehicles.vue')
const VehiclesForm = () => import( /* webpackChunkName: 'VehiclesForm' */ './Form/VehiclesForm.vue')

const routes = [
  {
    name: 'vehicles',
    path: '/vehicles',
    meta:{
      label: 'Novo',
      module: 'module_vehicles'
    },
    component: Vehicles
  },
  {
    name: 'vehiclesAdd',
    meta:{
      label: 'Veículo',
      module: 'module_vehicles'
    },
    path: '/vehicles/add',
    component: VehiclesForm
  },
  {
    name: 'vehiclesDetail',
    meta:{
      label: 'Veículo',
      module: 'module_vehicles'
    },
    path: '/vehicles/:id',
    component: VehiclesForm
  }
]

export default routes