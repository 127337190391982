import request from '@/api/plugins/request'
import download from '@/api/plugins/download'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchPaymentClosingHistory(params, success, error, done) {
    const query = objToQuery.parseAll(params.obj, 'id')
    return await request({
      method: 'get',
      url: `payment-closing?is_table=payment_closing_history&page=${params.page}&paginate=${params.paginate}&${query}&sort=desc`
    }, success, error, done)
  },

  async submitPaymentClosingFile(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'payment-closing',
      body
    }, success, error, done)
  },

  async previewPaymentClosingFile(id, success, error, done) {
    return await request({
      method: 'get',
      url: `payment-closing?is_table=payment_closing_preview&file_id=${id}`
    }, success, error, done)
  },

  async downloadPaymentClosingFile(params, success, error, done) {
    return await download({
      method: 'get',
      url: `payment-closing/${params.id}/${params.type}`,
    }, success, error, done)
  },

  async fetchFinancialImportErrors(id, success, error, done) {
    return await request({
      method: 'get',
      url: `financial-import-errors/${id}`
    }, success, error, done)
  },

  async fetchPaymentUploadHistory(params, success, error, done) {
    const query = objToQuery.parseAll(params.obj, 'id')

    return await request({
      method: 'get',
      url: `financial/operation-cost-files?is_table=operation_cost_files&page=${params.page}&paginate=${params.paginate}&${query}&sort=desc` 
    }, success, error, done)
  },

  async uploadPaymentFile(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/import-sheet/additional',
      body
    }, success, error, done)
  },

  async uploadMisplacementFile(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/import-sheet/misplacement',
      body
    }, success, error, done)
  },

  async downloadUploadedFile(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/operation-cost-file/${id}`,
    }, success, error, done)
  },

  async deleteUploadedFile(id, success, error, done) {
    return await download({
      method: 'delete',
      url: `financial/operation-cost-file/${id}`,
    }, success, error, done)
  },

  async fetchPaymentUploadFileErrors(id, success, error, done) {
    return await request({
      method: 'get',
      url: `financial/operation-cost-file-errors/${id}`
    }, success, error, done)
  },

  async fetchFinancialConsolidatedHistory(params, success, error, done) {
    const query = objToQuery.parseAll(params.filters, 'id')
    return await request({
      method: 'get',
      url: `financial/operation-cost-consolidation/finance?is_table=operation_cost_consolidations_finance&page=${params.page}&paginate=${params.paginate}&sort=desc&${query}`
    }, success, error, done)
  },

  async fetchOperationRule(success, error, done) {
    // endpoint needs refactor in status
    return await request({
      method: 'get',
      url: 'financial/operation-rule?is_table=operation_rules'
    }, success, error, done)
  },

  async fetchAnalystReview(params, success, error, done) {
    const filters = objToQuery.parseAll(params.obj, 'id')
    return await request({
      method: 'get',
      url: `financial/consolidations/review-analyst?is_table=operation_costs_administrator&page=${params.page}&paginate=${params.paginate}&sort=desc&${filters}`
    }, success, error, done)
  },

  async getMasterManagerApprove(params, success, error, done) {
    const query = objToQuery.parseAll(params.obj, 'id')

    return await request({
      method: 'get',
      url: `financial/operation-cost-consolidation/approval-manager?is_table=operation_costs_manager&page=${params.page}&paginate=${params.paginate}&sort=desc&${query}`
    }, success, error, done)
    
  },

  async changeConsolidateStatus(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/operation-cost-consolidation/change-status',
      body,
    }, success, error, done)
  },

  async submitReviewAnalyst(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/consolidations/review-analyst',
      body
    }, success, error, done)
  },

  async returnRevisionManager(id, success, error, done) {
    return await request({
      method: 'post',
      url: `financial/operation-cost-consolidation/return-to-manager-review/${id}`
    }, success, error, done)
  },

  async submitApproveMasterManager(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/operation-cost-consolidation/approval-manager',
      body
    }, success, error, done)
  },

  async changeCostStatus(params, success, error, done) {
    const body = params.body
    return await request({
      method: 'post', 
      url: `financial/operation-cost/manager-principal-change-status/${params.statusCode}`,
      body
    }, success, error, done)
  },

  async financialChangeStatus(body, success, error, done) {
    return await request({
      method: 'post', 
      url: 'financial/consolidations/financial-change-status',
      body
    }, success, error, done)
  },

  async financialBatchChangeStatus(type, success, error, done) {
    return await request({
      method: 'post', 
      url: `financial/consolidations/financial-${type}-all-costs`,
    }, success, error, done)
  },

  async fetchFinancialConsolidation(params, success, error, done) {
    const query = objToQuery.parseAll(params.obj, 'id')
    
    return await request({
      method: 'get',
      url: `financial/operation-cost-consolidation?is_table=operation_cost_consolidations&page=${params.page}&paginate=${params.paginate}&${query}&sort=desc`
    }, success, error, done)
  },

  async downloadFinancialConsolidation(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/operation-cost-consolidation/${id}/download`,
    }, success, error, done)
  },

  async deleteFinancialConsolidation(id, success, error, done) {
    return await request({
      method: 'delete',
      url: `financial/operation-cost-consolidation/${id}`
    }, success, error, done)
  },

  async fetchAnalystsComments(id, success, error, done) {
    return await request({
      method: 'get',
      url: `financial/operation-cost-message/${id}?is_table=operation_cost_messages&page=1&paginate=100&sort=asc`
    }, success, error, done)
  },

  async submitAnalystMessage(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/operation-cost-message',
      body
    }, success, error, done)
  },

  async fetchFortnight(params, success, error, done) {
    const query = objToQuery.parseAll(params.filters, 'id')

    return await request({
      method: 'get',
      url: `financial/operation-cost-consolidation/${params.id}/approved?is_table=operation_costs_approved&page=${params.page}&paginate=${params.paginate}&${query}&sort=desc`
    }, success, error, done)
  },

  async downloadConsolidateCNAB(params, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/operation-cost-consolidation/${params.id}/cnab/${params.type}`,
    }, success, error, done)
  },

  async downloadConsolidate(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/operation-cost-consolidation/${id}/download`,
    }, success, error, done)
  },

  async downloadPreFinancialConsolidate(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/operation-cost-consolidation/${id}/pre-consolidation-download`,
    }, success, error, done)
  },

  async downloadDailyPreConsolidate(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/operation-cost-consolidation/${id}/pre-consolidation-by-orders-download`,
    }, success, error, done)
  },

  async downloadFinancialReports(id, sucess, error, done) {
    return await download({
      method: 'get',
      url: `financial/operation-cost-consolidation/check-file-download/${id}`,
    }, sucess, error, done)
  },

  async fetchUploadHistoryEdit(params, success, error, done) {
    const query = objToQuery.parseAll(params.filters, 'id')

    return await request({
      method: 'get',
      url: `financial/operation-cost-files/${params.id}?is_table=operation_costs&page=${params.page}&paginate=${params.paginate}&${query}`,
    }, success, error, done)
  },

  async fetchDuplicateHistoryLines(id, success, error, done) {
    return await request({
      method: 'get',
      url: `financial/operation-cost/${id}/duplicate?is_table=operation_costs_duplicate`,
    }, success, error, done)
  },
  
  async deleteuploadHistoryLine(id, success, error, done) {
    return await request({
      method: 'delete',
      url: `financial/operation-cost/${id}`
    }, success, error, done)
  },

  async keepAllDuplicateLines(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/operation-cost/keep-duplicate',
      body
    }, success, error, done)
  },

  async downloadSpreadsheetModel(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/download-model-sheet-offline/${id}`
    }, success, error, done)
  },

  async fetchStatusList(params, success, error, done) {
    return await request({
      method: 'get',
      url: `financial/operation-cost-status/${params}`
    }, success, error, done)
  },

  async fetchOnlineErrors(params, success, error, done) {
    const query = objToQuery.parseAll(params.filters, 'id')

    return await request({
      method: 'get',
      url: `financial/operation-cost-errors-online?is_table=operation_cost_error_online&page=${params.page}&paginate=${params.paginate}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchReceiptsHistory(params, success, error, done) {
    const query = objToQuery.parseAll(params.filters, 'id')

    return await request({
      method: 'get',
      url: `financial/documents?is_table=operation_costs_driver_documents&page=${params.page}&paginate=${params.paginate}&${query}&sort=desc`
    }, success, error, done)
  },

  async downloadDriverReceipt(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/documents/${id}/receipt`
    }, success, error, done)
  },

  async downloadDriverExtract(id, success, error, done) {
    return await download({
      method: 'get',
      url: `financial/documents/${id}/extract`
    }, success, error, done)
  },

  async fetchVehiclesEdit(params, success, error, done) {
    const query = objToQuery.parseAll(params.filters, 'id')

    return await request({
      method: 'get',
      url: `financial/vehicles?is_table=vehicle_financial&page=${params.page}&paginate=${params.paginate}&${query}&sort=desc`
    }, success, error, done)
  },

  async submitVehiclesEdit(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'financial/vehicles',
      body
    }, success, error, done)
  },

  async fetchVehiclesTypes(success, error, done) {
    return await request({
      method: 'get',
      url: 'financial/vehicles/types'
    }, success, error, done)
  },

  async fetchFieldErrorsList(success, error, done) {
    return await request({
      method: 'get',
      url: 'financial/select/operation-cost-errors-online'
    }, success, error, done)
  },

  async fetchFinancialApprove(params, success, error, done) {
    const query = objToQuery.parseAll(params.obj, 'id')

    return await request({
      method: 'get',
      url: `financial/operation-cost-consolidation/approval-finance?is_table=operation_costs_finance&page=${params.page}&paginate=${params.paginate}&sort=desc&${query}`
    }, success, error, done)
    
  },
}