const ControlTowerOccurrences = () => import( /* webpackChunkName: 'ControlTowerOccurrences' */ './Occurrences/ControlTowerOccurrences.vue')
// const OccurrencesRealTime = () => import( /* webpackChunkName: 'OccurrencesRealTime' */ './Occurrences/RealTime/OccurrencesRealTime.vue')
// const OccurrencesHistory = () => import( /* webpackChunkName: 'OccurrencesHistory' */ './Occurrences/History/OccurrencesHistory.vue')
const OccurrencesConfig = () => import( /* webpackChunkName: 'OccurrencesConfig' */ './Occurrences/Config/OccurrencesConfig.vue')

const routes = [
  {
    name: 'controlTowerOccurrences',
    path: '/controlTower/occurrences',
    component: ControlTowerOccurrences,
    children: [
      {
        path: 'config',
        name: 'controlTowerOccurrencesConfig',
        title: 'Configuração de Ocorrências',
        meta: {
          module: 'module_control_tower',
        },
        component: OccurrencesConfig,
      },
    ],
  },
  {
    path: '/monitoring',
    name: 'controlTowerOccurrencesRealTime',
    title: 'Monitoramento de Rotas',
    meta: {
      module: 'module_control_tower',
    },
    component: {},
    beforeEnter() {
      window.location.href =
        'https://painel.pax.app.br/v2/controlTower/monitoring'
    },
  },
  {
    path: '/occurrence',
    name: 'controlTowerOccurrencesRealTime',
    title: 'Monitoramento de Rotas',
    meta: {
      module: 'module_control_tower',
    },
    component: {},
    beforeEnter() {
      window.location.href =
        'https://painel.pax.app.br/v2/controlTower/occurrence'
    },
  },
]

export default routes