// ##########
// Routes
// ##########

//Fetch Routes History
const fetchRouteHistory = {
  data:{
    headers: {
      id: {
        id: 'id',
        isVisible: false,
        label: 'id',
        method: false,
        param: 'route_history.id',
      },
      created_at: {
        id: 'created_at',
        isVisible: true,
        label: 'Data e hora do evento',
        method: 'millisecondsToDateHour',
        mutator: 'dateToTimestamp',
        param: 'created_at',
      },
      user_name: {
        id: 'user_name',
        isVisible: true,
        label: 'Login do usuário',
        method: false,
        param: 'user_name',
      },
      driver_name: {
        id: 'driver_name',
        isVisible: true,
        label: 'Nome do motorista',
        method: false,
        param: 'driver_name',
      },
      qtd_orders: {
        id: 'qtd_orders',
        isVisible: true,
        label: 'Quantidade de pedidos',
        method: false,
        param: 'qtd_orders',
      },
    },
    content: []
  },
  pagination:{
    last_page: 0,
    total: 20,
    per_page: 0
  }
}

for (let i = 0; i < 10; i++) {
  let obj = {
    id: i,
    created_at: 1623701552000,
    user_name: 'Usuário - ' + i,
    driver_name: 'Motorista - ' + i,
    qtd_orders: i,
  }
  fetchRouteHistory.data.content.push(obj)
}

const RoutesMock = {
  fetchRouteHistory
}

export default RoutesMock