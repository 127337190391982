<template>
  <div class="Files">
    <div class="Files__List">
      <transition-group name="fade">
        <BaseInputFileListItem 
          v-for="(file) in files" 
          :key="file.id" 
          :file="file"
          :multiple="multiple"
          :list="list"
          @confirmRemove="confirmRemove"
          @remove="handleRemove"
        />
      </transition-group>
    </div>
  </div>
</template>
<script>
import BaseInputFileListItem from './BaseInputFileListItem'
export default {
  name: 'BaseInputFileList',
  components: {
    BaseInputFileListItem
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    confirmRemove(data) {
      this.$emit('confirmRemove', data)
    },

    handleRemove(data) {
      this.$emit('remove', data)
    }
  }
}
</script>
<style lang="scss" scoped>
.Files {
  &__List {
    
  }
  &__Item {
    display: flex;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;

    &:last-of-type {
      border: none;
    }

    &__Icon {
      height: 75px;
      padding: 0px 10px;
      position: relative;

      .Icon {
        height: 100%;
        width: auto;
      }
    }

    &__Information {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-left: 10px;
    }

    &__Name {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    &__Close {
      padding: 5px;
      background-color: $color-neutral-stronger;

      .Icon {
        width: 15px;
        display: block;
      }
    }

    &__Progress {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 15px;

      &--bar {
        position: relative;
        width: 100%;
        height: 10px;
        margin-bottom: 5px;

        &::before,
        &::after {
          content: '';
          width: 100%;
          height: 10px;
          position: absolute;
          top: 0;
          left: 0;
        }

        &::before {
          background-color: #e3e3e3;
        }

        &::after {
          width: 40%;
          background-color: $color-primary-base;
        }
      }

      &--size {
        color: $color-neutral-stronger;
        font-weight: $font-weight-bold;
        @media (max-width: $viewport-xs) {
          order: 3;
        }
      }

      &--percent {
        color: $color-primary-base;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
