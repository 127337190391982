<template>
  <form class="DynamicForm" @submit.prevent="onSubmit">
    <component
      :is="i.tag"
      v-for="i in blueprint"
      :ref="i.id"
      :key="i.id"
      :value="value(i)"
      v-bind="i.props"
      :class="componentClass(i)"
      :style="componentStyle(i)"
      :model="model"
      :data-test="i.id"
      :invalid="invalidString(i)"
      :is-invalid="isInvalid(i.id)"
      :invalid-label="invalidString(i)"
      :invalid-fields="invalidFields"
      :autocomplete="autoComplete"
      v-on="events(i)"
      @blur="setInvalidField(i)"
      @input="input(i, $event)"
      @verify="verify(i, $event)"
    >
      {{ i.content }}
    </component>
    <slot />
  </form>
</template>

<script>
import Utility from '@/plugins/formData'
import { BaseTreeSelect, BaseDatePicker, BaseButton, BaseCheckBoxGroup, BaseCheckBox, BaseTextarea, BaseRadioGroup } from '@/components/atoms'
import { ButtonToggle, InputGroup, Recaptcha, Link, InputAddress, ThumbAction, InputFile, DataTable } from '@/components/molecules'
import BaseInputFileGroup from '@/components/atoms/BaseInputFile/BaseInputFileGroup'
import FiltersTag from '@/components/molecules/FiltersTag/FiltersTag.vue'
import Rules from '@doc88/flux-validator-js'

export default {
  name: 'DynamicForm',
  components: {
    BaseTreeSelect,
    BaseDatePicker,
    BaseButton,
    BaseCheckBoxGroup,
    BaseCheckBox,
    ButtonToggle,
    BaseTextarea,
    BaseRadioGroup,
    InputGroup,
    Recaptcha,
    InputAddress,
    Link,
    ThumbAction,
    InputFile,
    BaseInputFileGroup,
    FiltersTag,
    DataTable
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    blueprint: {
      type: Array,
      required: true,
      validator: items => {
        return (
          items.filter(item => {
            return Object.prototype.hasOwnProperty.call(item, 'id') && Object.prototype.hasOwnProperty.call(item, 'tag')
          }).length === items.length
        )
      }
    },
    endpointErrors: {
      type: Object,
      default: () => {}
    },
    submitType: {
      type: String,
      default: 'formData',
      validator: val => ['formData', 'json'].includes(val)
    },
    autoComplete: {
      type: String,
      default: 'one-time-code'
    }
  },
  data() {
    return {
      invalidFields: [] 
    }
  },
  watch: {
    endpointErrors() {
      this.setInvalidFields()
    }
  },
  methods: {
    componentClass(i) {
      const customClass = i.class ? `DynamicForm__${i.class}` : ''
      return `DynamicForm__${i.id} ${customClass}`
    },

    componentStyle(i) {
      return `grid-area: ${i.id}; ${i.style}`
    },

    isInvalid(id) {
      if (this.endpointErrors) return this.invalidFields.includes(id) || Object.prototype.hasOwnProperty.call(this.endpointErrors, id)
    },

    isValidField(rules, value, regex){
      return !rules.filter(rule => {
        if(rule) return !Rules[rule](rule, value, regex).valid
      }).length
    },

    setInvalidField(field) {
      const rules = field.rules
      const id = field.id
      const model = field.model

      if (field.rules === null) {
        this.invalidFields = this.invalidFields.filter(i => i !== id)
      }
      
      if (!field.rules) return  
      
      const newField = model instanceof Object ? this.model[Object.getOwnPropertyNames(model)][id] : this.model[id]

      const isValid = this.isValidField(rules, newField, field.regex)
      if (isValid) {
        this.invalidFields = this.invalidFields.filter(i => i !== id)
      } else {
        if (!this.invalidFields.includes(id)) this.invalidFields.push(id)
      }
    },

    setInvalidFields() {
      this.invalidFields = []
      for (let i in this.blueprint) {
        const field = this.blueprint[i]
        if (field.rules) this.setInvalidField(field)
      }
    },

    invalidString(i) {
      if (this.endpointErrors) {
        if (Object.prototype.hasOwnProperty.call(this.endpointErrors, i.id)) return this.endpointErrors[i.id].join('\r\n')
        if (Object.prototype.hasOwnProperty.call(this.endpointErrors, i.id) && i.model instanceof Object) return this.endpointErrors[Object.getOwnPropertyNames(i.model)][i.id].join('\r\n')
      }

      if (i.props) return i.props.invalidLabel
    },

    events(item) {
      let events = { ...this.$listeners }
      if (item.events) {
        for (let i in item.events) {
          events[i] = (e) => this.$emit(item.events[i], e)
        }
      }
      return events
    },

    getFieldReference(model, field) {
      // Itera se o field é um objeto e guarda a árvore de referência
      if (typeof field === 'string') return { model, field }
      const objKey = Object.keys(field)[0]
      return this.getFieldReference(model[objKey], field[objKey])
    },

    value(field) {
      if (!field.model) return
      if (typeof field.model === 'string') return this.model[field.model]

      const ref = this.getFieldReference(this.model, field.model)
      return ref.model[ref.field]
    },

    input(field, e) {
      if (this.endpointErrors) delete this.endpointErrors[field.id]
      /*eslint vue/no-mutating-props: 0*/
      if (typeof field.model === 'string') return this.model[field.model] = e

      let ref = this.getFieldReference(this.model, field.model)
      ref.model[ref.field] = e
    },

    verify(field, e) {
      let ref = this.getFieldReference(this.model, field.model)
      ref.model[ref.field] = e
      this.setInvalidField(field)
    },

    onSubmit() {
      this.setInvalidFields()
      this.$emit('beforeSubmit')

      this.invalidFields.length 
        ? window.scrollTo(0,0) 
        : this.$emit('submit', this.submitType === 'json' ? this.model : Utility.convertModelToFormData(this.model))

    }
  }
}
</script>

<style lang="scss" scoped>
.DynamicForm {
  grid-area: main;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 25px;
  grid-template-columns: repeat(12, 1fr);

  @media #{$mobile-view} {
    display: block;
  }

  & > * {
    @media #{$mobile-view} {
      margin-bottom: 20px;
      display: block;
    }
  }

  label {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: $font-size-1xmini;
    @media (min-width: $viewport-sm) {
      transform: translateY(100%)
    }
  }
}
</style>