import { auth } from '@/api'
import Cookies from 'js-cookie'
const md5 = require('md5')

export default {
  async signIn({ dispatch }, authData) {
    this.dispatch('loading/setLoading', true)
    const result = await auth.signIn(authData, async (res) => {
      await dispatch('setUser', res.user)
      await dispatch('setAccessToken', res.access_token)
      await dispatch('setModules', res.modules)
      await dispatch('setExpiresAt', res.expires_at)
      await dispatch('setExpired', false)
      return res
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
    return result
  },

  async signInGoogle({ dispatch }, authData) {
    this.dispatch('loading/setLoading', true)
    const result = await auth.signInGoogle(authData, async (res) => {
      await dispatch('setUser', res.user)
      await dispatch('setModules', res.modules)
      await dispatch('setAccessToken', res.access_token)
      await dispatch('setExpiresAt', res.expires_at)
      await dispatch('setExpired', false)
      return res
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
    return result
  },

  signOut({ dispatch }) {
    dispatch('setUser', {})
    dispatch('setAccessToken', '')
    dispatch('setExpiresAt', '')
    dispatch('setModules', '')
    dispatch('resetPermissions')
  },

  setExpired({ commit }, flag){
    commit('SET_EXPIRED', flag)
  },

  setAccessToken({ commit }, access_token) {
    commit('SET_ACCESS_TOKEN', access_token)
    if (access_token) {
      Cookies.set('access_token', access_token, {
        expires: 1,
        secure: process.env.VUE_APP_MODE === 'production',
        sameSite: 'strict'
      })
    } else {
      Cookies.remove('access_token')
    }
  },

  setExpiresAt({ commit }, expires_at) {
    commit('SET_EXPIRES_AT', expires_at)
    localStorage.setItem('expires_at', expires_at)
  },

  setModules({ commit }, modules) {
    commit('SET_MODULES', modules)
    localStorage.setItem('modules', JSON.stringify(modules))
  },

  async setRole({ dispatch }, role) {
    let user = JSON.parse(localStorage.getItem('user'))
    user.roles[0].name = role
    dispatch('setUser', user)
  },

  setPermissions({ commit }, permissions) {
    commit('SET_PERMISSIONS', permissions)
  },

  setUser({ commit }, userData) {
    localStorage.setItem('user', JSON.stringify(userData))
    commit('SET_USER', userData)
  },

  resetPermissions({ commit }) {
    commit('RESET_PERMISSIONS')
  },

  async fetchPermissionsByModules({ commit }, modules) {
    this.dispatch('loading/setLoading', true)
    return await auth.permissionsByModules(modules, (res) => {
      commit('SET_PERMISSIONS_BY_MODULES', res)
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async fetchModulesAndRoleByUser({ dispatch }) {
    this.dispatch('loading/setLoading', true)
    await auth.modulesAndRoleByUser(async res => {
      await dispatch('setRole', res.role)
      await dispatch('setModules', res.modules)
    }, (e) => e, () => this.dispatch('loading/setLoading', false))
  },

  async passwordRecover(ctx, mail) {
    this.dispatch('loading/setLoading', true)
    return await auth.passwordRecover(mail, (res) =>
      res, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async resetPassword(ctx, body) {
    this.dispatch('loading/setLoading', true)
    return await auth.resetPassword(body, (res) =>
      res, (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async changedPermissions({ commit }, changed = true) {
    commit('SET_CHANGED_PREMISSION', changed)
  },

  listenChangesOnUserRole({ dispatch }, userId) {
    window.echo.channel(`user_role.${userId}`)
      .listen('.updated_user_role', async () => {
        const oldUserRoleName = JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name
        if(oldUserRoleName) {
          dispatch('unlistenChangesOnRolePermissions', oldUserRoleName)
        }
        await dispatch('fetchModulesAndRoleByUser')
        dispatch('listenChangesOnRolePermissions', JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name)
        dispatch('changedPermissions')
      })
  },

  listenChangesOnRolePermissions({ dispatch }, userRoleName) {
    window.echo.channel(`role_permissions.${md5(userRoleName)}`)
      .listen('.updated_role_permissions', async () => {
        await dispatch('fetchModulesAndRoleByUser')
        dispatch('changedPermissions')
      })
  },

  unlistenChangesOnUserRole(userId) {
    window.echo.leave(`user_role.${userId}`)
  },

  unlistenChangesOnRolePermissions(ctx, userRoleName) {
    window.echo.leave(`role_permissions.${md5(userRoleName)}`)
  },
}