<template>
  <div class="Textarea" :class="TextareaClass">
    <!--Label -->
    <label v-if="!!label" class="Textarea__Label" :class="labelClass">
      {{ label }}
    </label>
    <textarea
      ref="input"
      v-model="val"
      class="Textarea__Input"
      v-bind="$attrs"
      :style="computedStyles"
      :placeholder="placeholder"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />
    <!-- Error -->
    <p v-if="isInvalid" class="Textarea__invalidLabel">
      {{ invalidLabel }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'BaseTextarea',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'dark',
      validator: (val) => ['dark', 'light'].includes(val),
    },
    invalidLabel: {
      type: String,
      default: ''
    },

    //Component
    autosize: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: Number,
      default: null,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    /*
     * Force !important for style properties
     */
    important: {
      type: [Boolean, Array],
      default: false,
    },
  },
  data() {
    return {
      focus: false,
      val: null,

      // works when content height becomes more then value of the maxHeight property
      maxHeightScroll: false,
      height: 'auto',
    }
  },
  computed: {
    TextareaClass() {
      return [
        !!this.isInvalid && 'Textarea--invalid',
        this.theme && `Textarea__Theme--${this.theme}`,
        this.disabled && 'Textarea--disabled',
      ]
    },
    labelClass() {
      return []
    },

    //Component
    computedStyles() {
      if (!this.autosize) return {}
      return {
        resize: !this.isResizeImportant ? 'none' : 'none !important',
        height: this.height,
        overflow: this.maxHeightScroll ? 'auto' : !this.isOverflowImportant ? 'hidden' : 'hidden !important',
      }
    },
    isResizeImportant() {
      const imp = this.important
      return imp === true || (Array.isArray(imp) && imp.includes('resize'))
    },
    isOverflowImportant() {
      const imp = this.important
      return imp === true || (Array.isArray(imp) && imp.includes('overflow'))
    },
    isHeightImportant() {
      const imp = this.important
      return imp === true || (Array.isArray(imp) && imp.includes('height'))
    },
  },
  watch: {
    value(val) {
      this.val = val
    },
    val(val) {
      this.$nextTick(this.resize)
      this.$emit('input', val)
    },
    minHeight() {
      this.$nextTick(this.resize)
    },
    maxHeight() {
      this.$nextTick(this.resize)
    },
    autosize(val) {
      if (val) this.resize()
    },
  },
  created() {
    this.val = this.value
  },
  mounted() {
    this.resize()
  },
  methods: {
    //Handlers
    onFocus() {
      this.resize()
      this.focus = !this.focus
    },
    onBlur() {
      this.focus = !this.focus
      this.$emit('blur')
    },
    onInput(evt) {
      this.resize()
      const value = evt.target.value
      this.$emit('input', value)
    },

    //Helpers
    resize() {
      const important = this.isHeightImportant ? 'important' : ''
      this.height = `auto${important ? ' !important' : ''}`
      this.$nextTick(() => {
        let contentHeight = this.$refs.input.scrollHeight + 1
        if (this.minHeight) {
          contentHeight =
            contentHeight < this.minHeight ? this.minHeight : contentHeight
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight
            this.maxHeightScroll = true
          } else {
            this.maxHeightScroll = false
          }
        }
        const heightVal = contentHeight + 'px'
        this.height = `${heightVal}${important ? ' !important' : ''}`
      })
      return this
    },
  },
}
</script>
<style lang="scss" scoped>
.Textarea {
  position: relative;
  @media #{$mobile-view} {
    margin-bottom: 20px;
  }

  //Status
  &--disabled {
    .Textarea__Input {
      background: #ededed;
      pointer-events: none;
      opacity: 0.6;
    }
  }

  //Themes
  &__Theme {
    &--dark {
      .Textarea__Label {
        color: $color-neutral-stronger;
      }
      .Textarea__Input {
        border-bottom: 2px solid $color-neutral-stronger;
        color: $color-neutral-stronger;
      }
    }
    &--light {
      .Textarea__Label {
        color: $color-neutral-softest;
      }
      .Textarea__Input {
        border-bottom: 2px solid $color-neutral-softest;
        color: $color-neutral-softest;
      }
    }
  }

  &__Label {
    display: block;
    margin-right: 10px;
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
    transition: transform 0.2s;
    pointer-events: none;
    &--floating {
      position: absolute;
      transform: translateY(5px);
      left: 10px;
    }
    &--upwards {
      transform: translateY(-20px);
    }

    .Textarea--invalid & {
      color: $color-alert-base;
    }
  }

  &__Input {
    padding: 9px;
    width: 100%;
    background: transparent;

    &:disabled {
      background-color: $color-neutral-soft;
    }
    &--has-icon {
      padding-right: 35px;
    }

    .Textarea--invalid & {
      color: $color-alert-base;
      border-bottom: 2px solid $color-alert-base;
    }
  }

  &__invalidLabel {
    padding: 5px 10px;
    font-size: $font-size-1xmini;
    color: $color-alert-base;
    font-weight: $font-weight-bold;
    position: absolute;
  }
}
</style>
