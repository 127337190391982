import request from '@/api/plugins/request'
import objToQuery from '@/api/plugins/query'

export default {
  async fetchUsers({ obj, paginate, page }, success, error, done) {
    const query = objToQuery.parseAll(obj, 'id', 'id')

    return await request({
      method: 'get',
      url: `users?is_table=users&paginate=${paginate}&page=${page}&${query}&sort=desc`
    }, success, error, done)
  },

  async fetchUser({ id }, success, error, done) {
    return await request({
      method: 'get',
      url: `users/${id}?with[]=transportersPivot&with[]=storesPivot&with[]=bankData.requestAccountValidation&with[]=documents&with[]=roles&with[]=information.transitPoint`
    }, success, error, done)
  },

  async fetchUserTypes(success, error) {
    return await request({
      method: 'get',
      url: 'user-types'
    }, success, error)
  },

  async fetchUserEnableCreate(success, error) {
    return await request({
      method: 'get',
      url: 'user-types?enable_create=1&paginate=99'
    }, success, error)
  },

  async createUser(body, success, error, done) {
    return await request({
      method: 'post',
      url: 'users',
      body
    }, success, error, done)
  },

  async deleteUser({ id }, success, error, done) {
    return await request({
      method: 'delete',
      url: `users/${id}`
    }, success, error, done)
  },

  async updateUser({ id, body }, success, error, done) {
    return await request({
      method: 'patch',
      url: `users/${id}`,
      body
    }, success, error, done)
  },

  async linkHubToUser({user_id, body}, success, error, done) {
    return await request({
      method: 'patch',
      url: `users/${user_id}/information/transit-point`,
      body
    }, success, error, done)
  }
}