const Packages = () => import( /* webpackChunkName: 'Packages' */ './Packages.vue')
const PackagesIncoming = () => import( /* webpackChunkName: 'PackagesIncoming' */ './Incoming/PackagesIncoming.vue')
const PackagesHistory = () => import( /* webpackChunkName: 'PackagesHistory' */ './History/PackagesHistory.vue')
const PackagesExport = () => import( /* webpackChunkName: 'PackagesExport' */ './Export/PackagesExport.vue')

const routes = [
  {
    name: 'packages',
    meta:{
      module: 'module_packages'
    },
    path: '/packages',
    component: Packages
  },
  {
    name: 'packagesIncoming',
    meta:{
      module: 'module_packages'
    },
    path: '/packages/incoming',
    component: PackagesIncoming
  },
  {
    name: 'packagesHistory',
    meta:{
      module: 'module_packages'
    },
    path: '/packages/history',
    component: PackagesHistory
  },
  {
    name: 'packagesExport',
    meta:{
      label: 'pacote',
      module: 'module_packages'
    },
    path: '/packages/export',
    component: PackagesExport
  }
]

export default routes