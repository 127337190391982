export default [
  {
    id: 10,
    name: '10'
  },
  {
    id: 20,
    name: '20'
  },
  {
    id: 50,
    name: '50'
  },
  {
    id: 100,
    name: '100'
  },
  {
    id: 200,
    name: '200'
  }
]
