import { tracking } from '@/api'

export default {
  async fetchOrderHistory({ commit }, code) {
    this.dispatch('loading/setLoading', true)
    return await tracking.fetchOrderHistory(
      code,
      (res) => {
        commit('SET_HISTORY', res)
      },
      (e) => e,
      () => this.dispatch('loading/setLoading', false)
    )
  },

  async fetchTrackingWithStoreId({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await tracking.fetchTrackingWithStoreId(
      params,
      res => {
        commit('SET_HISTORY', res)
      },
      e => e,
      () => this.dispatch('loading/setLoading', false)
    )
  },

  async fetchOrdersByDocument({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await tracking.fetchOrdersByDocument(
      params,
      (res) => commit('SET_ORDERS_BY_DOCUMENT', res),
      (e) => e,
      () => this.dispatch('loading/setLoading', false)
    )
  },

  async fetchTrackingComments({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await tracking.fetchTrackingComments(params, (res) => 
      commit('SET_TRACKING_COMMENTS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchAllTrackingComments({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await tracking.fetchAllTrackingComments(params, (res) => 
      commit('SET_TRACKING_COMMENTS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  clearChatMessages({commit}) {
    commit('SET_TRACKING_COMMENTS', [])
  },
}
