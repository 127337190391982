<template>
  <div class="Printer">
    <div class="Printer__Text">
      <p>
        {{ text }}
      </p>
    </div>
    <div class="Printer__Action">
      <BaseButton
        label="Inicializar Impressora"
        color="primary-base"
        filled
        text-color="white"
        @click="$emit('connect')"
      />
    </div>
  </div>
</template>
<script>
import { BaseButton } from '@/components/atoms'

export default {
  name: 'PrinterConnect',
  components: {
    BaseButton
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
}
</script>
<style lang="scss" scoped>
.Printer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;

  &__Text {
    margin-bottom: 2rem;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      margin: 0;
      text-align: center;
      background: $gradient-primary;
    }
  }
}
</style>