const SignIn = () => import(/* webpackChunkName: 'SignIn' */ './SignIn/SignIn.vue')
const ForgotPassword = () => import(/* webpackChunkName: 'ForgotPassword' */ './ForgotPassword/ForgotPassword.vue')

const routes = [
  {
    name: 'signin',
    path: '/auth/signin',
    meta: { public: true, noHeader: true, theme: 'blue' },
    component: SignIn
  },
  {
    name: 'forgotpassword',
    path: '/auth/forgotpassword',
    meta: { public: true, noHeader: true, theme: 'blue' },
    component: ForgotPassword
  }
]

export default routes
