export default class Query {
  static parseAll(obj, objKey = 'name'){
    let query = ''

    for (const key in obj) {
      const flag = typeof obj[key] !== 'undefined' && obj[key]
      
      if (flag || typeof obj[key] === 'boolean') {
        switch (obj[key].constructor.name) {
        case 'Array':
          query += this.parseArray(obj[key],key, objKey)
          break
        case 'Object':
          if(obj[key][objKey]) {
            query += `${key}=${obj[key][objKey]}&`
          } 
          break
        default:
          query += `${key}=${obj[key]}&`
          break
        }
      }
    }
    
    return query = query.substring(0, query.length - 1)
  }

  static parseArray(arr, key, objKey){
    const data = arr.map(param => `${key}[]=${param[objKey]}&`)
    return data.join('')
  }
}