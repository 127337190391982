<template>
  <div class="InputFile">
    <div class="InputFile__Label">
      {{ label }}
    </div>
    <div class="InputFile__Container">
      <input class="InputFile__Input" type="file" :accept="acceptFiles" :value="file" @change="onChange">
      <BaseButton 
        :label="archiveLabel" 
        class="InputFile__Button"
        theme="icon-left" 
        color="primary-base"  
        icon="document"
      />
      <p v-if="isInvalid" class="InputFile__invalidLabel">
        {{ invalidLabel }}
      </p>
    </div>  
  </div>
</template>

<script>
import { BaseButton } from '@/components/atoms'

export default {
  name: 'InputFile',
  components: { BaseButton },
  props: {
    value:{
      type: File,
      default: null
    },
    label:{
      type: String,
      default: 'Label'
    },
    btnLabel:{
      type: String,
      default: 'btn-label'
    },
    acceptFiles:{
      type: String,
      default: ''
    },
    invalidLabel: {
      type: String,
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      archiveLabel: this.btnLabel,
      archive: null,
      file: ''
    }
  },
  computed: {
    InputGroupClass() {
      return [
        {'InputGroup--invalid': !!this.isInvalid}
      ]
    },
  },

  methods: {
    onChange(e){
      const extensions = /(\w+)$/gm
      
      this.archive = e.target.files[0]
      let re = extensions.exec(this.archive.name)

      this.archiveLabel = this.archive.name.length >= 17 ? `${this.archive.name.substring(0,16)}(...).${re[0]}` : this.archive.name
      this.$emit('input', this.archive)
    },

    reset(){
      this.archiveLabel = this.btnLabel
      this.archive = null
      this.file = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.InputFile {
  width: 100%;
  &__Label{
    font-size: $font-size-1xmini;
    font-weight: $font-weight-bold;
  }
  &__Container{
    position: relative;
    margin-top: 3px;
  }
  &__Input{
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: $z-selected;
    cursor: pointer;
  }
  &__Button{
    width: 100%;
  }
   &__invalidLabel {
    padding: 5px 10px;
    font-size: $font-size-1xmini;
    color: $color-alert-base;
    font-weight: $font-weight-bold;
    position: absolute;
  }
}
</style>
