const Sellers = () => import( /* webpackChunkName: 'Sellers' */ './Sellers.vue')
const SellersVips = () => import( /* webpackChunkName: 'SellersVips' */ './Vips/SellersVips.vue')
const SellersForm = () => import( /* webpackChunkName: 'SellersForm' */ './Form/SellersForm.vue')

const routes = [
  {
    name: 'sellers',
    meta:{
      module: 'module_sellers'
    },
    path: '/sellers',
    component: Sellers
  },
  {
    name: 'sellersVips',
    meta:{
      module: 'module_sellers'
    },
    path: '/sellers/vips',
    component: SellersVips
  },
  {
    name: 'sellersDetail',
    meta:{
      module: 'module_sellers'
    },
    path: '/sellers/:id',
    component: SellersForm
  },
]


export default routes