const baseTreeSelectMixin = {
  props: {
    //Base Helper
    debounceTime: {
      type: Number,
      default: 500,
    },
    enableBipping: {
      type: Boolean,
      default: false,
    },
    enablePaste: {
      type: Boolean,
      default: false,
    },

    //Component Helper
    selectOptions: {
      type: Array,
      default: () => [],
    },
    fetchOptions: {
      type: String,
      default: ''
    },
    fetchParams: {
      type: [String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    suggestions: {
      type: [String, Boolean],
      default: false,
    },
    suggestionsParams: {
      type: [String, Number],
      default: '',
    },
    minSearchLength: {
      type: Number,
      default: 3,
    },
    tags: {
      type: Boolean,
      default: false
    },
    persistTags: {
      type: Boolean,
      default: false
    },
    preserveHeight: {
      type: Boolean,
      default: false
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    allowEmptyOptions: {
      type: Boolean,
      default: false,
    },
    allowEmptyOptionsText: {
      type: String,
      default: 'Digite o item a ser adicionado'
    },
    autoSelect: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: function () {
        return this.multiple ? true : false
      }
    },
    returnOnly: {
      type: Array,
      default: () => []
    },

    //Component
    allowClearingDisabled: {
      type: Boolean,
      default: false,
    },
    allowSelectingDisabledDescendants: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    async: {
      type: Boolean,
      default: function () {
        return this.suggestions ? true : false
      }
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    autoLoadRootOptions: {
      type: Boolean,
      default: true,
    },
    autoDeselectAncestors: {
      type: Boolean,
      default: false,
    },
    autoDeselectDescendants: {
      type: Boolean,
      default: false,
    },
    autoSelectAncestors: {
      type: Boolean,
      default: false,
    },
    autoSelectDescendants: {
      type: Boolean,
      default: false,
    },
    backspaceRemoves: {
      type: Boolean,
      default: false,
    },
    beforeClearAll: {
      type: Function,
      default: function() {
        this.$emit('clear')
        return true
      }
    },
    branchNodesFirst: {
      type: Boolean,
      default: false,
    },
    cacheOptions: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    clearAllText: {
      type: String,
      default: 'Limpar todos',
    },
    clearOnSelect: {
      type: Boolean,
      default: function() {
        return this.multiple ? false : true
      },
    },
    clearValueText: {
      type: String,
      default: 'Limpar valor',
    },
    defaultExpandLevel: {
      type: Number,
      default: 0,
    },
    defaultOptions: {
      type: [Array, Boolean],
      default: false
    },
    deleteRemoves: {
      type: Boolean, 
      default: false,
    },
    delimiter: {
      type: String,
      default: ','
    },
    flattenSearchResults: {
      type: Boolean,
      default: false
    },
    disableBranchNodes: {
      type: Boolean,
      default: function() {
        return this.multiple ? false : true
      },
    },
    disableFuzzyMatching: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    joinValues: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: Infinity
    },
    limitText: {
      type: Function,
      default: (count) =>
        `${count} ${count > 1 ? 'opções' : 'opção'} selecionada${count > 1 ? 's' : ''}`
    },
    loadingText: {
      type: String,
      default: 'Procurando...'
    },
    // loadOptions: {
    //   type: Function,
    //   default: () => {}
    // },
    matchKeys: {
      type: [Array, String],
      default: () => {
        return [
          'label'
        ]
      }
    },
    maxHeight: {
      type: Number,
      default: 300
    },
    noChildrenText: {
      type: String,
      default: 'Sem opções'
    },
    noOptionsText: {
      type: String,
      default: function() {
        return !this.allowEmptyOptions
          ? 'Nenhuma opção disponível'
          : this.allowEmptyOptionsText
      },     
    },
    noResultsText: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    normalizer: {
      type: Function,
      default: (node) => {
        if (Array.isArray(node)) {
          node = node[0]
        }
        if (!node?.id && !node?.name) {
          return null
        }
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      }
    },
    openDirection: {
      type: String,
      default: 'auto',
      validator: (val) => ['auto', 'below', 'bottom', 'above', 'top'].includes(val),
    },
    openOnClick: {
      type: Boolean,
      default: true
    },
    openOnFocus: {
      type: Boolean,
      default: false
    },
    retryText: {
      type: String,
      default: 'Tentar novamente'
    },
    retryTitle: {
      type: String,
      default: 'Clique para tentar novamente'
    },
    searchable: {
      type: Boolean,
      default: true
    },
    searchNested: {
      type: Boolean,
      default: false
    },
    searchPromptText: {
      type: String,
      default: 'Digite para procurar...'
    },
    showCount: {
      type: Boolean,
      default: false
    },
    showCountOf: {
      type: String,
      default: 'ALL_CHILDREN',
      validator: (val) => ['ALL_CHILDREN', 'ALL_DESCENDANTS', 'LEAF_CHILDREN', 'LEAF_DESCENDANTS'].includes(val),
    },
    showCountOnSearch: {
      type: Boolean,
      default: false
    },
    sortValueBy: {
      type: String,
      default: 'ORDER_SELECTED',
      validator: (val) => ['ORDER_SELECTED', 'LEVEL', 'INDEX'].includes(val),
    },
    valueConsistsOf: {
      type: String,
      default: 'LEAF_PRIORITY',
      validator: (val) => ['ALL', 'BRANCH_PRIORITY', 'LEAF_PRIORITY', 'ALL_WITH_INDETERMINATE'].includes(val),
    },
    valueFormat: {
      type: String,
      default: 'object'
    },
    zIndex: {
      type: [Number, String],
      default: 2000
    }
  },
}

export default baseTreeSelectMixin
