export default {
  readyVehicles(state) {    
    return { ...state.vehicles,
      data: { 
        ...state.vehicles.data, 
        headers: {
          ...state.vehicles.data.headers,
          actions: false
        }
      } 
    }
  },
}