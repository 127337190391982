import { financial } from '@/api'

export default {
  async fetchPaymentClosingHistory({ commit }, params) {
    return await financial.fetchPaymentClosingHistory(params, (res) =>
      commit('SET_PAYMENT_CLOSING_HISTORY', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchFinancialImportErrors({commit}, id){
    this.dispatch('loading/setLoading', true)
    return await financial.fetchFinancialImportErrors(id, (res) => 
      commit('SET_PAYMENT_IMPORT_ERRORS', res), (e) => e, () => 
      this.dispatch('loading/setLoading', false))
  },

  async fetchPaymentUploadHistory({commit}, params) {
    return await financial.fetchPaymentUploadHistory(params, (res) => 
      commit('SET_PAYMENT_UPLOAD_HISTORY', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchOperationConsolidate({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchFinancialConsolidation(params, (res) => 
      commit('SET_OPERATION_CONSOLIDATE', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchMasterManagerApprove({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.getMasterManagerApprove(params, (res) => 
      commit('SET_MASTER_MANAGER_APPROVE', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchAnalystsComments({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchAnalystsComments(params, (res) => 
      commit('SET_ANALYSTS_COMMENTS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  clearChatMessages({commit}) {
    let payload = { data: { headers: {}, content: [] } }
    commit('SET_ANALYSTS_COMMENTS', payload)
  },

  async fetchFinancialConsolidatedHistory({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchFinancialConsolidatedHistory(params, (res) => 
      commit('SET_FINANCIAL_CONSOLIDATE_HISTORY', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchAnalystReview({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchAnalystReview(params, (res) => 
      commit('SET_ANALYST_REVIEW', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },
  

  async fetchFortnight({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchFortnight(params, (res) => 
      commit('SET_FORTNIGHT', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchUploadHistoryEdit({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchUploadHistoryEdit(params, (res) => 
      commit('SET_UPLOAD_HISTORY_EDIT', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchDuplicateHistoryLines({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchDuplicateHistoryLines(params, (res) => 
      commit('SET_DUPLICATE_HISTORY_LINES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchStatusList({commit}, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchStatusList(params, (res) => 
      commit('SET_STATUS_LIST', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchOnlineErrors({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchOnlineErrors(params, (res) => 
      commit('SET_ONLINE_ERRORS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchReceiptsHistory({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchReceiptsHistory(params, (res) => 
      commit('SET_RECEIPTS', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchVehiclesEdit({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchVehiclesEdit(params, (res) => 
      commit('SET_VEHICLES_EDIT', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchVehiclesTypes({ commit }) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchVehiclesTypes((res) => 
      commit('SET_VEHICLES_TYPES', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchFieldErrorsList({ commit }) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchFieldErrorsList((res) => 
      commit('SET_FIELD_ERRORS_LIST', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  },

  async fetchFinancialApprove({ commit }, params) {
    this.dispatch('loading/setLoading', true)
    return await financial.fetchFinancialApprove(params, (res) => 
      commit('SET_FINANCIAL_APPROVE', res), (e) => e, () =>
      this.dispatch('loading/setLoading', false))
  }
}