<template>
  <!-- Modal -->
  <Modal v-if="modal" class="Modal Location" @close="close">
    <!-- Header -->
    <template #header>
      <div class="Location__Name">
        <div>{{ titleFormatted }}: <strong>{{ map.name }}</strong></div>
      </div>        
    </template>
    <!-- Body -->
    <template #body>
      <!-- Map -->
      <BaseMap ref="map" type="seller" :markers="mapMarker" hide-details-button draggable :zoom="16" @dragend="changeLocationMap" />

      <!-- Actions -->
      <div class="Location__Actions">
        <!-- LatLng -->
        <div class="Location__Actions__LatLong">
          <InputGroup
            :value="map.lat"
            label="Latitude"
            name="Latitude"
            placeholder="Digite a Latitude"
            filled="white"
            @debounce="changeLocationTempInput($event, 'lat')"
          />
          <InputGroup
            :value="map.lng"
            label="Longitude"
            name="Longitude"
            placeholder="Digite a Longitude"
            filled="white"
            @debounce="changeLocationTempInput($event, 'lng')"
          />
          <InputGroup
            :value="map.geolocation_radius"
            label="Raio"
            name="Raio"
            placeholder="Digite a radio"
            filled="white"
            @debounce="changeLocationTempInput($event, 'radius')"
          />
        </div>
        <!-- Save -->
        <BaseButton
          v-if="showSaveButton"
          class="Location__Actions__Save"
          icon="pin-check"
          color="primary-base"
          text-color="white"
          filled
          label="Salvar nova localização"
          @click="save"
        />
      </div>
    </template>
  </Modal>
</template>
<script>
import { BaseButton } from '@/components/atoms'
import { Modal, BaseMap, InputGroup } from '@/components/molecules'

export default {
  name: 'LocationChange',
  components: {
    BaseButton,
    BaseMap,
    InputGroup,
    Modal
  },
  props: {
    title: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      //Map
      map: {},

      //Helpers
      modal: false,
      showSaveButton: false,
    }
  },
  computed:{
    mapMarker() {
      return [{
        info: {
          vehicle_type: 'seller'
        },
        position: {
          lat: this.map.lat,
          lng: this.map.lng
        },
        radius: this.map.geolocation_radius
      }]
    },

    titleFormatted() {
      return `Alterar localização${this.title !== null ? ` ${this.title}` : ''}`
    }
  },
  watch: {
    data(val) {
      if (val) {
        this.setLocation()
      }
    }
  },
  methods: {

    //Location
    setLocation() {
      if(!this.data) return
      
      this.map = {
        name: this.data.name ? this.data.name : null,
        id: this.data.id ? this.data.id : null,
        lat: this.data.lat ? parseFloat(this.data.lat).toFixed(6) : null,
        lng: this.data.lng ? parseFloat(this.data.lng).toFixed(6) : null,
        geolocation_radius: this.data.geolocation_radius ? this.data.geolocation_radius : 1000,
        seller_code: this.data.seller_code ? this.data.seller_code : null,
      }
    },

    changeLocationMap(data) {
      if (!data) return

      this.showSaveButton = true
      this.map = {
        name: this.map.name,
        id: this.map.id,
        lat: data.lat ? parseFloat(data.lat).toFixed(6) : data.lat,
        lng: data.lng ? parseFloat(data.lng).toFixed(6) : data.lng,
        geolocation_radius: this.map.geolocation_radius,
        seller_code: this.map.seller_code,
      }
    },

    changeLocationTempInput(val, type) {
      if (!val) return

      this.showSaveButton = true
      type === 'lat' ? this.map.lat = parseFloat(val).toFixed(6) : ''
      type === 'lng' ? this.map.lng = parseFloat(val).toFixed(6) : ''
      type === 'radius' ? this.map.geolocation_radius = parseInt(val) : 1000
    },

    //Handlers
    open() {
      this.modal = true
      this.$emit('open')
    },
    close() {
      this.reset()
      this.$emit('close')
    },
    save() {
      this.reset()
      this.$emit('save', this.map)
    },

    //Reset
    reset() {
      this.modal = false
      this.showSaveButton = false
    }
  }
}
</script>
<style lang="scss" scoped>
.Location {
  /deep/ .Modal__container {
    position: relative;
  }
  &__Name {
    // font-weight: $font-weight-bold;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 8px;
  }

  &__Actions {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 400px;
    transform: translateX(-50%);

    &__LatLong {
      display: flex;
      gap: 10px;
      background: $color-white;
      box-shadow: $shadow-higher;
      border-radius: $border-radius-1xsmall;
      padding: $spacing-inline-small;
    }

    &__Save {
      margin-top: 10px;
      &:hover {
        opacity: 1
      }
    }

  }
}
</style>