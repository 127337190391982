export default {
  SET_USER(state, users) {
    state.users = users
  },

  SET_USER_TYPES(state, userTypes) {
    state.userTypes = userTypes
  },

  SET_USER_ENABLE_CREATE(state, userTypes) {
    state.userEnableCreate = userTypes
  },

  DELETE_USER(state, deletedUser){
    state.users.data.content = state.users.data.content.filter((user) => user.id !== deletedUser.id)
  },
}
