<template>
  <div class="chatMessanger-wrapper">
    <Modal v-if="chat" @close="close">
      <template #header>
        <div class="modal-title">
          Mensagens
        </div>
      </template>

      <template #body>
        <div class="modal-content">
          <div ref="msg" class="modal-messages">
            <div v-if="!messages.length" class="no-messages">
              <p>{{ emptyMessagesText }}</p>
            </div>
            <ul>
              <li
                v-for="(item, key) in messages"
                :key="key"
              >
                <div class="message">
                  <div
                    v-if="item.file_path"
                    :class="itemFile(item).class"
                    @click="handleItemFile(item)"
                  >
                    <a v-if="!itemFile(item).isTypeImage" class="download" :href="item.file_path" :download="item.original_name" />
                    <picture>
                      <img v-if="itemFile(item).isTypeImage" class="picture" :src="item.file_path">
                      <Icon :color="itemFile(item).icon.color" class="icon" :name="itemFile(item).icon.name" />
                    </picture>
                    <small>{{ item.original_name }}</small>                    
                  </div>
                  <p class="text">
                    {{ item.message || item.text }}
                  </p>
                </div>
                <p v-if="item.status" class="status">
                  status: <strong> {{ item.status }} </strong>
                </p>
                <p class="author">
                  por <strong> {{ item["user.name"] || item.user && item.user.name || 'Usuário' }} </strong> -  {{ formatDate(item.created_at) }}
                </p>
              </li>
            </ul>
          </div>

          <!-- File preview -->
          <BaseInputFileListItem
            v-if="acceptFile && formDataFiles.length > 0"
            class="filePreview"
            :file="formDataFiles[0]"
            :list="true"
            :actions="false"
            :has-progress="false"
            @remove="handleRemove"
            @confirmRemove="handleRemove"
          />

          <form v-if="!readOnly" class="modal-sending-message" type="submit" @submit.prevent="send">
            <component
              :is="inputType"
              v-model="input"
              :select-options="suggestions"
              :append-to-body="true"
              :z-index="9999"
              class="input-chat"
              type="text"
              placeholder="Digite aqui sua mensagem"
              @input="onInput"
            />

            <div v-if="acceptFile" :class="inputFileWrapperClass">
              <input
                type="file"
                class="inputFile"
                :disabled="formDataFiles.length > 0"
                @input="uploadFile($event)"
              >

              <Icon class="iconFile" color="dark" name="attachment" />
            </div>

            <BaseButton
              label="Enviar"
              color="dark"
              filled
              text-color="white"
              :disabled="disabled"
              type="submit"
            />
          </form>
        </div>
      </template>
    </Modal>

    <Dialog
      v-if="dialogSizeShow"
      warning="O tamanho do arquivo deve ser menor que 25MB"
      label-cancel=""
      @cancel="dialogSizeShow = false"
      @confirm="dialogSizeShow = false"
    />
  </div>
</template>

<script>
import { Modal, Dialog } from '@/components/molecules'
import { BaseButton, BaseInputFileListItem, BaseTreeSelect } from '@/components/atoms'
import BaseInputFileMixin from '@/mixins/baseInputFileMixin'
import { InputGroup } from '@/components/molecules'
import Mask from '@/plugins/mask/Mask.js'
import { Icon } from '@/components/photons'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  name: 'ChatMessenger',

  components: {
    Modal,
    Dialog,
    BaseButton,
    BaseInputFileListItem,
    Icon,
    InputGroup,
    BaseTreeSelect
  },
  mixins: [BaseInputFileMixin],
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    acceptFile: {
      type: Boolean,
      default: false
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    disableSend: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      input: null,
      activeLine: null,
      chat: false,
      readOnly: false,
      currentStatus: STATUS_INITIAL,
      uploadError: null,
      dialogSizeShow: false
    }
  },

  computed: {
    emptyMessagesText() {
      let text = 'Ainda não existem mensagens para esta solicitação'

      if (!this.readOnly) text+= ', digite uma nova mensagem para iniciar!'

      return text
    },
    status() {
      return {
        initial: this.currentStatus === STATUS_INITIAL,
        saving: this.currentStatus === STATUS_SAVING,
        success: this.currentStatus === STATUS_SUCCESS,
        failed: this.currentStatus === STATUS_FAILED
      }
    },
    inputFileWrapperClass() {
      return [
        'inputFileWrapper',
        this.formDataFiles.length > 0 ? 'inputFileWrapper--disabled' : ''
      ]
    },

    hasSuggestions() {
      return !!this.suggestions.length
    },

    inputType() {
      return this.hasSuggestions ? 'BaseTreeSelect' : 'InputGroup' 
    },

    disabled() {
      return !this.input || (!this.input && (this.acceptFile && !(this.formDataFiles.length > 0))) || this.disableSend
    }
  },

  watch: {
    formDataFiles(oldVal, newVal) {
      this.$emit('hasFile', newVal.length > 0)
    }
  },

  methods: {
    formatDate(item) {
      return `${Mask.millisecondsToDate(item)} às ${Mask.millisecondsToHour(item)}`
    },

    open(id, data) {
      this.activeLine = id
      this.$emit('fetchMessages', id, data)
      this.readOnly = data?.readOnly
      this.chat = true
      this.$emit('isOpen', true)
    },

    close() {
      this.chat = false
      this.resetInput()
      this.$emit('isOpen', false)
    },

    scrollMessages() {
      let container = this.$refs.msg
      container.scrollTop = container.scrollHeight + 150
    },

    async send() {
      const file = this.acceptFile ? this.formDataFiles[0] : null
      this.$emit('sendMessage', this.activeLine, this.hasSuggestions ? this.input.name : this.input, file)
    },

    resetInput() {
      this.input = null
      this.handleRemove(this.formDataFiles[0])
      this.formDataModel = new FormData()
      this.formDataFiles = []
    },

    resetFile() {
      this.currentStatus = STATUS_INITIAL
      this.uploadError = null
    },

    onInputFile() {
      this.$emit('inputFile', this.formDataModel, this.formDataName)
    },

    onInput(input) {
      this.$emit('input', input)
    },

    handleFile() {
      this.currentStatus = STATUS_SAVING

      this.setFormDataFiles()
        .then(async () => {
          this.currentStatus = STATUS_SUCCESS
          this.onInputFile()
        })
        .catch((err) => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
        })
    },

    uploadFile(data) {
      if(this.formDataFiles.length) {
        this.handleRemove(this.formDataFiles[0])
      }
      const fileList = data.target.files
      if (!fileList.length) return

      if(fileList[0].size > (25 * 1024 * 1024 )) { // 25MB
        this.dialogSizeShow = true
        return
      }

      this.populateFormData(fileList)   
      this.handleFile()
    },

    handleRemove(data) {
      this.deleteFileFormData(data)

      if (this.formDataFiles.length === 0) {
        this.resetFile()
      }

      this.onInputFile()
    },

    itemFile(item) {
      let isTypeImage = item.mimetype.indexOf('image') >= 0
      return {
        isTypeImage: isTypeImage,
        icon: {
          name: isTypeImage ? 'eye' : 'download',
          color: isTypeImage ? 'white' : 'dark'
        },
        class: isTypeImage ? 'image' : 'file',
      }
    },

    handleItemFile(item) {
      if (this.itemFile(item).isTypeImage) {
        this.$emit('openImage', item.original_name, 'file_path', item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chatMessanger-wrapper {
  /deep/.Modal__container {
    width: 100%;
    max-width: 500px;
    height: auto;

    .modal-title {
      line-height: $line-height-large;
      text-align: center;
    }
    
    .modal-content {
      .modal-messages {
        height: 400px;
        overflow-y: scroll;
        width: 100%;
        max-width: 500px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 15px;
            box-sizing: border-box;
            &:nth-child(odd) {
              background-color: #f2f2f2;
            }

            .message {
              width: 100%;
              display: flex;
              gap: 15px;
            }

            .image, .file {
              width: calc(160px * 1.25);
              height: auto;
              position: relative;
              background-size: cover;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border: 2px solid #000;
              flex-shrink: 0;
              cursor: pointer;

              &:hover {
                &:before {
                  opacity: 1;
                }
                small {
                  bottom: 0;
                }
                img.icon {
                  opacity: 1;
                }
              }

              &:before {
                content: "";
                background: rgba(#000, 0.5);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                opacity: 0;
                transition: all 0.3s ease;
                z-index: 1;
              }

              picture {
                display: flex;
                align-items: center;
                justify-content: center;
                img.picture {
                  width: 100%;
                  display: block;
                }

                img.icon {
                  position: absolute;
                  width: 40px;
                  opacity: 0;
                  transition: all 0.3s ease;
                  z-index: 10;
                }
              }

              small {
                position: absolute;
                bottom: -50%;
                box-sizing: border-box;
                padding: 5px;
                background: #333;
                color: $color-neutral-softest;
                width: 100%;
                font-size: $font-size-2xmini;
                transition: all 0.3s ease;
                z-index: 2;
                word-break: break-all;
              }
            }
            .file {
              height: auto;
              &:before {
                display: none;
              }
              picture {
                height: 100px;
                img.icon {
                  opacity: 1;
                }
              }
              small {
                bottom: initial;
                position: relative;
              }

              .download {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 20;
              }
            }

            .text {
              color: #424141;
              font-size: $font-size-1xsmall;
              line-height: $line-height-2xsmall;
              word-wrap: break-word;
              font-style: unset;
              width: auto;
              display: block;
            }

            .author {
              color: #8f8f8f;
              text-align: right;
              margin-top: 15px;
              font-size: $font-size-mini;
              font-style: italic;
              width: 100%;
            }

            .status {
              color: #8f8f8f;
              text-align: right;
              margin: 15px 0 -10px;
              font-size: 0.8rem;
              font-style: italic;
            }
          }
        }
      }

      .modal-sending-message {
        display: flex;
        padding: 10px 20px 20px;

        .input-chat {
          width: 80%;
          margin: 5px 0 0 -15px;
          padding: 0 12px 0 15px;
        }

        button {
          max-width: 150px;
        }
      }

      .no-messages {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;

        p {
          padding: 20px;
          border-radius: 5px;
          margin: 0 20px 6px;
          background-color: #e5e5e5;
        }
      }

      .inputFileWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;

        &--disabled {
          opacity: 0.3;
          cursor: not-allowed !important;
          pointer-events: none;
        } 

        .inputFile {
          opacity: 0;
          width: 1.2rem;
          height: 1.2rem;
          position: absolute;
          z-index: 1;
          cursor: pointer;
          font-size: 0;
        }

        .iconFile {
          width: 1.2rem;
          height: 1.2rem;
        }
      }

      .filePreview {
        padding: 5px 24px 0 18px;
      }
    }
  }
}
</style>