import request from '@/api/plugins/request'
import download from '@/api/plugins/download'

export default {
  // Users
  async exportFile(url, body, success, error, done) {
    return await request({
      method: 'post',
      url,
      body
    }, success, error, done)
  },

  async getFile({ url, id }, success, error) {
    return await download({
      method: 'get',
      url: `${url}/${id}`
    }, success, error)
  },
}