export default {
  ordersHistory: {
    authenticationRequired: false,
    data:{
      headers: {
        actions: {}
      },
      content: [],
      append: []
    },
    pagination:{
      last_page: 0
    }
  },
  ordersByDocument: {
    data:{
      headers: {},
      content: []
    }
  },
  trackingComments: []
}